import {Box, Flex} from '@indoqa/style-system'
import {IStyle} from 'fela'
import * as React from 'react'
import {useFela} from 'react-fela'
import {Link} from 'react-router-dom'
import {textColorLinksRules, undecoratedLinksRules} from '../../../app/mixins'
import {Theme} from '../../../app/theme'
import {CollapseIcon} from './CollapseIcon'

interface Props {
  name: string
  link: string
  collapsed?: boolean
  setCollapsed?: (nextCollapsed: boolean) => void
}

export const ResultGroupTemplate: React.FC<Props> = ({name, link, collapsed, setCollapsed}) => {
  const {theme} = useFela<Theme>()
  const cursorStyle: IStyle = {
    cursor: 's-resize',
  }
  const markStyle: any = {
    '& mark': {
      backgroundColor: theme.colors.bgMarkedInText,
    },
  }
  return (
    <Flex<Theme>
      bg="bgContentEmphasised"
      px={2}
      pt={2}
      pb={1}
      mb={3}
      fullWidth
      style={[cursorStyle, undecoratedLinksRules(), textColorLinksRules(theme), markStyle]}
      fontStyle="brandBold"
      fontSize="big"
      justifyContent="space-between"
      nowrap
      htmlAttrs={{
        onClick: () => setCollapsed?.(!collapsed),
      }}
    >
      <Box>
        <Link to={link}>
          <span dangerouslySetInnerHTML={{__html: name}} />
        </Link>
      </Box>
      {collapsed !== undefined && <CollapseIcon collapsed={collapsed} toggle={() => setCollapsed?.(!collapsed)} />}
    </Flex>
  )
}
