import {Button, Type} from '../../../commons/components/button/Button'

export const ArCallToActionButton = () => {
  return (
    <Button
      type={Type.CTA}
      linkTo={() => {
        window.open('https://shop.oegbverlag.at/arbeitsrecht-fuer-betriebsraete-plus/')
      }}
      text="Jetzt bestellen!"
    />
  )
}
