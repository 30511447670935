export function findScrollParent(node: HTMLElement | null): HTMLElement | null {
  if (node == null) {
    return null
  }
  if (node.scrollHeight > node.clientHeight) {
    return node
  } else {
    return findScrollParent(node.parentElement)
  }
}
