import {Box} from '@indoqa/style-system'
import * as React from 'react'
import 'react-datepicker/dist/react-datepicker.css'
import {ValidityDatePicker} from '../../commons/components/data-picker/ValidityDatePicker'

export const UIE_ValidityDatePicker: React.FC = () => {
  return (
    <Box bg="primaryLight" color="textInverted" p={3}>
      <ValidityDatePicker date={new Date()} onChange={(nextDate) => console.log('nextDate', nextDate)} />
    </Box>
  )
}
