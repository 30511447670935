import * as React from 'react'
import {useFela} from 'react-fela'
import {Theme} from '../../../../app/theme'
import {RenderDigitalNodeProps} from '../../../../commons/components/structure/digitalStructure.types'
import {
  ReaderLayoutContext,
  ReaderLayoutContextValue,
} from '../../../../commons/layouts/reader-layout/ReaderLayoutContext'
import {StructureNode} from '../../../../commons/types/Structure'
import {isMobile} from '../../../../commons/utils/isMobile'

export const useLoadStructureSlugContent = <S extends StructureNode>(props: RenderDigitalNodeProps<S>) => {
  const {theme} = useFela<Theme>()
  const {structure, eventHandlers, loadDigitalContent, inNodePath, selected} = props
  const slug = structure.slug
  const {toggleNav} = React.useContext<ReaderLayoutContextValue>(ReaderLayoutContext)
  React.useEffect(() => {
    const onClickDisposable = eventHandlers.click.on(() => {
      loadDigitalContent(slug!)
      if (isMobile(theme)) toggleNav()
    })
    return () => {
      onClickDisposable.dispose()
    }
  }, [eventHandlers, loadDigitalContent, toggleNav, slug, inNodePath, selected, theme])
}
