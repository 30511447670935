import * as React from 'react'
import {RenderDigitalNodeProps} from '../../../commons/components/structure/digitalStructure.types'
import {DigitalStructureLeafTemplate} from '../../../commons/components/structure/DigitalStructureLeafTemplate'
import {useLoadStructureContent} from '../../../commons/components/structure/useLoadStructureContent'
import {DocsetStructureNode} from '../../store/kv.types'

export const KvStructureLeafPanel: React.FC<RenderDigitalNodeProps<DocsetStructureNode>> = (props) => {
  const {inNodePath, selected, structure, createDigitalLink} = props
  const inactive = structure.noContent
  useLoadStructureContent(props)
  const createLink =
    createDigitalLink && !structure.noContent ? () => createDigitalLink(props.structure.link.id) : undefined
  return (
    <DigitalStructureLeafTemplate
      label={props.structure.link.name}
      selected={inNodePath || selected}
      paddingRight={props.style.padding}
      createLink={createLink}
      inactive={inactive}
    />
  )
}
