import querystring from 'query-string'
import {cleanUrlPathPart as c} from '../../commons/utils/urlUtils'
import {EBooksFilters} from './book.types'

export interface BookWebBookQueryParams {
  paraId?: string
}

export const bookPathParams = {
  bookId: 'bookId',
  chapterSlug: 'chapterSlug',
  chapterId: 'chapterId',
  bookTitle: 'bookTitle',
  chapterName: 'chapterName',
}

export const bookQueryParams = {
  userQuery: 'q',
  paraId: 'p',
  reihe: 'reihe',
}

const BASE_PATH = '/buecher'

// *** Overview ********************************************************************************************************
export const bookPathOverview = (userQuery?: string | null, filters?: EBooksFilters): string => {
  const queryParams: {[key: string]: string} = {}

  if (userQuery) {
    queryParams[bookQueryParams.userQuery] = userQuery
  }
  if (filters) {
    if (filters.reihe) {
      queryParams[bookQueryParams.reihe] = filters.reihe
    }
  }

  const data = querystring.stringify(queryParams)
  return `${BASE_PATH}${data ? '?' + data : ''}`
}

// *** eBook ***********************************************************************************************************

export const bookPathEbook = (id: string, title: string): string => {
  return `${BASE_PATH}/e/${id}/${c(title)}`
}

export const bookPathEbookWithSubtitle = (id: string, title: string, subtitle?: string | string[]): string => {
  const subtitlePathElement = subtitle ? (Array.isArray(subtitle) ? subtitle.join('-') : subtitle) : undefined
  return bookPathEbook(id, `${title}${subtitle ? '_' + c(subtitlePathElement) : ''}`)
}

export const bookResourceEbookCover = (id: string): string => {
  return `/api/v1/webc/ebooks/${id}/cover`
}

export const bookResourcePdfReader = (id: string): string => {
  return `/pdf-viewer/viewer.html?file=/api/v1/webc/ebooks/${id}/content`
}

// *** Webbook with Slug *************************************************************************************************

export const bookPathWebBookSlugId = (bookId: string): string => {
  return `${BASE_PATH}/w/${bookId}`
}

export const bookPathWebBookSlug = (bookId: string, chapterSlug: string, params?: BookWebBookQueryParams): string => {
  return `${BASE_PATH}/w/${bookId}/${c(chapterSlug)}${createWebBookQueryString(params)}`
}

const createWebBookQueryString = (params?: BookWebBookQueryParams) => {
  if (!params) {
    return ''
  }
  const queryParams: {[key: string]: string} = {}
  const {paraId} = params
  if (paraId) {
    queryParams[bookQueryParams.paraId] = paraId
  }
  return `?${querystring.stringify(queryParams)}`
}

// *** Webbook with ID *************************************************************************************************

export const bookPathWebBookIdId = (bookId: string): string => {
  return `${BASE_PATH}/w-id/${bookId}`
}

export const bookPathWebBookId = (
  bookId: string,
  chapterId: string,
  bookTitle: string,
  chapterName: string,
  params?: BookWebBookQueryParams
): string => {
  return `${BASE_PATH}/w-id/${bookId},${chapterId}/${c(bookTitle)}/${c(chapterName)}${createWebBookQueryString(params)}`
}
