import {useQueryParam} from '@oegbv/ui-shared'
import * as React from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {useParams} from 'react-router-dom'
import {AlignedContentPanel} from '../../commons/components/content/AlignedContentPanel'
import {ContentPanel} from '../../commons/components/content/ContentPanel'
import {ReaderFooterPanel} from '../../commons/components/footer/ReaderFooterPanel'
import {DigitalHelmet} from '../../commons/components/helmet/DigitalHelmet'
import {FeatureToggle} from '../../commons/feature-toggle/FeatureToggle'
import {ReaderLayout} from '../../commons/layouts/reader-layout/ReaderLayout'
import {useError} from '../../error/hooks/useError'
import {ErrorPage} from '../../error/pages/ErrorPage'
import {BookWebbookIdReaderHeaderPanel} from '../components/webbooks-id/header/BookWebbookIdReaderHeaderPanel'
import {BookWebbookIdStructurePanel} from '../components/webbooks-id/structure/BookWebbookIdStructurePanel'
import {bookLoadWebBookContent} from '../store/book.actions'
import {bookPathWebBookId, bookQueryParams} from '../store/book.paths'
import {selectWebBookContent, selectWebBookDocumentInfo, selectWebBookStructure} from '../store/book.selectors'

interface LocationParams {
  bookId: string
  chapterId: string
}

export const BookWebBookIdReaderPage: React.FC = () => {
  const {bookId, chapterId} = useParams<LocationParams>()
  const paraId = useQueryParam(bookQueryParams.paraId)
  const dispatch = useDispatch()
  React.useEffect(() => {
    if (bookId) {
      dispatch(bookLoadWebBookContent(bookId, chapterId, paraId ?? undefined))
    }
  }, [dispatch, bookId, chapterId, paraId])

  const structure = useSelector(selectWebBookStructure)
  const content = useSelector(selectWebBookContent)
  const documentInfo = useSelector(selectWebBookDocumentInfo)

  // error handling
  const hasError = useError()
  if (hasError) {
    return <ErrorPage />
  }

  if (!structure || !paraId || !bookId || !chapterId || !documentInfo) {
    return null
  }

  const bookTitle = structure ? structure.link.name : ''
  const chapterTitle = documentInfo ? documentInfo.documentName || '' : ''
  const header = <BookWebbookIdReaderHeaderPanel bookTitle={bookTitle} bookId={bookId} documentInfo={documentInfo} />
  const footer = <ReaderFooterPanel />
  const nav = <BookWebbookIdStructurePanel structure={structure} paraId={paraId} />
  return (
    <FeatureToggle redirectToHomepage>
      <ReaderLayout header={header} nav={nav} footer={footer} contentOverflowYHidden>
        <DigitalHelmet
          title={structure ? structure.link.name : ''}
          canonical={bookPathWebBookId(bookId, chapterId, bookTitle, chapterTitle)}
        />
        <AlignedContentPanel navShown={true} maxWidth={1000}>
          <ContentPanel contentNode={content} currentNodeId={{id: paraId || ''}} enableLawLinks disablePrinting />
        </AlignedContentPanel>
      </ReaderLayout>
    </FeatureToggle>
  )
}
