import {Box} from '@indoqa/style-system'
import * as React from 'react'
import {ResultGroup} from '../../types/SearchResult'
import {RenderDocument, RenderDocumentGroup} from './SearchResultPanel'

interface Props {
  resultGroup: ResultGroup
  renderDocumentGroup: RenderDocumentGroup
  renderDocument: RenderDocument
  collapsed: boolean
  searchId?: string
}

export const ResultGroupPanel: React.FC<Props> = (props) => {
  const {resultGroup, renderDocumentGroup, renderDocument, searchId, collapsed} = props
  const [groupCollapsed, setGroupCollapsed] = React.useState(true)
  const resultItems = resultGroup.documents.map((doc, i) => {
    return <div key={i}>{renderDocument(doc, resultGroup, searchId)}</div>
  })
  const showResultItems = !collapsed || !groupCollapsed
  return (
    <Box fullWidth>
      {renderDocumentGroup(resultGroup, groupCollapsed, setGroupCollapsed)}
      {showResultItems && <Box pl={2}>{resultItems}</Box>}
    </Box>
  )
}
