import {Box, Flex} from '@indoqa/style-system'
import * as React from 'react'
import {Button} from '../../../commons/components/button/Button'

export const BookOverviewPanel: React.FC = () => {
  return (
    <Box>
      <h1>E-Bibliothek des ÖGB-Verlags</h1>
      <p>
        Die E-Book Bibliothek des ÖGB-Verlags bietet einen elektronischen Zugriff auf verlagseigene Titel, diese sind
        sowohl im Webbrowser, als auch über PDF verfügbar. In ihrer Funktionalität ergänzt wird die Bibliothek durch
        eine Suchfunktion, welche das schnelle Auffinden von Informationen innerhalb der Bücher ermöglicht.
      </p>
      <Box pt={2} pb={4}>
        <Button text="Kontaktieren Sie uns!" linkTo="mailto:robert.leonhardt@oegbverlag.at" />
      </Box>
      <Flex center mt={4}>
        <Box mr={12} mb={4}>
          <a
            href="https://shop.oegbverlag.at/arbeitszeitgesetz-9783990465455"
            target="_blank"
            rel="noopener noreferrer"
          >
            <Flex direction="column" center>
              <img
                style={{width: '250px', marginBottom: 0}}
                alt="Arbeitszeitgesetz (Buchcover)"
                src="https://shop.oegbverlag.at/pub/media/catalog/product/cache/921464256700511907a87b39e432ad9b/g/a/gasteiger_georg-arbeitszeitgesetz.jpg"
              />
              <Box mt={2} />
              <img
                style={{width: '200px'}}
                alt="Logo - Shop the ÖGB Verlag"
                src="https://shop.oegbverlag.at/pub/static/version1578415143/frontend/Infortis/ultimo/de_DE/images/logo.png"
              />
            </Flex>
          </a>
        </Box>
        <Box mr={12} mb={4}>
          <a
            href="https://shop.oegbverlag.at/oegbverlagstitel-1-2/gesetzeundkommentare-1-9722/kommentierte-gesetze/drs-monika-urlaubsrecht"
            target="_blank"
            rel="noopener noreferrer"
          >
            <Flex direction="column" center>
              <img
                style={{width: '250px', marginBottom: 0}}
                alt="Urlaubsrecht (Buchcover)"
                src="https://shop.oegbverlag.at/pub/media/catalog/product/cache/921464256700511907a87b39e432ad9b/d/r/drs-monika_urlaubsrecht_5.jpg"
              />
              <Box mt={2} />
              <img
                style={{width: '200px'}}
                alt="Logo - Shop the ÖGB Verlag"
                src="https://shop.oegbverlag.at/pub/static/version1578415143/frontend/Infortis/ultimo/de_DE/images/logo.png"
              />
            </Flex>
          </a>
        </Box>
        <Box mr={4} mb={4}>
          <a
            href="https://shop.oegbverlag.at/die-betriebsratswahl-9783990466315"
            target="_blank"
            rel="noopener noreferrer"
          >
            <Flex direction="column" center>
              <img
                style={{width: '250px', marginBottom: 0}}
                alt="Betriebsratswahl (Buchcover)"
                src="https://shop.oegbverlag.at/media/catalog/product/cache/921464256700511907a87b39e432ad9b/b/r/br-wahl_2023.png"
              />
              <Box mt={2} />
              <img
                style={{width: '200px'}}
                alt="Logo - Shop the ÖGB Verlag"
                src="https://shop.oegbverlag.at/pub/static/version1578415143/frontend/Infortis/ultimo/de_DE/images/logo.png"
              />
            </Flex>
          </a>
        </Box>
        <Box pt={4}>
          Sie haben über einen Buchkauf bereits Zugriff zu einer Datenbank erworben, dann klicken Sie bitte&nbsp;
          <a href="https://www.oegbverlag.at/online-fachcontent/">hier</a>.
        </Box>
      </Flex>
    </Box>
  )
}
