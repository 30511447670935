import {Box} from '@indoqa/style-system'
import * as React from 'react'
import {Button} from '../../../commons/components/button/Button'

interface Props {
  titel: string
  linkTo: string
  linkText: string
}

export const ProductDescription: React.FC<Props> = ({titel, linkTo, linkText, children}) => {
  return (
    <Box>
      <h3>{titel}</h3>
      {children}
      <Box mt={3}>
        <Button text={linkText} linkTo={linkTo} />
      </Box>
    </Box>
  )
}
