import {Box} from '@indoqa/style-system'
import {IStyle} from 'fela'
import * as React from 'react'
import {useFela} from 'react-fela'
import {Theme} from '../../../app/theme'
import {getColor} from './digitalStructureHighlight.style'
import {StructureLink} from './StructureLink'

interface Props {
  label: string | JSX.Element
  selected: boolean
  inactive?: boolean
  paddingRight: number | string
  createLink?: () => string
}

export const DigitalStructureLeafTemplate: React.FC<Props> = ({
  label,
  selected,
  inactive = false,
  paddingRight,
  createLink,
}) => {
  const {theme} = useFela<Theme>()
  const queryTreeLeafStyle: IStyle = {
    paddingRight,
  }
  const color = getColor(theme, selected, inactive)
  const labelComponent = createLink !== undefined ? <StructureLink label={label} createLink={createLink} /> : label
  return (
    <Box<Theme>
      ellipsis
      fontSize="small"
      grow={1}
      pt={1}
      my={1}
      style={queryTreeLeafStyle}
      fontStyle="brand"
      color={color}
    >
      {labelComponent}
    </Box>
  )
}
