import {Box} from '@indoqa/style-system'
import * as React from 'react'
import history from '../../app/history'
import {createItemComponents, SearchInput} from '../../commons-shared/search-input/SearchInput'
import {AutoCompleteItem, AutoCompleteItemTargetType, Reload} from '../../commons-shared/search-input/SearchInput.types'

interface XYZItem extends AutoCompleteItem {
  foo?: string
}

const getResults = (prefix: string, pos: number | null): XYZItem[] => {
  if (prefix === '') {
    return []
  }
  if ('h'.startsWith(prefix)) {
    return [
      {proposal: 'aus', targetType: AutoCompleteItemTargetType.COMPLETION, text: prefix},
      {proposal: 'und', targetType: AutoCompleteItemTargetType.COMPLETION, text: prefix, foo: 'bar' + pos},
      {proposal: 'ütte', targetType: AutoCompleteItemTargetType.COMPLETION, text: prefix},
      {proposal: ['otel', ' mitte'], targetType: AutoCompleteItemTargetType.COMPLETION, text: [prefix, ' budapest']},
    ]
  }
  if ('ha'.startsWith(prefix)) {
    return [
      {proposal: 'us', targetType: AutoCompleteItemTargetType.COMPLETION, text: prefix},
      {proposal: 'nd', targetType: AutoCompleteItemTargetType.COMPLETION, text: prefix},
      {proposal: 'rfe', targetType: AutoCompleteItemTargetType.COMPLETION, text: prefix},
    ]
  }
  if ('hau'.startsWith(prefix)) {
    return [
      {proposal: 's', targetType: AutoCompleteItemTargetType.COMPLETION, text: prefix},
      {proposal: 's', targetType: AutoCompleteItemTargetType.SUGGESTION, text: prefix, foo: 'suggestion'},
      {proposal: 't', targetType: AutoCompleteItemTargetType.COMPLETION, text: prefix},
    ]
  }
  if ('haus'.startsWith(prefix)) {
    return [
      {proposal: ' klingel', targetType: AutoCompleteItemTargetType.COMPLETION, text: prefix},
      {proposal: ' eingang', targetType: AutoCompleteItemTargetType.COMPLETION, text: prefix},
    ]
  }
  if ('haus ' === prefix) {
    return [
      {proposal: 'klingel', targetType: AutoCompleteItemTargetType.COMPLETION, text: prefix},
      {proposal: 'eingang', targetType: AutoCompleteItemTargetType.COMPLETION, text: prefix},
      {proposal: 'rot', targetType: AutoCompleteItemTargetType.COMPLETION, text: prefix},
    ]
  }
  return []
}

export const UIE_SearchInput: React.FC = () => {
  const [inputItems, setInputItems] = React.useState<AutoCompleteItem[]>([])
  const [result, setResult] = React.useState('')
  const reload: Reload = (prefix, pos) => {
    setInputItems(getResults(prefix, pos))
  }
  return (
    <Box width={500}>
      <SearchInput<XYZItem>
        placeholder="Was suchen Sie?"
        inputValue="h"
        autofocus
        items={inputItems}
        reload={reload}
        search={(userInput) => setResult(userInput)}
        renderItem={(item) => (
          <>
            {createItemComponents(item)} {item.foo ? `(${item.foo})` : ''}
          </>
        )}
        selectSuggestion={(item) => history.push('/__uie')}
      />
      <p>Some text which should be covered by the auto-complete </p>
      <Box style={{marginTop: 100}}>You are searching for: {result ? `'${result}'` : ''}</Box>
    </Box>
  )
}
