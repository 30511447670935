import querystring from 'query-string'
import {Observable, of} from 'rxjs'
import {AjaxCreationMethod} from 'rxjs/internal/observable/dom/AjaxObservable'
import {catchError, map} from 'rxjs/operators'
import {AutoCompleteItemTargetType} from '../../commons-shared/search-input/SearchInput.types'
import {addClientInfo} from '../../commons/idqsa/idqsa'
import {getHeaders} from '../../commons/store/service-utils'
import {DocumentInfo} from '../../commons/types/DocumentInfo'
import {SearchResult} from '../../commons/types/SearchResult'
import {StructureNode} from '../../commons/types/Structure'
import {LawAutoCompleteItem, LawDocumentLookupInfo, LawGroup, LawOverview} from './law.types'
import {getLawAbbreviation, getLawName} from './law.utils'

const lawBasePath = '/api/v1/law'
const DEFAULT_GROUP_COUNT = 500

export const loadLawOverviewService$ = (ajax: AjaxCreationMethod, count: number): Observable<LawOverview> => {
  return ajax.getJSON(`${lawBasePath}/overview?count=${count}`, getHeaders())
}

export const loadLawGroupService$ = (ajax: AjaxCreationMethod, groupId: string): Observable<LawGroup> => {
  return ajax.getJSON(`${lawBasePath}/overview/${groupId}?count=${DEFAULT_GROUP_COUNT}`, getHeaders())
}

export const loadLawStructureService$ = (
  ajax: AjaxCreationMethod,
  lawId: string,
  currentStructure: StructureNode | null
): Observable<StructureNode> => {
  if (currentStructure && lawId === currentStructure.link.id) {
    return of(currentStructure)
  }
  return ajax.getJSON(`${lawBasePath}/structure/${lawId}`, getHeaders())
}

export const searchLawService$ = (
  ajax: AjaxCreationMethod,
  userQuery: string,
  page: number = 0,
  previousSearchId?: string
): Observable<SearchResult> => {
  const queryParams: {[key: string]: string} = {}
  queryParams['q'] = userQuery
  queryParams['page'] = String(page)

  addClientInfo(queryParams, previousSearchId)

  return ajax.getJSON(`${lawBasePath}/search?${querystring.stringify(queryParams)}`, getHeaders())
}

export const searchLawAutoSuggestionService$ = (
  ajax: AjaxCreationMethod,
  userQuery: string
): Observable<LawAutoCompleteItem[]> => {
  if (userQuery.length <= 1) {
    return of([])
  }

  const queryParams: {[key: string]: string} = {}
  queryParams['query'] = userQuery
  queryParams['page'] = '0'

  addClientInfo(queryParams)

  return ajax.getJSON(`${lawBasePath}/auto-suggest?${querystring.stringify(queryParams)}`, getHeaders()).pipe(
    map((response: SearchResult) => {
      if (!response || !response.documents) {
        return []
      }
      return response.documents.map((doc) => {
        return {
          proposal: '',
          targetType: AutoCompleteItemTargetType.SUGGESTION,
          text: doc.name,
          lawId: doc.id,
          lawName: getLawName(doc),
          lawAbbreviation: getLawAbbreviation(doc),
        }
      })
    }),
    catchError(() => {
      return of([])
    })
  )
}

export const loadLawContentService$ = (ajax: AjaxCreationMethod, documentInfo: DocumentInfo): Observable<string> => {
  const {documentId} = documentInfo
  if (!documentId) {
    throw Error('No law documentContent id available.')
  }
  return ajax({
    method: 'GET',
    url: `${lawBasePath}/document/${documentId}`,
    responseType: 'text',
  }).pipe(map((ajaxResponse) => ajaxResponse.response))
}

export const loadLawCompleteContentService$ = (ajax: AjaxCreationMethod, lawId: string): Observable<string> => {
  return ajax({
    method: 'GET',
    url: `${lawBasePath}/complete/${lawId}`,
    responseType: 'text',
  }).pipe(map((ajaxResponse) => ajaxResponse.response))
}

export const loadLawRedirectInfo$ = (
  ajax: AjaxCreationMethod,
  lawId: string,
  sectionId: string | null
): Observable<LawDocumentLookupInfo> => {
  const path = sectionId
    ? `/content/ris/services/document-lookup?law=${lawId}&section=${sectionId}`
    : `/content/ris/services/document-lookup?law=${lawId}`
  return ajax.getJSON(path, getHeaders())
}
