import {FooterFlex} from '@indoqa/style-system'
import * as React from 'react'
import {useFela} from 'react-fela'
import {Theme} from '../../../app/theme'
import {ReaderLayoutContext, ReaderLayoutContextValue} from '../../layouts/reader-layout/ReaderLayoutContext'
import {FooterLinks} from './FooterLinks'

interface Props {
  nowrap?: boolean
}

export const ReaderFooterPanel = ({nowrap}: Props) => {
  const {theme} = useFela<Theme>()
  const footerHeight = theme.layout.reader.footerHeight
  const {setFooterHeight} = React.useContext<ReaderLayoutContextValue>(ReaderLayoutContext)
  React.useEffect(() => {
    setFooterHeight(footerHeight)
  }, [setFooterHeight, footerHeight])
  return (
    <FooterFlex<Theme>
      px={2}
      py={1}
      bg="primaryLight"
      color="textInverted"
      fullWidth
      fontStyle="brand"
      fontSize="small"
      height={footerHeight}
      justifyContent="space-between"
      style={{zIndex: 10}}
    >
      <FooterLinks nowrap={nowrap} />
    </FooterFlex>
  )
}
