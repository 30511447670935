import {IStyle} from 'fela'
import * as React from 'react'
import {useFela} from 'react-fela'
import {Link, useLocation} from 'react-router-dom'
import {Theme} from '../../../app/theme'

interface Props {
  label: string
  path: string
  noMenuEntryHighlight?: boolean
  toggleMobileNav?: () => void
}

const isActive = (path: string, currentPath: string, noMenuEntryHighlight: undefined | boolean) => {
  if (noMenuEntryHighlight) {
    return false
  }
  if (path === '/') {
    return currentPath === path
  }
  return currentPath.startsWith(path)
}

export const MainMenuEntry: React.FC<Props> = (props) => {
  const {label, path, noMenuEntryHighlight, toggleMobileNav} = props
  const {css, theme} = useFela<Theme>()
  const {pathname: currentPath} = useLocation()
  const active = isActive(path, currentPath, noMenuEntryHighlight)
  const colorStyle: IStyle = {
    color: active ? theme.colors.accent : theme.colors.text,
    textDecoration: 'none',
  }
  const linkStyle: IStyle = {
    marginRight: theme.spacing.space2,
    marginLeft: theme.spacing.space2,
    paddingTop: theme.spacing.space2,
    paddingBottom: theme.spacing.space2,
    textAlign: 'left',
    border: 0,

    backgroundColor: 'transparent',
    ...theme.fontStyles.brandBold,
    fontSize: theme.fontSizes.text,

    ...colorStyle,
    '&:visited': colorStyle,
    '&:hover': colorStyle,

    cursor: 'pointer',
    outline: 'none',
    userSelect: 'none',

    tablet: {
      marginLeft: theme.spacing.space2,
      paddingTop: theme.spacing.space0,
      paddingBottom: theme.spacing.space0,
      textAlign: 'center',
    },
  }
  const onButtonClick = () => {
    if (toggleMobileNav) {
      toggleMobileNav()
    }
  }
  return (
    <Link to={path} onClick={onButtonClick} className={css(linkStyle)}>
      {label}
    </Link>
  )
}
