/*
 Note:
 This component is included directly into the sources of this project to simplify the
 build process.

 License:
 Copyright 2019, Indoqa Software Design und Beratung GmbH

 Permission is hereby granted, free of charge, to any person obtaining a copy
 of this software and associated documentation files (the "Software"), to deal
 in the Software without restriction, including without limitation the rights
 to use, copy, modify, merge, publish, distribute, sublicense, and/or sell
 copies of the Software, and to permit persons to whom the Software is
 furnished to do so, subject to the following conditions:
 The above copyright notice and this permission notice shall be included in all
 copies or substantial portions of the Software.

 THE SOFTWARE IS PROVIDED "AS IS", WITHOUT WARRANTY OF ANY KIND, EXPRESS OR
 IMPLIED, INCLUDING BUT NOT LIMITED TO THE WARRANTIES OF MERCHANTABILITY,
 FITNESS FOR A PARTICULAR PURPOSE AND NONINFRINGEMENT. IN NO EVENT SHALL
 THE AUTHORS OR COPYRIGHT HOLDERS BE LIABLE FOR ANY CLAIM, DAMAGES OR OTHER
 LIABILITY, WHETHER IN AN ACTION OF CONTRACT, TORT OR OTHERWISE, ARISING FROM,
 OUT OF OR IN CONNECTION WITH THE SOFTWARE OR THE USE OR OTHER DEALINGS IN THE SOFTWARE.
*/
import {IStyle} from 'fela'
import rafSchd from 'raf-schd'
import * as React from 'react'
import {CSSProperties} from 'react'
import {useFela} from 'react-fela'

export enum Position {
  TOP,
  RIGHT,
  BOTTOM,
  LEFT,
}

export interface Boundaries {
  min: number
  max: number
}

interface Props {
  onResize: (moveBy: number) => void
  pos?: Position
  getBoundaries?: () => Boundaries
}

export const RESIZER_OVERLAP = 3

export const DEFAULT_BOUNDARIES: Boundaries = {
  min: 0,
  max: window.innerWidth,
}

const EventListenerMode = {capture: true}

const getCssPosition = (pos: Position): IStyle => {
  switch (pos) {
    case Position.TOP:
      return {
        top: 0,
        left: 0,
        width: '100%',
        height: RESIZER_OVERLAP,
      }
    case Position.RIGHT:
      return {
        top: 0,
        right: RESIZER_OVERLAP * -1,
        width: RESIZER_OVERLAP + 1,
        height: '100%',
      }
    case Position.BOTTOM:
      return {
        bottom: RESIZER_OVERLAP * -1,
        left: 0,
        width: '100%',
        height: RESIZER_OVERLAP,
      }
    case Position.LEFT:
      return {
        top: 0,
        left: 0,
        width: RESIZER_OVERLAP + 1,
        height: '100%',
      }
  }
}

const getNextMousePosition = (e: MouseEvent, pos: Position): number => {
  switch (pos) {
    case Position.TOP:
      return e.clientY
    case Position.BOTTOM:
      return e.clientY
    case Position.RIGHT:
      return e.clientX
    case Position.LEFT:
      return e.clientX
  }
}

const getMoveCursor = (pos: Position): string => {
  switch (pos) {
    case Position.TOP:
      return 'row-resize'
    case Position.BOTTOM:
      return 'row-resize'
    case Position.RIGHT:
      return 'col-resize'
    case Position.LEFT:
      return 'col-resize'
  }
}

export const Resizer: React.FC<Props> = ({onResize, pos = Position.RIGHT, getBoundaries}) => {
  const {css} = useFela()
  const [moving, setMoving] = React.useState<boolean>(false)
  const previousMousePositionRef = React.useRef<number>(-1)
  const cursor = getMoveCursor(pos)

  function preventGlobalMouseEvents() {
    document.body.style.cursor = cursor
  }

  function restoreGlobalMouseEvents() {
    document.body.style.cursor = 'default'
  }

  function mousemoveListener(e: MouseEvent) {
    // do not allow any other events to be fired - this is part of the solution to prevent any unwanted mouse operations
    e.stopPropagation()

    // repositioning
    const nextMousePosition = getNextMousePosition(e, pos)

    // check if the move is within the provided boundaries
    const boundaries = getBoundaries?.() ?? DEFAULT_BOUNDARIES
    if (nextMousePosition < boundaries.min || nextMousePosition > boundaries.max) {
      return
    }

    if (previousMousePositionRef.current >= 0) {
      const moveBy = nextMousePosition - previousMousePositionRef.current
      const onResizeInternal = () => {
        onResize(moveBy)
      }
      rafSchd(onResizeInternal)()
    }

    // store the mouse position for the next listener invocation
    previousMousePositionRef.current = nextMousePosition
  }

  function mouseupListener(e: MouseEvent) {
    setMoving(false)
    restoreGlobalMouseEvents()
    document.removeEventListener('mouseup', mouseupListener, EventListenerMode)
    document.removeEventListener('mousemove', mousemoveListener, EventListenerMode)
    e.stopPropagation()
  }

  function captureMouseEvents(e: React.MouseEvent<HTMLDivElement, MouseEvent>) {
    // reset the previous mouse position
    previousMousePositionRef.current = -1
    setMoving(true)

    // register global mouse events
    preventGlobalMouseEvents()
    document.addEventListener('mouseup', mouseupListener, EventListenerMode)
    document.addEventListener('mousemove', mousemoveListener, EventListenerMode)

    // no further event propagation
    e.preventDefault()
    e.stopPropagation()
  }

  const style: IStyle = {
    display: 'none',
    tablet: {
      display: 'block',
      position: 'absolute',
      cursor,
      zIndex: 2,
      ...getCssPosition(pos),
    },
  }
  const veilStyle: CSSProperties = {
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100vw',
    height: '100vh',
    cursor,
    zIndex: 1000,
  }
  return (
    <div className={css(style)} onMouseDown={captureMouseEvents}>
      {moving && <div style={veilStyle} />}
    </div>
  )
}
