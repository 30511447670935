import {Spacing, Text} from '@indoqa/style-system'
import {IStyle} from 'fela'
import * as React from 'react'
import {useFela} from 'react-fela'
import {undecoratedLinksRules} from '../../../app/mixins'
import {Theme} from '../../../app/theme'

declare const UC_UI: any

interface Props {
  marginLeft?: Spacing
}

export const FooterLinkDatenschutzeinstellungen: React.FC<Props> = ({marginLeft}) => {
  const {theme} = useFela<Theme>()
  const style: IStyle = {
    color: 'inherit',
    cursor: 'pointer',
    marginLeft: marginLeft !== undefined ? marginLeft : theme.spacing.space2,
    '& a': {
      color: theme.colors.textInverted,
    },
    tablet: {
      marginLeft: marginLeft !== undefined ? marginLeft : theme.spacing.space4,
    },
  }
  return (
    <Text
      style={[style, undecoratedLinksRules()]}
      onClick={() => {
        UC_UI.showSecondLayer()
      }}
    >
      Cookie-Einstellungen
    </Text>
  )
}
