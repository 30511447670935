import {Box} from '@indoqa/style-system'
import * as React from 'react'
import {Theme} from '../../../app/theme'

interface Props {
  titel: string
}

export const DigitalStructureHeadline: React.FC<Props> = ({titel}) => {
  return (
    <Box<Theme>
      fullWidth
      bg="primaryLight"
      color="textInverted"
      mb={2}
      pl={2}
      py={1}
      mt={3}
      fontStyle="brand"
      fontSize="small"
      bold
    >
      {titel}
    </Box>
  )
}
