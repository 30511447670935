import {RootState} from '../../app/types'

export enum ErrorActionKeys {
  THROW_ERROR = 'error/THROW_ERROR',
  SET_ERROR = 'error/SET_ERROR',
}

export enum ErrorType {
  NOT_FOUND = 'NOT_FOUND',
  SIDE_EFFECT = 'SIDE_EFFECT',
  REACT_ERROR = 'REACT_ERROR',
}

export interface ErrorState {
  readonly message: string | null
  readonly type: ErrorType | null
  readonly timestamp: number
}

export interface ClientError {
  type: ErrorType
  location: string
  userAgent: string
  message: string
  timestamp: Date
  state: RootState
  componentStack?: React.ErrorInfo
  stacktrace?: string
}
