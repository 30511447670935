export enum LinkType {
  KV_DOCSET = 'KV_DOCSET',
  KV_TIME_SLICE = 'KV_TIME_SLICE',
  RIS_DOCUMENT = 'RIS_DOCUMENT',
  BOOKS_CHAPTER = 'BOOKS_CHAPTER',
  KV_PARAGRAPH = 'KV_PARAGRAPH',
  WEBC_DOCUMENT = 'WEBC_DOCUMENT',
}

export interface Link {
  set: string
  provider: string
  type: LinkType
  id: string
  name: string
  url?: string
}
