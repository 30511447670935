import {AutoCompleteItem} from '../../commons-shared/search-input/SearchInput.types'
import {DocumentInfo} from '../../commons/types/DocumentInfo'
import {SearchResult} from '../../commons/types/SearchResult'
import {BaseStructureNode, StructureNode} from '../../commons/types/Structure'

export enum LawActionKeys {
  LOAD_OVERVIEW = 'law/LOAD_OVERVIEW',
  LOAD_OVERVIEW_SUCCESS = 'law/LOAD_OVERVIEW_SUCCESS',
  LOAD_GROUP = 'law/LOAD_GROUP',
  LOAD_GROUP_SUCCESS = 'law/LOAD_GROUP_SUCCESS',
  LOAD_CONTENT = 'law/LOAD_CONTENT',
  LOAD_CONTENT_SUCCESS = 'law/LOAD_CONTENT_SUCCESS',
  LOAD_COMPLETE_CONTENT = 'law/LOAD_COMPLETE_CONTENT',
  LOAD_COMPLETE_CONTENT_SUCCESS = 'law/LOAD_COMPLETE_CONTENT_SUCCESS',
  SEARCH = 'law/SEARCH',
  SEARCH_SUCCESS = 'law/SEARCH_SUCCESS',
  SEARCH_MORE = 'law/SEARCH_MORE',
  SEARCH_MORE_SUCCESS = 'law/SEARCH_MORE_SUCCESS',
  SEARCH_AUTO_COMPLETE = 'law/SEARCH_AUTO_COMPLETE',
  SEARCH_AUTO_COMPLETE_SUCCESS = 'law/SEARCH_AUTO_COMPLETE_SUCCESS',
  REDIRECT = 'law/REDIRECT',
  REDIRECT_SUCCESS = 'law/REDIRECT_SUCCESS',
}

export type LawOverview = StructureNode

export interface LawGroup extends BaseStructureNode<LawGroup> {
  brand?: boolean
  properties?: {
    hidden?: boolean
  }
}

export interface LawState {
  readonly overview: LawOverview | null
  readonly group: LawGroup | null
  readonly reader: {
    readonly documentInfo: DocumentInfo | null
    readonly structure: StructureNode | null
    readonly content: string | null
    readonly completeContent: string | null
  }
  readonly search: {
    readonly userQuery: string
    readonly page: number
    readonly result: SearchResult | null
    readonly autoCompleteItems: LawAutoCompleteItem[]
  }
}

export interface LawDocumentLookupInfo {
  readonly lawId: string
  readonly lawName: string
  readonly documentId?: string
  readonly documentName?: string
  readonly documentSubTitle?: string
}

export interface LawAutoCompleteItem extends AutoCompleteItem {
  lawId: string
  lawAbbreviation: string | null
  lawName: string
}
