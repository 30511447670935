import {createSelector} from 'reselect'
import {selectBookState} from '../../app/selectors'
import {BookState} from './book.types'

export const selectEbooks = createSelector(selectBookState, (state: BookState) => state.ebooks)
export const selectEbook = createSelector(selectBookState, (state: BookState) => state.ebook)
export const selectWebBookStructure = createSelector(selectBookState, (state: BookState) => state.reader.structure)
export const selectWebBookContent = createSelector(selectBookState, (state: BookState) => state.reader.content)
export const selectWebBookDocumentInfo = createSelector(
  selectBookState,
  (state: BookState) => state.reader.documentInfo
)
