import {ErrorAction} from './error.actions'
import {ErrorActionKeys, ErrorState} from './error.types'

const initialState: ErrorState = {
  message: null,
  type: null,
  timestamp: 0,
}

export const errorReducer = (state: ErrorState = initialState, action: ErrorAction): ErrorState => {
  switch (action.type) {
    case ErrorActionKeys.SET_ERROR:
      return {
        ...state,
        message: action.message,
        type: action.errorType,
        timestamp: action.timestamp,
      }
  }
  return state
}
