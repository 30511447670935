import {useQueryParam} from '@oegbv/ui-shared'
import * as React from 'react'
import {useHistory} from 'react-router'
import * as Rx from 'rxjs'
import {ajax} from 'rxjs/ajax'
import {catchError, map} from 'rxjs/operators'
import {FooterPanel} from '../../commons/components/footer/FooterPanel'
import {MainContainerPanel} from '../../commons/components/main/MainContainerPanel'
import {ContentLayout} from '../../commons/layouts/content-layout/ContentLayout'
import {digitalPathOverview} from '../../digital/store/digital.paths'
import {ErrorPanel} from '../../error/components/ErrorPanel'
import {LawHeaderPanel} from '../components/header/LawHeaderPanel'
import {lawQueryParams} from '../store/law.paths'
import {loadLawRedirectInfo$} from '../store/law.services'
import {createLawRedirectLink} from '../store/law.utils'

export const LawRedirectPage: React.FC = () => {
  const history = useHistory()
  const lawId = useQueryParam(lawQueryParams.redirectLawId) || ''
  const sectionId = useQueryParam(lawQueryParams.redirectSectionId) || ''
  const [loadingError, setLoadingError] = React.useState(false)
  loadLawRedirectInfo$(ajax, lawId, sectionId)
    .pipe(
      map((lookupInfo) => {
        const nextPath = createLawRedirectLink(lookupInfo)
        history.replace(nextPath)
      }),
      catchError(() => {
        setLoadingError(true)
        return Rx.of('Error')
      })
    )
    .subscribe()
  const header = <LawHeaderPanel noSearch />
  const footer = <FooterPanel />
  return (
    <ContentLayout header={header} footer={footer}>
      {!loadingError && <MainContainerPanel>Die Weiterleitung erfolgt in Kürze ...</MainContainerPanel>}
      {loadingError && (
        <ErrorPanel
          message="Die Weiterleitung zum Gesetz ist fehlgeschlagen."
          buttonText="Zurück zur Startseite"
          linkTo={digitalPathOverview()}
        />
      )}
    </ContentLayout>
  )
}
