import * as React from 'react'
import {ResultType} from '../../../commons/types/SearchResult'
import {ResultItemProps} from '../../store/ar.types'
import {ArBooksChapterResultItem} from '../result-items/ArBooksChapterResultItem'
import {ArBookResultItem} from '../result-items/ArBooksResultItem'
import {ArVideoResultItem} from '../result-items/ArVideoResultItem'
import {ArWebcDocumentResultItem} from '../result-items/ArWebcDocumentResultItem'

export const ArResultItemFactory: React.FC<ResultItemProps> = (props) => {
  const {resultDocument} = props
  switch (resultDocument.type) {
    case ResultType.BOOKS_CHAPTER:
      return <ArBooksChapterResultItem {...props} />
    case ResultType.BOOKS_BOOK:
      return <ArBookResultItem {...props} />
    case ResultType.WEBC_DOCUMENT:
      if (resultDocument.properties.webcType === 'Video') {
        return <ArVideoResultItem {...props} />
      }
      return <ArWebcDocumentResultItem {...props} />
    default:
      return <div>unknown</div>
  }
}
