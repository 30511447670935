import {ContentNode} from '../../commons/types/ContentNode'
import {StructureNode} from '../../commons/types/Structure'
import {BookAction, BookLoadWebBookContent} from './book.actions'
import {BookActionKeys, BookState} from './book.types'

const initialState: BookState = {
  ebooks: null,
  ebook: null,
  reader: {
    structure: null,
    content: null,
    documentInfo: null,
  },
}

const willStructureChange = (action: BookLoadWebBookContent, structure: StructureNode | null): boolean => {
  if (!structure) {
    return true
  }
  return structure.link.id !== action.bookId
}

const willContentChange = (action: BookLoadWebBookContent, contentNode: ContentNode | null): boolean => {
  if (!contentNode) {
    return true
  }
  return contentNode.id !== action.chapterId
}

export const bookReducer = (state: BookState = initialState, action: BookAction): BookState => {
  switch (action.type) {
    case BookActionKeys.LOAD_EBOOKS:
      return {
        ...state,
        ebooks: null,
      }
    case BookActionKeys.LOAD_EBOOKS_SUCCESS:
      return {
        ...state,
        ebooks: action.ebooks,
      }
    case BookActionKeys.LOAD_EBOOK_SUCCESS:
      return {
        ...state,
        ebook: action.resultDocument,
      }
    case BookActionKeys.LOAD_WEBBOOK_CONTENT: {
      const structureWillChange = willStructureChange(action, state.reader.structure)
      return {
        ...state,
        reader: {
          structure: structureWillChange ? null : state.reader.structure,
          content: willContentChange(action, state.reader.content) ? null : state.reader.content,
          documentInfo: structureWillChange ? null : state.reader.documentInfo,
        },
      }
    }
    case BookActionKeys.LOAD_WEBBOOK_CONTENT_SUCCESS:
      return {
        ...state,
        reader: {
          structure: action.structure,
          content: action.content,
          documentInfo: action.documentInfo,
        },
      }
  }
  return state
}
