import {Box, Flex} from '@indoqa/style-system'
import {IStyle} from 'fela'
import React from 'react'
import {useFela} from 'react-fela'
import {Theme} from '../../app/theme'

export const KvERechtDemoLoginPage = () => {
  const {css} = useFela<Theme>()
  const textAreaStyle: IStyle = {
    width: '100%',
    height: 200,
  }
  const buttonStyle: IStyle = {
    border: 0,
    padding: '6px 12px',
  }
  return (
    <Flex fullWidth fullHeight center>
      <Box height="500" width="600">
        <h1>eRecht Login Demo</h1>
        <form action="/kvsystem/erecht-login" method="post">
          <textarea name="token" className={css(textAreaStyle)} placeholder="Enter an eRecht token here" />
          <button type="submit" className={css(buttonStyle)}>
            Login
          </button>
        </form>
      </Box>
    </Flex>
  )
}
