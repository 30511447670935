import * as React from 'react'
import {useSelector} from 'react-redux'
import {FooterPanel} from '../../commons/components/footer/FooterPanel'
import {DigitalHelmet} from '../../commons/components/helmet/DigitalHelmet'
import {useEnableBodyClassReader} from '../../commons/hooks/use-body-class-reader/useBodyClassReader'
import {ContentLayout} from '../../commons/layouts/content-layout/ContentLayout'
import {DigitalHeaderPanel} from '../../digital/components/header/DigitalHeaderPanel'
import {digitalPathOverview} from '../../digital/store/digital.paths'
import {ErrorPanel} from '../components/ErrorPanel'
import {selectErrorType} from '../store/error.selectors'
import {ErrorType} from '../store/error.types'

interface Props {
  reactError?: boolean
  onClick?: () => void
}

interface ErrorInfo {
  title: string
  message: string
}

const getErrorInfo = (errorType: ErrorType | null, clientError?: boolean): ErrorInfo => {
  if (clientError) {
    return {
      title: 'Frontend-Fehler',
      message: 'Leider ist ein Fehler aufgetreten',
    }
  }
  if (errorType === ErrorType.NOT_FOUND) {
    return {
      title: 'Seite nicht gefunden',
      message: 'Die gewünschte Seite konnte nicht gefunden werden.',
    }
  }
  if (errorType === ErrorType.SIDE_EFFECT) {
    return {
      title: '500 - Serverfehler',
      message: 'Leider ist ein Fehler aufgetreten',
    }
  }
  return {
    title: 'Fehler',
    message: 'Leider ist ein Fehler aufgetreten',
  }
}

export const ErrorPage: React.FC<Props> = ({reactError, onClick}) => {
  useEnableBodyClassReader()
  const errorType = useSelector(selectErrorType)
  const errorInfo = getErrorInfo(errorType, reactError)
  const header = reactError ? <div /> : <DigitalHeaderPanel noSearch noMenuEntryHighlight />
  const footer = reactError ? <div /> : <FooterPanel />
  return (
    <ContentLayout header={header} footer={footer}>
      <DigitalHelmet canonical={digitalPathOverview()} noIndex title={errorInfo.title} />
      <ErrorPanel
        message={errorInfo.message}
        buttonText="Zurück zur Startseite"
        linkTo={digitalPathOverview()}
        onClick={onClick}
      />
    </ContentLayout>
  )
}
