import {Flex} from '@indoqa/style-system'
import {IStyle} from 'fela'
import * as React from 'react'
import {useFela} from 'react-fela'
import {Theme} from '../../../app/theme'

interface Props {
  onClick: () => void
  bottom?: number
  disabled?: boolean
  bgColor?: string
}

export const FloatingButton: React.FC<Props> = ({onClick, bottom = 50, disabled, bgColor, children}) => {
  const {theme} = useFela<Theme>()
  const getColor = () => {
    if (disabled) {
      return theme.colors.iconReduced
    }
    if (bgColor) {
      return bgColor
    }
    return theme.colors.primaryLight
  }
  const containerStyle: IStyle = {
    position: 'fixed',
    bottom,
    right: 25,
    width: 50,
    height: 50,
    borderRadius: '50%',
    zIndex: theme.zIndexes.floatingButton,
    backgroundColor: getColor(),
    boxShadow:
      '0 2.8px 2.2px rgba(0, 0, 0, 0.02),0 6.7px 5.3px rgba(0, 0, 0, 0.028),0 12.5px 10px rgba(0, 0, 0, 0.035),0 22.3px 17.9px rgba(0, 0, 0, 0.042),0 41.8px 33.4px rgba(0, 0, 0, 0.05),0 100px 80px rgba(0, 0, 0, 0.07)',
    cursor: disabled ? 'not-allowed' : 'pointer',
    print: {
      display: 'none',
    },
  }
  return (
    <Flex
      style={containerStyle}
      center
      onClick={() => {
        if (!disabled) {
          onClick()
        }
      }}
    >
      {children}
    </Flex>
  )
}
