import {IStyle} from 'fela'
import {useFela} from 'react-fela'
import {useHistory} from 'react-router-dom'
import {Theme} from '../../../app/theme'

interface Props {
  name: string
  link: string
  accent?: boolean
}

export function ArLink({name, link, accent}: Props) {
  const {css, theme} = useFela<Theme>()
  const history = useHistory()
  const style: IStyle = {
    textDecoration: 'underline',
    fontWeight: 'normal',
    color: accent ? theme.colors.accent : theme.colors.primaryLight,
    '&:visited': {
      color: accent ? theme.colors.accent : theme.colors.primaryLight,
    },
    '&:hover': {
      color: accent ? theme.colors.accent : theme.colors.primaryLight,
    },
    '&:active': {
      color: accent ? theme.colors.accent : theme.colors.primaryLight,
    },
  }
  return (
    <a
      className={css(style)}
      href={link}
      onClick={(e) => {
        e.preventDefault()

        if (link.startsWith('http')) {
          window.open(link, '_blank')
        } else {
          history.push(link)
        }
      }}
    >
      {name}
    </a>
  )
}
