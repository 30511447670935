import {Box, Col, ColRow, Grid} from '@indoqa/style-system'
import * as React from 'react'
import {arPathOverview} from '../../../ar/store/ar.paths'
import {bookPathOverview} from '../../../book/store/book.paths'
import {MainContainerPanel} from '../../../commons/components/main/MainContainerPanel'
import {kvPathOverview} from '../../../kv/store/kv.paths'
import {lawPathOverview} from '../../../law/store/law.paths'
import {ProductDescription} from './ProductDescription'

export const ProductsOverview: React.FC = () => {
  return (
    <Box py={4} fullWidth>
      <MainContainerPanel>
        <Grid spacing="3rem" width="100%" maxWidth="100%">
          <ColRow>
            <Col size={[12, 6]}>
              <ProductDescription titel="E-Book Bibliothek" linkTo={bookPathOverview()} linkText="MEHR ERFAHREN">
                <p>
                  Die E-Book Bibliothek des ÖGB-Verlags bietet einen elektronischen Zugriff auf verlagseigene Titel,
                  diese sind sowohl im Webbrowser, als auch über PDF verfügbar. In ihrer Funktionalität ergänzt wird die
                  Bibliothek durch eine Suchfunktion, welche das schnelle Auffinden von Informationen innerhalb der
                  Bücher ermöglicht.
                </p>
              </ProductDescription>
            </Col>
            <Col size={[12, 6]}>
              <ProductDescription titel="Sammlung Arbeitsrecht" linkTo={lawPathOverview()} linkText="MEHR ERFAHREN">
                <p>
                  Die Sammlung Arbeitsrecht ist eine Plattform des ÖGB-Verlags welche einen gänzlich freien Zugriff auf
                  mehr als 400 arbeitsrechtlich relevante Rechtsnormen bietet. Diese sind von ArbeitsrechtsexpertInnen
                  des ÖGB-Verlags zu Sachgruppen zusammengefasst worden und werden so übersichtlich für Sie aufbereitet.
                  Der übersichtliche Einstieg wird durch eine Suchfunktion über den gesamten Content ergänzt.
                </p>
              </ProductDescription>
            </Col>
            <Col size={[12, 6]}>
              <ProductDescription
                titel="Arbeitsrecht für Betriebsräte"
                linkTo={arPathOverview()}
                linkText="MEHR ERFAHREN"
              >
                <p>
                  Arbeitsrecht für Betriebsräte unterstützt Betriebsrät:innen bei ihrer Arbeit und stellt Infos aus
                  unterschiedlichen Contentquellen zur Beantwortung arbeitsrechtlicher Fragen bereit. Die Palette der
                  Inhalte reicht von den Arbeitsrechts-Skripten des VÖGB, über Handbücher (Arbeitsrecht in Frage und
                  Antwort, Arbeitsverfassungsgesetz in Frage und Antwort, Betriebsratsarbeit in der Praxis, Leitfaden
                  Betriebsvereinbarung sowie weitere ausgewählte Handbücher) bis hin zum Standardwerk
                  „Arbeitsrecht/Löschnigg“ und Videos. In einer weiteren Ausbaustufe sollen auch arbeitsrechtliche
                  Kommentare zur Verfügung stehen.
                </p>
                <p>
                  Arbeitsrecht für Betriebsräte ermöglicht Nutzer:innen über die zentrale Suche den schnellen und
                  einfachen Zugriff auf die angeführten arbeitsrechtlichen Inhalte. Die Nutzer:innen erhalten sofort die
                  für Ihren Suchbegriff relevanten Treffer angezeigt und können die Inhalte für Ihre Arbeit nutzen.
                </p>
              </ProductDescription>
            </Col>
            <Col size={[12, 6]}>
              <ProductDescription titel="KVSystem" linkTo={kvPathOverview()} linkText="MEHR ERFAHREN">
                <p>
                  Das KVSystem ist das umfassendste Informationssystem für die elektronische Recherche in den
                  österreichischen Kollektivverträgen. Es enthält verlässliche Informationen aus erster Hand, direkt von
                  der Gewerkschaft über KV-Abschlüsse, Berichte über Verhandlungen und Aktualisierungen für mehr als 700
                  Kollektivverträge, für Sie als Expertin oder Experte des österreichischen Arbeits- und Sozialrechts.
                </p>
              </ProductDescription>
            </Col>
          </ColRow>
        </Grid>
      </MainContainerPanel>
      <Box pt={4} />
    </Box>
  )
}
