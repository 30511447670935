import {Box, Flex} from '@indoqa/style-system'
import {IStyle} from 'fela'
import * as React from 'react'
import {useFela} from 'react-fela'
import {Theme} from '../../../app/theme'
import {MainContainerPanel} from '../main/MainContainerPanel'

interface Props {
  contentLeft: React.ReactNode
  contentRight?: React.ReactNode
  validUntil?: Date
  onClick?: () => void
}

function isValid(validUntil?: Date) {
  if (!validUntil) {
    return true
  }
  return new Date() < validUntil
}

export function NotificationPanel({contentLeft, contentRight, validUntil, onClick}: Props) {
  const {theme} = useFela<Theme>()
  const containerStyle: IStyle = {
    border: `1px solid ${theme.colors.primaryLight}`,
  }
  if (!isValid(validUntil)) {
    return null
  }
  return (
    <MainContainerPanel>
      <Flex
        fullWidth
        r={10}
        style={containerStyle}
        p={4}
        alignItems="center"
        mb={8}
        bg="#eef3f9"
        onClick={onClick}
        cursorPointer={onClick !== undefined}
      >
        <Box grow={1} shrink={1}>
          {contentLeft}
        </Box>
        {contentRight && (
          <Box grow={0} shrink={0}>
            {contentRight}
          </Box>
        )}
      </Flex>
    </MainContainerPanel>
  )
}
