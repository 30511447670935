import {Text} from '@indoqa/style-system'
import {IStyle} from 'fela'
import * as React from 'react'
import {useFela} from 'react-fela'
import {Theme} from '../../../app/theme'
import {ThemedIcon} from '../../../commons-shared/themed-icon/ThemedIcon'
import {ReactComponent as SearchIcon} from '../../icons/search.svg'

interface Props {
  onClick?: () => void
}

export const SearchButton: React.FC<Props> = ({onClick}) => {
  const {css, theme} = useFela<Theme>()
  const buttonStyle: IStyle = {
    display: 'flex',
    position: 'relative',
    alignItems: 'center',
    border: 0,
    backgroundColor: 'transparent',
    cursor: 'pointer',
  }
  const textStyle: IStyle = {
    display: 'none',
    tablet: {
      display: 'block',
      position: 'relative',
      color: theme.colors.text,
      marginRight: theme.spacing.space2,
    },
  }
  return (
    <button className={css(buttonStyle)} onClick={onClick}>
      <Text<Theme> style={textStyle} fontStyle="brandBold">
        Suche
      </Text>
      <ThemedIcon size={20}>
        <SearchIcon />
      </ThemedIcon>
    </button>
  )
}
