import {createSelector} from 'reselect'
import {selectDigitalState} from '../../app/selectors'
import {DigitalState} from './digital.types'

export const selectDigitalContentNodes = createSelector(selectDigitalState, (state: DigitalState) => state.contentNodes)
export const selectDigitalToCs = createSelector(selectDigitalState, (state: DigitalState) => state.tocs)
export const selectDigitalMappedContentNodes = createSelector(
  selectDigitalState,
  (state: DigitalState) => state.mappedContentNodes
)
