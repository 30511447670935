import {Box} from '@indoqa/style-system'
import {IStyle} from 'fela'
import * as React from 'react'
import {useFela} from 'react-fela'
import {transparentBackgroundPrintRule} from '../../../app/mixins'
import {Theme} from '../../../app/theme'

export const ReaderStructureTopInfo: React.FC = ({children}) => {
  const {theme} = useFela<Theme>()
  const style: IStyle = {
    position: 'fixed',
  }
  return (
    <Box<Theme>
      fullWidth
      bg="bgContentEmphasised"
      display={['block', 'none']}
      p={2}
      fontStyle="brandBold"
      height={theme.layout.reader.toolbarHeight}
      maxWidth="100%"
      style={[style, transparentBackgroundPrintRule()]}
      ellipsis
    >
      {children}
    </Box>
  )
}
