import {Box, Flex} from '@indoqa/style-system'
import {IStyle} from 'fela'
import * as React from 'react'
import theme, {Theme} from '../../../app/theme'
import {getColor} from './digitalStructureHighlight.style'
import {StructureLink} from './StructureLink'

type Label = string | boolean | JSX.Element

interface Props {
  label: Label
  inactive?: boolean
  subLabel?: string | boolean | JSX.Element
  paddingRight: number | string
  selected: boolean
  createLink?: () => string
}

const createLabelComponent = (label: Label, createLink?: () => string) => {
  return createLink ? <StructureLink label={label} createLink={createLink} /> : label
}

export const DigitalStructureFolderTemplate = ({
  label,
  subLabel,
  paddingRight,
  selected,
  createLink,
  inactive = false,
}: Props) => {
  const queryTreeFolderStyle: IStyle = {
    paddingRight,
  }
  const color = getColor(theme, selected, inactive)
  return (
    <>
      <Flex
        direction="column"
        py={1}
        nowrap
        grow={0}
        overflowHidden
        style={[{lineHeight: 1.2}, queryTreeFolderStyle]}
        color={color}
      >
        <Box<Theme> ellipsis grow={0} pt={subLabel ? 0 : 1} pr={2} fontSize="small">
          {createLabelComponent(label, createLink)}
        </Box>
        {subLabel && (
          <Box<Theme> ellipsis fontSize="small" grow={0} style={{overflow: 'hidden', lineHeight: 1.2}}>
            {createLabelComponent(subLabel, createLink)}
          </Box>
        )}
      </Flex>
    </>
  )
}
