import {Box} from '@indoqa/style-system'
import {IStyle} from 'fela'
import * as React from 'react'
import {useFela} from 'react-fela'
import {Theme} from '../../../app/theme'
import {ThemedIcon} from '../../../commons-shared/themed-icon/ThemedIcon'
import {ReactComponent as CollapseDown} from './icons/collapse-down.svg'
import {ReactComponent as CollapseUp} from './icons/collapse-up.svg'

interface Props {
  collapsed: boolean
  toggle: () => void
}

export const CollapseIcon: React.FC<Props> = ({collapsed, toggle}) => {
  const {theme} = useFela<Theme>()
  const iconColor = theme.colors.iconReduced
  const style: IStyle = {
    cursor: 's-resize',
  }
  return (
    <Box onClick={toggle} style={style}>
      <ThemedIcon size={16} color={iconColor} hoverColor={iconColor}>
        {collapsed ? <CollapseDown /> : <CollapseUp />}
      </ThemedIcon>
    </Box>
  )
}
