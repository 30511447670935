import {IStyle} from 'fela'
import {useFela} from 'react-fela'
import {Theme} from '../../../app/theme'

export interface ArResultItemBreadcrumb {
  chapterName: string
  chapterId?: string
  disabled: boolean
}

interface Props {
  items: ArResultItemBreadcrumb[]
  breadcrumbLink: (chapterId?: string) => string
}

export const createBreadcrumbItem = (
  chapterName: string,
  chapterId: string | undefined,
  disabled: boolean
): ArResultItemBreadcrumb => {
  return {chapterName, chapterId, disabled}
}

export function ChapterBreadcrumbs({items, breadcrumbLink}: Props) {
  const {css, theme} = useFela<Theme>()
  const listStyle: IStyle = {
    listStyle: 'none',
    padding: 0,
    fontSize: 'small',
  }
  const itemStyle: IStyle = {
    display: 'inline',
    '&::after': {
      content: '"›"',
      paddingRight: theme.spacing.space1,
      paddingLeft: theme.spacing.space1,
    },
    '&:last-child::after': {
      content: '""',
    },
  }
  const linkStyle = {
    fontSize: theme.fontSizes.small,
    color: theme.colors.textReduced,
    textDecoration: 'none',
    '&:active': {
      color: theme.colors.textReduced,
      textDecoration: 'none',
    },
    '&:visited': {
      color: theme.colors.textReduced,
      textDecoration: 'none',
    },
    '&:hover': {
      color: theme.colors.textReduced,
      textDecoration: 'none',
    },
  }

  return (
    <ul className={css(listStyle)}>
      {items.map((item, pos) => {
        const breadcrumb = item.disabled ? (
          item.chapterName
        ) : (
          <a href={breadcrumbLink(item.chapterId)} rel="noreferrer" className={css(linkStyle)}>
            {item.chapterName}
          </a>
        )
        return (
          <li className={css(itemStyle)} key={pos}>
            {breadcrumb}
          </li>
        )
      })}
    </ul>
  )
}
