import {Action} from 'redux'
import {ContentNode} from '../../commons/types/ContentNode'
import {TableOfContent} from '../../commons/types/TableOfContent'
import {ThrowError} from '../../error/store/error.actions'
import {DigitalActionKeys, MappedContentNodes} from './digital.types'

export type DigitalAction =
  | DigitalLoadContent
  | DigitalLoadContentSuccess
  | DigitalLoadMappedContent
  | DigitalLoadMappedContentSuccess
  | ThrowError

// ----------------------------------------------------------------------------

export interface DigitalLoadContent extends Action {
  type: DigitalActionKeys.LOAD_CONTENT
  tocId: string
  documentId?: string
}

export const digitalLoadContent = (tocId: string, documentId?: string): DigitalLoadContent => ({
  type: DigitalActionKeys.LOAD_CONTENT,
  tocId,
  documentId,
})

export interface DigitalLoadContentSuccess extends Action {
  type: DigitalActionKeys.LOAD_CONTENT_SUCCESS
  tocId: string
  toc: TableOfContent
  contentNode: ContentNode
}

export const digitalLoadContentSuccess = (
  tocId: string,
  toc: TableOfContent,
  contentNode: ContentNode
): DigitalLoadContentSuccess => ({
  type: DigitalActionKeys.LOAD_CONTENT_SUCCESS,
  tocId,
  toc,
  contentNode,
})

// ----------------------------------------------------------------------------

export interface DigitalLoadMappedContent extends Action {
  type: DigitalActionKeys.LOAD_MAPPED_CONTENT
  tocId: string
  names: string[]
}

export const digitalLoadMappedContent = (tocId: string, names: string[]): DigitalLoadMappedContent => ({
  type: DigitalActionKeys.LOAD_MAPPED_CONTENT,
  tocId,
  names,
})

export interface DigitalLoadMappedContentSuccess extends Action {
  type: DigitalActionKeys.LOAD_MAPPED_CONTENT_SUCCESS
  tocId: string
  toc: TableOfContent
  mappedContentNodes: MappedContentNodes
}

export const digitalLoadMappedContentSuccess = (
  tocId: string,
  toc: TableOfContent,
  mappedContentNodes: MappedContentNodes
): DigitalLoadMappedContentSuccess => ({
  type: DigitalActionKeys.LOAD_MAPPED_CONTENT_SUCCESS,
  tocId,
  toc,
  mappedContentNodes,
})
