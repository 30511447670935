import {Col, ColRow, Grid} from '@indoqa/style-system'
import * as React from 'react'
import {useFela} from 'react-fela'
import {Theme} from '../../../app/theme'
import {StructureNode} from '../../../commons/types/Structure'
import {LawOverview} from '../../store/law.types'
import {LawOverviewGroupPanel} from './LawOverviewGroupPanel'

interface Props {
  overview: LawOverview
}

const renderRow = (overviewEntry: StructureNode) => {
  return (
    <Col size={[12, 4]} key={overviewEntry.link.id}>
      <LawOverviewGroupPanel overviewEntry={overviewEntry} />
    </Col>
  )
}

export const LawOverviewGroupsPanel: React.FC<Props> = ({overview}) => {
  const {theme} = useFela<Theme>()
  const {children} = overview
  if (!children) {
    return null
  }

  // set width to 100 % for IE11
  return (
    <Grid spacing={theme.spacing.space4} width="100%">
      <ColRow>{children.map(renderRow)}</ColRow>
    </Grid>
  )
}
