import {DocumentInfo} from '../types/DocumentInfo'
import {StructureNode} from '../types/Structure'

export type StructureNodePredicate = (node: StructureNode) => boolean

const getDocumentInfo = (
  currentNode: StructureNode,
  nodePredicate: StructureNodePredicate,
  currentDocumentInfo?: DocumentInfo
): DocumentInfo | undefined => {
  if (nodePredicate(currentNode)) {
    return {
      documentId: currentNode.link.id,
      documentName: currentNode.link.name,
    }
  }
  return currentDocumentInfo
}

export const findDocumentInfoByPara = (
  structure: StructureNode,
  paraId: string,
  nodePredicate: StructureNodePredicate,
  documentInfo?: DocumentInfo
): DocumentInfo | undefined => {
  if (!structure.children) {
    return undefined
  }
  const {children} = structure
  for (let i = 0; i < children.length; i += 1) {
    const currentNode = children[i]

    // check if the current node qualifies to be a documentInfo (time-slice), otherwise take the passed documentInfo
    const resultDocumentInfo = getDocumentInfo(currentNode, nodePredicate, documentInfo)

    // the current node is the requested para -> return the documentInfo as result
    if (currentNode.link.id === paraId) {
      return resultDocumentInfo
    }

    // dig deeper
    const id = findDocumentInfoByPara(currentNode, paraId, nodePredicate, resultDocumentInfo)

    // pass up the result, otherwise continue with the siblings
    if (id) {
      return id
    }
  }
  return undefined
}
