import * as React from 'react'
import {useFela} from 'react-fela'
import history from '../../../app/history'
import {treeNodeRules} from '../../../app/mixins'
import {Theme} from '../../../app/theme'
import {TreeView} from '../../../commons-shared/treeview/TreeView'
import {RenderNodeProps, SelectedNode, StructureAccessors} from '../../../commons-shared/treeview/treeView.types'
import {AlignedDigitalStructurePanel} from '../../../commons/components/structure/AlignedDigitalStructurePanel'
import {ReaderLayoutContext, ReaderLayoutContextValue} from '../../../commons/layouts/reader-layout/ReaderLayoutContext'
import {StructureNode} from '../../../commons/types/Structure'
import {lawPathReader} from '../../store/law.paths'
import {LawStructureFolderPanel} from './LawStructureFolderPanel'
import {LawStructureLeafPanel} from './LawStructureLeafPanel'

interface Props {
  structure: StructureNode | null
  documentId?: string
  lawId: string | null
  groupId?: string | null
}

export const LAW_STRUCTURE_NODE_ACCESSORS: StructureAccessors<StructureNode> = {
  getId: (structure) => structure.link.id || structure.link.name,
  getChildren: (structure) => structure.children,
  isLeaf: (structure) => !(structure.children && structure.children.length > 0),
  isActive: () => true,
}

export interface RenderLawNodeProps<S> extends RenderNodeProps<S> {
  loadLawContent: (documentId: string, documentName: string) => void
  createLawContentLink: (documentId: string, documentName: string) => string
}

export type RenderLawNode<S> = (props: RenderLawNodeProps<S>) => JSX.Element

export const LawStructurePanel: React.FC<Props> = ({structure, documentId, lawId, groupId}) => {
  const {navShown} = React.useContext<ReaderLayoutContextValue>(ReaderLayoutContext)
  const [selectedNode, setSelectedNode] = React.useState<SelectedNode>({id: documentId || ''})
  const {theme} = useFela<Theme>()

  // Reset the selected node if navShown changes:
  // When a law page loads initially, the structure is created as React
  // component but does not exist in the DOM. This means that the scroll to
  // the selected node does not happen. This change makes sure that when
  // the nav structure is activated, the selected node changes (that's the
  // reason why the selected node is an object and not a string) which causes
  // a re-rendering.
  React.useEffect(() => {
    setSelectedNode({id: documentId || ''})
  }, [navShown, setSelectedNode, documentId])

  if (!structure || !lawId) {
    return null
  }

  const createLawContentLink = (documentId: string, documentName: string) => {
    return lawPathReader(lawId, documentId, structure.link.name, documentName, structure.subTitle, groupId)
  }
  const loadLawContent = (documentId: string, documentName: string) => {
    history.push(createLawContentLink(documentId, documentName))
  }
  const renderFolderNode: RenderLawNode<StructureNode> = (props) => <LawStructureFolderPanel {...props} />
  const renderLeafNode: RenderLawNode<StructureNode> = (props) => (
    <LawStructureLeafPanel {...props} loadLawContent={loadLawContent} createLawContentLink={createLawContentLink} />
  )
  return (
    <AlignedDigitalStructurePanel>
      <TreeView
        selectedNode={selectedNode}
        structure={structure}
        renderLeafNode={renderLeafNode}
        renderFolderNode={renderFolderNode}
        style={treeNodeRules(theme)}
        structureAccessors={LAW_STRUCTURE_NODE_ACCESSORS}
      />
    </AlignedDigitalStructurePanel>
  )
}
