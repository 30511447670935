export const kvProductName = 'KVSystem'

export const kvFacetTranslations = {
  union: {
    G00: 'Generalkollektivverträge',
    GBH: 'GBH',
    GPA: 'GPA',
    GPF: 'GPF',
    GÖD: 'GÖD',
    'PRO-GE': 'PRO-GE',
    VIDA: 'VIDA',
    YOUNION: 'YOUNION',
  },
  province: {
    B: 'Burgenland',
    K: 'Kärnten',
    NÖ: 'Niederösterreich',
    OÖ: 'Oberösterreich',
    S: 'Salzburg',
    ST: 'Steiermark',
    T: 'Tirol',
    V: 'Vorarlberg',
    W: 'Wien',
  },
  ang_arb: {
    ARB: 'Arbeiter',
    ANG: 'Angestellte',
  },
  contentModified: {
    '1MONTHS': '< 1 Monat',
    '12MONTHS': '< 12 Monate',
    end: 'älter als 12 Monate',
  },
  sector: {
    K: 'Bank und Versicherung',
    G: 'Gewerbe und Handwerk',
    H: 'Handel',
    I: 'Industrie',
    C: 'Information und Consulting',
    F: 'Tourismus und Freizeitwirtschaft',
    V: 'Transport und Verkehr',
    S: 'Sonstiges',
  },
}

export const HIST_GEHALTSTABELLE_NAME = 'Historische Gehaltstabelle'
export const GEHALTSTABELLE_NAME = 'Gehaltstabelle'
