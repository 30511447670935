import {createSelector} from 'reselect'
import {selectKvState} from '../../app/selectors'
import {KvState} from './kv.types'

export const selectKvReaderStructure = createSelector(selectKvState, (state: KvState) => state.reader.structure)
export const selectKvReaderContent = createSelector(selectKvState, (state: KvState) => state.reader.content)
export const selectKvReaderDocumentInfo = createSelector(selectKvState, (state: KvState) => state.reader.documentInfo)
export const selectKvReaderParams = createSelector(selectKvState, (state: KvState) => state.reader.kvParams)
export const selectKvReaderEmptySearch = createSelector(selectKvState, (state: KvState) => state.reader.emptySearch)
export const selectReaderSourceInfo = createSelector(selectKvState, (state: KvState) => state.reader.sourceInfo)
export const selectKvReaderDocSetInfo = createSelector(selectKvState, (state: KvState) => state.reader.docSetInfo)

export const selectKvLoading = createSelector(selectKvState, (state: KvState) => state.reader.loading)
export const selectKvDocSetName = createSelector(selectKvState, (state: KvState) => state.reader.docSetName)
export const selectKvSearchUserQuery = createSelector(selectKvState, (state: KvState) => state.search.userQuery)
export const selectKvSearchLoading = createSelector(selectKvState, (state: KvState) => state.search.loading)
export const selectKvSearchPage = createSelector(selectKvState, (state: KvState) => state.search.page)
export const selectKvSearchFilters = createSelector(selectKvState, (state: KvState) => state.search.filters)
export const selectKvSearchResult = createSelector(selectKvState, (state: KvState) => state.search.result)
export const selectKvSearchAutoCompleteItems = createSelector(
  selectKvState,
  (state: KvState) => state.search.autoCompleteItems
)

export const selectKvChangesItems = createSelector(selectKvState, (state: KvState) => state.changes.items)
export const selectKvChangesCurrentPage = createSelector(selectKvState, (state: KvState) => state.changes.currentPage)

export const selectKvNews = createSelector(selectKvState, (state: KvState) => state.news.result?.documents || [])
export const selectKvNewsTotalCount = createSelector(
  selectKvState,
  (state: KvState) => state.news.result?.documentsFound || 0
)
export const selectKvNewsCurrentPage = createSelector(selectKvState, (state: KvState) => state.news.currentPage)
export const selectKvTopNews = createSelector(selectKvState, (state: KvState) => {
  const documents = state.news.result?.documents
  if (documents) {
    return documents.slice(0, 4)
  }
  return []
})

export const selectKvContentNode = createSelector(selectKvState, (state: KvState) => state.content.contentNode)
export const selectKvToC = createSelector(selectKvState, (state: KvState) => state.content.toc)

export const selectFeedbackSentSuccessfully = createSelector(
  selectKvState,
  (state: KvState) => state.feedbackSentSuccessfully
)
