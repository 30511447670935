import {SearchResult} from '../types/SearchResult'

export const mergeSearchResults = (
  baseResult: SearchResult | null,
  moreResult: SearchResult | null
): SearchResult | null => {
  if (!baseResult) {
    return moreResult
  }
  if (!moreResult) {
    return baseResult
  }
  const currentResults = Array.isArray(baseResult.documentGroups) ? baseResult.documentGroups : []
  const moreResults = Array.isArray(moreResult.documentGroups) ? moreResult.documentGroups : []
  const mergedResult: SearchResult = {
    ...baseResult,
    documentGroups: [...currentResults, ...moreResults],
  }
  return mergedResult
}
