import {Box} from '@indoqa/style-system'
import * as React from 'react'
import {ReaderLayoutContext, ReaderLayoutContextValue} from './ReaderLayoutContext'

export const ReaderLayoutHeader: React.FC = ({children}) => {
  const {dimensions} = React.useContext<ReaderLayoutContextValue>(ReaderLayoutContext)
  return (
    <Box fullWidth height={dimensions.headerHeight}>
      {children}
    </Box>
  )
}
