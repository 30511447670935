import {createSelector} from 'reselect'
import {selectLawState} from '../../app/selectors'
import {LawState} from './law.types'

export const selectOverview = createSelector(selectLawState, (state: LawState) => state.overview)
export const selectReaderStructure = createSelector(selectLawState, (state: LawState) => state.reader.structure)
export const selectReaderContent = createSelector(selectLawState, (state: LawState) => state.reader.content)
export const selectReaderCompleteContent = createSelector(
  selectLawState,
  (state: LawState) => state.reader.completeContent
)
export const selectReaderDocumentInfo = createSelector(selectLawState, (state: LawState) => state.reader.documentInfo)
export const selectSearchUserQuery = createSelector(selectLawState, (state: LawState) => state.search.userQuery)
export const selectSearchPage = createSelector(selectLawState, (state: LawState) => state.search.page)
export const selectSearchResult = createSelector(selectLawState, (state: LawState) => state.search.result)
export const selectGroup = createSelector(selectLawState, (state: LawState) => state.group)
export const selectSearchAutoCompleteItems = createSelector(
  selectLawState,
  (state: LawState) => state.search.autoCompleteItems
)
