import {useDebouncedService} from '@indoqa-react-internal/react-rxjs-utils'
import * as React from 'react'
import {useFela} from 'react-fela'
import {useDispatch} from 'react-redux'
import {useParams} from 'react-router'
import {useHistory} from 'react-router-dom'
import {ajax} from 'rxjs/ajax'
import {Theme} from '../../../app/theme'
import {SearchInput} from '../../../commons-shared/search-input/SearchInput'
import {
  AutoCompleteItem,
  AutoCompleteItemTargetType,
  StyleConfig,
} from '../../../commons-shared/search-input/SearchInput.types'
import {Pool, sendSelectSignal} from '../../../commons/idqsa/idqsa'
import {search} from '../../store/ar.actions'
import {arPathOverview} from '../../store/ar.paths'
import {arAutoCompleteService$} from '../../store/ar.services'
import {ArSearchParams, CompletionsResponse} from '../../store/ar.types'

const mapPredictions = (completionResponse?: CompletionsResponse): AutoCompleteItem[] => {
  if (!completionResponse?.completions) {
    return []
  }
  return completionResponse.completions.map((entry) => {
    const user: string[] = []
    const prediction: string[] = []
    entry.forEach((completionPart) => {
      if (completionPart.type === 'PREDICTION') {
        prediction.push(completionPart.value)
        user.push('')
      } else {
        prediction.push('')
        user.push(completionPart.value)
      }
    })
    return {
      text: user,
      proposal: prediction,
      targetType: AutoCompleteItemTargetType.COMPLETION,
    }
  })
}

export const ArSearchInput = () => {
  const {theme} = useFela<Theme>()
  const history = useHistory()
  const dispatch = useDispatch()

  const {query} = useParams<ArSearchParams>()
  // const filterBook = useQueryParam(arQueryParams.filterHandbook)
  // const filterSkriptum = useQueryParam(arQueryParams.filterSkriptum)

  const predictionService = React.useCallback((userInput: string) => {
    return arAutoCompleteService$(ajax, userInput)
  }, [])
  const [emitPredictionInput, predictionResult] = useDebouncedService(predictionService)
  const mappedPredictions = React.useMemo(() => mapPredictions(predictionResult), [predictionResult])

  const styleConfig: Partial<StyleConfig> = {
    borderRadius: 0,
    borderStyle: `2px solid ${theme.colors.contextMenu}`,
  }
  return (
    <SearchInput
      inputValue={query || ''}
      items={mappedPredictions}
      reload={emitPredictionInput}
      search={(input) => {
        dispatch(search(input))
      }}
      clear={() => {
        history.push(arPathOverview())
      }}
      autofocus
      placeholder="Arbeitsrecht für Betriebsräte durchsuchen"
      styleConfig={styleConfig}
      onCompletionSelected={(text) => {
        const searchId = predictionResult?.tracking?.searchId
        if (searchId) {
          sendSelectSignal(Pool.ARFBR, text, '-')
        } else {
          console.error('No searchId assigned')
        }
      }}
    />
  )
}
