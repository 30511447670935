import * as React from 'react'
import {useFela} from 'react-fela'
import {Theme} from '../../../app/theme'
import {getResultDocumentText} from '../../../ar/utils/resultItem.utils'
import {ResultItemTemplate} from '../../../commons/components/search-result/ResultItemTemplate'
import {Pool} from '../../../commons/idqsa/idqsa'
import {ResultDocument} from '../../../commons/types/SearchResult'
import {lawPathReaderIds} from '../../store/law.paths'

interface Props {
  resultDocument: ResultDocument
  searchId?: string
}

export const LawParagraphResultItem: React.FC<Props> = ({resultDocument, searchId}) => {
  const {theme} = useFela<Theme>()
  return (
    <ResultItemTemplate
      resultItemId={resultDocument.id}
      searchId={searchId}
      name={resultDocument.name}
      type="PARAGRAPH"
      typeBgColor={theme.colors.contentTypeLaw}
      pb={3}
      link={lawPathReaderIds(resultDocument.parentId, resultDocument.id)}
      pool={Pool.LAW}
    >
      <p dangerouslySetInnerHTML={{__html: getResultDocumentText(resultDocument)}} />
    </ResultItemTemplate>
  )
}
