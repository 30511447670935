import * as React from 'react'
import {useFela} from 'react-fela'
import history from '../../../app/history'
import {treeNodeRules} from '../../../app/mixins'
import {Theme} from '../../../app/theme'
import {ThemedIcon} from '../../../commons-shared/themed-icon/ThemedIcon'
import {ReactComponent as OpenIcon} from '../../../commons-shared/treeview/icons/caret-bottom.svg'
import {ReactComponent as ClosedIcon} from '../../../commons-shared/treeview/icons/caret-right.svg'
import {TreeView} from '../../../commons-shared/treeview/TreeView'
import {SelectedNode, StructureAccessors, StyleContainerNodeProp} from '../../../commons-shared/treeview/treeView.types'
import {CreateDigitalLink} from '../../../commons/components/structure/digitalStructure.types'
import {StructureNode, StructureSubtype} from '../../../commons/types/Structure'
import {RenderLawNode} from '../../../law/components/structure/LawStructurePanel'
import {DocsetStructureNode} from '../../store/kv.types'
import {KvStructureFolderPanel} from './KvStructureFolderPanel'
import {KvStructureLeafPanel} from './KvStructureLeafPanel'

interface Props {
  structure: StructureNode | null
  createDigitalLink: CreateDigitalLink
  paraId?: string | null
  paramsKey?: string
  openAll?: boolean
}

const KV_STRUCTURE_NODE_ACCESSORS: StructureAccessors<StructureNode> = {
  getId: (structure) => structure.link.id || structure.link.name,
  getChildren: (structure) => structure.children,
  isLeaf: (structure) =>
    !(structure.children && structure.children.length > 0 && structure.subType !== StructureSubtype.SL),
  isActive: (structure) => !structure.noContent,
}

export const KvTreeView: React.FC<Props> = ({structure, paraId, paramsKey, createDigitalLink, openAll}) => {
  const {theme} = useFela<Theme>()
  const [selectedNode, setSelectedNode] = React.useState<SelectedNode>({id: paraId || ''})

  React.useEffect(() => {
    setSelectedNode({id: paraId || ''})
  }, [setSelectedNode, paraId, paramsKey])

  if (!structure) {
    return null
  }

  const loadContent = (paraId: string) => {
    const documentLink = createDigitalLink(paraId)
    history.push(documentLink)
  }

  const renderFolderNode: RenderLawNode<DocsetStructureNode> = (props) => (
    <KvStructureFolderPanel {...props} loadDigitalContent={loadContent} createDigitalLink={createDigitalLink} />
  )

  const renderLeafNode: RenderLawNode<DocsetStructureNode> = (props) => (
    <KvStructureLeafPanel {...props} loadDigitalContent={loadContent} createDigitalLink={createDigitalLink} />
  )

  const styleContainerNode: StyleContainerNodeProp<DocsetStructureNode> = (props) => {
    const active = KV_STRUCTURE_NODE_ACCESSORS.isActive(props.structure)
    const color = !active ? theme.colors.textReduced : props.inNodePath ? theme.colors.textHighlight : theme.colors.icon
    const icon = (
      <ThemedIcon size={10} verticalCenter color={color}>
        {props.open ? <OpenIcon /> : <ClosedIcon />}
      </ThemedIcon>
    )
    return {icon}
  }

  return (
    <TreeView<StructureNode>
      structureAccessors={KV_STRUCTURE_NODE_ACCESSORS}
      structure={structure}
      style={treeNodeRules(theme)}
      renderFolderNode={renderFolderNode}
      renderLeafNode={renderLeafNode}
      styleContainerNode={styleContainerNode}
      selectedNode={selectedNode}
      reloadKey={paramsKey}
      openAll={openAll}
    />
  )
}
