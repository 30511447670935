import {SearchResult} from '../../commons/types/SearchResult'
import {ArAction} from './ar.actions'
import {ArActionKeys, ArState} from './ar.types'

const initialState: ArState = {
  loading: false,
  searchResult: null,
  error: null,
  questionAnswer: null,
  bookStructure: null,
  overviewVideos: null,
}

export const arReducer = (state: ArState = initialState, action: ArAction): ArState => {
  switch (action.type) {
    case ArActionKeys.SEARCH: {
      return {
        ...state,
        loading: true,
        error: null,
      }
    }
    case ArActionKeys.SEARCH_SUCCESS: {
      return {
        ...state,
        searchResult: action.replace ? action.searchResult : mergeResults(state.searchResult, action.searchResult),
        loading: false,
      }
    }
    case ArActionKeys.SEARCH_CLEAR: {
      return {
        ...state,
        loading: false,
        error: null,
        searchResult: null,
      }
    }
    case ArActionKeys.LOAD_QUESTION: {
      return {
        ...state,
        loading: true,
        error: null,
        questionAnswer: null,
      }
    }
    case ArActionKeys.LOAD_QUESTION_SUCCESS: {
      return {
        ...state,
        loading: false,
        error: null,
        questionAnswer: action.content,
      }
    }
    case ArActionKeys.LOAD_STRUCTURE: {
      return {
        ...state,
        loading: true,
        error: null,
        bookStructure: null,
      }
    }
    case ArActionKeys.LOAD_STRUCTURE_SUCCESS: {
      return {
        ...state,
        loading: false,
        error: null,
        bookStructure: action.structure,
      }
    }
    case ArActionKeys.LOAD_OVERVIEW_VIDEOS: {
      return {
        ...state,
        overviewVideos: null,
      }
    }
    case ArActionKeys.LOAD_OVERVIEW_VIDEOS_SUCCESS: {
      return {
        ...state,
        overviewVideos: action.videosSearchResult,
      }
    }
  }
  return state
}

// const clearResults = (searchResult: ArSearchResult | null, keepFacets?: boolean): ArSearchResult | null => {
//   if (!keepFacets || !searchResult) {
//     return null
//   }
//   return {
//     facets: searchResult.facets,
//     othersFound: 0
//   }
// }
//
const mergeResults = (currentResults: SearchResult | null, nextResults: SearchResult | null): SearchResult | null => {
  if (!currentResults || !currentResults.documents) {
    return nextResults
  }
  if (!nextResults || !nextResults.documents) {
    return currentResults
  }
  return {
    ...currentResults,
    documents: [...currentResults.documents!, ...nextResults.documents!],
  }
}
