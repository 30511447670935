/* eslint-disable */
import {authenticator} from '@oegbv/ui-shared'
import * as React from 'react'
import {useParams} from 'react-router-dom'
import {Observable} from 'rxjs'
import {ajax} from 'rxjs/ajax'
import {AlignedContentPanel} from '../../commons/components/content/AlignedContentPanel'
import {ContentPanel} from '../../commons/components/content/ContentPanel'
import {ReaderFooterPanel} from '../../commons/components/footer/ReaderFooterPanel'
import {DigitalHelmet} from '../../commons/components/helmet/DigitalHelmet'
import {ReaderLayout} from '../../commons/layouts/reader-layout/ReaderLayout'
import {getHeaders} from '../../commons/store/service-utils'
import {ContentNode} from '../../commons/types/ContentNode'
import {StructureNode} from '../../commons/types/Structure'
import {MissingCredentialsErrorPage} from '../../error/pages/MissingCredentialsErrorPage'
import {NotAvailableErrorPage} from '../../error/pages/NotAvailableErrorPage'
import {BookWebbookSlugReaderHeaderPanel} from '../components/webbook-slug/header/BookWebbooSlugReaderHeaderPanel'
import {BookWebbookSlugStructurePanel} from '../components/webbook-slug/structure/BookWebbookSlugStructurePanel'
import {bookPathWebBookSlug, bookPathWebBookSlugId} from '../store/book.paths'

interface LocationParams {
  bookId: string
  chapterSlug?: string
}

interface StructureNodeHierarchy {
  node: StructureNode
  hierarchy: StructureNode[]
}

enum LoadingState {
  LOADING,
  SUCCESS,
  ERROR,
  NOT_AVAILABLE,
}

export function BookWebBookSlugReaderPage() {
  const isAuthenticated = authenticator.isAuthenticated()
  const hasRoleArbvg = authenticator.hasRole(['arbvg'])
  const {bookId, chapterSlug} = useParams<LocationParams>()
  const [loadingState, setLoadingState] = React.useState(LoadingState.LOADING)
  const [structure, setStructure] = React.useState<StructureNode | null>(null)
  const [loadedChapterSlug, setLoadedChapterSlug] = React.useState<string | null>(null)
  const [chapterContent, setChapterContent] = React.useState<ContentNode | null>(null)

  const canonicalUrl = chapterSlug ? bookPathWebBookSlug(bookId, chapterSlug) : bookPathWebBookSlugId(bookId)

  const nodeHierarchy = React.useMemo(() => {
    return getNodeHierarchy(structure, chapterSlug)
  }, [structure, chapterSlug])

  const chapterNode = React.useMemo(() => {
    return getChapterNode(nodeHierarchy)
  }, [nodeHierarchy])

  React.useEffect(() => {
    if (bookId !== 'arbvg') {
      setLoadingState(LoadingState.NOT_AVAILABLE)
      return
    }
    if (structure !== null) {
      return
    }
    loadStructure$().subscribe(
      (structure) => setStructure(structure),
      (e) => {
        setLoadingState(LoadingState.NOT_AVAILABLE)
      }
    )
  }, [])

  React.useEffect(() => {
    if (bookId !== 'arbvg') {
      setLoadingState(LoadingState.NOT_AVAILABLE)
      return
    }
    if (structure === null) {
      return
    }
    if (chapterNode !== null && chapterNode.slug && chapterNode.slug !== loadedChapterSlug) {
      setLoadedChapterSlug(chapterNode.slug)
      loadChapter$(chapterNode.slug).subscribe(
        (chapterContent) => {
          setLoadingState(LoadingState.SUCCESS)
          setChapterContent(chapterContent)
        },
        (e) => {
          setLoadingState(LoadingState.NOT_AVAILABLE)
        }
      )
    } else {
      setLoadingState(LoadingState.NOT_AVAILABLE)
    }
  }, [structure, chapterNode])

  // console.log({
  //   paraId: nodeHierarchy?.node?.link.id,
  //   structure,
  //   chapterSlug,
  //   chapterNode,
  //   nodeHierarchy,
  //   chapterContent,
  //   isGroupArbvg,
  // })

  if (!isAuthenticated || !hasRoleArbvg) {
    return <MissingCredentialsErrorPage url={window.location.href} />
  } else if (loadingState === LoadingState.NOT_AVAILABLE) {
    return <NotAvailableErrorPage />
  } else if (loadingState === LoadingState.ERROR) {
    return <div>Error</div>
  }

  const header = <BookWebbookSlugReaderHeaderPanel bookId="ArbVG" bookTitle="Kommentar Arbeitsverfassungsrecht" />
  const footer = <ReaderFooterPanel />
  const nav = <BookWebbookSlugStructurePanel structure={structure} paraId={nodeHierarchy?.node.link.id} />
  return (
    <ReaderLayout header={header} nav={nav} footer={footer} contentOverflowYHidden>
      <DigitalHelmet title={structure ? structure.link.name : ''} canonical={canonicalUrl} />
      <AlignedContentPanel navShown={true} maxWidth={1000}>
        <ContentPanel
          contentNode={chapterContent}
          currentNodeId={{id: nodeHierarchy?.node?.link.id || ''}}
          enableLawLinks
          disablePrinting
        />
      </AlignedContentPanel>
    </ReaderLayout>
  )
}

const loadStructure$ = (): Observable<StructureNode> => {
  return ajax.getJSON(`/api/v1/arbvg/book-structure`, getHeaders())
}

const loadChapter$ = (chapterSlug: string): Observable<ContentNode> => {
  return ajax.getJSON(`/api/v1/arbvg/chapters/${chapterSlug}?include-children=true`, getHeaders())
}
const getNodeHierarchy = (
  structure: StructureNode | null,
  chapterSlug?: string | null
): StructureNodeHierarchy | null => {
  if (!structure) {
    return null
  }
  if (!chapterSlug) {
    return findFirstChapter(structure)
  }

  const stack: StructureNodeHierarchy[] = [
    {
      node: structure,
      hierarchy: [],
    },
  ]
  const parentMap = new Map<StructureNode, StructureNode | null>()
  parentMap.set(structure, null)

  while (stack.length > 0) {
    const element = stack.pop()

    if (!element) {
      continue
    }

    if (element.node.slug === chapterSlug) {
      return element
    }

    if (element.node.children) {
      for (const child of element.node.children) {
        stack.push({
          node: child,
          hierarchy: [...element.hierarchy, element.node],
        })
      }
    }
  }

  return null
}

const findFirstChapter = (structure: StructureNode): StructureNodeHierarchy | null => {
  const chapters = structure.children
  if (!chapters || chapters.length < 1) {
    return null
  }
  return {
    node: chapters[0],
    hierarchy: [structure],
  }
}

const getChapterNode = (nodeHierarchy?: StructureNodeHierarchy | null): StructureNode | null => {
  if (!nodeHierarchy) {
    return null
  }
  if (nodeHierarchy.hierarchy.length == 1) {
    return nodeHierarchy.node
  }
  return nodeHierarchy.hierarchy[1]
}
