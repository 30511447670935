import {Box, Col, ColRow, Grid, Text} from '@indoqa/style-system'
import {useHistory} from 'react-router'
import {Theme} from '../../../app/theme'
import {AustrianMapPanel} from '../../../commons/components/austrian-map/AustrianMapPanel'
import {UnionLogo} from '../../../commons/components/union-logo/UnionLogo'
import {kvFacetTranslations} from '../../store/kv.constants'
import {kvPathDocument, kvPathKvDocSetId} from '../../store/kv.paths'
import {DocSetInfo} from '../../store/kv.types'
import {angArbToLongString} from '../../store/kv.utils'
import {formatValidityDateUI} from '../../utils/validityDateUtils'

interface Props {
  docSetInfo: DocSetInfo
}

const renderUnionLogos = (unions?: string[]) => {
  if (!unions) {
    return null
  }
  return (
    <Box>
      {unions.map((union) => (
        <Box pr={2} key={union}>
          <UnionLogo shortName={union} />
        </Box>
      ))}
    </Box>
  )
}

const renderSectors = (sectors?: string[]) => {
  if (!sectors) {
    return '-'
  }
  let result = ''
  for (let i = 0; i < sectors.length; i++) {
    result += kvFacetTranslations.sector[sectors[i]]
    if (i < sectors.length - 1) {
      result += ', '
    }
  }
  return result
}

const renderLabel = (text: string) => (
  <Text<Theme> fontStyle="brandLight" style={{fontWeight: 400}} pr={1}>
    {text}:
  </Text>
)

export const DocSetInfoPanel = ({docSetInfo}: Props) => {
  const history = useHistory()
  const newsLink = (() => {
    const {id, newsId, name, newsName} = docSetInfo
    if (!newsId || !newsName) {
      return null
    }
    return kvPathDocument(id, newsId, name, newsName)
  })()
  return (
    <Box p={2}>
      <h1>{docSetInfo.name}</h1>
      <Box pt={4}>
        <Grid spacing="3rem">
          <ColRow>
            <Col size={[12, 4]}>
              <h3>Eckdaten</h3>
              {renderLabel('Letzte Änderung')} {formatValidityDateUI(docSetInfo.lastContentUpdate)}
              <br />
              {renderLabel('Gültig für')} {angArbToLongString(docSetInfo.angArb)}
              <br />
              {docSetInfo.sectors && docSetInfo.sectors.length > 1
                ? renderLabel('Branchen')
                : renderLabel('Branche')}{' '}
              {renderSectors(docSetInfo.sectors)}
              {docSetInfo.familyName && docSetInfo.familyId && (
                <p>
                  {renderLabel('Basis-Kollektivvertrag')}
                  <Text onClick={() => history.push(kvPathKvDocSetId(docSetInfo.familyId!))}>
                    <a href={kvPathKvDocSetId(docSetInfo.familyId!)}>{docSetInfo.familyName}</a>
                  </Text>
                </p>
              )}
            </Col>
            <Col size={[12, 4]}>
              <h3>{docSetInfo.unions && docSetInfo.unions.length > 1 ? 'Gewerkschaften' : 'Gewerkschaft'}</h3>
              {renderUnionLogos(docSetInfo.unions)}
              <br />
            </Col>
            <Col size={[12, 4]}>
              <h3>Räumliche Gültigkeit</h3>
              <Box pr={5}>
                <AustrianMapPanel provinces={docSetInfo.provinces} />
              </Box>
            </Col>
            {newsLink && (
              <Col size={[12, 4]}>
                <h3>Aktuelle News</h3>
                <Text>
                  <a
                    href={newsLink}
                    onClick={(e) => {
                      e.preventDefault()
                      history.push(newsLink)
                      return false
                    }}
                  >
                    {docSetInfo.newsName}
                  </a>
                </Text>
              </Col>
            )}
            {docSetInfo.lohndatenEntries && (
              <Col size={[12, 4]}>
                <h3>Lohndaten-Schnittstelle</h3>
                Dieser Kollektivvertrag wird auch über die Lohndaten-Schnittstelle zur Verfügung gestellt. Damit kann
                strukturiert auf Informationen wie Gehältern, Löhnen, Lohnarten, Zulagen, Zuschlägen, etc. im XML-Format
                zugegriffen werden, um sie in Lohnverrechnungssoftware zu verwenden.
              </Col>
            )}
          </ColRow>
        </Grid>
      </Box>
    </Box>
  )
}
