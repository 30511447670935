import {Box} from '@indoqa/style-system'
import {IStyle} from 'fela'
import * as React from 'react'
import {Link} from 'react-router-dom'
import {digitalPathOverview} from '../../store/digital.paths'
import {ReactComponent as LogoSmall} from './digital-logo-small.svg'
import {ReactComponent as Logo} from './digital-logo.svg'

interface Props {
  small?: boolean
}

export const DigitalLogo: React.FC<Props> = ({small}) => {
  if (small) {
    const style: IStyle = {
      lineHeight: 1,
      '& svg': {
        height: '20px',
        width: '175px',
      },
    }
    return (
      <Box height={20} width={175} style={style}>
        <Link to={digitalPathOverview()}>
          <LogoSmall />
        </Link>
      </Box>
    )
  }
  return (
    <Box width={150}>
      <Link to={digitalPathOverview()}>
        <Logo />
      </Link>
    </Box>
  )
}
