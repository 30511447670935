import gph from './gbh.jpeg'
import goed from './goed.png'
import gpa from './gpa.jpeg'
import gpf from './gpf.jpeg'
import proge from './proge.jpeg'
import vida from './vida.jpeg'
import younion from './younion.jpeg'

interface Props {
  shortName: string
}

export const UnionLogo = ({shortName}: Props) => {
  switch (shortName) {
    case 'G00': {
      return null
    }
    case 'GBH': {
      return <img src={gph} alt="GBH" />
    }
    case 'GPA': {
      return <img src={gpa} alt="GPA" />
    }
    case 'GPF': {
      return <img src={gpf} alt="GPF" />
    }
    case 'GÖD': {
      return <img src={goed} alt="GÖD" />
    }
    case 'PRO-GE': {
      return <img src={proge} alt="PRO-GE" />
    }
    case 'VIDA': {
      return <img src={vida} alt="VIDA" />
    }
    case 'YOUNION': {
      return <img src={younion} alt="YOUNION" />
    }
    default: {
      return null
    }
  }
}
