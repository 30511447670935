import {authenticator} from '@oegbv/ui-shared'
import {getCurrentSetFromSessionStorage} from '../components/settings/settings'

export const getHeaders = () => {
  const headers = {
    Accept: 'application/json',
  }
  const currentSet = getCurrentSetFromSessionStorage()
  if (currentSet === 'PREVIEW') {
    headers['set'] = currentSet
  }
  const token = authenticator.getToken()
  const tokenType = authenticator.getTokenType()
  if (token && tokenType) {
    Object.assign(headers, {Authorization: `${tokenType} ${token}`})
  }
  return headers
}
