import {Box} from '@indoqa/style-system'
import {IStyle} from 'fela'
import * as React from 'react'
import {removePaddingLeftPrintRule} from '../../../app/mixins'
import {Pool} from '../../idqsa/idqsa'

interface Props {
  navShown: boolean
  maxWidth?: number
  trackingInfo?: {
    pool: Pool
    resultItemId?: string
  }
}

export const AlignedContentPanel: React.FC<Props> = ({navShown, maxWidth = 700, trackingInfo, children}) => {
  const style: IStyle = {
    tablet: {
      marginTop: 0,
    },
    print: {
      marginTop: 0,
    },
  }
  return (
    <Box
      fullWidth
      style={[{overflowY: 'auto', overflowX: 'hidden', wordBreak: 'break-word'}, removePaddingLeftPrintRule()]}
      py={navShown ? 2 : 1}
      px={3}
    >
      <Box maxWidth={maxWidth} style={style}>
        {children}
      </Box>
    </Box>
  )
}
