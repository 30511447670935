import {IStyle} from 'fela'
import {TreeNodeStyle} from '../commons-shared/treeview/treeView.types'
import {Theme} from './theme'

export const centeredLayoutRules = (theme: Theme): IStyle => ({
  desktop: {
    minWidth: theme.layout.centeredLayoutBoundaries.minWidth,
    maxWidth: theme.layout.centeredLayoutBoundaries.maxWidth,
    margin: '0 auto',
  },
})

export const containerRules = (theme: Theme): IStyle => ({
  paddingLeft: theme.spacing.space3,
  paddingRight: theme.spacing.space3,
  tablet: {
    paddingLeft: theme.spacing.space3,
    paddingRight: theme.spacing.space3,
  },
  desktop: {
    paddingLeft: theme.spacing.space3,
    paddingRight: theme.spacing.space3,
  },
})

export const searchResultWidthRules = (theme: Theme): IStyle => ({
  maxWidth: theme.layout.search.maxWidth,
  width: '100%',
})

export const undecoratedLinksRules = (): IStyle => ({
  '& a': {
    textDecoration: 'none',
  },
})

export const textColorLinksRules = (theme: Theme): IStyle => ({
  '& a': {
    color: theme.colors.primary,
  },
})

export const underlinedLinkRules = (theme: Theme): IStyle => ({
  '& a': {
    borderBottom: `1px solid ${theme.colors.text}`,
  },
})

export const treeNodeRules = (theme: Theme): Partial<TreeNodeStyle> => ({
  colorHover: theme.colors.bgStructureHover,
  colorSelected: theme.colors.bgStructureSelected,
  padding: theme.spacing.space2 as string,
})

export const invisibleButtonRules: IStyle = {
  display: 'flex',
  position: 'relative',
  alignItems: 'center',
  border: 0,
  backgroundColor: 'transparent',
  cursor: 'pointer',
}

export const clippedBackgroundImageRules = (bgImg?: string, disableClipping?: boolean): IStyle => {
  if (bgImg) {
    return {
      backgroundImage: `url(${bgImg})`,
      backgroundSize: 'cover',
      backgroundPosition: 'center top',
      clipPath: disableClipping ? undefined : 'polygon(0 0, 1200px 0, 1200px 87%, 0 100%)',
      desktop: {
        display: 'flex',
        clipPath: disableClipping ? undefined : 'polygon(0 0, 100% 0, 100% 87%, 0 100%)',
      },
    }
  }
  return {}
}

export const noPrintRule = (): IStyle => {
  return {
    print: {
      display: 'none',
    },
  }
}
export const transparentBackgroundPrintRule = (): IStyle => {
  return {
    print: {
      backgroundColor: 'transparent',
    },
  }
}

export const removePaddingLeftPrintRule = (): IStyle => {
  return {
    print: {
      paddingLeft: 0,
    },
  }
}
