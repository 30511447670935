import {useQueryParam} from '@oegbv/ui-shared'
import * as React from 'react'
import {useHistory} from 'react-router'
import * as Rx from 'rxjs'
import {ajax} from 'rxjs/ajax'
import {catchError, map} from 'rxjs/operators'
import {FooterPanel} from '../../commons/components/footer/FooterPanel'
import {MainContainerPanel} from '../../commons/components/main/MainContainerPanel'
import {ContentLayout} from '../../commons/layouts/content-layout/ContentLayout'
import {ErrorPanel} from '../../error/components/ErrorPanel'
import {KvHeaderPanel} from '../components/header/KvHeaderPanel'
import {kvPathIds, kvQueryParams} from '../store/kv.paths'
import {loadKvRedirectInfo$} from '../store/kv.services'
import {KvReaderQueryParams} from '../store/kv.types'
import {parseValidityDate} from '../utils/validityDateUtils'

export const KvRedirectPage: React.FC = () => {
  const history = useHistory()
  const from = useQueryParam(kvQueryParams.redirectFromDocument) || ''
  const idref = useQueryParam(kvQueryParams.redirectToIdRef) || ''
  const validityDate = useQueryParam(kvQueryParams.validityDate) || ''
  const [loadingError, setLoadingError] = React.useState(false)

  React.useEffect(() => {
    loadKvRedirectInfo$(ajax, idref, from)
      .pipe(
        map((lookupInfo) => {
          const {docSetId, timeSliceId, paragraphId} = lookupInfo
          const params: KvReaderQueryParams = {
            paraId: paragraphId || undefined,
            validityDate: validityDate ? parseValidityDate(validityDate) : undefined,
          }
          const nextPath = kvPathIds(docSetId, timeSliceId, paragraphId || validityDate ? params : undefined)
          history.replace(nextPath)
        }),
        catchError(() => {
          setLoadingError(true)
          return Rx.of('Error')
        })
      )
      .subscribe()
  }, [history, idref, from, validityDate])

  const header = <KvHeaderPanel noSearch />
  const footer = <FooterPanel />
  return (
    <ContentLayout header={header} footer={footer}>
      {!loadingError && <MainContainerPanel>Die Weiterleitung erfolgt in Kürze ...</MainContainerPanel>}
      {loadingError && (
        <ErrorPanel
          message={`Das KV-Dokument mit der ID «${idref}» existiert nicht.`}
          buttonText="Zurück"
          disableLinkTo
          linkTo=""
          onClick={() => history.goBack()}
        />
      )}
    </ContentLayout>
  )
}
