import {Box} from '@indoqa/style-system'
import * as React from 'react'
import {useFela} from 'react-fela'
import {Link} from 'react-router-dom'
import {textColorLinksRules, undecoratedLinksRules} from '../../../app/mixins'
import {Theme} from '../../../app/theme'
import {LinkedSquareHeader} from '../../../commons/components/square/LinkedSquareHeader'
import {lawPathGroup} from '../../store/law.paths'
import {LawOverview} from '../../store/law.types'
import {LawEntryPanel} from './LawEntryPanel'

interface Props {
  overviewEntry: LawOverview
}

export const LawOverviewGroupPanel: React.FC<Props> = ({overviewEntry}) => {
  const {theme} = useFela<Theme>()
  const {children} = overviewEntry
  if (!children) {
    return null
  }
  const lawGroupLink = lawPathGroup(overviewEntry.link.id, overviewEntry.link.name)
  return (
    <Box fullWidth>
      <Box<Theme>
        fullWidth
        bold
        px={2}
        pb={1}
        pt={2}
        mb={1}
        fontSize="veryBig"
        fontStyle="brandBold"
        style={[undecoratedLinksRules(), textColorLinksRules(theme)]}
      >
        <LinkedSquareHeader link={lawGroupLink} name={overviewEntry.link.name} />
      </Box>
      <Box style={{wordBreak: 'break-word', paddingLeft: 12}} px={2}>
        <Box style={{borderLeft: theme.borders.border1, paddingLeft: 10}}>
          {children.map((c) => (
            <LawEntryPanel key={c.link.id} id={c.link.id} name={c.link.name} shortName={c.subTitle} />
          ))}
        </Box>
      </Box>
      <Box<Theme> textAlign="right" pt={2}>
        <Link to={lawGroupLink}>Mehr ...</Link>
      </Box>
    </Box>
  )
}
