import {Epic, ofType} from 'redux-observable'
import {forkJoin, of} from 'rxjs'
import {catchError, map, switchMap} from 'rxjs/operators'
import {RootState, Services} from '../../app/types'
import {loadTableOfContentService$, loadWebcDocumentService$} from '../../commons/store/webc.service'
import {throwError} from '../../error/store/error.actions'
import {
  DigitalAction,
  DigitalLoadContent,
  digitalLoadContentSuccess,
  DigitalLoadMappedContent,
  digitalLoadMappedContentSuccess,
} from './digital.actions'
import {selectDigitalToCs} from './digital.selectors'
import {DigitalActionKeys} from './digital.types'

interface DigitalEpic extends Epic<DigitalAction, DigitalAction, RootState, Services> {}

const loadWebCDocument$: DigitalEpic = (action$, state$, {ajax}) =>
  action$.pipe(
    ofType<DigitalLoadContent>(DigitalActionKeys.LOAD_CONTENT),
    switchMap((action) => {
      const currentTocs = selectDigitalToCs(state$.value)
      const currentToc = currentTocs[action.tocId]
      return loadTableOfContentService$(ajax, action.tocId, currentToc).pipe(
        switchMap((toc) => {
          const documentId = action.documentId || toc.rootDocumentId
          return loadWebcDocumentService$(ajax, documentId).pipe(
            map((contentNode) => digitalLoadContentSuccess(action.tocId, toc, contentNode))
          )
        }),
        catchError((e) => of(throwError(e)))
      )
    })
  )

const loadMappedWebCDocuments: DigitalEpic = (action$, state$, {ajax}) =>
  action$.pipe(
    ofType<DigitalLoadMappedContent>(DigitalActionKeys.LOAD_MAPPED_CONTENT),
    switchMap((action) => {
      const currentTocs = selectDigitalToCs(state$.value)
      const currentToc = currentTocs[action.tocId]
      return loadTableOfContentService$(ajax, action.tocId, currentToc).pipe(
        switchMap((toc) => {
          const webcDocumentRequests = {}
          action.names.forEach((name) => {
            const webcId = toc.mappings[name]
            webcDocumentRequests[name] = loadWebcDocumentService$(ajax, webcId)
          })
          return forkJoin(webcDocumentRequests).pipe(
            map((joinedResults) => digitalLoadMappedContentSuccess(action.tocId, toc, joinedResults)),
            catchError(() => of(digitalLoadMappedContentSuccess(action.tocId, toc, {})))
          )
        }),
        catchError((e) => of(throwError(e)))
      )
    })
  )

export const digitalEpics = [loadWebCDocument$, loadMappedWebCDocuments]
