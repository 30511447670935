import {Box, Flex, VStack} from '@indoqa/style-system'
import {authenticator, useQueryParam} from '@oegbv/ui-shared'
import * as React from 'react'
import {useHistory} from 'react-router'
import {Link} from 'react-router-dom'
import {Button, Type} from '../../commons/components/button/Button'
import {FooterPanel} from '../../commons/components/footer/FooterPanel'
import {DigitalHelmet} from '../../commons/components/helmet/DigitalHelmet'
import {ContentLayout} from '../../commons/layouts/content-layout/ContentLayout'
import {DigitalHeaderPanel} from '../components/header/DigitalHeaderPanel'
import akLogo from '../components/login-provider/logo-ak.svg'
import {digitalAkLoginPage, digitalLoginPage, digitalQueryParams} from '../store/digital.paths'

const DigitalAkLoginPage = () => {
  const history = useHistory()
  const redirectUri = useQueryParam(digitalQueryParams.redirect)

  const authenticateAk = (broker: string) => {
    history.push(digitalAkLoginPage())
    authenticator.login(broker, redirectUri)
  }

  // const akTestLogins = getInitialState().it

  const header = <DigitalHeaderPanel noSearch noLogin />
  const footer = <FooterPanel />

  return (
    <ContentLayout header={header} footer={footer} noFooterMarginTop>
      <DigitalHelmet canonical={digitalAkLoginPage()} />
      <Flex px={3} pt={6} alignItems="center" fullWidth direction="column">
        <Box pb={6}>
          <img src={akLogo} height={80} alt="Arbeiterkammer - Logo" />
        </Box>
        <VStack spacing={3}>
          <Button linkTo={() => authenticateAk('AK_BGLD_AD')} text={`Login AK-Burgenland`} type={Type.REDUCED} />
          <Button linkTo={() => authenticateAk('AK_KTN_AD')} text={`Login AK-Kärnten`} type={Type.REDUCED} />
          <Button linkTo={() => authenticateAk('AK_NO_AD')} text={`Login AK-Niederösterreich`} type={Type.REDUCED} />
          <Button linkTo={() => authenticateAk('AK_OO_AD')} text={`Login AK-Oberösterreich`} type={Type.REDUCED} />
          <Button linkTo={() => authenticateAk('AK_SBG_AD')} text={`Login AK-Salzburg`} type={Type.REDUCED} />
          <Button linkTo={() => authenticateAk('AK_STMK_AD')} text={`Login AK-Steiermark`} type={Type.REDUCED} />
          <Button linkTo={() => authenticateAk('AK_Tirol_AD')} text={`Login AK-Tirol`} type={Type.REDUCED} />
          <Button linkTo={() => authenticateAk('AK_VGB_AD')} text={`Login AK-Vorarlberg`} type={Type.REDUCED} />
          <Button linkTo={() => authenticateAk('AK_AD')} text={`Login AK-Wien`} type={Type.REDUCED} />
        </VStack>
        <Box pt={6}>
          <Link to={digitalLoginPage()}>Zurück zur Login-Seite</Link>
        </Box>
      </Flex>
    </ContentLayout>
  )
}

export {DigitalAkLoginPage}
