import {ResultDocument} from '../../../../commons/types/SearchResult'

export const getBookTitleFromResultItem = (resultDocument: ResultDocument) => {
  const {properties} = resultDocument
  if (properties) {
    if (properties) {
      if (properties.quelle_kurz) {
        return properties.quelle_kurz
      }
      if (properties.quelle_lang) {
        return properties.quelle_lang
      }
    }
  }
  return resultDocument.parentName
}
