import * as React from 'react'
import {FooterPanel} from '../../commons/components/footer/FooterPanel'
import {DigitalHelmet} from '../../commons/components/helmet/DigitalHelmet'
import {useEnableBodyClassReader} from '../../commons/hooks/use-body-class-reader/useBodyClassReader'
import {ContentLayout} from '../../commons/layouts/content-layout/ContentLayout'
import {DigitalHeaderPanel} from '../../digital/components/header/DigitalHeaderPanel'
import {digitalPathOverview} from '../../digital/store/digital.paths'
import {ErrorPanel} from '../components/ErrorPanel'

const MSG_NOT_AVAIL = 'Die Seite wurde nicht gefunden.'

export function NotAvailableErrorPage() {
  useEnableBodyClassReader()

  const header = <DigitalHeaderPanel noSearch noMenuEntryHighlight />
  const footer = <FooterPanel />

  return (
    <ContentLayout header={header} footer={footer}>
      <DigitalHelmet canonical={digitalPathOverview()} noIndex title={MSG_NOT_AVAIL} />
      <ErrorPanel message={MSG_NOT_AVAIL} buttonText="Zur Startseite" linkTo={digitalPathOverview()} />
    </ContentLayout>
  )
}
