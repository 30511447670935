import {getInitialState} from '@oegbv/ui-shared'

export enum Pool {
  ARFBR = 'oegbv-arfbr',
  LAW = 'oegbv-law',
  KVS = 'oegbv-kvs',
}

export interface ClientInfo {
  sessionId: string
  tabId: string
  deviceId: string
  userId?: string
  allSignals?: boolean
  displayHeight: number
  displayWidth: number
}

function prepareIdqsa(pool: Pool) {
  const {env} = getInitialState()

  // set the pool identifier
  idqsa('id', `${pool}-${env}`)

  // set the userId
  // const userId = authenticator.currentUser()?.id
  // if (userId) {
  //   idqsa('userId', userId)
  // }

  // disable dryRunMode in deployed environments
  idqsa('dryRunMode', env !== 'prod' && env !== 'test' && env !== 'alpha')
  // idqsa('debugReadSignals', true)

  // debugging
  if (env === 'localhost') {
    idqsa('debugSend', true)
  }
}

export function getClientInfo(): ClientInfo | undefined {
  return idqsa('clientInfo')
}

export function addClientInfo(queryParams: {[key: string]: string}, previousSearchId?: string): void {
  const clientInfo = getClientInfo()
  if (!clientInfo) {
    return
  }
  queryParams['session-id'] = clientInfo.sessionId
  queryParams['tab-id'] = clientInfo.tabId
  queryParams['device-id'] = clientInfo.deviceId
  queryParams['user-id'] = clientInfo.userId || ''
  queryParams['all-signals'] = String(clientInfo.allSignals)
  queryParams['display-height'] = String(clientInfo.displayHeight)
  queryParams['display-width'] = String(clientInfo.displayWidth)

  if (previousSearchId && previousSearchId.length > 0) {
    queryParams['previous-search-id'] = previousSearchId
  }
}

export function sendSelectSignal(pool: Pool, resultItemId: string, searchId: string): void {
  prepareIdqsa(pool)
  idqsa('selectSignal', {resultItemId, searchId})
}

export function sendReadSignal(
  pool: Pool,
  resultItemId: string,
  duration?: number,
  height?: number,
  scrollTopMin?: number,
  scrollBottomMax?: number
): void {
  prepareIdqsa(pool)
  idqsa('readSignal', {resultItemId, duration, scrollBottomMax, scrollTopMin, height})
}

export function trackReadSignal(pool: Pool, resultItemId: string, element: Element): void {
  prepareIdqsa(pool)
  idqsa('trackReadSignal', {resultItemId, element})
}

export function endTrackReadSignal(pool: Pool): void {
  prepareIdqsa(pool)
  idqsa('endTrackReadSignal')
}
