import {Box, Flex} from '@indoqa/style-system'
import * as React from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {Link} from 'react-router-dom'
import {Button} from '../../commons/components/button/Button'
import {FooterPanel} from '../../commons/components/footer/FooterPanel'
import {DigitalHelmet} from '../../commons/components/helmet/DigitalHelmet'
import {MainContainerPanel} from '../../commons/components/main/MainContainerPanel'
import {createNewsLink} from '../../commons/components/news/createNewsLink'
import {ContentLayout} from '../../commons/layouts/content-layout/ContentLayout'
import {ResultDocument} from '../../commons/types/SearchResult'
import {formatDate} from '../../commons/utils/formatDate'
import {KvHeaderPanel} from '../components/header/KvHeaderPanel'
import {kvLoadNews, kvLoadNewsMore} from '../store/kv.actions'
import {kvProductName} from '../store/kv.constants'
import {kvPathChanges} from '../store/kv.paths'
import {selectKvNews, selectKvNewsTotalCount} from '../store/kv.selectors'

const shorten = (shortText: string, name: string, unions?: string[]): string => {
  const cleanedName = name.replace(/\\n/g, ' ')
  // console.log({cleanedName, shortText, sw: shortText.startsWith(cleanedName)})
  if (!name || shortText.length < name.length || !shortText.startsWith(cleanedName)) {
    return shortText
  }

  const union = unions && unions.length > 0 ? unions[0] : undefined
  const cleanedShortText = shortText.substr(name.length + 1)
  if (!union) {
    return cleanedShortText
  }

  const unionText = `Gewerkschaft ${union}`
  console.log({unionText, cleanedShortText, sw: cleanedShortText.startsWith(unionText)})
  if (cleanedShortText.length < unionText.length || !cleanedShortText.startsWith(unionText)) {
    return cleanedShortText
  }
  return cleanedShortText.substr(unionText.length)
}

const renderNewsDocuments = (newsDocuments: ResultDocument[]) => {
  return (
    <>
      {newsDocuments.map((item, i) => {
        const link = createNewsLink(item)
        const union = item.properties.union
        const unionOrigin = union ? `Gewerkschaft ${item.properties.union}` : undefined
        const origin = item.properties.firstNote || unionOrigin
        return (
          <Box key={i} pb={3}>
            <h3>{item.name}</h3>
            <Box color="textReduced" fontSize="small">
              {formatDate(item.properties.news_date || new Date())}
            </Box>
            {origin && (
              <Box color="textReduced" fontSize="small" pb={1}>
                {origin}
              </Box>
            )}
            <p>{shorten(item.shortText, item.name, item.properties.union)}</p>
            {link && (
              <p>
                <Link to={link}>Mehr erfahren</Link>
              </p>
            )}
          </Box>
        )
      })}
    </>
  )
}

export const KvNewsPage = () => {
  const dispatch = useDispatch()

  // scroll to top
  React.useLayoutEffect(() => {
    window.scrollTo({top: 0})
  }, [])

  // load news
  React.useEffect(() => {
    dispatch(kvLoadNews())
  }, [dispatch])

  const news = useSelector(selectKvNews)
  const totalNewsCount = useSelector(selectKvNewsTotalCount)

  const header = <KvHeaderPanel noSearch />
  const footer = <FooterPanel />
  return (
    <ContentLayout header={header} footer={footer}>
      <DigitalHelmet title={kvProductName} canonical={kvPathChanges()} />
      <MainContainerPanel>
        <Box fullWidth>
          <h1>Kollektivverträge - News</h1>
          {renderNewsDocuments(news)}
          {totalNewsCount > news.length && (
            <Flex mt={3} fullWidth justifyContent="center">
              <Button
                linkTo={() => {
                  dispatch(kvLoadNewsMore())
                }}
                text="ÄLTERE EINTRÄGE LADEN"
              />
            </Flex>
          )}
        </Box>
      </MainContainerPanel>
    </ContentLayout>
  )
}
