import {mergeSearchResults} from '../../commons/store/mergeSearchResults'
import {ContentNode} from '../../commons/types/ContentNode'
import {KvAction, KvLoadDocument} from './kv.actions'
import {KvActionKeys, KvState} from './kv.types'
import {haveKvParamsChanged} from './kv.utils'

const initialState: KvState = {
  reader: {
    structure: null,
    content: null,
    documentInfo: null,
    docSetName: '',
    emptySearch: false,
    kvParams: {},
    loading: false,
    sourceInfo: null,
    docSetInfo: null,
  },
  search: {
    userQuery: undefined,
    loading: false,
    page: 0,
    filters: null,
    result: null,
    autoCompleteItems: [],
  },
  changes: {
    items: [],
    currentPage: -1,
  },
  news: {
    result: null,
    currentPage: 0,
  },
  content: {
    contentNode: null,
    toc: null,
  },
  feedbackSentSuccessfully: null,
}

const willDocSetChange = (action: KvLoadDocument, state: KvState): boolean => {
  const {structure} = state.reader
  if (!structure) {
    return true
  }
  if (haveKvParamsChanged(action.kvParams, state.reader.kvParams)) {
    return true
  }
  return structure.main.link.id !== action.docSetId
}

const willContentChange = (action: KvLoadDocument, contentNode: ContentNode | null): boolean => {
  if (!contentNode) {
    return true
  }
  return contentNode.id !== action.documentId
}

const reduceLoadContent = (state: KvState, action: KvLoadDocument): KvState => {
  const docSetWillChange = willDocSetChange(action, state)
  const contentWillChange = willContentChange(action, state.reader.content)
  return {
    ...state,
    reader: {
      ...state.reader,
      structure: docSetWillChange ? null : state.reader.structure,
      content: contentWillChange ? null : state.reader.content,
      documentInfo: docSetWillChange ? null : state.reader.documentInfo,
      docSetName: docSetWillChange ? '' : state.reader.docSetName,
      emptySearch: false,
      loading: true,
      docSetInfo: null,
    },
  }
}

export const kvReducer = (state: KvState = initialState, action: KvAction): KvState => {
  switch (action.type) {
    case KvActionKeys.LOAD_DOCUMENT: {
      return reduceLoadContent(state, action)
    }
    case KvActionKeys.LOAD_DOCUMENT_SUCCESS:
      return {
        ...state,
        reader: {
          ...state.reader,
          structure: action.structure,
          content: action.content,
          documentInfo: action.documentInfo,
          docSetName: action.docSetName,
          emptySearch: false,
          kvParams: action.kvParams,
          loading: false,
        },
      }
    case KvActionKeys.LOAD_DOCUMENT_ERROR:
      return {
        ...state,
        reader: {
          ...state.reader,
          emptySearch: true,
          loading: false,
          content: null,
        },
      }
    case KvActionKeys.EMPTY_SEARCH_RESULT:
      return {
        ...state,
        reader: {
          ...state.reader,
          kvParams: action.kvParams,
          docSetName: action.docsetDisplayName,
          structure: action.structure,
          documentInfo: null,
          emptySearch: true,
          content: null,
          loading: false,
        },
      }
    case KvActionKeys.DOCUMENT_NOT_AVAILABLE:
      return {
        ...state,
        reader: {
          ...state.reader,
          kvParams: action.kvParams,
          docSetName: action.docsetDisplayName,
          structure: action.structure,
          documentInfo: null,
          emptySearch: true,
          content: null,
          loading: false,
        },
      }
    case KvActionKeys.SEARCH: {
      return {
        ...state,
        search: {
          ...state.search,
          loading: true,
          userQuery: action.userQuery,
          filters: action.filter || null,
        },
      }
    }
    case KvActionKeys.SEARCH_SUCCESS:
      return {
        ...state,
        search: {
          ...state.search,
          loading: false,
          result: action.searchResult,
          page: 0,
        },
      }
    case KvActionKeys.SEARCH_MORE_SUCCESS:
      return {
        ...state,
        search: {
          ...state.search,
          result: mergeSearchResults(state.search.result, action.searchResult),
          page: action.page,
        },
      }
    case KvActionKeys.SEARCH_AUTO_COMPLETE_SUCCESS:
      return {
        ...state,
        search: {
          ...state.search,
          autoCompleteItems: action.items,
        },
      }
    case KvActionKeys.LOAD_SOURCE_INFO:
      return {
        ...state,
        reader: {
          ...state.reader,
          sourceInfo: null,
        },
      }
    case KvActionKeys.LOAD_SOURCE_INFO_SUCCESS:
      return {
        ...state,
        reader: {
          ...state.reader,
          sourceInfo: action.sourceInfo,
        },
      }
    case KvActionKeys.LOAD_CHANGES_SUCCESS:
      return {
        ...state,
        changes: {
          items: action.items,
          currentPage: action.nextPage,
        },
      }
    case KvActionKeys.LOAD_NEWS_SUCCESS:
      return {
        ...state,
        news: {
          result: action.result,
          currentPage: action.currentPage,
        },
      }
    case KvActionKeys.LOAD_CONTENT:
      return {
        ...state,
        content: {
          ...state.content,
          contentNode: null,
        },
      }
    case KvActionKeys.LOAD_CONTENT_SUCCESS:
      return {
        ...state,
        content: {
          toc: action.toc,
          contentNode: action.contentNode,
        },
      }
    case KvActionKeys.SEND_FEEDBACK: {
      return {
        ...state,
        feedbackSentSuccessfully: null,
      }
    }
    case KvActionKeys.SEND_FEEDBACK_SUCCESS: {
      return {
        ...state,
        feedbackSentSuccessfully: true,
      }
    }
    case KvActionKeys.SEND_FEEDBACK_ERROR: {
      return {
        ...state,
        feedbackSentSuccessfully: false,
      }
    }
    case KvActionKeys.LOAD_DOCSET_INFO_SUCCESS: {
      return {
        ...state,
        reader: {
          ...state.reader,
          docSetInfo: action.docSetInfo,
          structure: action.structure,
          docSetName: action.docSetName,
        },
      }
    }
  }
  return state
}
