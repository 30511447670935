import {Spacing, Text} from '@indoqa/style-system'
import {IStyle} from 'fela'
import * as React from 'react'
import {useFela} from 'react-fela'
import {undecoratedLinksRules} from '../../../app/mixins'
import {Theme} from '../../../app/theme'

interface Props {
  name: string
  linkTo: string
  marginLeft?: Spacing
}

export const FooterLink: React.FC<Props> = ({name, linkTo, marginLeft}) => {
  const {theme} = useFela<Theme>()
  const style: IStyle = {
    color: 'inherit',
    marginLeft: marginLeft !== undefined ? marginLeft : theme.spacing.space2,
    '& a': {
      color: theme.colors.textInverted,
    },
    tablet: {
      marginLeft: marginLeft !== undefined ? marginLeft : theme.spacing.space4,
    },
  }
  return (
    <Text style={[style, undecoratedLinksRules()]}>
      <a href={linkTo}>{name}</a>
    </Text>
  )
}
