import {useQueryParam} from '@oegbv/ui-shared'
import * as React from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {useParams} from 'react-router-dom'
import {ReaderFooterPanel} from '../../commons/components/footer/ReaderFooterPanel'
import {DigitalHelmet} from '../../commons/components/helmet/DigitalHelmet'
import {ReaderLayout} from '../../commons/layouts/reader-layout/ReaderLayout'
import {useError} from '../../error/hooks/useError'
import {ErrorPage} from '../../error/pages/ErrorPage'
import {LawContentPanel} from '../components/content/LawContentPanel'
import {LawParagraphPageContentPanel} from '../components/content/LawParagraphPageContentPanel'
import {LawReaderHeaderPanel} from '../components/header/LawReaderHeaderPanel'
import {LawStructurePanel} from '../components/structure/LawStructurePanel'
import {lawLoadCompleteDocument, lawLoadDocument} from '../store/law.actions'
import {lawPathReader, lawPathReaderLawComplete, lawQueryParams} from '../store/law.paths'
import {
  selectReaderCompleteContent,
  selectReaderContent,
  selectReaderDocumentInfo,
  selectReaderStructure,
} from '../store/law.selectors'

interface LocationParams {
  lawId: string
  documentId: string
}

const createLawTitle = (lawName: string, lawParagraphName?: string, lawShortName?: string) => {
  return `${lawParagraphName ? lawParagraphName + ' | ' : ''}${lawName}${lawShortName ? ' - ' + lawShortName : ''}`
}

export const LawReaderPage: React.FC = () => {
  const {lawId, documentId} = useParams<LocationParams>()
  const completeLawDocument = useQueryParam(lawQueryParams.completeLawDocument)
  const lawGroupId = useQueryParam(lawQueryParams.groupId)
  const dispatch = useDispatch()

  React.useEffect(() => {
    if (!lawId) {
      return
    }
    if (completeLawDocument === null && !documentId) {
      dispatch(lawLoadCompleteDocument(lawId))
    } else {
      dispatch(lawLoadDocument(lawId, documentId, lawGroupId))
    }
    return
  }, [dispatch, lawId, documentId, completeLawDocument, lawGroupId])

  const structure = useSelector(selectReaderStructure)
  const content = useSelector(selectReaderContent)
  const completeContent = useSelector(selectReaderCompleteContent)
  const documentInfo = useSelector(selectReaderDocumentInfo)

  const lawName = structure ? structure.link.name : ''
  const paragraphName = documentInfo ? documentInfo.documentName : ''
  const lawShortName = structure ? structure.subTitle : ''

  // error handling
  const hasError = useError()
  if (hasError) {
    return <ErrorPage />
  }

  // no rendering if there is no law set
  if (!structure || !lawId || !lawName) {
    return null
  }

  const contentRequiresNav = content !== null
  const lawHeader = (
    <LawReaderHeaderPanel
      lawStructure={structure}
      lawDocumentInfo={documentInfo}
      navShown={contentRequiresNav}
      groupId={lawGroupId}
    />
  )
  const lawNav = contentRequiresNav ? (
    <LawStructurePanel structure={structure} documentId={documentId} lawId={lawId} groupId={lawGroupId} />
  ) : undefined
  const lawFooter = <ReaderFooterPanel nowrap />

  const paragraphContentPanel = content && (
    <LawParagraphPageContentPanel
      content={content}
      navShown={true}
      lawStructure={structure}
      lawDocumentInfo={documentInfo}
    />
  )
  const completeContentPanel = completeContent && <LawContentPanel content={completeContent} navShown={false} />
  const contentPanel = content ? paragraphContentPanel : completeContentPanel

  const canonical =
    documentId && paragraphName
      ? lawPathReader(lawId, documentId, lawName, paragraphName, lawShortName)
      : lawPathReaderLawComplete(lawId, lawName, lawShortName)

  return (
    <ReaderLayout header={lawHeader} nav={lawNav} footer={lawFooter} enableScrollTop>
      <DigitalHelmet
        title={createLawTitle(lawName, paragraphName, lawShortName)}
        noIndex={!!completeContent}
        canonical={canonical}
      />
      {contentPanel}
    </ReaderLayout>
  )
}
