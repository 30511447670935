import {ActionsObservable, combineEpics, ofType, StateObservable} from 'redux-observable'
import {ajax} from 'rxjs/ajax'
import {takeUntil} from 'rxjs/operators'
import {arEpics} from '../ar/store/ar.epics'
import {bookEpics} from '../book/store/book.epics'
import {digitalEpics} from '../digital/store/digital.epics'
import {errorEpics} from '../error/store/error.epics'
import {kvEpics} from '../kv/store/kv.epics'
import {lawEpics} from '../law/store/law.epics'
import history from './history'
import {RootAction, RootState} from './types'

export const services = {
  ajax,
  scheduler: undefined,
  history,
}

// prettier-ignore
const combinedEpics = combineEpics(
  ...arEpics,
  ...bookEpics,
  ...digitalEpics,
  ...errorEpics,
  ...kvEpics,
  ...lawEpics
)

// prettier-ignore
const rootEpic = (action$: ActionsObservable<RootAction>, state$: StateObservable<RootState>) => {
  return combinedEpics(action$, state$, services).pipe(
    takeUntil(action$.pipe(
      ofType('END')
    ))
  )
}

export default rootEpic
