import {Action} from 'redux'
import {ContentNode} from '../../commons/types/ContentNode'
import {DocumentInfo} from '../../commons/types/DocumentInfo'
import {SearchResult} from '../../commons/types/SearchResult'
import {SourceInfo} from '../../commons/types/SourceInfo'
import {TableOfContent} from '../../commons/types/TableOfContent'
import {ThrowError} from '../../error/store/error.actions'
import {
  DocSetInfo,
  DocsetStructure,
  KvActionKeys,
  KvAutoCompleteItem,
  KvChangesItem,
  KvReaderQueryParams,
} from './kv.types'

export type KvAction =
  | KvLoadDocument
  | KvLoadDocumentSuccess
  | KvLoadDocumentError
  | KvRedirectLoadContent
  | KvEmptySearchResult
  | KvDocumentNotAvailable
  | KvSearch
  | KvSearchSuccess
  | KvSearchMore
  | KvSearchMoreSuccess
  | KvSearchAutoComplete
  | KvSearchAutoCompleteSuccess
  | KvLoadSourceInfo
  | KvLoadSourceInfoSuccess
  | KvLoadChanges
  | KvLoadChangesMore
  | KvLoadChangesSuccess
  | KvLoadNews
  | KvLoadNewsMore
  | KvLoadNewsSuccess
  | KvLoadContent
  | KvLoadContentSuccess
  | KvSendFeedback
  | KvSendFeedbackSuccess
  | KvSendFeedbackError
  | KvLoadDocsetInfoSuccess
  | ThrowError

// ----------------------------------------------------------------------------

export interface KvLoadDocument extends Action {
  type: KvActionKeys.LOAD_DOCUMENT
  docSetId: string
  documentId?: string
  kvParams?: KvReaderQueryParams
}

export const kvLoadDocument = (
  docSetId: string,
  documentId?: string,
  kvParams?: KvReaderQueryParams
): KvLoadDocument => ({
  type: KvActionKeys.LOAD_DOCUMENT,
  docSetId,
  documentId,
  kvParams,
})

export interface KvLoadDocumentSuccess extends Action {
  type: KvActionKeys.LOAD_DOCUMENT_SUCCESS
  structure: DocsetStructure
  content: ContentNode
  documentInfo: DocumentInfo
  docSetName: string
  kvParams: KvReaderQueryParams
}

export const kvLoadDocumentSuccess = (
  structure: DocsetStructure,
  content: ContentNode,
  documentInfo: DocumentInfo,
  docSetName: string,
  kvParams: KvReaderQueryParams
): KvLoadDocumentSuccess => ({
  type: KvActionKeys.LOAD_DOCUMENT_SUCCESS,
  structure,
  content,
  documentInfo,
  docSetName,
  kvParams,
})

export interface KvRedirectLoadContent extends Action {
  type: KvActionKeys.REDIRECT_LOAD_CONTENT
}

export const kvRedirectContent = (): KvRedirectLoadContent => ({
  type: KvActionKeys.REDIRECT_LOAD_CONTENT,
})

export interface KvLoadDocumentError extends Action {
  type: KvActionKeys.LOAD_DOCUMENT_ERROR
}

export const kvLoadDocumentError = (): KvLoadDocumentError => ({
  type: KvActionKeys.LOAD_DOCUMENT_ERROR,
})

export interface KvEmptySearchResult extends Action {
  type: KvActionKeys.EMPTY_SEARCH_RESULT
  kvParams: KvReaderQueryParams
  structure: DocsetStructure
  docsetDisplayName: string
}

export const kvEmptySearchResult = (
  kvParams: KvReaderQueryParams,
  structure: DocsetStructure,
  docsetDisplayName: string
): KvEmptySearchResult => ({
  type: KvActionKeys.EMPTY_SEARCH_RESULT,
  kvParams,
  structure,
  docsetDisplayName,
})

export interface KvDocumentNotAvailable extends Action {
  type: KvActionKeys.DOCUMENT_NOT_AVAILABLE
  kvParams: KvReaderQueryParams
  structure: DocsetStructure
  docsetDisplayName: string
}

export const kvDocumentNotAvailable = (
  kvParams: KvReaderQueryParams,
  structure: DocsetStructure,
  docsetDisplayName: string
): KvDocumentNotAvailable => ({
  type: KvActionKeys.DOCUMENT_NOT_AVAILABLE,
  kvParams,
  structure,
  docsetDisplayName,
})

// ----------------------------------------------------------------------------

export interface KvFilters {
  province?: string | null
  union?: string | null
  ang_arb?: string | null
  contentModified?: string | null
  validOn?: string | null
  sector?: string | null
}

export interface KvSearch extends Action {
  type: KvActionKeys.SEARCH
  userQuery?: string
  filter?: KvFilters
}

export const kvSearch = (userQuery?: string, filter?: KvFilters): KvSearch => ({
  type: KvActionKeys.SEARCH,
  userQuery,
  filter,
})

export interface KvSearchSuccess extends Action {
  type: KvActionKeys.SEARCH_SUCCESS
  searchResult: SearchResult | null
}

export const kvSearchSuccess = (searchResult: SearchResult | null): KvSearchSuccess => ({
  type: KvActionKeys.SEARCH_SUCCESS,
  searchResult,
})

export interface KvSearchAutoComplete extends Action {
  type: KvActionKeys.SEARCH_AUTO_COMPLETE
  userQuery: string
}

export interface KvSearchMore extends Action {
  type: KvActionKeys.SEARCH_MORE
}

export const kvSearchMore = (): KvSearchMore => ({
  type: KvActionKeys.SEARCH_MORE,
})

export interface KvSearchMoreSuccess extends Action {
  type: KvActionKeys.SEARCH_MORE_SUCCESS
  searchResult: SearchResult | null
  page: number
}

export const kvSearchMoreSuccess = (searchResult: SearchResult | null, page: number): KvSearchMoreSuccess => ({
  type: KvActionKeys.SEARCH_MORE_SUCCESS,
  searchResult,
  page,
})

export interface KvSearchAutoComplete extends Action {
  type: KvActionKeys.SEARCH_AUTO_COMPLETE
  userQuery: string
}

export const searchAutoComplete = (userQuery: string): KvSearchAutoComplete => ({
  type: KvActionKeys.SEARCH_AUTO_COMPLETE,
  userQuery,
})

export interface KvSearchAutoCompleteSuccess extends Action {
  type: KvActionKeys.SEARCH_AUTO_COMPLETE_SUCCESS
  items: KvAutoCompleteItem[]
}

export const searchAutoCompleteSuccess = (items: KvAutoCompleteItem[]): KvSearchAutoCompleteSuccess => ({
  type: KvActionKeys.SEARCH_AUTO_COMPLETE_SUCCESS,
  items,
})

// ----------------------------------------------------------------------------

export interface KvLoadSourceInfo extends Action {
  type: KvActionKeys.LOAD_SOURCE_INFO
  documentId?: string
  paraId?: string
}

export const kvLoadSourceInfo = (documentId?: string, paraId?: string): KvLoadSourceInfo => ({
  type: KvActionKeys.LOAD_SOURCE_INFO,
  documentId,
  paraId,
})

export interface KvLoadSourceInfoSuccess extends Action {
  type: KvActionKeys.LOAD_SOURCE_INFO_SUCCESS
  sourceInfo: SourceInfo | null
}

export const kvLoadSourceInfoSuccess = (sourceInfo: SourceInfo | null): KvLoadSourceInfoSuccess => ({
  type: KvActionKeys.LOAD_SOURCE_INFO_SUCCESS,
  sourceInfo,
})

// ----------------------------------------------------------------------------

export interface KvLoadChanges extends Action {
  type: KvActionKeys.LOAD_CHANGES
}

export const kvLoadChanges = (): KvLoadChanges => ({
  type: KvActionKeys.LOAD_CHANGES,
})

export interface KvLoadChangesMore extends Action {
  type: KvActionKeys.LOAD_CHANGES_MORE
}

export const kvLoadChangesMore = (): KvLoadChangesMore => ({
  type: KvActionKeys.LOAD_CHANGES_MORE,
})

export interface KvLoadChangesSuccess extends Action {
  type: KvActionKeys.LOAD_CHANGES_SUCCESS
  items: KvChangesItem[]
  nextPage: number
}

export const kvLoadChangesSuccess = (items: KvChangesItem[], nextPage: number): KvLoadChangesSuccess => ({
  type: KvActionKeys.LOAD_CHANGES_SUCCESS,
  items,
  nextPage,
})

// ----------------------------------------------------------------------------

export interface KvLoadNews extends Action {
  type: KvActionKeys.LOAD_NEWS
}

export const kvLoadNews = (): KvLoadNews => ({
  type: KvActionKeys.LOAD_NEWS,
})

export interface KvLoadNewsMore extends Action {
  type: KvActionKeys.LOAD_NEWS_MORE
}

export const kvLoadNewsMore = (): KvLoadNewsMore => ({
  type: KvActionKeys.LOAD_NEWS_MORE,
})

export interface KvLoadNewsSuccess extends Action {
  type: KvActionKeys.LOAD_NEWS_SUCCESS
  result: SearchResult
  currentPage: number
}

export const kvLoadNewsSuccess = (result: SearchResult, currentPage: number): KvLoadNewsSuccess => ({
  type: KvActionKeys.LOAD_NEWS_SUCCESS,
  result,
  currentPage,
})

// ----------------------------------------------------------------------------

export interface KvLoadContent extends Action {
  type: KvActionKeys.LOAD_CONTENT
  tocId: string
  documentId?: string
}

export const kvLoadContent = (tocId: string, documentId?: string): KvLoadContent => ({
  type: KvActionKeys.LOAD_CONTENT,
  tocId,
  documentId,
})

export interface KvLoadContentSuccess extends Action {
  type: KvActionKeys.LOAD_CONTENT_SUCCESS
  toc: TableOfContent
  contentNode: ContentNode
}

export const kvLoadContentSuccess = (toc: TableOfContent, contentNode: ContentNode): KvLoadContentSuccess => ({
  type: KvActionKeys.LOAD_CONTENT_SUCCESS,
  toc,
  contentNode,
})

// ----------------------------------------------------------------------------

export interface KvSendFeedback extends Action {
  type: KvActionKeys.SEND_FEEDBACK
  url: string
  documentId: string
  docSetId: string
  text: string
  date: Date
}

export const kvSendFeedback = (
  url: string,
  docSetId: string,
  documentId: string,
  text: string,
  date: Date
): KvSendFeedback => ({
  type: KvActionKeys.SEND_FEEDBACK,
  url,
  documentId,
  docSetId,
  text,
  date,
})

export interface KvSendFeedbackSuccess extends Action {
  type: KvActionKeys.SEND_FEEDBACK_SUCCESS
}

export const kvSendFeedbackSuccess = (): KvSendFeedbackSuccess => ({
  type: KvActionKeys.SEND_FEEDBACK_SUCCESS,
})

export interface KvSendFeedbackError extends Action {
  type: KvActionKeys.SEND_FEEDBACK_ERROR
}

export const kvSendFeedbackError = (): KvSendFeedbackError => ({
  type: KvActionKeys.SEND_FEEDBACK_ERROR,
})

export interface KvLoadDocsetInfoSuccess extends Action {
  type: KvActionKeys.LOAD_DOCSET_INFO_SUCCESS
  docSetInfo: DocSetInfo
  structure: DocsetStructure
  docSetName: string
}

export const kvLoadDocsetInfoSuccess = (
  docSetInfo: DocSetInfo,
  structure: DocsetStructure,
  docSetName: string
): KvLoadDocsetInfoSuccess => ({
  type: KvActionKeys.LOAD_DOCSET_INFO_SUCCESS,
  docSetInfo,
  structure,
  docSetName,
})
