import {Box, Text} from '@indoqa/style-system'
import {IStyle} from 'fela'
import * as React from 'react'
import {useFela} from 'react-fela'
import {Theme} from '../../../app/theme'
import {ThemedIcon} from '../../../commons-shared/themed-icon/ThemedIcon'
import {ReactComponent as RemoveIcon} from '../../../commons/icons/remove.svg'

interface Props {
  videoOnly: boolean
  bookTitleOnly: boolean
  onVideoOnly: (checked: boolean) => void
  onBookTitleOnly: (checked: boolean) => void
}

export function ArTypeFilter({videoOnly, bookTitleOnly, onVideoOnly, onBookTitleOnly}: Props) {
  const {theme, css} = useFela<Theme>()
  const inputStyle: IStyle = {
    marginRight: theme.spacing.space1_5,
    display: 'none',
  }
  const labelStyle: IStyle = {
    color: videoOnly ? 'red' : undefined,
    cursor: 'pointer',
  }
  return (
    <Box>
      <Box fontSize="small">
        <label className={css(labelStyle)}>
          <input
            className={css(inputStyle)}
            type="checkbox"
            checked={videoOnly}
            onChange={(e) => onVideoOnly(e.target.checked)}
          />
          <Text mr={1}>Nur Videos anzeigen</Text>
          {videoOnly && (
            <Text style={{position: 'relative', top: -2}}>
              <ThemedIcon size={14} color={theme.colors.accent} hoverColor={theme.colors.accent}>
                <RemoveIcon />
              </ThemedIcon>
            </Text>
          )}
        </label>
      </Box>
      <Box fontSize="small" display="none">
        <label>
          <input
            className={css(inputStyle)}
            type="checkbox"
            checked={bookTitleOnly}
            onChange={(e) => onBookTitleOnly(e.target.checked)}
          />
          Nur Buchtitel anzeigen
        </label>
      </Box>
    </Box>
  )
}
