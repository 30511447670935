import {Box} from '@indoqa/style-system'
import * as React from 'react'
import {Theme} from '../../../../app/theme'
import {AlignedDigitalStructurePanel} from '../../../../commons/components/structure/AlignedDigitalStructurePanel'
import {CreateDigitalLink} from '../../../../commons/components/structure/digitalStructure.types'
import {convertDate} from '../../../../commons/utils/formatDate'
import {bookPathWebBookSlug} from '../../../store/book.paths'
import {BookStructureNode} from '../../../store/book.types'
import {BookWebbookSlugTreeView} from './BookWebbookSlugTreeView'

interface Props {
  structure: BookStructureNode | null
  paraId?: string
}

export const BookWebbookSlugStructurePanel: React.FC<Props> = ({structure, paraId}) => {
  if (!structure) {
    return null
  }
  const createDigitalLink: CreateDigitalLink = (chapterSlug) => {
    return bookPathWebBookSlug('arbvg', chapterSlug)
  }

  const titel = structure.properties?.quelle_lang
  const rechtsstand = structure.properties?.rechtsstand

  return (
    <AlignedDigitalStructurePanel noPaddingTop>
      {rechtsstand && (
        <Box<Theme> bg="bgContentEmphasised" fullWidth px={2} pt={2} pb={1} mb={2} fontStyle="brand" fontSize="small">
          {titel}
          <br />
          Rechtsstand: {convertDate(rechtsstand)}
        </Box>
      )}
      <BookWebbookSlugTreeView structure={structure} paraId={paraId} createDigitalLink={createDigitalLink} />
    </AlignedDigitalStructurePanel>
  )
}
