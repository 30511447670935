import {Box, Flex, VStack} from '@indoqa/style-system'
import {IStyle} from 'fela'
import {ArCallToActionButton} from './ArCallToActionButton'
import {ArTestzugangButton} from './ArTestzugangButton'

export const ArCallToActionPanel = () => {
  const ctaBoxStyle: IStyle = {
    flexDirection: 'column',
    tablet: {
      flexDirection: 'row',
    },
  }
  return (
    <Flex nowrap style={ctaBoxStyle}>
      <Box bg="bgContentEmphasised" p={3} r={8}>
        <h2>Sichere dir jetzt: Arbeitsrecht für Betriebsräte PLUS</h2>
        <p>
          Arbeitsrecht für Betriebsräte PLUS bietet eine Vielzahl zusätzlicher Inhalte – von Literatur speziell für die
          Betriebsratsarbeit bis hin zum Standardwerk „Arbeitsrecht/Der Löschnigg".
        </p>
      </Box>
      <Flex px={5} py={3} center>
        <VStack fullWidth spacing={2} minWidth={340}>
          <ArCallToActionButton />
          <ArTestzugangButton />
        </VStack>
      </Flex>
    </Flex>
  )
}
