import {LinkType} from '../../../commons/types/Link'
import {StructureNode} from '../../../commons/types/Structure'
import {DocsetStructure} from '../../store/kv.types'
import {Queue} from './Queue'

export interface Hit {
  documentId: string
  documentName: string
  paraId: string
}

export const getHitsFromDocsetStructure = (structure?: DocsetStructure | null): Hit[] => {
  if (!structure) {
    return []
  }
  const hits: Hit[] = []
  hits.push(...getHitsFromStructure(structure.main))
  if (structure.family) {
    hits.push(...getHitsFromStructure(structure.family))
  }
  return hits
}

const getHitsFromStructure = (structure: StructureNode): Hit[] => {
  if (!structure) {
    return []
  }
  const hits: Hit[] = []
  const queue = new Queue<StructureNode>()
  let currentDocumentId
  queue.enqueue([structure])
  while (queue.length > 0) {
    const nextNode = queue.dequeue()

    if (nextNode.link.type === LinkType.KV_TIME_SLICE) {
      currentDocumentId = nextNode.link.id
    }

    if (nextNode.hits && nextNode.hits > 0 && currentDocumentId) {
      hits.push({documentId: currentDocumentId, documentName: nextNode.link.name, paraId: nextNode.link.id})
    }

    // go deeper into the structure
    if (nextNode.children) {
      queue.enqueue(nextNode.children)
    }
  }
  return hits
}
