import {Box, Flex} from '@indoqa/style-system'
import {convertDate} from '../../commons/utils/formatDate'

interface Props {
  meta?: {[key: string]: string}
}

export const ArBookMetaComponent = ({meta}: Props) => {
  if (!meta) {
    return null
  }
  const quelleLang = meta['quelle_lang']
  const authoren = meta['authoren']
  const links = meta['links']?.replace(/<a /g, '<a target="_blank" ')
  const rechtsstand = convertDate(meta['rechtsstand'])
  return (
    <Box textAlign="center" mt={4}>
      <div className="bookMeta">
        <hr />
        <Box mt={3}>
          {quelleLang && (
            <Box my={1}>
              <strong>Quelle:</strong> {quelleLang}
            </Box>
          )}
          {authoren && (
            <Flex fullWidth justifyContent="center">
              <Box grow={0} textAlign="right">
                <strong>AutorInnen:&nbsp;</strong>
              </Box>
              <Box grow={0}>
                <div dangerouslySetInnerHTML={{__html: authoren}} />
              </Box>
            </Flex>
          )}
          {rechtsstand && (
            <Box>
              <strong>Rechtsstand:</strong> {rechtsstand}
            </Box>
          )}
          {links && (
            <Box>
              <div dangerouslySetInnerHTML={{__html: links}} />
            </Box>
          )}
        </Box>
      </div>
    </Box>
  )
}
