import {cleanUrlPathPart as c} from '../../commons/utils/urlUtils'

export const lawQueryParams = {
  userQuery: 'q',
  completeLawDocument: 'gesamt',
  groupId: 'g',
  redirectLawId: 'lawId',
  redirectSectionId: 'sectionId',
}

export const lawPathParams = {
  lawId: 'lawId',
  documentId: 'documentId',
  lawName: 'lawName',
  documentName: 'documentName',
  groupId: 'groupId',
  groupName: 'groupName',
}

const LAW_BASE_PATH = '/recht'
const LAW_BASE_PATH_READER = `${LAW_BASE_PATH}/g`
const LAW_BASE_PATH_GROUP = `${LAW_BASE_PATH}/l`
export const LAW_BASE_PATH_REDIRECT = `${LAW_BASE_PATH}/r`

const buildLawName = (lawName: string, shortName?: string) => {
  if (shortName) {
    return `${c(shortName)}_${c(lawName)}`
  }
  return c(lawName)
}

export const lawPathOverview = (userQuery?: string) => {
  return !userQuery || userQuery === '' ? LAW_BASE_PATH : `${LAW_BASE_PATH}?q=${userQuery}`
}

export const lawPathGroup = (groupId: string, groupName: string) => {
  return `${LAW_BASE_PATH_GROUP}/${groupId}/${c(groupName)}`
}

export const lawPathGroupId = (groupId: string) => {
  return `${LAW_BASE_PATH_GROUP}/${groupId}`
}

export const lawPathReader = (
  lawId: string,
  documentId: string,
  lawName: string,
  documentName: string,
  shortName?: string,
  groupId?: string | null
) => {
  const queryParams = groupId !== undefined && groupId !== null ? `?${lawQueryParams.groupId}=${groupId}` : ''
  return `${LAW_BASE_PATH_READER}/${lawId},${documentId}/${buildLawName(lawName, shortName)}/${c(
    documentName
  )}${queryParams}`
}

export const lawPathReaderIds = (lawId: string, documentId: string) => {
  return `${LAW_BASE_PATH_READER}/${lawId},${documentId}`
}

export const lawPathReaderLaw = (lawId: string, lawName: string, shortName?: string) => {
  return `${LAW_BASE_PATH_READER}/${lawId}/${buildLawName(lawName, shortName)}`
}

export const lawPathReaderLawWithGroup = (
  lawId: string,
  lawName: string,
  shortName?: string,
  groupId?: string | null
) => {
  if (!groupId) {
    return lawPathReaderLaw(lawId, lawName, shortName)
  }
  return `${LAW_BASE_PATH_READER}/${lawId}/${buildLawName(lawName, shortName)}?${lawQueryParams.groupId}=${groupId}`
}

export const lawPathReaderLawId = (lawId: string) => {
  return `${LAW_BASE_PATH_READER}/${lawId}`
}

export const lawPathReaderLawComplete = (lawId: string, lawName: string, shortName?: string) => {
  return `${LAW_BASE_PATH_READER}/${lawId}/${buildLawName(lawName, shortName)}?${lawQueryParams.completeLawDocument}`
}

export const lawPathRedirect = (lawId: string | null, sectionId?: string | null) => {
  return `${LAW_BASE_PATH_REDIRECT}?${lawQueryParams.redirectLawId}=${lawId}&${lawQueryParams.redirectSectionId}=${
    sectionId || ''
  }`
}
