import {Action} from 'redux'
import {ContentNode} from '../../commons/types/ContentNode'
import {DocumentInfo} from '../../commons/types/DocumentInfo'
import {ResultDocument, SearchResult} from '../../commons/types/SearchResult'
import {StructureNode} from '../../commons/types/Structure'
import {ThrowError} from '../../error/store/error.actions'
import {BookActionKeys, EBooksFilters} from './book.types'

export type BookAction =
  | BookLoadEbooks
  | BookLoadEbooksSuccess
  | BookLoadEbook
  | BookLoadEbookSuccess
  | BookLoadWebBookContent
  | BookLoadWebBookContentSuccess
  | ThrowError

// ----------------------------------------------------------------------------

export interface BookLoadEbooks extends Action {
  type: BookActionKeys.LOAD_EBOOKS
  userQuery?: string | null
  filters?: EBooksFilters | null
}

export const bookLoadEbooks = (userQuery?: string | null, filters?: EBooksFilters | null): BookLoadEbooks => ({
  type: BookActionKeys.LOAD_EBOOKS,
  userQuery,
  filters,
})

export interface BookLoadEbooksSuccess extends Action {
  type: BookActionKeys.LOAD_EBOOKS_SUCCESS
  ebooks: SearchResult
}

export const bookLoadEbooksSuccess = (ebooks: SearchResult): BookLoadEbooksSuccess => ({
  type: BookActionKeys.LOAD_EBOOKS_SUCCESS,
  ebooks,
})

// ----------------------------------------------------------------------------

export interface BookLoadEbook extends Action {
  type: BookActionKeys.LOAD_EBOOK
  id: string
}

export const bookLoadEbook = (id: string): BookLoadEbook => ({
  type: BookActionKeys.LOAD_EBOOK,
  id,
})

export interface BookLoadEbookSuccess extends Action {
  type: BookActionKeys.LOAD_EBOOK_SUCCESS
  resultDocument: ResultDocument
}

export const bookLoadEbookSuccess = (resultDocument: ResultDocument): BookLoadEbookSuccess => ({
  type: BookActionKeys.LOAD_EBOOK_SUCCESS,
  resultDocument,
})

// ----------------------------------------------------------------------------

export interface BookLoadWebBookContent extends Action {
  type: BookActionKeys.LOAD_WEBBOOK_CONTENT
  bookId: string
  chapterId?: string | null
  paraId?: string | null
  suppressRedirect?: boolean
}

export const bookLoadWebBookContent = (
  bookId: string,
  chapterId?: string | null,
  paraId?: string | null,
  suppressRedirect?: boolean
): BookLoadWebBookContent => ({
  type: BookActionKeys.LOAD_WEBBOOK_CONTENT,
  bookId,
  chapterId,
  paraId,
  suppressRedirect,
})

export interface BookLoadWebBookContentSuccess extends Action {
  type: BookActionKeys.LOAD_WEBBOOK_CONTENT_SUCCESS
  structure: StructureNode
  content: ContentNode
  documentInfo: DocumentInfo
}

export const bookLoadWebBookContentSuccess = (
  structure: StructureNode,
  content: ContentNode,
  documentInfo: DocumentInfo
): BookLoadWebBookContentSuccess => ({
  type: BookActionKeys.LOAD_WEBBOOK_CONTENT_SUCCESS,
  structure,
  content,
  documentInfo,
})
