import {Flex, Text} from '@indoqa/style-system'
import {IStyle} from 'fela'
import * as React from 'react'
import {useFela} from 'react-fela'
import {Theme} from '../../../app/theme'
import {ThemedIcon} from '../../../commons-shared/themed-icon/ThemedIcon'
import {ReactComponent as CloseIcon} from '../../icons/close.svg'
import {ReactComponent as SearchIcon} from '../../icons/search.svg'

interface Props {
  onSearch: (userInput: string) => void
  userQuery?: string
  placeholder: string
  disabled: boolean
}

export const ReaderSearchInput: React.FC<Props> = (props) => {
  const {onSearch, userQuery, placeholder, disabled} = props
  const [localUserQuery, setLocalUserQuery] = React.useState<string>(userQuery || '')
  const inputRef = React.useRef<HTMLInputElement>(null)
  const {theme, css} = useFela<Theme>()

  React.useLayoutEffect(() => {
    if (inputRef && inputRef.current) {
      inputRef.current.focus()
    }
  })

  React.useEffect(() => {
    setLocalUserQuery(userQuery || '')
  }, [userQuery])

  const containerStyle: IStyle = {
    paddingBottom: 1,
    borderBottom: `2px solid ${disabled ? theme.colors.iconReduced : theme.colors.primaryLight}`,
  }
  const inputStyle: IStyle = {
    backgroundColor: 'transparent',
    padding: 0,
    outline: 'none',
    color: theme.colors.text,
    fontSize: theme.fontSizes.small,
    border: 0,
    flex: 1,
  }
  return (
    <Flex style={containerStyle} nowrap fullWidth>
      <input
        className={css(inputStyle)}
        placeholder={placeholder}
        spellCheck={false}
        onChange={(e: any) => setLocalUserQuery(e.target.value)}
        onKeyDown={(e) => {
          if (e.key === 'Enter' && localUserQuery !== null) {
            onSearch(localUserQuery)
          }
        }}
        value={localUserQuery}
        disabled={disabled}
        ref={inputRef}
      />
      <Text
        cursorPointer
        style={{width: 20}}
        onClick={() => {
          setLocalUserQuery('')
          onSearch('')
        }}
      >
        {localUserQuery.length > 0 && (
          <ThemedIcon size={12} color={theme.colors.iconReduced}>
            <CloseIcon />
          </ThemedIcon>
        )}
      </Text>
      <Text cursorPointer onClick={() => onSearch(localUserQuery)}>
        <ThemedIcon size={20} color={disabled ? theme.colors.iconReduced : theme.colors.icon}>
          <SearchIcon />
        </ThemedIcon>
      </Text>
    </Flex>
  )
}
