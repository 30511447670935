import * as React from 'react'
import {RenderDigitalNodeProps} from '../../../../commons/components/structure/digitalStructure.types'
import {DigitalStructureFolderTemplate} from '../../../../commons/components/structure/DigitalStructureFolderTemplate'
import {StructureNode} from '../../../../commons/types/Structure'
import {useLoadStructureSlugContent} from './useLoadStructureSlugContent'

export const BookWebbookSlugStructureFolderPanel: React.FC<RenderDigitalNodeProps<StructureNode>> = (props) => {
  const {structure, style, inNodePath, selected, createDigitalLink} = props
  const {link} = structure
  useLoadStructureSlugContent(props)
  const createLink = createDigitalLink ? () => createDigitalLink(props.structure.slug!) : undefined
  return (
    <DigitalStructureFolderTemplate
      label={link.name}
      paddingRight={style.padding}
      selected={inNodePath || selected}
      createLink={createLink}
    />
  )
}
