import * as React from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {SearchMore} from '../../../commons/components/search-more/SearchMore'
import {lawSearchMore} from '../../store/law.actions'
import {selectSearchPage, selectSearchResult} from '../../store/law.selectors'

export const LawSearchMore: React.FC = () => {
  const dispatch = useDispatch()
  const page = useSelector(selectSearchPage)
  const result = useSelector(selectSearchResult)
  const totalCount = result?.documentGroupsFound || 0
  return (
    <SearchMore
      totalCount={totalCount}
      alreadyLoadedCount={(page + 1) * 10}
      loadMoreAction={() => dispatch(lawSearchMore())}
    />
  )
}
