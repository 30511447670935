import {DocumentInfo} from '../types/DocumentInfo'
import {BaseStructureNode} from '../types/Structure'
import {CreateDocumentName, findDocumentInfoById} from './findDocumentInfoById'
import {findFirstDocumentInfo, LinkPredicate} from './findFirstDocumentInfo'

export const findDocumentInfo = <T extends BaseStructureNode<T>>(
  structure: T,
  documentPredicate: LinkPredicate,
  paraPredicate: LinkPredicate,
  searchMode: boolean,
  proposedDocumentId?: string,
  proposedParaId?: string,
  createDocumentName?: CreateDocumentName<T>,
  allowNoContent?: boolean
): DocumentInfo | null => {
  if (proposedDocumentId) {
    const documentInfo = findDocumentInfoById(
      structure,
      proposedDocumentId,
      proposedParaId || '',
      searchMode,
      createDocumentName,
      allowNoContent
    )
    if (documentInfo) {
      return documentInfo
    }
  }
  return findFirstDocumentInfo(structure, documentPredicate, paraPredicate, searchMode)
}
