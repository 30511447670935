import * as React from 'react'
import {AlignedDigitalStructurePanel} from '../../../../commons/components/structure/AlignedDigitalStructurePanel'
import {CreateDigitalLink} from '../../../../commons/components/structure/digitalStructure.types'
import {StructureNode} from '../../../../commons/types/Structure'
import {findDocumentInfoByPara} from '../../../../commons/utils/findDocumentByPara'
import {bookPathWebBookId} from '../../../store/book.paths'
import {isBookChapterSubType} from '../../../store/book.utils'
import {BookWebbookIdTreeView} from './BookWebbookIdTreeView'

interface Props {
  structure: StructureNode | null
  paraId?: string
}

export const BookWebbookIdStructurePanel: React.FC<Props> = ({structure, paraId}) => {
  if (!structure) {
    return null
  }
  const createDigitalLink: CreateDigitalLink = (paraId) => {
    if (!structure) {
      throw Error('no structure')
    }
    const documentInfo = findDocumentInfoByPara(structure, paraId, isBookChapterSubType)
    if (!documentInfo || !documentInfo.documentId || !documentInfo.documentName) {
      throw Error('no document info')
    }
    const bookName = structure.link.name
    const link = bookPathWebBookId(
      structure.link.id,
      documentInfo.documentId,
      bookName,
      documentInfo.documentName || '',
      {paraId}
    )
    return link
  }
  return (
    <AlignedDigitalStructurePanel>
      <BookWebbookIdTreeView structure={structure} paraId={paraId} createDigitalLink={createDigitalLink} />
    </AlignedDigitalStructurePanel>
  )
}
