import {IRenderer} from 'fela'
import {Theme} from '../../app/theme'
import {CLASS_NAME_READER} from '../hooks/use-body-class-reader/useBodyClassReader'

export const DIGITAL_CSS_CLASSNAME = 'cp_digital'
export const DIGITAL_PARA_CSS_CLASSNAME = 'cp_digital_para'
export const DIGITAL_DEBUG_CSS_CLASSNAME = 'cp_debug'
export const DIGITAL_ENABLE_LAW_LINKS = 'cp_law-links'

const C = `.${DIGITAL_CSS_CLASSNAME}`
const C_DEBUG = `.${DIGITAL_CSS_CLASSNAME}.${DIGITAL_DEBUG_CSS_CLASSNAME}`
const C_HP = `.${DIGITAL_CSS_CLASSNAME}.homepage`
const C_LAW = `.${DIGITAL_CSS_CLASSNAME}.${DIGITAL_ENABLE_LAW_LINKS}`

const debugColors = {
  normGrp: 'green',
  normel: 'orange',
  textgr: 'blue',
  listenTitel: 'darkpink',
}

export const renderDigitalCSS = (renderer: IRenderer, theme: Theme) => {
  applyBaseRules(renderer, theme)
  applyBasePrintRules(renderer, theme)
  applyLinkRules(renderer, theme)
  applyTitelbereichRules(renderer, theme)
  applyNormelStyles(renderer, theme)
  applyListeStyles(renderer, theme)
  applyTextGrpStyles(renderer, theme)
  applyTextStyles(renderer, theme)
  applyEditorTextStyles(renderer, theme)
  applyTeasertextStyles(renderer, theme)
  applyTableRules(renderer, theme)
  applyHomepageRules(renderer, theme)
  applyKvRules(renderer, theme)
  applyBookRules(renderer, theme)
}

const applyBaseRules = (renderer: IRenderer, theme: Theme) => {
  const styles = `
    * {
      scrollbar-color: ${theme.colors.bgScrollbarThumb} ${theme.colors.bgScrollbarTrack};
      scrollbar-width: thin;
    }
    body {
      overflow-y: scroll;
      line-height: 1.5;
    }
    body.${CLASS_NAME_READER} {
      overflow-y: hidden;
    }
    h1, h2, h3, h4, h5, h6 {
      color: ${theme.colors.primary};
    }
    mark {
      background-color: ${theme.colors.bgMarkedInSearchResult};
      padding-right: 2px;
      padding-left: 2px;
      color: inherit;
    }
    .klein {
      display: inline;
    }
    img {
      display: block;
      width: 80%;
      max-width: 600px;
      margin: 1rem auto;
    }
    
    @media (min-width: 1600px) { 
      .marg {
        display: inline;
        float: right;
        position: relative;
        width: 180px;
        margin-right: -220px;
      }
    }
    @media (max-width: 1600px) { 
      .marg {
        background-color: ${theme.colors.bgContentEmphasised};
        padding: 3px;
      }
    }
    
    ::-webkit-scrollbar-track {
      background: ${theme.colors.bgScrollbarTrack};
    }
    ::-webkit-scrollbar-thumb {
      background: ${theme.colors.bgScrollbarThumb};
    }
    ::-webkit-scrollbar-thumb:hover {
      background: ${theme.colors.bgScrollbarThumbHover};
    }
    ::-webkit-scrollbar-track:hover {
      background: ${theme.colors.bgScrollbarTrackHover};
    }
    ::-webkit-scrollbar {
      width: 10px;
    }
  `
  renderer.renderStatic(styles)
}

const applyBasePrintRules = (renderer: IRenderer, theme: Theme) => {
  renderer.renderStatic(`
    @media print {
      * {
        color: black;
      }
      
      .printing-icon,
      .selective-printing .title, 
      .selective-printing .content,
      .selective-printing ${C} .footnote-catalogue {
        display: none;
      }
      .selective-printing .title.print-selected,
      .selective-printing .content.print-selected, 
      .selective-printing .print-selected .content, 
      .selective-printing .print-selected .title 
      {
        display: block;
      }
      .print-selected {
        padding-left: 0 !important;
        margin-left: 0 !important;
        padding-right: 0 !important;
        margin-right: 0 !important;
        padding-bottom: 0 !important;
        margin-bottom: 0 !important;
        border-left-width: 0 !important;
        background-color: transparent !important;
      }
    }
    .print-selected {
      padding-left: 5px;
      margin-left: -11px;
      padding-right: 15px;
      margin-right: -15px;
      padding-bottom: 10px;
      margin-bottom: -10px;
      border-left: solid 6px #511d00;
      background-color: #F3F3F3;
      border-radius: 2px;
    }
  `)
}

const applyLinkRules = (renderer: IRenderer, theme: Theme) => {
  const linkStyles = `
    ${C_LAW} a[data-law] {
      cursor: pointer;
    }
  `
  renderer.renderStatic(linkStyles)
}

// see https://confluence.oegbverlag.at/pages/viewpage.action?pageId=67045716
const applyTitelbereichRules = (renderer: IRenderer, theme: Theme) => {
  // reset margin and padding
  const resetStyles = `
    ${C} .title {
      width: 100%;
    }
    ${C} .titelbereich .titel {
      display: inline;
      margin: 0;
      padding: 0;
    }
    ${C} .titelbereich .titel * {
      display: inline;
    }
    ${C} .titelbereich .titel sup {
      font-size: 70% !important;
    }
    ${C} .titelbereich .titel .titel {
      flex-wrap: wrap;
    }
  `
  renderer.renderStatic(resetStyles)
  const titelnumSpacingRules = `
    ${C} .titelbereich .titel .titelnum {
      padding-right: 0.5ch;
      white-space: nowrap;
  }`
  renderer.renderStatic(titelnumSpacingRules)

  // untertitel
  const untertitelStyles = `
    ${C} .titelbereich .untertitel {
      text-align: center;
      font-weight: bold;
    }
  `
  renderer.renderStatic(untertitelStyles)

  // h1
  renderer.renderStatic(
    {
      ...theme.fontStyles.alt,
      fontSize: theme.fontSizes.extraBig,
    },
    `${C} .titelbereich.h1 .titel *`
  )
  renderer.renderStatic(
    {
      paddingBottom: theme.spacing.space2,
    },
    `${C} .titelbereich.h1`
  )
  renderer.renderStatic(
    {
      paddingTop: theme.spacing.space4,
    },
    `${C} .titelbereich.h1:not(:first-of-type)`
  )

  // h2
  renderer.renderStatic(
    {
      ...theme.fontStyles.alt,
      fontSize: theme.fontSizes.veryBig,
    },
    `${C} .titelbereich.h2 .titel *`
  )
  renderer.renderStatic(
    {
      paddingBottom: theme.spacing.space2,
      paddingTop: theme.spacing.space3,
    },
    `${C} .titelbereich.h2`
  )

  // h3
  renderer.renderStatic(
    {
      ...theme.fontStyles.alt,
      fontSize: theme.fontSizes.big,
    },
    `${C} .titelbereich.h3 .titel *`
  )
  renderer.renderStatic(
    {
      paddingBottom: theme.spacing.space2,
      paddingTop: theme.spacing.space3,
    },
    `${C} .titelbereich.h3`
  )

  // h4
  renderer.renderStatic(
    {
      ...theme.fontStyles.alt,
      fontSize: theme.fontSizes.text,
    },
    `${C} .titelbereich.h4 .titel *`
  )
  renderer.renderStatic(
    {
      paddingBottom: theme.spacing.space2,
      paddingTop: theme.spacing.space3,
    },
    `${C} .titelbereich.h4`
  )

  // h5
  renderer.renderStatic(
    {
      ...theme.fontStyles.alt,
      fontSize: theme.fontSizes.text,
    },
    `${C} .titelbereich.h5 .titel *`
  )
  renderer.renderStatic(
    {
      paddingBottom: theme.spacing.space2,
      paddingTop: theme.spacing.space3,
    },
    `${C} .titelbereich.h5`
  )

  // h6
  renderer.renderStatic(
    {
      ...theme.fontStyles.alt,
      fontSize: theme.fontSizes.text,
    },
    `${C} .titelbereich.h6 .titel *`
  )
  renderer.renderStatic(
    {
      paddingBottom: theme.spacing.space2,
      paddingTop: theme.spacing.space3,
    },
    `${C} .titelbereich.h6`
  )
}

const applyNormelStyles = (renderer: IRenderer, theme: Theme) => {
  const styles = `
    ${C} .normel .num, 
    ${C} .normel .titel 
    {
      font-weight: bold;
    }
    ${C_DEBUG} .normel .num,
    ${C_DEBUG} .normel .titel 
    {
      color: ${debugColors.normel};
    }
    ${C} .normel .num + .titel {
      display: inline;
    }
    ${C} .normel .num::after {
      content: ' ';
    } 
    ${C} .normel .num + p {
      display: inline;
    }
    ${C} .normel .titel + p {
      margin-top: ${theme.spacing.space2};
      margin-bottom: ${theme.spacing.space1};
    }
    ${C} .normel {
      margin-bottom: ${theme.spacing.space2};
    }
    ${C} .normel .titel + .normgrp {
      margin-top: ${theme.spacing.space2};
      margin-bottom: ${theme.spacing.space1};
    }

    ${C} .normgrp > .titel {
      font-weight: bold;
    }
    ${C_DEBUG} .normgrp > .titel {
      color: ${debugColors.normGrp};
    }
    
    ${C} .normgrp.normgrp-level-1 > .titel {
      font-size: ${theme.fontSizes.big};
      padding-bottom: ${theme.spacing.space1};
      padding-top: ${theme.spacing.space2};
    }
  `
  renderer.renderStatic(styles)
}

const applyTextGrpStyles = (renderer: IRenderer, theme: Theme) => {
  const styles = `
    ${C} .textgr {
      margin-top:0;
      margin-bottom: 0;
    }
    ${C} .textgr .textgr {
      /* margin-left: 20px; */
    }
    ${C} .textgr .text-grtit {
      font-weight: bold;
      margin-top: ${theme.spacing.space3};
    }
    ${C_DEBUG} .textgr .text-grtit {
      color: ${debugColors.textgr};
    }
    ${C} .textgr .text-grobertitel + .text-grtit {
      margin-top: 0px;
    }
    ${C} .textgr .text-grobertitel {
      margin-top: ${theme.spacing.space3};
    }
    ${C_DEBUG} .textgr .text-grobertitel {
      color: ${debugColors.textgr};
    }
    ${C_DEBUG} .textgr .text-gruntertitel {
      color: ${debugColors.textgr};
    }
    ${C} .textgr div + p {
      margin-top: ${theme.spacing.space2};    
    }
  `
  renderer.renderStatic(styles)
}

const applyListeStyles = (renderer: IRenderer, theme: Theme) => {
  const styles = `
    ${C} .liste {
      margin-bottom: ${theme.spacing.space1};
      margin-left: 0;
      padding-left: 0;
    }
    ${C} .liste .listen_titel {
      font-weight: bold;
      margin-top: ${theme.spacing.space2};
      margin-bottom: ${theme.spacing.space1};
    }
    ${C_DEBUG} .liste .listen_titel {
      color: ${debugColors.listenTitel};
    }
    ${C} .liste li {
      margin-left: 20px;
      padding-left: 5px;
    }
    ${C} .nummerierung-none li {
      padding-left: 0;
      margin-left: 0;
      list-style-type: none;
    }
    ${C} ul.liste.nummerierung-circle li {
      list-style-type: circle;
    }
    ${C} ul.nummerierung-hyphen li {
      display: table;
      list-style-type: none;
      margin-left: 0;
      padding-left: 0;
    }
    ${C} ul.nummerierung-hyphen li:before {
      display: table-cell;
      content: "\\002d";
      padding-right: 0.6em;
      word-break: keep-all;
    }
    ${C} ul.liste.nummerierung-disc li {
      list-style-type: disc;
    }
    ${C} ol.liste {
      counter-reset: item;
    }
    ${C} ol.liste li, ${C} ul.nummerierung-none.liste li {
      list-style: none;
      display: table;
      counter-increment: item;
      margin-left: 0;
      padding-left: 0;
    }
    ${C} ol.liste > li:before, ${C} ul.nummerierung-none.liste > li .listenel_num {
      display: table-cell;
      padding-right: 0.6em;
      word-break: keep-all;
    }
    ${C} ol.liste.nummerierung-decimal > li:before {
      content: counters(item, ".") ". ";
    }
    ${C} ol.liste.nummerierung-lower-alpha > li:before {
      content: "(" counter(item, lower-alpha) ") ";
    }
    ${C} ol.liste.nummerierung-lower-roman > li:before {
      content: counter(item, lower-roman) ". ";
    }
    ${C} ol.liste.nummerierung-upper-alpha > li:before {
      content: "(" counter(item, upper-alpha) ") ";
    }
    ${C} ol.liste.nummerierung-upper-roman > li:before {
      content: counter(item, upper-roman) ". ";
    }
  `
  renderer.renderStatic(styles)
}

const applyTextStyles = (renderer: IRenderer, theme: Theme) => {
  const styles = `
    ${C} mark {
      background-color: ${theme.colors.bgMarkedInText};
    }
    ${C} .fett {
      font-weight: bold;
    }
    ${C} .unter {
      text-decoration: underline;
    }
    ${C} .kursiv {
      font-style: italic;
    }
    ${C} .fussnote {
      display: inline-block !important;
      position: relative !important;
      top: -.4em !important;
      text-align: center !important;
      line-height: 180% !important;
      color: ${theme.colors.textHighlight} !important;
      font-weight: 700 !important;
      cursor: pointer !important;
    }
    ${C} .footnote-catalogue {
      display: none;
    }
    @media print {
      ${C} .fussnote {
        font-size: 0 !important;
        font-weight: normal !important;
        color: ${theme.colors.text} !important;
      }
      ${C} .fussnote:before {
        content: " (siehe Fußnote " attr(data-id) ")";
        font-size: initial;
      }
      ${C} .footnote-catalogue {
        display: block;
      }
    }
    ${C} blockquote {
      /*font-style: italic;*/
      /*background-color: #ffd1d1;*/
      padding: ${theme.spacing.space2};
      margin: ${theme.spacing.space3} 0;
    }
    ${C} q {
      /*font-style: italic;*/
      /*background-color: #ffd1d1;*/
    }
    ${C} .autor {
      /*font-style: italic;*/
      /*background-color: #ffd1d1;*/
    }
    ${C} .herausgeber {
      display: inline;
      /*font-style: italic;*/
      /*background-color: #ffd1d1;*/
    }
    ${C} .subtext {
      /*font-style: italic;*/
      /*background-color: #ffd1d1;*/
    }
    ${C} .kommentar {
      color: ${theme.colors.textHighlight};
    }
    ${C} .marg {
      /*font-style: italic;*/
      /*background-color: #ffd1d1;*/
    }
    ${C} .randziffer {
      float: left;
      font-weight: bold;
      padding-right: ${theme.spacing.space6}
    }
    ${C} .textinhaltmetadaten {
      display: none;
    }
    ${C} .drda {
      display: inline;
    }
    ${C} .drdaref {
      display: inline;
    }
  `
  renderer.renderStatic(styles)
}

const applyEditorTextStyles = (renderer: IRenderer, theme: Theme) => {
  const styles = `
    ${C} .ktanf, ${C} .ktende, ${C} .note {
      color: ${theme.colors.textHighlight};
      font-weight: bold;
    }
    ${C} .ktanf .quelle, ${C} .note > :first-child {
      display: block;
    }
    ${C} .ktanf > *, ${C} .note > * {
      font-weight: normal;
    }
    ${C} .metadata {
      color: ${theme.colors.textHighlight};
      margin-top: ${theme.spacing.space2};
      margin-bottom: ${theme.spacing.space1};
    }
    ${C} .note {
      margin: 10px 0;
    }
    ${C} .note .text {
      display: block;
    }
  `
  renderer.renderStatic(styles)
}

const applyTeasertextStyles = (renderer: IRenderer, theme: Theme) => {
  const styles = `
    ${C} .Teasertext {
      background-color: #c5f9c8;
      padding: ${theme.spacing.space2};
      margin: ${theme.spacing.space3} 0;
    }
  `
  renderer.renderStatic(styles)
}

const applyTableRules = (renderer: IRenderer, theme: Theme) => {
  const styles = `
    ${C} table {
      margin: ${theme.spacing.space3} 0;
      overflow-x: auto;
      border-width: 0;
    }
    ${C} table td, ${C} table th {
      padding: ${theme.spacing.space2};
    }
    ${C} table th[align=center] {
      text-align: center;
    }
    ${C} table th[align=right] {
      text-align: right;
    }
    ${C} table.stil-fragen td:first-child {
      white-space: nowrap;
    }
  `
  renderer.renderStatic(styles)
}

const applyHomepageRules = (renderer: IRenderer, theme: Theme) => {
  const styles = `
    ${C_HP} {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin: 0 auto;
    }
    ${C_HP} .Infotext {
      background-color: #f2f2f2;
      margin-bottom: ${theme.spacing.space4};
      padding: ${theme.spacing.space3};
      width: 100%;
      max-width: 500px;
    }
    ${C_HP}.folder .Infotext .titelbereich.h1 h1 {
      font-size: ${theme.fontSizes.text};
      font-weight: bold;
    }
    ${C_HP} img {
      margin: auto;
      max-width: 80%;
      width: 80%;
      height: auto;
      padding: ${theme.spacing.space2} 0 0 0;
    }
  `
  renderer.renderStatic(styles)
}

const applyKvRules = (renderer: IRenderer, theme: Theme) => {
  const styles = `
    ${C} span[stil=zentriert], ${C} .zentriert {
      display: block;
      text-align: center;
    }
    ${C} div[setid] {
      color: ${theme.colors.secondary};
      text-decoration: underline;
      cursor: pointer;
    }
    
    ${C} .cp_digital_para.linde_kommentar {
      border: 1px solid black;
      padding: 0 0.5rem;
    }  
    
    ${C} .cp_digital_para.linde_kommentar > div > div > div.title {
      cursor: pointer;
    }    
    ${C} .cp_digital_para.linde_kommentar.closed > div > div > div.title h3::before {
      content: '\\25BC';
      margin-right: 1ch;
    } 
    ${C} .cp_digital_para.linde_kommentar.opened > div > div > div.title h3::before {
      content: '\\25B2';
      margin-right: 1ch;
    }
    
    ${C} .cp_digital_para.linde_kommentar > div > div.content {
      margin-top: 0.5rem;
    }
  `
  // Does not work because sometimes there are header areas
  // e.g. http://localhost:3408/kv-system/kollektivvertrag/SI-2073_de,4189188_20161101/Bergbau/Rahmen?p=pr-41891880000001_7641018
  /*
    ${C} .para.d-1:not(:first-child) {
      margin-top: calc(${theme.spacing.space4} * 2);
    }
   */
  renderer.renderStatic(styles)

  const debugStyles = `
      ${C_DEBUG} .d-1, 
      ${C_DEBUG} .d-2, 
      ${C_DEBUG} .d-3 {
        padding-left: ${theme.spacing.space2};
        border-left: 5px solid ${theme.colors.bgScrollbarTrack};
      }
      ${C_DEBUG} .normgrp.level-1, 
      ${C_DEBUG} .normgrp.level-2, 
      ${C_DEBUG} .normgrp.level-3 {
        padding-left: ${theme.spacing.space2};
        border-left: 5px solid ${debugColors.normGrp};
      }
      ${C_DEBUG} .textgr {
        padding-left: ${theme.spacing.space2};
        border-left: 5px solid ${debugColors.normel};
      }
      ${C_DEBUG} .textgr.level-1, 
      ${C_DEBUG} .textgr.level-2, 
      ${C_DEBUG} .textgr.level-3 {
        padding-left: ${theme.spacing.space2};
        border-left: 5px solid ${debugColors.textgr};
      }
      ${C_DEBUG} .normel {
        border-left: 5px solid ${debugColors.normel};
        padding-left: ${theme.spacing.space2};
      }      
    `
  renderer.renderStatic(debugStyles)
}

const applyBookRules = (renderer: IRenderer, theme: Theme) => {
  const styles = `
    .bookMeta .verfasser-gr, .bookMeta .verfasser {
      display: inline;
    }
    .bookMeta .verfasser:not(:last-child):after {
      content: ", "
    }
  `
  renderer.renderStatic(styles)
}
