import * as React from 'react'
import {RenderDigitalNodeProps} from '../../../../commons/components/structure/digitalStructure.types'
import {DigitalStructureFolderTemplate} from '../../../../commons/components/structure/DigitalStructureFolderTemplate'
import {useLoadStructureContent} from '../../../../commons/components/structure/useLoadStructureContent'
import {findFirstKvParaChild} from '../../../../kv/utils/findFirstKvParaChild'
import {BookStructureNode} from '../../../store/book.types'

export const BookWebbookIdStructureFolderPanel: React.FC<RenderDigitalNodeProps<BookStructureNode>> = (props) => {
  const {structure, style, inNodePath, selected, createDigitalLink} = props
  const {link} = structure
  const firstChild = findFirstKvParaChild(structure)
  useLoadStructureContent(props, firstChild)
  const createLink = createDigitalLink ? () => createDigitalLink(props.structure.link.id) : undefined
  return (
    <DigitalStructureFolderTemplate
      label={link.name}
      paddingRight={style.padding}
      selected={inNodePath || selected}
      createLink={createLink}
    />
  )
}
