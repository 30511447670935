import {Box} from '@indoqa/style-system'
import {useQueryParam} from '@oegbv/ui-shared'
import * as React from 'react'
import {useFela} from 'react-fela'
import {useDispatch, useSelector} from 'react-redux'
import {useParams} from 'react-router-dom'
import history from '../../app/history'
import {Theme} from '../../app/theme'
import {ReaderFooterPanel} from '../../commons/components/footer/ReaderFooterPanel'
import {ReaderContentTopInfo} from '../../commons/components/header/ReaderContentTopInfo'
import {DigitalHelmet} from '../../commons/components/helmet/DigitalHelmet'
import {ReaderLayout} from '../../commons/layouts/reader-layout/ReaderLayout'
import {isMobile} from '../../commons/utils/isMobile'
import {useError} from '../../error/hooks/useError'
import {ErrorPage} from '../../error/pages/ErrorPage'
import {KvContentPanel} from '../components/content/KvContentPanel'
import {DocSetInfoPanel} from '../components/docset-info/DocSetInfoPanel'
import {
  ChangeValidityDate,
  ExecuteQuickSearch,
  ExecuteSearch,
  KvReaderHeaderPanel,
  ResetAllSearch,
} from '../components/header/KvReaderHeaderPanel'
import {SelectivePrintingComponent} from '../components/printing/SelectivePrintingComponent'
import {SelectivePrintingContext} from '../components/printing/SelectivePrintingContext'
import {useInitSelectivePrinting} from '../components/printing/useInitSelectivePrinting'
import {GoToPara, KvSearchBar} from '../components/search-bar/KvSearchBar'
import {KvStructurePanel} from '../components/structure/KvStructurePanel'
import {kvLoadDocument} from '../store/kv.actions'
import {kvPathDocument, kvPathKvDocSetId, kvQueryParams} from '../store/kv.paths'
import {
  selectKvDocSetName,
  selectKvLoading,
  selectKvReaderContent,
  selectKvReaderDocSetInfo,
  selectKvReaderDocumentInfo,
  selectKvReaderEmptySearch,
  selectKvReaderParams,
  selectKvReaderStructure,
} from '../store/kv.selectors'
import {KvReaderQueryParams} from '../store/kv.types'
import {createDocsetNamePathPart, isInSearchMode} from '../store/kv.utils'
import {findValidFromDateInStructure} from '../utils/findValidFromDateInStructure'
import {formatValidityDateApi, formatValidityDateUI, parseValidityDate} from '../utils/validityDateUtils'

interface PathParams {
  docSetId: string
  documentId: string
  docSetName: string
  documentName: string
}

function updatePath(
  docSetId: string,
  documentId?: string,
  docSetName?: string,
  documentName?: string,
  kvParamsRef?: React.MutableRefObject<KvReaderQueryParams>,
  nextParams?: KvReaderQueryParams
) {
  if (kvParamsRef && kvParamsRef.current && nextParams) {
    kvParamsRef.current = nextParams
  }
  let link: string
  if (documentId && docSetName && documentName && kvParamsRef && kvParamsRef.current) {
    link = kvPathDocument(docSetId, documentId, docSetName, documentName, kvParamsRef.current)
  } else {
    link = kvPathKvDocSetId(docSetId, kvParamsRef && kvParamsRef.current ? kvParamsRef.current : undefined)
  }
  history.push(link)
}

export const KvReaderPage: React.FC = () => {
  const {theme} = useFela<Theme>()
  const dispatch = useDispatch()
  const {docSetId, documentId, docSetName, documentName} = useParams<PathParams>()
  const reqKvParams: KvReaderQueryParams = {
    paraId: useQueryParam(kvQueryParams.paraId) || undefined,
    validityDate: parseValidityDate(useQueryParam(kvQueryParams.validityDate)),
    quickSearch: useQueryParam(kvQueryParams.quickSearch) || undefined,
    userQuery: useQueryParam(kvQueryParams.userQuery) || undefined,
    isDocsetOnlyRequest: useQueryParam(kvQueryParams.docsetRedirect) || undefined,
  }
  const kvParamsRef = React.useRef(reqKvParams)
  const mobileKvInfoRef = React.useRef<HTMLDivElement>(null)
  const previousDocumentIdRef = React.useRef(documentId)
  const selectivePrintingContextValue = useInitSelectivePrinting(documentId)

  const mobile = isMobile(theme)
  const inSearchMode = isInSearchMode(reqKvParams)

  const structure = useSelector(selectKvReaderStructure)
  const storedKvParams = useSelector(selectKvReaderParams)
  const documentInfo = useSelector(selectKvReaderDocumentInfo) || {}
  const content = useSelector(selectKvReaderContent)
  const emptySearch = useSelector(selectKvReaderEmptySearch)
  const docsetDisplayName = useSelector(selectKvDocSetName)
  const loading = useSelector(selectKvLoading)
  const docSetInfo = useSelector(selectKvReaderDocSetInfo)

  React.useEffect(() => {
    if (docSetId) {
      // reset the kvParams to the values from the request
      kvParamsRef.current = reqKvParams
      previousDocumentIdRef.current = documentId
      dispatch(kvLoadDocument(docSetId, documentId, reqKvParams))
    }
    return
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    dispatch,
    docSetId,
    documentId,
    reqKvParams.quickSearch,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    formatValidityDateApi(reqKvParams.validityDate),
    reqKvParams.userQuery,
  ])

  // error handling
  const hasError = useError()
  if (hasError) {
    return <ErrorPage />
  }

  if (!docSetId) {
    return null
  }

  const changeValidityDate: ChangeValidityDate = (validityDate) => {
    const nextParams: KvReaderQueryParams = {
      ...reqKvParams,
      validityDate,
    }
    updatePath(docSetId, documentId, docSetName, documentName, kvParamsRef, nextParams)
  }
  const changeQuickSearch: ExecuteQuickSearch = (quickSearch) => {
    const nextParams: KvReaderQueryParams = {
      ...reqKvParams,
      paraId: undefined,
      quickSearch,
      userQuery: '',
    }
    updatePath(docSetId, undefined, docSetName, undefined, kvParamsRef, nextParams)
  }
  const executeSearch: ExecuteSearch = (userQuery) => {
    const nextParams: KvReaderQueryParams = {
      ...reqKvParams,
      userQuery,
      paraId: undefined,
      quickSearch: '',
    }
    updatePath(docSetId, undefined, docSetName!, undefined, kvParamsRef, nextParams)
  }
  const resetAllSearch: ResetAllSearch = () => {
    const nextParams: KvReaderQueryParams = {
      ...reqKvParams,
      userQuery: '',
      quickSearch: '',
    }
    updatePath(docSetId, documentId, docSetName!, documentName!, kvParamsRef, nextParams)
  }
  const gotToPara: GoToPara = (nextDocumentId, nextDocumentName, nextParaId) => {
    const nextParams: KvReaderQueryParams = {
      ...reqKvParams,
      paraId: nextParaId,
    }
    updatePath(docSetId, nextDocumentId, docSetName!, nextDocumentName, kvParamsRef, nextParams)
  }

  const kvHeader = (
    <KvReaderHeaderPanel
      name={docsetDisplayName}
      docSetId={docSetId}
      loading={loading}
      documentInfo={documentInfo}
      paraId={reqKvParams.paraId}
      validityDate={storedKvParams.validityDate}
      changeValidityDate={changeValidityDate}
      quickSearch={storedKvParams.quickSearch}
      executeQuickSearch={changeQuickSearch}
      userQuery={storedKvParams.userQuery}
      executeSearch={executeSearch}
      resetAllSearch={resetAllSearch}
    />
  )

  const kvSearchBar = inSearchMode ? (
    <KvSearchBar
      key={reqKvParams.userQuery}
      userQuery={reqKvParams.userQuery}
      structure={structure}
      paraId={reqKvParams.paraId}
      goToPara={gotToPara}
      documentId={documentId}
    />
  ) : undefined

  const kvFooter = <ReaderFooterPanel nowrap />

  const paramsKey = structure
    ? `${docSetId}_${structure.main.link.id}_${structure.family ? structure.family.link.id : '?'}_` +
      `${documentId}_${storedKvParams.paraId}_${storedKvParams.quickSearch}`
    : ''
  const kvNav =
    emptySearch && !mobile ? undefined : (
      <KvStructurePanel
        structure={structure}
        kvParamsRef={kvParamsRef}
        paraId={reqKvParams.paraId || ''}
        paramsKey={paramsKey}
        hidden={content === null && !loading}
        emptySearch={emptySearch}
        docsetDisplayName={docsetDisplayName}
        docSetInfoActive={!!docSetInfo}
        openAll={inSearchMode}
      />
    )

  // WARNING: the no-index must not be removed for family documents since this would create duplicates!!!
  const title = docsetDisplayName ? `KV: ${docsetDisplayName}` : ''
  const canonical =
    structure && documentId
      ? kvPathDocument(
          docSetId,
          documentId,
          createDocsetNamePathPart(structure.main.link.name, structure.main.angArb),
          documentInfo.documentName!
        )
      : ''

  const date = documentInfo.documentId ? findValidFromDateInStructure(documentInfo.documentId, structure) : null
  const datePanel = date !== null ? <>Geltungsdatum: {formatValidityDateUI(date)}</> : null

  const getContentPanel = () => {
    if (docSetInfo) {
      return <DocSetInfoPanel docSetInfo={docSetInfo} />
    }
    return (
      <Box innerRef={mobileKvInfoRef} fullWidth>
        {documentInfo.documentName && documentInfo.documentId && (
          <ReaderContentTopInfo>
            {docsetDisplayName} &gt; {documentInfo.documentName}
            <br />
            {datePanel}
          </ReaderContentTopInfo>
        )}
        <SelectivePrintingComponent>
          <KvContentPanel
            content={content}
            docSetId={docSetId}
            documentId={documentId}
            docSetName={docSetName}
            documentName={documentName}
            kvParams={reqKvParams}
            loading={loading}
            topNode={mobileKvInfoRef}
            isDocsetRedirect={reqKvParams.isDocsetOnlyRequest === 'true'}
          />
        </SelectivePrintingComponent>
      </Box>
    )
  }
  return (
    <SelectivePrintingContext.Provider value={selectivePrintingContextValue}>
      <DigitalHelmet title={title} canonical={canonical} noIndex />
      <ReaderLayout header={kvHeader} nav={kvNav} searchbar={kvSearchBar} footer={kvFooter}>
        {getContentPanel()}
      </ReaderLayout>
    </SelectivePrintingContext.Provider>
  )
}
