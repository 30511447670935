import moment from 'moment'

const VALIDITY_DATE_PATTERN_API = 'YYYY-MM-DD'
const VALIDITY_DATE_PATTERN_UI = 'D.M.YYYY'

export const formatValidityDateApi = (date?: Date): string | undefined => {
  if (!date) {
    return undefined
  }
  return moment(date).format(VALIDITY_DATE_PATTERN_API)
}

export const formatValidityDateUI = (date?: Date): string | undefined => {
  if (!date) {
    return undefined
  }
  return moment(date).format(VALIDITY_DATE_PATTERN_UI)
}

export const parseValidityDate = (dateString: string | null | undefined): Date | undefined => {
  if (!dateString) {
    return undefined
  }
  const d = moment(dateString, VALIDITY_DATE_PATTERN_API)
  if (!d.isValid()) {
    return undefined
  }
  return d.toDate()
}
