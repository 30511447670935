import {authenticator, Permission, useQueryParam} from '@oegbv/ui-shared'
import * as React from 'react'
import {useDispatch} from 'react-redux'
import {FooterPanel} from '../../commons/components/footer/FooterPanel'
import {DigitalHelmet} from '../../commons/components/helmet/DigitalHelmet'
import {MainContainerPanel} from '../../commons/components/main/MainContainerPanel'
import {SearchAreaPanel} from '../../commons/components/search-area/SearchAreaPanel'
import {ContentLayout} from '../../commons/layouts/content-layout/ContentLayout'
import {useError} from '../../error/hooks/useError'
import {ErrorPage} from '../../error/pages/ErrorPage'
import lawSearchAreaBackgroundImage from '../../law/components/overview/LawOverviewSearchBg.jpg'
import {EbookSearchInput} from '../components/ebooks/EbookSearchInput'
import {EbooksPanel} from '../components/ebooks/EbooksPanel'
import {BookOverviewHeaderPanel} from '../components/overview/BookOverviewHeaderPanel'
import {BookOverviewPanel} from '../components/overview/BookOverviewPanel'
import {bookLoadEbooks} from '../store/book.actions'
import {bookProductName} from '../store/book.constants'
import {bookPathOverview, bookQueryParams} from '../store/book.paths'
import {EBooksFilters} from '../store/book.types'

export const BookOverviewPage: React.FC = () => {
  const dispatch = useDispatch()
  const userQuery = useQueryParam(bookQueryParams.userQuery)
  const filterReihe = useQueryParam(bookQueryParams.reihe)
  const permissionEbooks = authenticator.hasPermission(Permission.BOOKS)

  React.useLayoutEffect(() => {
    if (permissionEbooks) {
      const filters: EBooksFilters = {reihe: filterReihe}
      dispatch(bookLoadEbooks(userQuery, filters))
    }
  }, [dispatch, userQuery, permissionEbooks, filterReihe])

  // error handling
  const hasError = useError()
  if (hasError) {
    return <ErrorPage />
  }
  const header = <BookOverviewHeaderPanel noSearch />
  const footer = <FooterPanel />
  return (
    <ContentLayout header={header} footer={footer}>
      <DigitalHelmet title={bookProductName} canonical={bookPathOverview()} />
      {permissionEbooks && (
        <SearchAreaPanel backgroundImage={lawSearchAreaBackgroundImage}>
          <EbookSearchInput />
        </SearchAreaPanel>
      )}
      <MainContainerPanel>
        {permissionEbooks && <EbooksPanel />}
        {!permissionEbooks && <BookOverviewPanel />}
      </MainContainerPanel>
    </ContentLayout>
  )
}
