import {Flex} from '@indoqa/style-system'
import * as React from 'react'
import {useFela} from 'react-fela'
import {centeredLayoutRules, containerRules} from '../../../app/mixins'
import {Theme} from '../../../app/theme'

export const MainContainerPanel: React.FC = ({children}) => {
  const {theme} = useFela<Theme>()
  return (
    <Flex style={[centeredLayoutRules(theme), containerRules(theme)]} nowrap pt={4} fullWidth>
      {children}
    </Flex>
  )
}
