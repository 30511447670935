import {Box, Flex} from '@indoqa/style-system'
import {IStyle} from 'fela'
import * as React from 'react'
import {useFela} from 'react-fela'
import {Link} from 'react-router-dom'
import history from '../../../app/history'
import {textColorLinksRules, undecoratedLinksRules} from '../../../app/mixins'
import {Theme} from '../../../app/theme'
import {Button} from '../../../commons/components/button/Button'
import {ResultDocument} from '../../../commons/types/SearchResult'
import {bookPathEbookWithSubtitle, bookResourceEbookCover} from '../../store/book.paths'

interface Props {
  ebook: ResultDocument
}

const createBookName = (ebook: ResultDocument) => {
  let name = ebook.name
  if (ebook.properties.untertitel) {
    name = `${name} - ${ebook.properties.untertitel}`
  }
  return name
}

export const EbookPanel = ({ebook}: Props) => {
  const {css, theme} = useFela<Theme>()

  const untertitelStyle: IStyle = {
    marginTop: `-${theme.spacing.space2}`,
    marginBottom: theme.spacing.space2,
  }
  const coverStyle: IStyle = {
    width: 170,
    cursor: 'pointer',
    margin: 'unset',
  }

  const bookLink = bookPathEbookWithSubtitle(ebook.id, ebook.name, ebook.properties.untertitel)
  const onClickLink = () => history.push(bookLink)

  const cover = (
    <img
      src={bookResourceEbookCover(ebook.id)}
      className={css(coverStyle)}
      alt={`Cover: ${createBookName(ebook)}`}
      onClick={onClickLink}
    />
  )

  const rowStyle: IStyle = {
    flexWrap: 'nowrap',
    flexDirection: 'column',
    tablet: {
      flexDirection: 'row',
    },
  }

  return (
    <Flex pb={3} nowrap fullWidth>
      <Flex pt={2} fullWidth mb={4} style={rowStyle}>
        <Box grow={0} shrink={0} pr={2} mb={2} width={200}>
          {cover}
        </Box>
        <Box grow={1}>
          <Box>
            <Box<Theme>
              fontStyle="brandBold"
              fontSize="veryBig"
              style={[undecoratedLinksRules(), textColorLinksRules(theme)]}
            >
              <Link to={bookLink}>{ebook.name}</Link>
            </Box>
            <Box<Theme> style={untertitelStyle}>{ebook.properties.untertitel}</Box>
          </Box>
          <Box<Theme> color="textReduced">
            <div dangerouslySetInnerHTML={{__html: ebook.properties.herausgeber_html || ''}} />
          </Box>
          <Box<Theme> color="textReduced">
            <strong>Rechtsstand: </strong>
            {ebook.properties.rechtsstand}
          </Box>
          <Box<Theme> color="textReduced">
            <strong>Reihe: </strong>
            {ebook.properties.reihe}
          </Box>

          <Flex pt={2} py={3} alignItems="center">
            {/*{ebook.meta['linked-resource'] && (*/}
            {/*  <Box mr={2}>*/}
            {/*    <Button text="WEBBOOK VIEWER" linkTo={bookPathWebBookId(ebook.meta['linked-resource'])} />*/}
            {/*  </Box>*/}
            {/*)}*/}
            <Button text="JETZT LESEN!" linkTo={bookLink} />
            <Box ml={2}>
              <a
                href={`/api/v1/webc/ebooks/${ebook.id}/content`}
                target="_blank"
                rel="noopener noreferrer"
                download={`${createBookName(ebook)}.pdf`}
              >
                <small>Download</small>
              </a>
            </Box>
          </Flex>
        </Box>
      </Flex>
    </Flex>
  )
}
