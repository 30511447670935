import {Flex} from '@indoqa/style-system'
import {IStyle} from 'fela'
import * as React from 'react'
import {hideAll} from 'tippy.js'
import {ReaderLayoutContext, ReaderLayoutContextValue} from './ReaderLayoutContext'

interface Props {
  overflowYHidden?: boolean
  children: React.ReactNode
}

export const ReaderLayoutContent = ({children, overflowYHidden}: Props) => {
  const {navShown, scrollTopEnabled} = React.useContext<ReaderLayoutContextValue>(ReaderLayoutContext)
  const containerRef = React.useRef<HTMLDivElement>(null)

  React.useLayoutEffect(() => {
    if (scrollTopEnabled && containerRef.current) {
      if (containerRef.current.scrollTo) {
        containerRef.current.scrollTo({top: 0})
      }
    }
    if (containerRef.current) {
      containerRef.current.addEventListener('scroll', () => hideAll())
    }
  })

  // const scrollToTop = () => {
  //   if (containerRef.current) {
  //     if (!window.scrollTo) {
  //       containerRef.current.scrollTop = 0
  //       return
  //     }
  //     const scrollTopValue = containerRef.current.scrollTop
  //     if (scrollTopValue > 0) {
  //       window.requestAnimationFrame(scrollToTop)
  //       containerRef.current.scrollTo(0, scrollTopValue - scrollTopValue / 8)
  //     }
  //   }
  // }

  const contentStyle: IStyle = {
    display: navShown ? 'none' : 'flex',
    overflowY: overflowYHidden ? 'hidden' : 'scroll',
    tablet: {
      display: 'flex',
    },
    print: {
      display: 'block',
      height: 'auto',
      overflow: 'visible',
      width: '100%',
    },
  }

  return (
    <Flex fullHeight grow={1} style={contentStyle} innerRef={containerRef} direction="column">
      {children}
    </Flex>
  )
}
