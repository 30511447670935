import {History} from 'history'
import {Epic, ofType} from 'redux-observable'
import {of} from 'rxjs'
import {catchError, debounceTime, distinctUntilChanged, map, switchMap, tap} from 'rxjs/operators'
import {RootState, Services} from '../../app/types'
import {DocumentInfo} from '../../commons/types/DocumentInfo'
import {StructureNode} from '../../commons/types/Structure'
import {throwError} from '../../error/store/error.actions'
import {notFoundError} from '../../error/store/error.utils'
import {
  LawAction,
  LawLoadCompleteContent,
  lawLoadCompleteContentSuccess,
  LawLoadContent,
  lawLoadDocumentSuccess,
  LawLoadGroup,
  lawLoadGroupSuccess,
  LawLoadOverview,
  lawLoadOverviewSuccess,
  LawRedirect,
  lawRedirectSuccess,
  LawSearch,
  LawSearchAutoComplete,
  LawSearchMore,
  lawSearchMoreSuccess,
  lawSearchSuccess,
  searchLawAutoCompleteSuccess,
} from './law.actions'
import {lawPathGroup, lawPathOverview, lawPathReader} from './law.paths'
import {selectReaderStructure, selectSearchPage, selectSearchResult, selectSearchUserQuery} from './law.selectors'
import {
  loadLawCompleteContentService$,
  loadLawContentService$,
  loadLawGroupService$,
  loadLawOverviewService$,
  loadLawRedirectInfo$,
  loadLawStructureService$,
  searchLawAutoSuggestionService$,
  searchLawService$,
} from './law.services'
import {LawActionKeys} from './law.types'
import {createLawRedirectLink, findLawDocument} from './law.utils'

interface LawEpic extends Epic<LawAction, LawAction, RootState, Services> {}

const loadOverview$: LawEpic = (action$, state, {ajax, history}) =>
  action$.pipe(
    ofType<LawLoadOverview>(LawActionKeys.LOAD_OVERVIEW),
    switchMap((action) =>
      loadLawOverviewService$(ajax, action.count).pipe(
        map((overview) => lawLoadOverviewSuccess(overview)),
        tap(() => {
          // reset URL in the case that location search is ?q=
          history.replace(lawPathOverview())
        }),
        catchError((e) => of(throwError(e)))
      )
    )
  )

const loadGroup$: LawEpic = (action$, state, {ajax, history}) =>
  action$.pipe(
    ofType<LawLoadGroup>(LawActionKeys.LOAD_GROUP),
    switchMap((action) =>
      loadLawGroupService$(ajax, action.groupId).pipe(
        map((overview) => lawLoadGroupSuccess(overview)),
        tap((lawLoadGroupSuccessAction) => {
          history.replace(
            lawPathGroup(lawLoadGroupSuccessAction.group.link.id, lawLoadGroupSuccessAction.group.link.name)
          )
        }),
        catchError((e) => of(throwError(e)))
      )
    )
  )

const updateLawPathReaderUrl = (
  structure: StructureNode,
  action: LawLoadContent,
  documentInfo: DocumentInfo,
  history: History
) => {
  const link = lawPathReader(
    action.lawId,
    documentInfo.documentId || '',
    structure.link.name,
    documentInfo.documentName || '',
    structure.simpleTitle,
    action.groupId
  )
  // If the original request contains the documentId, then create a history entry, otherwise
  // the URL is replaced because the documentId + bookName are retrieved from the structure
  // In this case the URL there should be only one history entry.
  if (action.documentId) {
    history.replace(link)
  } else {
    history.replace(link)
  }
}

const loadLawContent$: LawEpic = (action$, state$, {ajax, history}) =>
  action$.pipe(
    ofType<LawLoadContent>(LawActionKeys.LOAD_CONTENT),
    switchMap((action) => {
      const currentStructure = selectReaderStructure(state$.value)
      return loadLawStructureService$(ajax, action.lawId, currentStructure).pipe(
        switchMap((structure) => {
          const documentInfo = findLawDocument(structure, action.documentId)
          if (!documentInfo) {
            throw notFoundError(`The document '${action.documentId}' cannot be found in the structure.`)
          }
          updateLawPathReaderUrl(structure, action, documentInfo, history)
          return loadLawContentService$(ajax, documentInfo).pipe(
            map((content) => lawLoadDocumentSuccess(structure, documentInfo, content))
          )
        }),
        catchError((e) => of(throwError(e)))
      )
    })
  )

const loadLawCompleteContent$: LawEpic = (action$, state$, {ajax}) =>
  action$.pipe(
    ofType<LawLoadCompleteContent>(LawActionKeys.LOAD_COMPLETE_CONTENT),
    switchMap((action) => {
      const currentStructure = selectReaderStructure(state$.value)
      return loadLawStructureService$(ajax, action.lawId, currentStructure).pipe(
        switchMap((structure) => {
          return loadLawCompleteContentService$(ajax, action.lawId).pipe(
            map((content) => lawLoadCompleteContentSuccess(structure, content))
          )
        }),
        catchError((e) => of(throwError(e)))
      )
    })
  )

const lawSearch$: LawEpic = (action$, state$, {ajax, history}) =>
  action$.pipe(
    ofType<LawSearch>(LawActionKeys.SEARCH),
    switchMap((action) => {
      const {userQuery} = action
      // append userQuery param to URL search location
      history.replace(lawPathOverview(userQuery))
      // stop here if the query is empty -> no server round-trip necessary
      if (userQuery === '') {
        return of(lawSearchSuccess(null))
      }
      const previousSearchId = selectSearchResult(state$.value)?.tracking?.searchId
      return searchLawService$(ajax, userQuery, undefined, previousSearchId).pipe(
        map((searchResult) => lawSearchSuccess(searchResult)),
        catchError((e) => of(throwError(e)))
      )
    })
  )

const lawSearchMore$: LawEpic = (action$, state$, {ajax}) =>
  action$.pipe(
    ofType<LawSearchMore>(LawActionKeys.SEARCH_MORE),
    switchMap((action) => {
      const userQuery = selectSearchUserQuery(state$.value)
      const nextPage = selectSearchPage(state$.value) + 1
      const previousSearchId = selectSearchResult(state$.value)?.tracking?.searchId
      return searchLawService$(ajax, userQuery, nextPage, previousSearchId).pipe(
        map((searchResult) => lawSearchMoreSuccess(searchResult, nextPage)),
        catchError((e) => of(throwError(e)))
      )
    })
  )

const lawSearchAutoComplete$: LawEpic = (action$, state$, {ajax}) =>
  action$.pipe(
    ofType<LawSearchAutoComplete>(LawActionKeys.SEARCH_AUTO_COMPLETE),
    distinctUntilChanged(),
    debounceTime(250),
    switchMap((action) => {
      return searchLawAutoSuggestionService$(ajax, action.userQuery).pipe(
        map((items) => searchLawAutoCompleteSuccess(items)),
        catchError((e) => of(throwError(e)))
      )
    })
  )

const lawRedirect$: LawEpic = (action$, state, {ajax, history}) =>
  action$.pipe(
    ofType<LawRedirect>(LawActionKeys.REDIRECT),
    switchMap((action) => {
      return loadLawRedirectInfo$(ajax, action.lawId, action.sectionId).pipe(
        map((lookupInfo) => {
          const lawLink = createLawRedirectLink(lookupInfo)
          history.push(lawLink)
          // do nothing by returning an empty action
          return lawRedirectSuccess()
        }),
        catchError(() => {
          // do nothing because there is no recovery for this problem
          return of(lawRedirectSuccess())
        })
      )
    })
  )

export const lawEpics = [
  loadOverview$,
  loadGroup$,
  loadLawContent$,
  loadLawCompleteContent$,
  lawSearch$,
  lawSearchMore$,
  lawSearchAutoComplete$,
  lawRedirect$,
]
