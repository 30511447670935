import {Box, Flex} from '@indoqa/style-system'
import {IStyle} from 'fela'
import * as React from 'react'
import {useFela} from 'react-fela'
import {useHistory} from 'react-router-dom'
import {centeredLayoutRules, clippedBackgroundImageRules, containerRules} from '../../../app/mixins'
import {Theme} from '../../../app/theme'
import {ThemedIcon} from '../../../commons-shared/themed-icon/ThemedIcon'
import {HeaderPanel} from '../../../commons/components/header/HeaderPanel'
import {Breadcrumb, ReaderBreadcrumbsPanel} from '../../../commons/components/header/ReaderBreadcrumbsPanel'
import {useMobile} from '../../../commons/hooks/use-mobile/useMobile'
import {ReactComponent as CloseIcon} from '../../../commons/icons/close.svg'
import {ReactComponent as TocIcon} from '../../../commons/icons/table-of-content.svg'
import {ReaderLayoutContext, ReaderLayoutContextValue} from '../../../commons/layouts/reader-layout/ReaderLayoutContext'
import {DocumentInfo} from '../../../commons/types/DocumentInfo'
import {DigitalLogo} from '../../../digital/components/digital-logo/DigitalLogo'
import arSearchAreaBackgroundImage from '../../components/search/ArOverviewSearchBg.jpg'
import {ArFilters} from '../../store/ar.actions'
import {arPathBook, arPathContentResources, arPathSearch} from '../../store/ar.paths'
import {ArSearchInput} from '../search/ArSearchInput'

interface Props {
  noSearch?: boolean
  query?: string | null
  filters?: ArFilters
  page?: number
  bookName?: string
  bookId?: string | null
  documentInfo?: DocumentInfo | null
  paraId?: string | null
}

export const ARHeaderPanel: React.FC<Props> = ({
  noSearch,
  query,
  filters,
  page,
  bookName,
  bookId,
  documentInfo,
  paraId,
}) => {
  const {css, theme} = useFela<Theme>()
  const history = useHistory()
  const mobile = useMobile()
  const {setHeaderHeight, navShown, toggleNav} = React.useContext<ReaderLayoutContextValue>(ReaderLayoutContext)
  const initial = React.useRef(true)

  React.useLayoutEffect(() => {
    setHeaderHeight(noSearch ? 100 : 179)
  }, [setHeaderHeight, noSearch])

  React.useEffect(() => {
    if (initial && mobile && !noSearch && bookId && !paraId) {
      toggleNav()
      initial.current = false
    }
  }, [mobile, toggleNav, noSearch, bookId, paraId])

  // CP-945: Breadcrumb bleibt leer
  // const arfbrBreadcrumbItem = {name: 'Arbeitsrecht für Betriebsräte', linkTo: arPathOverview()}
  // const breadcrumbs: Breadcrumb[] = [arfbrBreadcrumbItem]
  const breadcrumbs: Breadcrumb[] = []
  if (query) {
    // the query
    // CP-945: Breadcrumb bleibt leer
    // breadcrumbs.push({name: `Suche: »${query}«`, linkTo: arPathSearch(query, filters, page)})

    if (bookName && bookId) {
      // the book
      breadcrumbs.push({
        name: bookName,
        linkTo: arPathBook(query, bookId!, undefined, undefined, filters, page),
        emphasize: true,
      })

      // the chapter
      if (documentInfo && documentInfo.documentId && documentInfo.documentName) {
        breadcrumbs.push({
          name: documentInfo.documentName,
          linkTo: arPathBook(query, bookId!, documentInfo.documentId, undefined, filters, page),
        })
      }
    }
  }

  const mobileBreadcrumbs: Breadcrumb[] = []
  if (!bookId || !query) {
    // CP-945: Breadcrumb bleibt leer
    // mobileBreadcrumbs.push(arfbrBreadcrumbItem)
  } else if (!navShown) {
    mobileBreadcrumbs.push({name: `Zurück zu den Suchergebnissen`, linkTo: arPathSearch(query, filters, page)})
  }

  const outerContainerStyle: IStyle = {
    position: 'relative',
    height: 79,
  }
  const middleContainerStyle: IStyle = {
    position: 'absolute',
    height: 79,
    paddingTop: 0,
    top: 0,
    left: 0,
    width: '100%',
    transitionProperty: 'height',
    transitionDuration: '0.1s',
  }
  const linkStyle: IStyle = {
    textDecoration: 'none',
    color: theme.colors.textInverted,
    '&:hover': {
      textDecoration: 'none',
      color: theme.colors.textInverted,
    },
    '&:visited': {
      textDecoration: 'none',
      color: theme.colors.textInverted,
    },
  }
  return (
    <Box>
      <HeaderPanel logo={<DigitalLogo />} noSearch />
      {!noSearch && (
        <Flex fullWidth style={outerContainerStyle}>
          <Flex style={[clippedBackgroundImageRules(arSearchAreaBackgroundImage, true), middleContainerStyle]} />
          <Box style={[centeredLayoutRules(theme), containerRules(theme)]} fullWidth>
            <Box fullWidth py={4} maxWidth={theme.layout.search.maxWidth}>
              <ArSearchInput />
            </Box>
          </Box>
        </Flex>
      )}
      <Flex<Theme>
        bg={theme.colors.primaryLight}
        py={1}
        color={theme.colors.textInverted}
        fontStyle="brand"
        maxHeight={30}
      >
        <Box style={[centeredLayoutRules(theme), containerRules(theme)]} fullWidth>
          <Flex pl={2} nowrap justifyContent="space-between" alignItems="baseline">
            {!mobile && (
              <Box display={['none', 'block']}>
                <ReaderBreadcrumbsPanel breadcrumbs={breadcrumbs} />
              </Box>
            )}

            {mobile && (
              <Flex display={['block', 'none']} nowrap alignItems="baseline">
                {bookId && (
                  <Box onClick={() => toggleNav()} pr={2} style={{position: 'relative', top: 3, left: -3}}>
                    <ThemedIcon
                      size={14}
                      color={theme.colors.iconInverted}
                      hoverColor={theme.colors.iconInverted}
                      verticalCenter
                    >
                      {navShown ? <CloseIcon /> : <TocIcon />}
                    </ThemedIcon>
                  </Box>
                )}
                <ReaderBreadcrumbsPanel breadcrumbs={mobileBreadcrumbs} />
              </Flex>
            )}

            <Box fontSize="small">
              <a
                href={arPathContentResources()}
                onClick={(e) => {
                  e.preventDefault()
                  history.push(arPathContentResources())
                }}
                className={css(linkStyle)}
              >
                Alle Inhalte
              </a>
            </Box>
          </Flex>
        </Box>
      </Flex>
    </Box>
  )
}
