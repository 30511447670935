import * as React from 'react'
import {useFela} from 'react-fela'
import {useHistory} from 'react-router'
import {Theme} from '../../../app/theme'
import {Badge} from '../../../ar/components/badge/Badge'
import {SearchInput} from '../../../commons-shared/search-input/SearchInput'
import {Reload, RenderItem, Search, StyleConfig} from '../../../commons-shared/search-input/SearchInput.types'
import {lawPathReaderLawId} from '../../store/law.paths'
import {LawAutoCompleteItem} from '../../store/law.types'

interface Props {
  userQuery: string
  items: LawAutoCompleteItem[]
  search: Search
  reload: Reload
}

const styleConfig: Partial<StyleConfig> = {
  borderRadius: 0,
}

export const LawSearchInput: React.FC<Props> = ({userQuery, items, search, reload}) => {
  const {theme} = useFela<Theme>()
  const {push} = useHistory()

  const selectSuggestion = (item: LawAutoCompleteItem) => {
    push(lawPathReaderLawId(item.lawId))
  }

  const renderItem: RenderItem<LawAutoCompleteItem> = (item) => {
    const lawAbbreviation = item.lawAbbreviation ? ` (${item.lawAbbreviation})` : ''
    const name = `${item.lawName}${lawAbbreviation}`
    return <span dangerouslySetInnerHTML={{__html: name}} />
  }
  const renderItemIcon: RenderItem<LawAutoCompleteItem> = (item) => {
    return (
      <Badge bg={theme.colors.contentTypeLaw} small>
        §§
      </Badge>
    )
  }

  return (
    <SearchInput
      inputValue={userQuery}
      items={items}
      placeholder="Über 400 Gesetze durchsuchen"
      styleConfig={styleConfig}
      reload={reload}
      search={search}
      renderItem={renderItem}
      renderItemIcon={renderItemIcon}
      selectSuggestion={selectSuggestion}
    />
  )
}
