/*
 Note:
 This component is included directly into the sources of this project to simplify the
 build process.

 License:
 Copyright 2019, Indoqa Software Design und Beratung GmbH

 Permission is hereby granted, free of charge, to any person obtaining a copy
 of this software and associated documentation files (the "Software"), to deal
 in the Software without restriction, including without limitation the rights
 to use, copy, modify, merge, publish, distribute, sublicense, and/or sell
 copies of the Software, and to permit persons to whom the Software is
 furnished to do so, subject to the following conditions:
 The above copyright notice and this permission notice shall be included in all
 copies or substantial portions of the Software.

 THE SOFTWARE IS PROVIDED "AS IS", WITHOUT WARRANTY OF ANY KIND, EXPRESS OR
 IMPLIED, INCLUDING BUT NOT LIMITED TO THE WARRANTIES OF MERCHANTABILITY,
 FITNESS FOR A PARTICULAR PURPOSE AND NONINFRINGEMENT. IN NO EVENT SHALL
 THE AUTHORS OR COPYRIGHT HOLDERS BE LIABLE FOR ANY CLAIM, DAMAGES OR OTHER
 LIABILITY, WHETHER IN AN ACTION OF CONTRACT, TORT OR OTHERWISE, ARISING FROM,
 OUT OF OR IN CONNECTION WITH THE SOFTWARE OR THE USE OR OTHER DEALINGS IN THE SOFTWARE.
*/
import {Box, Flex, HtmlDivAttributesWithoutStyle} from '@indoqa/style-system'
import {IStyle} from 'fela'
import * as React from 'react'
import {TreeNode} from './TreeNode'
import {treeNodeContextMenu, treeNodeOnMouseOut, treeNodeOnMouseOver} from './treeNodeEvents'
import {TreeViewContextValue} from './treeView.types'
import {calcIndent, createEventHandlers, cursorPointerStyle, overflowHiddenStyle} from './treeView.utils'
import {TreeViewContext} from './TreeViewContext'

interface Props<S> {
  structure: S
  level: number
  first: boolean
  last: boolean
}

enum UserOpenState {
  NONE,
  OPENED,
  CLOSED,
}

const getNextUserOpenedState = (currentState: UserOpenState, showChildren: boolean) => {
  if (currentState === UserOpenState.OPENED) {
    return UserOpenState.CLOSED
  }
  if (currentState === UserOpenState.CLOSED) {
    return UserOpenState.OPENED
  }
  if (currentState === UserOpenState.NONE && showChildren) {
    return UserOpenState.CLOSED
  }
  return UserOpenState.OPENED
}

export function TreeFolder<S>(props: Props<S>) {
  const {structure, level, first, last} = props
  const {structureAccessors, selectedNode, selectedNodePath, renderFolderNode, styleContainerNode, style} =
    React.useContext<TreeViewContextValue<S>>(TreeViewContext)
  const containerRef = React.useRef<HTMLDivElement>(null)
  const [showChildren, setShowChildren] = React.useState(level < 1)
  const [userOpened, setUserOpened] = React.useState<UserOpenState>(UserOpenState.NONE)
  const [hover, setHover] = React.useState(false)
  const nodeId = structureAccessors.getId(structure)
  const nodeChildren = structureAccessors.getChildren(structure)
  const active = structureAccessors.isActive(structure)

  // open closed nodes if one of its descendants is selected
  React.useLayoutEffect(() => {
    if (userOpened === UserOpenState.OPENED) {
      setShowChildren(true)
    } else {
      setShowChildren(false)
    }
  }, [setShowChildren, userOpened])

  // simulate a user open
  React.useLayoutEffect(() => {
    if (selectedNodePath.includes(nodeId)) {
      setUserOpened(UserOpenState.OPENED)
    }
  }, [selectedNodePath, nodeId, setUserOpened])

  const renderChildren = () => {
    if (!nodeChildren) {
      return null
    }
    return nodeChildren.map((child, index, array) => (
      <TreeNode key={index} structure={child} level={level + 1} first={index === 0} last={index === array.length - 1} />
    ))
  }

  // do not render the root node, only its nodeChildren
  if (level === 0) {
    return <>{renderChildren()}</>
  }

  const eventHandlers = createEventHandlers()
  const inNodePath = selectedNodePath.includes(nodeId)
  const selected = selectedNode.id === nodeId
  const folderPanel = renderFolderNode({
    structure,
    level,
    first,
    last,
    eventHandlers,
    style,
    inNodePath,
    selected,
  })
  const containerNodeStyle = styleContainerNode({
    structure,
    level,
    open: showChildren,
    selected,
    inNodePath,
  })
  const treeFolderOnClick = (e: React.MouseEvent<HTMLElement>) => {
    e.stopPropagation()
    if (active) {
      let prevent = false
      const preventClosing = () => (prevent = true)
      eventHandlers.click.emit({timeStamp: e.timeStamp, preventClosing})
      if (prevent) {
        return
      }
    }
    setUserOpened(getNextUserOpenedState(userOpened, showChildren))
  }
  const iconClick = (e: React.MouseEvent<HtmlDivAttributesWithoutStyle>) => {
    e.stopPropagation()
    // a click on the icon does not emit a event to the event handler (see above)
    const nextState = getNextUserOpenedState(userOpened, showChildren)
    setUserOpened(nextState)
  }

  const paddingLeftIndentationStyle: IStyle = {
    paddingLeft: calcIndent(level - 1, level - 1, style),
    flexBasis: calcIndent(level, level - 1, style),
    flexShrink: 0,
  }
  const selectedStyle: IStyle = {
    backgroundColor: selectedNode.id === nodeId ? style.colorSelected : hover ? style.colorHover : 'transparent',
  }
  return (
    <>
      <Flex
        nowrap
        style={[cursorPointerStyle, containerNodeStyle.style, selectedStyle]}
        innerRef={containerRef}
        htmlAttrs={{
          onClick: treeFolderOnClick,
          onMouseOver: treeNodeOnMouseOver(eventHandlers),
          onMouseOut: treeNodeOnMouseOut(eventHandlers, containerRef, setHover),
          onContextMenu: treeNodeContextMenu(eventHandlers),
        }}
      >
        <Box align="center" grow={0} style={paddingLeftIndentationStyle} onClick={iconClick}>
          {containerNodeStyle.icon}
        </Box>
        <Flex nowrap justifyContent="space-between" grow={1} style={overflowHiddenStyle}>
          {folderPanel}
        </Flex>
      </Flex>
      {showChildren && (
        <Flex nowrap>
          <Box grow={1} style={[overflowHiddenStyle]}>
            {renderChildren()}
          </Box>
        </Flex>
      )}
    </>
  )
}
