import {combineReducers} from 'redux'
import {arReducer} from '../ar/store/ar.reducer'
import {bookReducer} from '../book/store/book.reducer'
import {digitalReducer} from '../digital/store/digital.reducer'
import {errorReducer} from '../error/store/error.reducer'
import {kvReducer} from '../kv/store/kv.reducer'
import {lawReducer} from '../law/store/law.reducer'
import {RootAction, RootState} from './types'

const rootReducer = combineReducers<RootState, RootAction>({
  ar: arReducer,
  book: bookReducer,
  digital: digitalReducer,
  error: errorReducer,
  kv: kvReducer,
  law: lawReducer,
})

export default rootReducer
