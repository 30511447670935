import {Flex} from '@indoqa/style-system'
import * as React from 'react'
import {StringFacetPanel} from '../../../commons/components/facets/StringFacetPanel'
import {MainContainerPanel} from '../../../commons/components/main/MainContainerPanel'
import {ResultGroupTemplate} from '../../../commons/components/search-result/ResultGroupTemplate'
import {
  RenderDocument,
  RenderDocumentGroup,
  SearchResultPanel,
} from '../../../commons/components/search-result/SearchResultPanel'
import {Pool} from '../../../commons/idqsa/idqsa'
import {SelectSignal} from '../../../commons/idqsa/SelectSignal'
import {Facets, ResultDocument, ResultGroup, SearchResult} from '../../../commons/types/SearchResult'
import {PerformFiltering} from '../../pages/KvOverviewPage'
import {KvFilters} from '../../store/kv.actions'
import {kvFacetTranslations} from '../../store/kv.constants'
import {kvPathKvDocSetId} from '../../store/kv.paths'
import {KvReaderQueryParams} from '../../store/kv.types'
import {angArbToString} from '../../store/kv.utils'
import {parseValidityDate} from '../../utils/validityDateUtils'
import {KvDocumentResultItem} from './KvDocumentResultItem'
import {KvSearchMore} from './KvSearchMore'

interface Props {
  searchResult: SearchResult
  filters: KvFilters
  userQuery?: string
  performFiltering: PerformFiltering
}

const getParentAlternativeName = (document: ResultDocument) => {
  const parentAlternativeNameHighlight = document?.highlights?.parentAlternativeName
  if (!parentAlternativeNameHighlight) {
    return ''
  }
  return ` / ${parentAlternativeNameHighlight}`
}

const getParentName = (document: ResultDocument) => {
  const parentNameHighlight = document?.highlights?.parentName
  if (!parentNameHighlight) {
    return document.parentName
  }
  return parentNameHighlight
}

const getName = (resultGroup: ResultGroup): string => {
  const firstDocument = resultGroup.documents[0]
  const angArb = angArbToString(resultGroup.groupDocument.properties.ang_arb)
  const parentAlternativeName = getParentAlternativeName(firstDocument)
  const parentName = getParentName(firstDocument)
  return `${parentName}${parentAlternativeName}${angArb ? ` (${angArb})` : ''}`
}

export const KvSearchResultPanel = ({searchResult, filters, userQuery, performFiltering}: Props) => {
  const renderFacets = (facets: Facets) => {
    const facetComponents = []
    if (facets.strings_property_union) {
      const facet = (
        <StringFacetPanel<KvFilters>
          key="union"
          name="Gewerkschaften"
          currentFilter="union"
          values={facets.strings_property_union}
          filters={filters}
          translations={kvFacetTranslations.union}
          performFiltering={performFiltering}
        />
      )
      facetComponents.push(facet)
    }
    if (facets.strings_property_sector) {
      const facet = (
        <StringFacetPanel<KvFilters>
          key="sector"
          name="Branchen"
          currentFilter="sector"
          values={facets.strings_property_sector}
          filters={filters}
          translations={kvFacetTranslations.sector}
          performFiltering={performFiltering}
        />
      )
      facetComponents.push(facet)
    }
    if (facets.strings_property_province) {
      const facet = (
        <StringFacetPanel<KvFilters>
          key="province"
          name="Bundesländer"
          currentFilter="province"
          values={facets.strings_property_province}
          filters={filters}
          translations={kvFacetTranslations.province}
          performFiltering={performFiltering}
        />
      )
      facetComponents.push(facet)
    }
    if (facets.strings_property_ang_arb) {
      const facet = (
        <StringFacetPanel
          key="ang_arb"
          name="Arbeiter/Angestellte"
          currentFilter="ang_arb"
          values={facets.strings_property_ang_arb}
          filters={filters}
          translations={kvFacetTranslations.ang_arb}
          performFiltering={performFiltering}
        />
      )
      facetComponents.push(facet)
    }
    if (facets.date_property_contentModified) {
      const facet = (
        <StringFacetPanel
          key="contentModified"
          name="Letzte Änderung"
          currentFilter="contentModified"
          values={facets.date_property_contentModified}
          filters={filters}
          translations={kvFacetTranslations.contentModified}
          performFiltering={performFiltering}
        />
      )
      facetComponents.push(facet)
    }
    return <>{facetComponents}</>
  }

  const {tracking} = searchResult
  const renderDocument: RenderDocument = (resultDocument, resultGroup, searchId) => {
    return (
      <KvDocumentResultItem
        resultDocument={resultDocument}
        key={resultDocument.id}
        userQuery={userQuery}
        searchId={searchId}
      />
    )
  }
  const renderDocumentGroup: RenderDocumentGroup = (documentGroup, collapsed, setCollapsed) => {
    const validityDateFilter: KvReaderQueryParams | undefined =
      filters.validOn === undefined ? undefined : {validityDate: parseValidityDate(filters.validOn)}
    const link = kvPathKvDocSetId(documentGroup.groupDocument.id, validityDateFilter)
    return (
      <SelectSignal searchId={tracking?.searchId} resultItemId={documentGroup.groupDocument.id} pool={Pool.KVS}>
        <ResultGroupTemplate
          name={getName(documentGroup)}
          link={link}
          collapsed={collapsed}
          setCollapsed={setCollapsed}
        />
      </SelectSignal>
    )
  }
  return (
    <MainContainerPanel>
      <Flex direction="column" fullWidth>
        <SearchResultPanel
          searchResult={searchResult}
          resultNamePlural="Kollektivverträgen"
          resultNameSingular="Kollektivvertrag"
          renderDocumentGroup={renderDocumentGroup}
          renderDocument={renderDocument}
          renderFacets={renderFacets}
        />
        <KvSearchMore />
      </Flex>
    </MainContainerPanel>
  )
}
