import {Box, Col, ColRow, Flex, Grid} from '@indoqa/style-system'
import * as React from 'react'
import {Button, Type} from '../../../commons/components/button/Button'
import {ContentPanel} from '../../../commons/components/content/ContentPanel'
import {MainContainerPanel} from '../../../commons/components/main/MainContainerPanel'
import {ContentNode} from '../../../commons/types/ContentNode'

interface Props {
  contentNodeLeft?: ContentNode
}

export const KvIntroPanel = ({contentNodeLeft}: Props) => (
  <MainContainerPanel>
    <Box mt={2} mb={3}>
      <Grid>
        <ColRow>
          <Col size={[12, 7]}>
            {contentNodeLeft && <ContentPanel contentNode={contentNodeLeft} disablePrinting={true} />}
          </Col>
          <Col size={[12, 5]}>
            <Flex center fullHeight direction="column">
              <Box pt={[4, 0]}>
                <Button type={Type.CTA} linkTo="mailto:digital@oegbverlag.at" text="JETZT BESTELLEN" />
              </Box>
              <Box>
                <p>
                  Und im ersten Monat <strong>kostenlos</strong> testen!
                </p>
              </Box>
            </Flex>
          </Col>
        </ColRow>
      </Grid>
    </Box>
  </MainContainerPanel>
)
