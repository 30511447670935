import {ResultDocument} from '../../commons/types/SearchResult'

export const getResultDocumentName = (resultItem: ResultDocument): string => {
  if (!resultItem.highlights) {
    return resultItem.name
  }
  const {name} = resultItem.highlights
  if (name && name.length > 0) {
    return name[0]
  }
  return resultItem.name
}

export const getResultDocumentText = (resultItem: ResultDocument, max?: number): string => {
  // do not show highlights -> CP-892 and CP-953
  // if (!resultItem.highlights) {
  //   return resultItem.shortText
  // }
  // const {fullText} = resultItem.highlights
  // if (fullText && fullText.length > 0) {
  //   return `${fullText.slice(0, max || fullText.length).join(' \u2026 ')} \u2026 `
  // }
  if (!resultItem.shortText) {
    return ''
  }
  return resultItem.shortText.replace(/ …/g, '&nbsp;…').replace(/ \./g, '.')
}
