import {Box} from '@indoqa/style-system'
import {IStyle} from 'fela'
import * as React from 'react'
import {ReaderLayoutContext, ReaderLayoutContextValue} from './ReaderLayoutContext'

export const ReaderLayoutFooter: React.FC = ({children}) => {
  const {dimensions} = React.useContext<ReaderLayoutContextValue>(ReaderLayoutContext)
  const footerStyle: IStyle = {
    print: {
      display: 'none',
    },
  }
  return (
    <Box fullWidth height={dimensions.footerHeight} style={footerStyle}>
      {children}
    </Box>
  )
}
