import {Observable, of} from 'rxjs'
import {AjaxCreationMethod} from 'rxjs/internal/observable/dom/AjaxObservable'
import {getHeaders} from '../../commons/store/service-utils'
import {ContentNode} from '../../commons/types/ContentNode'
import {DocumentInfo} from '../../commons/types/DocumentInfo'
import {SearchResult} from '../../commons/types/SearchResult'
import {EBooksFilters} from './book.types'

export const loadEBooksService$ = (
  ajax: AjaxCreationMethod,
  userQuery?: string | null,
  filters?: EBooksFilters | null
): Observable<SearchResult> => {
  const urlQuery = userQuery ? `&query=${encodeURIComponent(userQuery)}` : ''
  const filtersQuery = filters && filters.reihe ? `&reihe=${encodeURIComponent(filters.reihe)}` : ''
  return ajax.getJSON(`/api/v1/webc/ebooks?&page-size=100${urlQuery}${filtersQuery}`, getHeaders())
}

export const loadBookDocumentService$ = (
  ajax: AjaxCreationMethod,
  documentInfo: DocumentInfo,
  currentChapter: ContentNode | null
): Observable<ContentNode> => {
  if (currentChapter && currentChapter.id === documentInfo.documentId) {
    return of(currentChapter)
  }
  return ajax.getJSON(`/api/v1/arfbr/chapters/${documentInfo.documentId}?include-children=true`, getHeaders())
}
