import {Observable} from 'rxjs'
import {AjaxCreationMethod} from 'rxjs/internal/observable/dom/AjaxObservable'
import {getHeaders} from '../../commons/store/service-utils'

const AUTH_DETAILS_BASE_PATH = '/api/v1/auth-details'

export const postForAuthDetails = (ajax: AjaxCreationMethod): Observable<object> => {
  const headers = {
    ...getHeaders(),
    'Content-Type': 'application/json',
  }
  return ajax.post(`${AUTH_DETAILS_BASE_PATH}`, {}, headers)
}
