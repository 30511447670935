import {Flex, HeaderBox, NavBox} from '@indoqa/style-system'
import {IStyle} from 'fela'
import * as React from 'react'
import {useFela} from 'react-fela'
import {centeredLayoutRules, containerRules} from '../../../app/mixins'
import {Theme} from '../../../app/theme'
import {arPathOverview} from '../../../ar/store/ar.paths'
import {bookPathOverview} from '../../../book/store/book.paths'
import {kvPathOverview} from '../../../kv/store/kv.paths'
import {lawPathOverview} from '../../../law/store/law.paths'
import {LoginButton} from './LoginButton'
import {MainMenuEntry} from './MainMenuEntry'
import {MenuButton} from './MenuButton'
import {SearchButton} from './SearchButton'

interface Props {
  logo: JSX.Element
  noSearch?: boolean
  noMenuEntryHighlight?: boolean
  noLogin?: boolean
}

export const HeaderPanel = (props: Props) => {
  const {logo, noSearch, noMenuEntryHighlight} = props
  const {theme} = useFela<Theme>()
  const [showMobileNav, setShowMobileNav] = React.useState(false)

  const toggleMobileNav = () => {
    setShowMobileNav(!showMobileNav)
  }

  const navBoxStyle: IStyle = {
    display: showMobileNav ? 'flex' : 'none',
    flexDirection: 'column',
    position: 'fixed',
    top: theme.layout.header.height,
    left: 0,
    width: '100vw',
    paddingTop: theme.spacing.space4,
    paddingBottom: theme.spacing.space4,
    zIndex: 10000,
    backgroundColor: theme.colors.bgContentEmphasised,
    borderBottom: theme.borders.border1,
    tablet: {
      display: 'flex',
      flexDirection: 'row',
      position: 'static',
      width: 'auto',
      paddingTop: theme.spacing.space0,
      paddingBottom: theme.spacing.space0,
      zIndex: 0,
      backgroundColor: 'transparent',
      borderBottom: theme.borders.border0,
    },
  }
  return (
    <HeaderBox fullWidth height={theme.layout.header.height} style={{borderBottom: theme.borders.border1}}>
      <Flex
        style={[centeredLayoutRules(theme), containerRules(theme)]}
        justifyContent="space-between"
        alignItems="center"
        fullHeight
      >
        {logo}
        <Flex<Theme> fontStyle="brandBold" alignItems="baseline" nowrap>
          <NavBox mr={4} style={navBoxStyle}>
            <MainMenuEntry
              label="Bücher"
              path={bookPathOverview()}
              toggleMobileNav={toggleMobileNav}
              noMenuEntryHighlight={noMenuEntryHighlight}
            />
            <MainMenuEntry
              label="KVSystem"
              path={kvPathOverview()}
              toggleMobileNav={toggleMobileNav}
              noMenuEntryHighlight={noMenuEntryHighlight}
            />
            <MainMenuEntry
              label="Arbeitsrecht für Betriebsräte"
              path={arPathOverview()}
              toggleMobileNav={toggleMobileNav}
              noMenuEntryHighlight={noMenuEntryHighlight}
            />
            <MainMenuEntry
              label="Sammlung Arbeitsrecht"
              path={lawPathOverview()}
              toggleMobileNav={toggleMobileNav}
              noMenuEntryHighlight={noMenuEntryHighlight}
            />
          </NavBox>
          {!noSearch && <SearchButton />}
          {!props.noLogin && <LoginButton />}
          <MenuButton onClick={toggleMobileNav} />
        </Flex>
      </Flex>
    </HeaderBox>
  )
}
