import {Text} from '@indoqa/style-system'
import * as React from 'react'
import {useFela} from 'react-fela'
import {Link} from 'react-router-dom'
import {Theme} from '../../../app/theme'
import {ThemedIcon} from '../../../commons-shared/themed-icon/ThemedIcon'
import {ReactComponent as SquareIcon} from '../../icons/square.svg'

interface Props {
  link: string
  name: string
}

export const LinkedSquareHeader: React.FC<Props> = ({link, name}) => {
  const {theme} = useFela<Theme>()
  return (
    <Link to={link}>
      <Text style={{position: 'relative', top: -2, marginRight: theme.spacing.space2}}>
        <ThemedIcon size={10}>
          <SquareIcon />
        </ThemedIcon>
      </Text>
      {name}
    </Link>
  )
}
