import {createSelector} from 'reselect'
import {selectArState} from '../../app/selectors'
import {ArState} from './ar.types'

export const selectError = createSelector(selectArState, (state: ArState) => state.error)
export const selectSearchResult = createSelector(selectArState, (state: ArState) => state.searchResult)
export const selectLoading = createSelector(selectArState, (state: ArState) => state.loading)
export const selectQuestionAnswer = createSelector(selectArState, (state: ArState) => state.questionAnswer)
export const selectBookStructure = createSelector(selectArState, (state: ArState) => state.bookStructure)
export const selectOverviewVideos = createSelector(selectArState, (state: ArState) => state.overviewVideos)
