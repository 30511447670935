import {Box, Col, ColRow, Flex, Grid} from '@indoqa/style-system'
import {IStyle} from 'fela'
import * as React from 'react'
import {useFela} from 'react-fela'
import {centeredLayoutRules} from '../../../app/mixins'
import {Theme} from '../../../app/theme'
import {Button} from '../button/Button'
import {Card} from '../card/Card'
import bgImg from './InfoContainerBackground.jpg'

export const InfoContainer = () => {
  const {theme} = useFela<Theme>()

  const containerStyle: IStyle = {
    background: `url(${bgImg}) center`,
    tablet: {
      background: `url(${bgImg})`,
      backgroundSize: 'cover',
      backgroundPosition: 'center top',
    },
  }

  const innerContainerRules = (): IStyle => ({
    paddingLeft: 0,
    paddingRight: 0,
    tablet: {
      paddingLeft: theme.spacing.space3,
      paddingRight: theme.spacing.space3,
    },
    desktop: {
      paddingLeft: theme.spacing.space3,
      paddingRight: theme.spacing.space3,
    },
  })

  return (
    <Flex fullWidth style={containerStyle}>
      <Box style={[centeredLayoutRules(theme), innerContainerRules()]} fullWidth mb={2}>
        <Grid fullWidth spacing="0">
          <ColRow>
            <Col size={[12, 6]}>
              <Box py={4} pl={[4, 0]} pr={[4]} fullHeight fullWidth>
                <Card height="100%">
                  <h3 className="titel">Lohndaten API</h3>
                  <p>
                    Die KV-Lohndaten-API stellt Inhalte aus Kollektivverträgen bereit, die für die Entlohnung von
                    ArbeitnehmerInnen relevant sind.
                  </p>
                  <Button
                    text="Alle Infos zur Lohndaten-API"
                    linkTo="https://www.oegbverlag.at/lohndaten-api/"
                  ></Button>
                </Card>
              </Box>
            </Col>
            <Col size={[12, 6]}>
              <Box py={4} pt={4} pl={[4]} pr={[4, 0]} fullHeight fullWidth>
                <Card height="100%">
                  <h3 className="titel">Newsletter</h3>
                  <p>Bleiben Sie jede Woche über die aktuellen Änderungen und die neuen Abschlüsse informiert!</p>
                  <Button text="Zur Newsletter-Anmeldung" linkTo="https://www.oegbverlag.at/#newsletter"></Button>
                </Card>
              </Box>
            </Col>
          </ColRow>
        </Grid>
      </Box>
    </Flex>
  )
}
