import {Box} from '@indoqa/style-system'
import {authenticator} from '@oegbv/ui-shared'
import * as React from 'react'
import {useFela} from 'react-fela'
import {useHistory} from 'react-router'
import {Theme} from '../../../app/theme'
import {ReaderStructureTopInfo} from '../../../commons/components/header/ReaderStructureTopInfo'
import {AlignedDigitalStructurePanel} from '../../../commons/components/structure/AlignedDigitalStructurePanel'
import {CreateDigitalLink} from '../../../commons/components/structure/digitalStructure.types'
import {DigitalStructureHeadline} from '../../../commons/components/structure/DigitalStructureHeadline'
import {StructureLink} from '../../../commons/components/structure/StructureLink'
import {findDocumentInfoByPara} from '../../../commons/utils/findDocumentByPara'
import {kvPathDocument, kvPathKvDocSetId} from '../../store/kv.paths'
import {DocsetStructure, DocsetStructureNode, KvReaderQueryParams} from '../../store/kv.types'
import {createDocsetNamePathPart, isTimeSliceNode} from '../../store/kv.utils'
import {createErrorMessage} from '../content/KvContentPanel'
import {KvTreeView} from './KvTreeView'

interface Props {
  structure: DocsetStructure | null
  paraId: string
  paramsKey: string
  kvParamsRef: React.RefObject<KvReaderQueryParams>
  hidden: boolean
  emptySearch: boolean
  docsetDisplayName: string
  docSetInfoActive: boolean
  openAll?: boolean
}

const createDigitalLink = (
  paraId: string,
  docSetId: string,
  docSetName: string,
  kvParamsRef: React.RefObject<KvReaderQueryParams>,
  structure?: DocsetStructureNode
) => {
  // the structure should never be undefined -> in the case of a not-existing structure, there are no link created at all
  if (!structure) {
    return '/error-page'
  }
  const documentInfo = findDocumentInfoByPara(structure, paraId, isTimeSliceNode)
  if (!documentInfo || !documentInfo.documentId || !documentInfo.documentName) {
    console.error('The requested paraId does not exist.', paraId)
    return ''
  }
  if (!kvParamsRef || !kvParamsRef.current) {
    return ''
  }
  const {documentId, documentName} = documentInfo
  const nextKvParams: KvReaderQueryParams = {
    ...kvParamsRef.current,
    paraId,
    isDocsetOnlyRequest: undefined,
  }
  const name = createDocsetNamePathPart(docSetName, structure.angArb)
  return kvPathDocument(docSetId, documentId, name, documentName, nextKvParams)
}

export const KvStructurePanel: React.FC<Props> = (props) => {
  const {theme} = useFela<Theme>()
  const {structure, kvParamsRef, paraId, paramsKey, hidden, emptySearch, docsetDisplayName, docSetInfoActive, openAll} =
    props
  const history = useHistory()
  const authenticated = authenticator.isAuthenticated()

  if (!structure) {
    return null
  }

  if (emptySearch) {
    return (
      <Box fullWidth pt={4} px={2} style={{marginTop: theme.layout.reader.toolbarHeight}}>
        {createErrorMessage(kvParamsRef.current!)}
      </Box>
    )
  }

  const mainStructure = structure.main
  const mainDocSetId = mainStructure.link.id
  const mainDocSetName = mainStructure.link.name

  const createDigitalLinkMain: CreateDigitalLink = (localParaId) => {
    return createDigitalLink(localParaId, mainDocSetId, mainDocSetName, kvParamsRef, mainStructure)
  }

  const familyStructure = structure.family
  const createDigitalLinkFamily: CreateDigitalLink = (localParaId) => {
    return createDigitalLink(localParaId, mainDocSetId, mainDocSetName, kvParamsRef, familyStructure)
  }

  return (
    <AlignedDigitalStructurePanel hidden={hidden} noPaddingTop>
      <ReaderStructureTopInfo>{docsetDisplayName}</ReaderStructureTopInfo>
      {mainStructure?.children && (
        <>
          <Box display={['block', 'none']} height={theme.layout.reader.toolbarHeight} />
          <Box pt={3}>
            {!authenticated && (
              <Box<Theme>
                pl={2}
                fontSize="small"
                fullWidth
                pt={2}
                pb={1}
                color={docSetInfoActive ? theme.colors.textHighlight : undefined}
                bg={docSetInfoActive ? theme.colors.bgStructureSelected : undefined}
                onClick={() => history.push(kvPathKvDocSetId(mainDocSetId))}
              >
                <StructureLink label="Überblick" createLink={() => kvPathKvDocSetId(mainDocSetId)} />
              </Box>
            )}
            <KvTreeView
              key={'main_' + String(!!openAll)}
              paramsKey={paramsKey}
              structure={structure.main}
              paraId={paraId}
              createDigitalLink={createDigitalLinkMain}
              openAll={openAll}
            />
          </Box>
        </>
      )}
      {familyStructure?.children && (
        <>
          <DigitalStructureHeadline titel={familyStructure.link.name} />
          <KvTreeView
            key={'family' + String(!!openAll)}
            paramsKey={paramsKey}
            structure={familyStructure}
            paraId={paraId}
            createDigitalLink={createDigitalLinkFamily}
            openAll={openAll}
          />
        </>
      )}
    </AlignedDigitalStructurePanel>
  )
}
