import {Action} from 'redux'
import {DocumentInfo} from '../../commons/types/DocumentInfo'
import {SearchResult} from '../../commons/types/SearchResult'
import {StructureNode} from '../../commons/types/Structure'
import {ThrowError} from '../../error/store/error.actions'
import {LawActionKeys, LawAutoCompleteItem, LawGroup, LawOverview} from './law.types'

export type LawAction =
  | LawLoadOverview
  | LawLoadOverviewSuccess
  | LawLoadGroup
  | LawLoadGroupSuccess
  | LawLoadContent
  | LawLoadContentSuccess
  | LawLoadCompleteContent
  | LawLoadCompleteContentSuccess
  | LawSearch
  | LawSearchSuccess
  | LawSearchMore
  | LawSearchMoreSuccess
  | LawSearchAutoComplete
  | LawSearchAutoCompleteSuccess
  | LawRedirect
  | LawRedirectSuccess
  | ThrowError

export interface LawLoadOverview extends Action {
  type: LawActionKeys.LOAD_OVERVIEW
  count: number
}

export const lawLoadOverview = (count: number): LawLoadOverview => ({
  type: LawActionKeys.LOAD_OVERVIEW,
  count,
})

export interface LawLoadOverviewSuccess extends Action {
  type: LawActionKeys.LOAD_OVERVIEW_SUCCESS
  overview: LawOverview
}

export const lawLoadOverviewSuccess = (overview: LawOverview): LawLoadOverviewSuccess => ({
  type: LawActionKeys.LOAD_OVERVIEW_SUCCESS,
  overview,
})

// ----------------------------------------------------------------------------

export interface LawLoadGroup extends Action {
  type: LawActionKeys.LOAD_GROUP
  groupId: string
}

export const lawLoadGroup = (groupId: string): LawLoadGroup => ({
  type: LawActionKeys.LOAD_GROUP,
  groupId,
})

export interface LawLoadGroupSuccess extends Action {
  type: LawActionKeys.LOAD_GROUP_SUCCESS
  group: LawGroup
}

export const lawLoadGroupSuccess = (group: LawGroup): LawLoadGroupSuccess => ({
  type: LawActionKeys.LOAD_GROUP_SUCCESS,
  group,
})

// ----------------------------------------------------------------------------

export interface LawLoadContent extends Action {
  type: LawActionKeys.LOAD_CONTENT
  lawId: string
  documentId?: string
  groupId?: string | null
}

export const lawLoadDocument = (lawId: string, documentId?: string, groupId?: string | null): LawLoadContent => ({
  type: LawActionKeys.LOAD_CONTENT,
  lawId,
  documentId,
  groupId,
})

export interface LawLoadContentSuccess extends Action {
  type: LawActionKeys.LOAD_CONTENT_SUCCESS
  structure: StructureNode
  documentInfo: DocumentInfo
  documentContent: string
}

export const lawLoadDocumentSuccess = (
  structure: StructureNode,
  documentInfo: DocumentInfo,
  documentContent: string
): LawLoadContentSuccess => ({
  type: LawActionKeys.LOAD_CONTENT_SUCCESS,
  structure,
  documentContent,
  documentInfo,
})

// ----------------------------------------------------------------------------

export interface LawLoadCompleteContent extends Action {
  type: LawActionKeys.LOAD_COMPLETE_CONTENT
  lawId: string
}

export const lawLoadCompleteDocument = (lawId: string): LawLoadCompleteContent => ({
  type: LawActionKeys.LOAD_COMPLETE_CONTENT,
  lawId,
})

export interface LawLoadCompleteContentSuccess extends Action {
  type: LawActionKeys.LOAD_COMPLETE_CONTENT_SUCCESS
  structure: StructureNode
  lawCompleteContent: string
}

export const lawLoadCompleteContentSuccess = (
  structure: StructureNode,
  lawCompleteContent: string
): LawLoadCompleteContentSuccess => ({
  type: LawActionKeys.LOAD_COMPLETE_CONTENT_SUCCESS,
  structure,
  lawCompleteContent,
})

// ----------------------------------------------------------------------------

export interface LawSearch extends Action {
  type: LawActionKeys.SEARCH
  userQuery: string
}

export const lawSearch = (userQuery: string): LawSearch => ({
  type: LawActionKeys.SEARCH,
  userQuery,
})

export interface LawSearchSuccess extends Action {
  type: LawActionKeys.SEARCH_SUCCESS
  searchResult: SearchResult | null
}

export const lawSearchSuccess = (searchResult: SearchResult | null): LawSearchSuccess => ({
  type: LawActionKeys.SEARCH_SUCCESS,
  searchResult,
})

export interface LawSearchMore extends Action {
  type: LawActionKeys.SEARCH_MORE
}

export const lawSearchMore = (): LawSearchMore => ({
  type: LawActionKeys.SEARCH_MORE,
})

export interface LawSearchMoreSuccess extends Action {
  type: LawActionKeys.SEARCH_MORE_SUCCESS
  searchResult: SearchResult | null
  page: number
}

export const lawSearchMoreSuccess = (searchResult: SearchResult | null, page: number): LawSearchMoreSuccess => ({
  type: LawActionKeys.SEARCH_MORE_SUCCESS,
  searchResult,
  page,
})

export interface LawSearchAutoComplete extends Action {
  type: LawActionKeys.SEARCH_AUTO_COMPLETE
  userQuery: string
}

export const lawSearchAutoComplete = (userQuery: string): LawSearchAutoComplete => ({
  type: LawActionKeys.SEARCH_AUTO_COMPLETE,
  userQuery,
})

export interface LawSearchAutoCompleteSuccess extends Action {
  type: LawActionKeys.SEARCH_AUTO_COMPLETE_SUCCESS
  items: LawAutoCompleteItem[]
}

export const searchLawAutoCompleteSuccess = (items: LawAutoCompleteItem[]): LawSearchAutoCompleteSuccess => ({
  type: LawActionKeys.SEARCH_AUTO_COMPLETE_SUCCESS,
  items,
})

// ----------------------------------------------------------------------------

export interface LawRedirect extends Action {
  type: LawActionKeys.REDIRECT
  lawId: string
  sectionId: string | null
}

export const lawRedirect = (lawId: string, sectionId: string | null): LawRedirect => ({
  type: LawActionKeys.REDIRECT,
  lawId,
  sectionId,
})

export interface LawRedirectSuccess extends Action {
  type: LawActionKeys.REDIRECT_SUCCESS
}

export const lawRedirectSuccess = (): LawRedirectSuccess => ({
  type: LawActionKeys.REDIRECT_SUCCESS,
})
