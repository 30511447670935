import {Box, Flex} from '@indoqa/style-system'
import {IStyle} from 'fela'
import * as React from 'react'
import {useFela} from 'react-fela'
import {Theme} from '../../../app/theme'
import {ThemedIcon} from '../../../commons-shared/themed-icon/ThemedIcon'
import {ReactComponent as RemoveIcon} from '../../icons/remove.svg'
import {FacetValue} from '../../types/SearchResult'

interface Props {
  label: string
  value: FacetValue
  selected: boolean
  onClick: () => void
}

export const FacetEntry = ({label, value, selected, onClick}: Props) => {
  const {theme} = useFela<Theme>()
  const style: IStyle = {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  }
  return (
    <Flex<Theme>
      color={selected ? theme.colors.accent : theme.colors.text}
      onClick={onClick}
      cursorPointer
      fontSize="small"
      fontStyle="brand"
      alignItems="baseline"
      nowrap
    >
      <Box shrink={1} style={style}>
        {label}
      </Box>
      <Box
        grow={0}
        shrink={0}
        pl={1}
        display="inline-block"
        fontSize="small"
        color={selected ? theme.colors.accent : theme.colors.textReduced}
      >
        ({value.count})
      </Box>

      {selected && (
        <Flex display="inline-block" pl={1} alignItems="baseline">
          <Box style={{position: 'relative', top: -2}}>
            <ThemedIcon size={14} color={theme.colors.accent} hoverColor={theme.colors.accent}>
              <RemoveIcon />
            </ThemedIcon>
          </Box>
        </Flex>
      )}
    </Flex>
  )
}
