import {Box, Flex} from '@indoqa/style-system'
import * as React from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {Button} from '../../commons/components/button/Button'
import {FooterPanel} from '../../commons/components/footer/FooterPanel'
import {DigitalHelmet} from '../../commons/components/helmet/DigitalHelmet'
import {MainContainerPanel} from '../../commons/components/main/MainContainerPanel'
import {ContentLayout} from '../../commons/layouts/content-layout/ContentLayout'
import {KvHeaderPanel} from '../components/header/KvHeaderPanel'
import {KvChangesItemPanel} from '../components/overview/KvChangesItemPanel'
import {kvLoadChanges, kvLoadChangesMore} from '../store/kv.actions'
import {kvProductName} from '../store/kv.constants'
import {kvPathChanges} from '../store/kv.paths'
import {selectKvChangesItems} from '../store/kv.selectors'
import {KvChangesItem} from '../store/kv.types'

const renderItems = (items: KvChangesItem[]) => (
  <ul>
    {items.map((item) => (
      <KvChangesItemPanel item={item} key={item.link.id} showLink />
    ))}
  </ul>
)

export const KvChangesPage = () => {
  const dispatch = useDispatch()
  React.useLayoutEffect(() => {
    window.scrollTo({top: 0})
  }, [])
  React.useEffect(() => {
    dispatch(kvLoadChanges())
  }, [dispatch])
  const items = useSelector(selectKvChangesItems)

  const header = <KvHeaderPanel noSearch />
  const footer = <FooterPanel />
  return (
    <ContentLayout header={header} footer={footer}>
      <DigitalHelmet title={kvProductName} canonical={kvPathChanges()} />
      <MainContainerPanel>
        <Box fullWidth>
          <h1>Kollektivverträge - Aktuelle Änderungen</h1>
          {renderItems(items)}
          <Flex mt={3} fullWidth justifyContent="center">
            <Button
              linkTo={() => {
                dispatch(kvLoadChangesMore())
              }}
              text="FRÜHERE ÄNDERUNGEN"
            />
          </Flex>
        </Box>
      </MainContainerPanel>
    </ContentLayout>
  )
}
