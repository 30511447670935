import * as React from 'react'
import {useFela} from 'react-fela'
import {Theme} from '../../../app/theme'
import {DigitalStructureLeafTemplate} from '../../../commons/components/structure/DigitalStructureLeafTemplate'
import {ReaderLayoutContext, ReaderLayoutContextValue} from '../../../commons/layouts/reader-layout/ReaderLayoutContext'
import {StructureNode} from '../../../commons/types/Structure'
import {isMobile} from '../../../commons/utils/isMobile'
import {RenderLawNodeProps} from './LawStructurePanel'

export const LawStructureLeafPanel: React.FC<RenderLawNodeProps<StructureNode>> = ({
  structure,
  style,
  eventHandlers,
  loadLawContent,
  createLawContentLink,
  selected,
}) => {
  const {link, subTitle} = structure
  const {id, name} = link

  const {theme} = useFela<Theme>()
  const {toggleNav} = React.useContext<ReaderLayoutContextValue>(ReaderLayoutContext)

  React.useEffect(() => {
    const onClickDisposable = eventHandlers.click.on(() => {
      loadLawContent(id, name)
      if (isMobile(theme)) {
        toggleNav()
      }
    })
    return () => {
      onClickDisposable.dispose()
    }
  }, [eventHandlers, loadLawContent, toggleNav, id, name, theme])

  const label = `${name} ${subTitle || ''}`
  return (
    <DigitalStructureLeafTemplate
      label={label}
      selected={selected}
      paddingRight={style.padding}
      createLink={() => createLawContentLink(id, name)}
    />
  )
}
