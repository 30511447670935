import {DocumentInfo} from '../types/DocumentInfo'
import {Link} from '../types/Link'
import {BaseStructureNode} from '../types/Structure'

export type LinkPredicate = (link: Link) => boolean

export const findFirstLeaf = <T extends BaseStructureNode<T>>(
  structure: T,
  hitsOnly: boolean,
  paraPredicate: LinkPredicate
): string | null => {
  const {hits, children, link} = structure
  const hasHitCount = hits !== undefined && hits > 0
  const follow = hitsOnly ? hasHitCount : true

  const noChildren = !children || children.length === 0

  // since there are no further children, a leaf was found
  if (follow && (noChildren || paraPredicate(link))) {
    return link.id
  }

  // in case of a no follow, there might be any children either
  if (!children) {
    return null
  }

  // go deeper
  for (let i = 0; i < children.length; i += 1) {
    const eachChild = children[i]
    const id = findFirstLeaf(eachChild, hitsOnly, paraPredicate)
    if (id) {
      return id
    }
  }
  return null
}

export const findFirstDocumentInfo = <T extends BaseStructureNode<T>>(
  structure: T,
  documentPredicate: LinkPredicate,
  paraPredicate: LinkPredicate,
  searchMode: boolean
): DocumentInfo | null => {
  const {link, children} = structure

  if (link && link.id && documentPredicate(link) && !structure.noContent) {
    const documentInfo: DocumentInfo = {
      documentId: link.id,
      documentName: link.name,
    }
    const firstLeaf = findFirstLeaf(structure, searchMode, paraPredicate)
    if (firstLeaf) {
      documentInfo.paraId = firstLeaf
    }
    return documentInfo
  }

  if (!children) {
    return null
  }

  for (let i = 0; i < children.length; i += 1) {
    const eachChild = children[i]
    const documentInfo = findFirstDocumentInfo(eachChild, documentPredicate, paraPredicate, searchMode)
    if (documentInfo) {
      return documentInfo
    }
  }

  return null
}
