import * as React from 'react'
import {useSelector} from 'react-redux'
import history from '../../../app/history'
import {AlignedContentPanel} from '../../../commons/components/content/AlignedContentPanel'
import {ContentPanel} from '../../../commons/components/content/ContentPanel'
import {LinkHandler} from '../../../commons/components/content/handleContentPanelLink'
import {Pool} from '../../../commons/idqsa/idqsa'
import {ContentNode} from '../../../commons/types/ContentNode'
import {ErrorPanel} from '../../../error/components/ErrorPanel'
import {kvPathDocument, kvPathKvDocSetId, kvPathOverview, kvPathRedirect} from '../../store/kv.paths'
import {selectKvReaderParams} from '../../store/kv.selectors'
import {KvReaderQueryParams} from '../../store/kv.types'
import {formatValidityDateUI} from '../../utils/validityDateUtils'

interface Props {
  content: ContentNode | null
  docSetId: string
  documentId: string
  docSetName: string
  documentName: string
  kvParams: KvReaderQueryParams
  topNode?: React.RefObject<HTMLDivElement>
  loading: boolean
  isDocsetRedirect: boolean
}

export const createErrorMessage = (kvParams: KvReaderQueryParams) => {
  if (kvParams.quickSearch !== undefined) {
    return `Leider führte die Schnellsuche nach "${kvParams.quickSearch}" zu keinen Ergebnissen`
  }
  if (kvParams.userQuery !== undefined) {
    return `Leider führte die Suche nach "${kvParams.userQuery}" zu keinen Ergebnissen`
  }
  if (kvParams.validityDate !== undefined) {
    return `Leider konnte das Dokument zum Stichtag ${formatValidityDateUI(
      kvParams.validityDate
    )} nicht gefunden werden.`
  }
  return `Ihr Account hat nicht die notwendigen Berechtigungen, um das Dokument anzuzeigen.`
}

export const KvContentPanel: React.FC<Props> = ({
  kvParams,
  docSetId,
  documentId,
  docSetName,
  documentName,
  content,
  topNode,
  loading,
  isDocsetRedirect,
}) => {
  const {validityDate} = useSelector(selectKvReaderParams)
  if (loading) {
    return null
  }

  if (!content) {
    if (kvParams.userQuery === undefined && kvParams.quickSearch === undefined && kvParams.validityDate === undefined) {
      return (
        <ErrorPanel
          message="Ihr Account hat nicht die notwendigen Berechtigungen, um das Dokument anzuzeigen."
          buttonText="Zur Übersichtsseite"
          linkTo={kvPathOverview()}
        />
      )
    }
    if (kvParams.quickSearch !== undefined) {
      const nextKvParams = {
        ...kvParams,
        quickSearch: '',
      }
      return (
        <ErrorPanel
          message={`Leider führte die Schnellsuche nach "${kvParams.quickSearch}" zu keinen Ergebnissen.`}
          buttonText="Zurück"
          linkTo={kvPathDocument(docSetId, documentId, docSetName, documentName, nextKvParams)}
        />
      )
    }
    if (kvParams.userQuery !== undefined) {
      const nextKvParams = {
        ...kvParams,
        userQuery: '',
      }
      return (
        <ErrorPanel
          message={`Leider führte die Suche nach "${kvParams.userQuery}" zu keinen Ergebnissen.`}
          buttonText="Zurück"
          linkTo={kvPathDocument(docSetId, documentId, docSetName, documentName, nextKvParams)}
        />
      )
    }
    if (kvParams.validityDate !== undefined) {
      const formattedDate = formatValidityDateUI(kvParams.validityDate)
      const nextKvParams = {
        ...kvParams,
        validityDate,
      }
      return (
        <ErrorPanel
          message={`Leider konnte das Dokument zum Stichtag ${formattedDate} nicht gefunden werden.`}
          buttonText="Zurück"
          linkTo={kvPathDocument(docSetId, documentId, docSetName, documentName, nextKvParams)}
        />
      )
    }
    return (
      <ErrorPanel
        message={'Es ist ein Fehler aufgetreten.'}
        buttonText="Zurück zur Übersichtsseite"
        linkTo={kvPathOverview()}
      />
    )
  }

  // link handling
  const idrefLinkHandler: LinkHandler = {
    createLink: (idref) => kvPathRedirect(idref, documentId, kvParams.validityDate),
    executeAction: (idref) => history.push(kvPathRedirect(idref, documentId, kvParams.validityDate)),
  }
  const docsetLinkHandler: LinkHandler = {
    createLink: (idref) => kvPathKvDocSetId(`${idref}_de`),
    executeAction: (idref) => {
      window.open(kvPathKvDocSetId(`${idref}_de`))
      // history.push(kvPathKvDocSetId(`${idref}_de`))
    },
  }

  const trackingInfoResultItemId = isDocsetRedirect ? docSetId : kvParams.paraId || undefined

  return (
    <AlignedContentPanel navShown maxWidth={1000}>
      <ContentPanel
        contentNode={content}
        currentNodeId={{id: kvParams.paraId || ''}}
        topNode={topNode}
        enableLawLinks
        idrefLinkHandler={idrefLinkHandler}
        docsetLinkHandler={docsetLinkHandler}
        trackingInfo={{pool: Pool.KVS, resultItemId: trackingInfoResultItemId}}
      />
    </AlignedContentPanel>
  )
}
