import {IStyle} from 'fela'
import * as React from 'react'
import {useFela} from 'react-fela'

export type Label = string | boolean | JSX.Element

interface Props {
  label: Label
  createLink: () => string
}

export const StructureLink: React.FC<Props> = ({label, createLink}) => {
  const {css} = useFela()
  const linkStyle: IStyle = {
    color: 'inherit',
    textDecoration: 'none',
  }
  const style: IStyle = {
    ...linkStyle,
    '&:visited': linkStyle,
    '&:hover': linkStyle,
  }
  return (
    <a
      href="/"
      className={css(style)}
      onMouseEnter={(e) => {
        e.currentTarget.setAttribute('href', createLink())
      }}
      onClick={(e) => {
        e.preventDefault()
        return false
      }}
    >
      {label}
    </a>
  )
}
