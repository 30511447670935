import {History} from 'history'
import {Epic, ofType} from 'redux-observable'
import {of} from 'rxjs'
import {catchError, map, switchMap} from 'rxjs/operators'
import {RootState, Services} from '../../app/types'
import {loadBookStructureService$} from '../../commons/store/structure.service'
import {DocumentInfo} from '../../commons/types/DocumentInfo'
import {throwError} from '../../error/store/error.actions'
import {notFoundError} from '../../error/store/error.utils'
import {
  BookAction,
  BookLoadEbook,
  BookLoadEbooks,
  bookLoadEbooksSuccess,
  bookLoadEbookSuccess,
  BookLoadWebBookContent,
  bookLoadWebBookContentSuccess,
} from './book.actions'
import {bookPathWebBookId} from './book.paths'
import {selectWebBookContent, selectWebBookStructure} from './book.selectors'
import {loadBookDocumentService$, loadEBooksService$} from './book.services'
import {BookActionKeys} from './book.types'
import {findBookChapter} from './book.utils'

interface BookEpics extends Epic<BookAction, BookAction, RootState, Services> {}

const loadEbooks$: BookEpics = (action$, state$, {ajax}) =>
  action$.pipe(
    ofType<BookLoadEbooks>(BookActionKeys.LOAD_EBOOKS),
    switchMap((action) =>
      loadEBooksService$(ajax, action.userQuery, action.filters).pipe(
        map((ebooks) => bookLoadEbooksSuccess(ebooks)),
        catchError((e) => of(throwError(e)))
      )
    )
  )

const loadEbook$: BookEpics = (action$, state$, {ajax}) =>
  action$.pipe(
    ofType<BookLoadEbook>(BookActionKeys.LOAD_EBOOK),
    switchMap((action) =>
      loadEBooksService$(ajax).pipe(
        map((ebooks) => {
          if (!ebooks.documents) {
            throw notFoundError(`The book with the id '${action.id} could not be found.`)
          }
          const filteredBooks = ebooks.documents.filter((result) => result.id === action.id)
          if (filteredBooks.length !== 1) {
            throw notFoundError(`The book with the id '${action.id} could not be found.`)
          }
          return bookLoadEbookSuccess(filteredBooks[0])
        }),
        catchError((e) => of(throwError(e)))
      )
    )
  )

const updateWebBookPath = (
  action: BookLoadWebBookContent,
  bookTitle: string,
  documentInfo: DocumentInfo,
  history: History
) => {
  const link = bookPathWebBookId(
    action.bookId,
    documentInfo.documentId || '',
    bookTitle,
    documentInfo.documentName || '',
    {paraId: action.paraId || documentInfo.documentId}
  )
  history.replace(link)
}

const bookLoadContent$: BookEpics = (action$, state$, {ajax, history}) => {
  return action$.pipe(
    ofType<BookLoadWebBookContent>(BookActionKeys.LOAD_WEBBOOK_CONTENT),
    switchMap((action) => {
      const currentStructure = selectWebBookStructure(state$.value)
      return loadBookStructureService$(ajax, action.bookId, currentStructure).pipe(
        switchMap((bookStructure) => {
          // sanitize structure with missing name
          if (!bookStructure.link.name) {
            bookStructure.link.name = bookStructure.link.id
          }
          const documentInfo = findBookChapter(bookStructure, action.chapterId ?? undefined, action.paraId || undefined)
          if (!documentInfo) {
            throw notFoundError(`The chapter ${action.chapterId} was not found.`)
          }
          if (!action.suppressRedirect) {
            updateWebBookPath(action, bookStructure.link.name, documentInfo, history)
          }
          const currentDocument = selectWebBookContent(state$.value)
          return loadBookDocumentService$(ajax, documentInfo, currentDocument).pipe(
            map((content) => bookLoadWebBookContentSuccess(bookStructure, content, documentInfo))
          )
        }),
        catchError((e) => of(throwError(e)))
      )
    })
  )
}

export const bookEpics = [loadEbooks$, loadEbook$, bookLoadContent$]
