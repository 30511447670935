import {DocsetStructure, DocsetStructureNode} from '../store/kv.types'

// see https://dmitripavlutin.com/javascript-queue/
class Queue<T> {
  items: T[]
  headIndex: number
  tailIndex: number

  constructor() {
    this.items = []
    this.headIndex = 0
    this.tailIndex = 0
  }

  enqueue(item: T) {
    this.items[this.tailIndex] = item
    this.tailIndex++
  }

  dequeue() {
    const item = this.items[this.headIndex]
    delete this.items[this.headIndex]
    this.headIndex++
    return item
  }

  peek() {
    return this.items[this.headIndex]
  }

  get length(): number {
    return this.tailIndex - this.headIndex
  }
}

const findStructureNode = (documentId: string, node?: DocsetStructureNode): Date | null => {
  if (!node) {
    return null
  }
  const queue = new Queue<DocsetStructureNode>()
  queue.enqueue(node)
  while (queue.length > 0) {
    const nextNode = queue.dequeue()
    if (nextNode.link.id === documentId) {
      return nextNode.amended || nextNode.validFrom
    }
    nextNode.children?.forEach((childNode) => queue.enqueue(childNode))
  }
  return null
}

export const findValidFromDateInStructure = (documentId: string, structure: DocsetStructure | null): Date | null => {
  if (!structure) {
    return null
  }
  const mainDate = findStructureNode(documentId, structure.main)
  if (mainDate !== null) {
    return mainDate
  }
  return findStructureNode(documentId, structure.family)
}
