import {Box, Flex} from '@indoqa/style-system'
import * as React from 'react'
import {useFela} from 'react-fela'
import {useDispatch, useSelector} from 'react-redux'
import {Theme} from '../../../app/theme'
import {Button} from '../../../commons/components/button/Button'
import {ModalWindow} from '../../../commons/components/modal-window/ModalWindow'
import {kvSendFeedback} from '../../store/kv.actions'
import {selectFeedbackSentSuccessfully} from '../../store/kv.selectors'

interface Props {
  docSetId: string
  documentId: string
  show: boolean
  validityDate: Date
  setShow: (show: boolean) => void
}

export const FeedbackPanel = ({show, docSetId, documentId, validityDate, setShow}: Props) => {
  const {css, theme} = useFela<Theme>()
  const dispatch = useDispatch()
  const textAreaRef = React.useRef<HTMLTextAreaElement>(null)
  const [text, setText] = React.useState<string>('')
  const [feedbackSent, setFeedbackSent] = React.useState(false)
  const feedbackSentSuccessfullyToServer = useSelector(selectFeedbackSentSuccessfully)
  React.useLayoutEffect(() => {
    if (!textAreaRef.current) {
      return
    }
    textAreaRef.current.focus()
  })
  const header = (
    <Box<Theme> p={2} color="textInverted" bold fontStyle="brand" fontSize="small">
      Ihr Feedback zum Kollektivvertrag
    </Box>
  )
  const footer = !feedbackSent ? (
    <Flex p={2} justifyContent="flex-end" fullWidth alignItems="baseline">
      <Box mr={4}>
        <Box pl={2} cursorPointer onClick={() => setShow(false)} color={theme.colors.primaryLight}>
          Abbrechen
        </Box>
      </Box>
      <Box>
        <Button
          linkTo={() => {
            setFeedbackSent(true)
            dispatch(kvSendFeedback(window.location.toString(), docSetId, documentId, text, validityDate))
          }}
          text="Feedback senden"
        />
      </Box>
    </Flex>
  ) : undefined
  const textAreaStyle: any = {
    height: '100%',
    width: '100%',
    resize: 'none',
    padding: theme.spacing.space1,
    outline: 'none',
    borderWidth: 0,
    borderColor: theme.colors.primaryLight,
    '&:focus': {
      outline: 'none',
    },
  }
  const content = !feedbackSent ? (
    <Box p={2} fullHeight fullWidth>
      <textarea
        className={css(textAreaStyle)}
        ref={textAreaRef}
        onChange={(e) => setText(e.target.value)}
        maxLength={2000}
        value={text}
        placeholder="Geben Sie hier Ihr Feedback zur Verwendung der Schnellabfragen oder Volltext-Suche, der Darstellung des Textes, der Unvollständigkeit der Dokumente, usw. ein."
      />
    </Box>
  ) : (
    <Flex fullWidth fullHeight center direction="column">
      {feedbackSentSuccessfullyToServer ? (
        <Box>Vielen Dank, ihr Feedback wurde übermittelt.</Box>
      ) : (
        <Box>
          Leider ist beim Verwenden ihrer Feedbacks ein Problem aufgetreten. Wenden Sie sich bitte an den Support des
          ÖGB-Verlags.
        </Box>
      )}
      <Box mt={3}>
        <Button
          linkTo={() => {
            setText('')
            setFeedbackSent(false)
            setShow(false)
          }}
          text="Schließen"
        />
      </Box>
    </Flex>
  )
  return (
    <ModalWindow show={show} onRequestClose={() => setShow(false)} header={header} footer={footer} footerHeight={60}>
      {content}
    </ModalWindow>
  )
}
