import {Flex, FooterBox} from '@indoqa/style-system'
import * as React from 'react'
import {useFela} from 'react-fela'
import {centeredLayoutRules, containerRules} from '../../../app/mixins'
import {Theme} from '../../../app/theme'
import {FooterLinks} from './FooterLinks'

export const FooterPanel: React.FC = () => {
  const {theme} = useFela<Theme>()
  return (
    <FooterBox<Theme> fullWidth bg="primary" color="textInverted" fontStyle="brand" fontSize="small">
      <Flex style={[centeredLayoutRules(theme), containerRules(theme)]} py={2} px={2} justifyContent="space-between">
        <FooterLinks />
      </Flex>
    </FooterBox>
  )
}
