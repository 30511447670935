import {Flex} from '@indoqa/style-system'
import {IStyle} from 'fela'
import * as React from 'react'
import {useFela} from 'react-fela'
import {removePaddingLeftPrintRule} from '../../../app/mixins'
import {Theme} from '../../../app/theme'

interface Props {
  height: number
}

export const ReaderSubHeaderPanel: React.FC<Props> = ({height, children}) => {
  const {theme} = useFela<Theme>()
  const style: IStyle = {
    print: {
      backgroundColor: 'transparent',
      color: theme.colors.text,
      marginLeft: 0,
    },
  }
  return (
    <Flex<Theme>
      fullWidth
      px={2}
      height={height}
      bg="primaryLight"
      color="textInverted"
      justifyContent="space-between"
      fontStyle="brand"
      alignItems="center"
      nowrap
      style={[style, removePaddingLeftPrintRule()]}
    >
      {children}
    </Flex>
  )
}
