import * as React from 'react'
import {useFela} from 'react-fela'
import {Theme} from '../../../app/theme'
import {ReaderLayoutContext, ReaderLayoutContextValue} from '../../layouts/reader-layout/ReaderLayoutContext'
import {StructureNode} from '../../types/Structure'
import {isMobile} from '../../utils/isMobile'
import {RenderDigitalNodeProps} from './digitalStructure.types'

export const useLoadStructureContent = <S extends StructureNode>(
  props: RenderDigitalNodeProps<S>,
  altParaId?: string
) => {
  const {theme} = useFela<Theme>()
  const {structure, eventHandlers, loadDigitalContent, inNodePath, selected} = props
  const paraId = structure.link.id
  const {toggleNav} = React.useContext<ReaderLayoutContextValue>(ReaderLayoutContext)
  React.useEffect(() => {
    const onClickDisposable = eventHandlers.click.on((event) => {
      // if (!canLoadContent) {
      //   return
      // }
      // if (event.preventClosing && inNodePath && !selected) {
      //   event.preventClosing()
      // }
      loadDigitalContent(altParaId ?? paraId)
      if (isMobile(theme)) toggleNav()
    })
    return () => {
      onClickDisposable.dispose()
    }
  }, [eventHandlers, loadDigitalContent, toggleNav, paraId, altParaId, inNodePath, selected, theme])
}
