import * as React from 'react'

export interface Dimensions {
  headerHeight: number
  footerHeight: number
  searchBarHeight: number
  navWidth: number
}

export interface ReaderLayoutContextValue {
  navShown: boolean
  dimensions: Dimensions
  scrollTopEnabled: boolean
  contentRef: React.RefObject<HTMLElement> | null
  toggleNav: () => void
  enableNav: () => void
  disableNav: () => void
  setHeaderHeight: (height: number) => void
  setFooterHeight: (height: number) => void
  setNavbarWidth: (height: number) => void
  setSearchBarHeight: (height: number) => void
  setContentRef: (contentRef: React.RefObject<HTMLElement>) => void
}

export const ReaderLayoutContext = React.createContext<any>(null)
