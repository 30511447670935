import * as React from 'react'
import {ResultItemTemplate} from '../../../commons/components/search-result/ResultItemTemplate'
import {ActivationState, getArfbrDebugFromSessionStorage} from '../../../commons/components/settings/settings'
import {Pool} from '../../../commons/idqsa/idqsa'
import {arPathBook} from '../../store/ar.paths'
import {ResultItemProps} from '../../store/ar.types'
import {getResourceAvailabilityForUser} from '../../utils/getResourceAvailabilityForUser'
import {ArResultItemBreadcrumb, ChapterBreadcrumbs, createBreadcrumbItem} from './ChapterBreadcrumbs'
import {getAvailability} from './utils/getAvailability'
import {getBookTitleFromResultItem} from './utils/getBookTitleFromResultItem'
export const ArBookResultItem: React.FC<ResultItemProps> = ({resultDocument, searchId, userInput, filters, page}) => {
  const arfbrDebug = getArfbrDebugFromSessionStorage()

  const link = arPathBook(
    userInput,
    resultDocument.properties.bookId!,
    resultDocument.properties.chapterId,
    resultDocument.id,
    filters,
    page
  )

  const source = getBookTitleFromResultItem(resultDocument)
  const name = resultDocument.properties.simpleName || resultDocument.name
  const userResourceAvailability = getResourceAvailabilityForUser(getAvailability(resultDocument))
  const notAvailable = userResourceAvailability && !userResourceAvailability.available

  const items: ArResultItemBreadcrumb[] = []
  if (source) {
    items.push(createBreadcrumbItem(source, undefined, notAvailable))
  }

  const breadcrumbLink = (chapterId?: string) => {
    return arPathBook(userInput, resultDocument.properties.bookId!, chapterId, chapterId, filters, page)
  }
  const breadcrumbs = <ChapterBreadcrumbs items={items} breadcrumbLink={breadcrumbLink || (() => '')} />

  return (
    <ResultItemTemplate
      resultItemId={resultDocument.id}
      name={name}
      source={getBookTitleFromResultItem(resultDocument)}
      userResourceAvailability={userResourceAvailability}
      link={link}
      pool={Pool.ARFBR}
      searchId={searchId}
      type={resultDocument.properties.skriptum ? 'SKRIPTUM' : 'BUCH'}
      typeBgColor="contentTypeBookChapter"
      disableBadge={arfbrDebug === ActivationState.NO}
      breadcrumbs={breadcrumbs}
    >
      {}
    </ResultItemTemplate>
  )
}
