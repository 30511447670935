import {AngArb} from '../../kv/store/kv.types'
import {Link} from './Link'

export interface Highlights {
  name?: string[]
  fullText?: string[]
  string_property_lawAbbreviation?: string[]
  parentName?: string[]
  parentAlternativeName?: string[]
}

export interface FacetValue {
  name: string
  count: number
}

export interface DateFacetValue extends FacetValue {
  from: string
  to: string
}

export interface Facets {
  string_property_chapterSimpleName?: FacetValue[]
  strings_property_province?: FacetValue[]
  strings_property_ang_arb?: FacetValue[]
  strings_property_union?: FacetValue[]
  strings_property_sector?: FacetValue[]
  date_property_contentModified?: DateFacetValue[]
  skriptum_name: FacetValue[]
  handbook_name: FacetValue[]
  reihe?: FacetValue[]
}

export interface Properties {
  bookId?: string
  chapter?: string
  chapterId?: string
  chapterPath?: string[]
  chapterSimpleName?: string
  chapterSimpleNamePath?: string[]
  chapterIdPath?: string[]
  referencedLawCitations?: string[]
  referencedLawDefinitions?: string[]
  referencedLaws?: string[]
  referencedLawsNormalized?: string[]
  simpleName?: string
  skriptum?: boolean
  hasQuestions?: boolean
  quelle_lang?: string
  quelle_kurz?: string
  rechtstand?: string

  assemblyType?: string
  ang_arb?: AngArb[]
  docSetId?: string
  docSetName?: string
  assemblyTitle?: string
  timeSliceId?: string
  firstNote?: string

  untertitel?: string
  herausgeber?: string
  herausgeber_html?: string
  rechtsstand?: string
  reihe?: string

  lawAbbreviation?: string

  news_date?: Date
  union?: string[]

  firstParagraphId?: string

  webcType?: string
  youtubeLink?: string
}

export enum ResultType {
  BOOKS_QUESTION = 'BOOKS_QUESTION',
  BOOKS_CHAPTER = 'BOOKS_CHAPTER',
  BOOKS_BOOK = 'BOOKS_BOOK',
  WEBC_DOCUMENT = 'WEBC_DOCUMENT',
  NEWS_ENTRY = 'NEWS',
  VIDEO = 'VIDEO',
  DEFINITION = 'DEFINITION',
  RIS_DOCUMENT = 'RIS_DOCUMENT',
  RIS_LAW = 'RIS_LAW',
}

export enum DocumentSet {
  LIVE = 'LIVE',
  PREVIEW = 'PREVIEW',
}

export enum Provider {
  BOOKS = 'BOOKS',
  WEBC = 'WEBC',
  RIS = 'RIS',
}

export enum Language {
  DE = 'de',
}

export type Availability = 'FREE' | 'PLUS' | 'PREMIUM'

export interface ResultDocument {
  id: string
  name: string
  type: ResultType
  score: number
  set: DocumentSet
  link: Link
  created: Date
  modified: Date
  provider: Provider
  parentId: string
  parentProvider: Provider
  parentName: string
  language: Language
  location: string
  shortText: string
  properties: Properties
  highlights?: Highlights
  validTo?: Date
  validFrom?: Date
  availability?: Availability
}

export interface ResultGroup {
  documentsFound: number
  documents: ResultDocument[]
  groupDocument: ResultDocument
  groupValue: string
}

export interface Tracking {
  searchId: string
  sessionId: string
}

export interface SearchResult {
  queryTime: number
  executionTime: number
  documentsFound: number
  documents?: ResultDocument[]
  documentGroupsFound?: number
  documentGroups?: ResultGroup[]
  facets?: Facets
  tracking?: Tracking
}
