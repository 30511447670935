import {Observable, of} from 'rxjs'
import {AjaxCreationMethod} from 'rxjs/internal/observable/dom/AjaxObservable'
import {StructureNode} from '../types/Structure'
import {getHeaders} from './service-utils'

export const STRUCTURE_IDS = {
  digital: 'verlag-digital',
  kvsystem: 'kvsystem',
}

export const loadBookStructureService$ = (
  ajax: AjaxCreationMethod,
  id: string,
  structure: StructureNode | null
): Observable<StructureNode> => {
  if (structure && structure.link.id === id) {
    return of(structure)
  }
  return ajax.getJSON(`/api/v1/arfbr/books/${id}/structure`, getHeaders())
}
