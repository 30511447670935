import * as React from 'react'
import {
  AddParaId,
  ClearParaIds,
  ContainsAnyParaId,
  ContainsParaId,
  CountParaIds,
  RemoveParaId,
  SelectivePrintingContextValue,
  SelectivePrintingContextValueState,
} from './selectivePrinting.types'

export const useInitSelectivePrinting = (documentId: string): SelectivePrintingContextValue => {
  const [state, setState] = React.useState<SelectivePrintingContextValueState>({
    paraIds: [],
  })
  const addParaId: AddParaId = (id) => {
    setState((prevState) => ({
      ...prevState,
      paraIds: [...prevState.paraIds, id],
    }))
  }
  const removeParaId: RemoveParaId = (id) => {
    setState((prevState) => ({
      ...prevState,
      paraIds: prevState.paraIds.filter((paraId) => paraId !== id),
    }))
  }
  const containsParaId: ContainsParaId = (id) => {
    for (let i = 0; i < state.paraIds.length; i++) {
      if (state.paraIds[i] === id) {
        return true
      }
    }
    return false
  }
  const containsAnyParaId: ContainsAnyParaId = () => {
    return state.paraIds.length > 0
  }
  const countParaIds: CountParaIds = () => {
    return state.paraIds.length
  }
  const clearParaIds: ClearParaIds = () => {
    setState((prevState) => ({
      ...prevState,
      paraIds: [],
    }))
  }

  React.useEffect(() => {
    clearParaIds()
  }, [documentId])

  return {
    addParaId,
    removeParaId,
    containsParaId,
    containsAnyParaId,
    countParaIds,
    clearParaIds,
  }
}
