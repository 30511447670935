import * as React from 'react'
import {useFela} from 'react-fela'
import {useDispatch, useSelector} from 'react-redux'
import {useParams} from 'react-router-dom'
import {Theme} from '../../app/theme'
import {ReaderFooterPanel} from '../../commons/components/footer/ReaderFooterPanel'
import {DigitalHelmet} from '../../commons/components/helmet/DigitalHelmet'
import {ReaderLayout} from '../../commons/layouts/reader-layout/ReaderLayout'
import {useError} from '../../error/hooks/useError'
import {ErrorPage} from '../../error/pages/ErrorPage'
import {BookWebbookIdReaderHeaderPanel} from '../components/webbooks-id/header/BookWebbookIdReaderHeaderPanel'
import {bookLoadEbook} from '../store/book.actions'
import {bookPathEbook, bookResourcePdfReader} from '../store/book.paths'
import {selectEbook} from '../store/book.selectors'

interface LocationParams {
  bookId: string
  bookTitle: string
}

export const BookEbookReaderPage: React.FC = () => {
  const {css} = useFela<Theme>()
  const {bookId, bookTitle} = useParams<LocationParams>()
  const dispatch = useDispatch()
  React.useLayoutEffect(() => {
    if (bookId) {
      dispatch(bookLoadEbook(bookId))
    }
  }, [dispatch, bookId])

  const ebook = useSelector(selectEbook)

  // error handling
  const hasError = useError()
  if (hasError) {
    return <ErrorPage />
  }

  if (!ebook || !bookId || !bookTitle) {
    return null
  }

  const title = ebook.properties.untertitel ? `${ebook.name} - ${ebook.properties.untertitel}` : ebook.name
  const header = <BookWebbookIdReaderHeaderPanel bookTitle={title} bookId={bookId} />
  const footer = <ReaderFooterPanel />
  const style = {
    width: '100%',
    height: '100%',
    border: 0,
  }
  const bookLink = bookResourcePdfReader(bookId)
  return (
    <ReaderLayout header={header} footer={footer}>
      <DigitalHelmet title={title} canonical={bookPathEbook(bookId, bookTitle)} />
      <iframe title={`PDF`} className={css(style)} src={bookLink}>
        Die Anzeige ist nicht möglich, da ihr Browser keine iFrames unterstützt.
      </iframe>
    </ReaderLayout>
  )
}
