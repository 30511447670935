import {ArState} from '../ar/store/ar.types'
import {BookState} from '../book/store/book.types'
import {DigitalState} from '../digital/store/digital.types'
import {ErrorState} from '../error/store/error.types'
import {KvState} from '../kv/store/kv.types'
import {LawState} from '../law/store/law.types'
import {RootState} from './types'

export const selectArState = (state: RootState): ArState => state.ar
export const selectBookState = (state: RootState): BookState => state.book
export const selectDigitalState = (state: RootState): DigitalState => state.digital
export const selectErrorState = (state: RootState): ErrorState => state.error
export const selectKvState = (state: RootState): KvState => state.kv
export const selectLawState = (state: RootState): LawState => state.law
