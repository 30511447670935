import {Box} from '@indoqa/style-system'
import * as React from 'react'
import {ReaderContentTopInfo} from '../../../commons/components/header/ReaderContentTopInfo'
import {Pool} from '../../../commons/idqsa/idqsa'
import {useTrackReadSignalRef} from '../../../commons/idqsa/useTrackReadSignalRef'
import {DocumentInfo} from '../../../commons/types/DocumentInfo'
import {StructureNode} from '../../../commons/types/Structure'
import {LawContentPanel} from './LawContentPanel'

interface Props {
  content: string | null
  navShown: boolean
  lawStructure: StructureNode | null
  lawDocumentInfo: DocumentInfo | null
}

export const LawParagraphPageContentPanel = (props: Props) => {
  const {content, navShown, lawStructure, lawDocumentInfo} = props
  const idqsaTrackedElementRef = useTrackReadSignalRef(Pool.LAW, lawDocumentInfo?.documentId)
  return (
    <Box innerRef={idqsaTrackedElementRef}>
      <ReaderContentTopInfo>
        {lawStructure ? lawStructure.link.name : ''} &gt; {lawDocumentInfo ? lawDocumentInfo.documentName : ''}
      </ReaderContentTopInfo>
      <LawContentPanel content={content} navShown={navShown} />
    </Box>
  )
}
