import * as React from 'react'
import {RenderDigitalNodeProps} from '../../../../commons/components/structure/digitalStructure.types'
import {DigitalStructureLeafTemplate} from '../../../../commons/components/structure/DigitalStructureLeafTemplate'
import {useLoadStructureContent} from '../../../../commons/components/structure/useLoadStructureContent'
import {BookStructureNode} from '../../../store/book.types'

export const BookWebbookIdStructureLeafPanel: React.FC<RenderDigitalNodeProps<BookStructureNode>> = (props) => {
  const {inNodePath, selected, createDigitalLink} = props
  useLoadStructureContent(props)
  const createLink = createDigitalLink ? () => createDigitalLink(props.structure.link.id) : undefined
  return (
    <DigitalStructureLeafTemplate
      label={props.structure.link.name}
      selected={inNodePath || selected}
      paddingRight={props.style.padding}
      createLink={createLink}
    />
  )
}
