import * as React from 'react'
import {Helmet} from 'react-helmet'

interface Props {
  title?: string
  canonical: string
  noIndex?: boolean
}

const defaultTitle = 'ÖGB Verlag Digital'

const createTitle = (title?: string) => {
  return title && title.length > 0 ? `${title} | ${defaultTitle}` : defaultTitle
}

/*
 * see https://github.com/nfl/react-helmet/issues/314 for the defer prop
 */
export const DigitalHelmet: React.FC<Props> = ({title, canonical, noIndex}) => {
  return (
    <Helmet defer={false}>
      <title>{createTitle(title)}</title>
      <link rel="canonical" href={`https://digital.oegbverlag.at${canonical}`} />
      {noIndex && <meta name="robots" content="noindex,nofollow" />}
    </Helmet>
  )
}
