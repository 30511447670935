import * as React from 'react'
import {useFela} from 'react-fela'
import {Theme} from '../../../app/theme'
import {getResultDocumentText} from '../../../ar/utils/resultItem.utils'
import {ResultItemTemplate} from '../../../commons/components/search-result/ResultItemTemplate'
import {Pool} from '../../../commons/idqsa/idqsa'
import {ResultDocument} from '../../../commons/types/SearchResult'
import {kvPathDocument} from '../../store/kv.paths'
import {createDocsetNamePathPart} from '../../store/kv.utils'

interface Props {
  resultDocument: ResultDocument
  userQuery?: string
  searchId?: string
}

export const getType = (resultDocument: ResultDocument) => {
  const {assemblyType, assemblyTitle} = resultDocument.properties
  const typeName =
    assemblyTitle && assemblyTitle.length > 0 ? assemblyTitle.substr(assemblyTitle.lastIndexOf('/') + 1) : null
  return typeName || assemblyType || 'Document'
}

export const getShortenedAssemblyTitle = (docSetName?: string, assemblyTitle?: string) => {
  if (!docSetName || !assemblyTitle) {
    return assemblyTitle
  }
  // remove the docset name and the remaining leading dash
  if (assemblyTitle.startsWith(docSetName) && assemblyTitle.length >= docSetName.length + 3) {
    return assemblyTitle.substr(docSetName.length + 3)
  }
  return assemblyTitle
}

export const KvDocumentResultItem: React.FC<Props> = ({resultDocument, userQuery, searchId}) => {
  const {theme} = useFela<Theme>()
  const type = getType(resultDocument)
  const {timeSliceId, docSetName, assemblyTitle, ang_arb} = resultDocument.properties
  const shortenedAssemblyTitle = getShortenedAssemblyTitle(docSetName, assemblyTitle)
  const link = kvPathDocument(
    resultDocument.parentId!,
    timeSliceId!,
    createDocsetNamePathPart(docSetName!, ang_arb),
    shortenedAssemblyTitle!,
    {paraId: resultDocument.id, userQuery}
  )
  return (
    <ResultItemTemplate
      resultItemId={resultDocument.id}
      searchId={searchId}
      name={resultDocument.name}
      type={type}
      pb={3}
      link={link}
      typeBgColor={theme.colors.contentTypeKv}
      pool={Pool.KVS}
    >
      <p dangerouslySetInnerHTML={{__html: getResultDocumentText(resultDocument)}} />
    </ResultItemTemplate>
  )
}
