import {ContentNode} from '../../commons/types/ContentNode'
import {TableOfContent} from '../../commons/types/TableOfContent'

export enum DigitalActionKeys {
  LOAD_CONTENT = 'digital/LOAD_CONTENT',
  LOAD_CONTENT_SUCCESS = 'digital/LOAD_CONTENT_SUCCESS',
  LOAD_CONTENT_ERROR = 'digital/LOAD_CONTENT_ERROR',
  LOAD_MAPPED_CONTENT = 'digital/LOAD_MAPPED_CONTENT',
  LOAD_MAPPED_CONTENT_SUCCESS = 'digital/LOAD_MAPPED_CONTENT_SUCCESS',
}

export interface MappedContentNodes {
  [id: string]: ContentNode
}

export interface DigitalState {
  readonly tocs: {[id: string]: TableOfContent}
  readonly contentNodes: {[id: string]: ContentNode}
  readonly mappedContentNodes: {[id: string]: MappedContentNodes}
}
