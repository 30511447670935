import * as React from 'react'
import {AlignedContentPanel} from '../../../commons/components/content/AlignedContentPanel'
import {LAW_CSS_CLASSNAME} from '../../../commons/css-rules/LawCSSRules'

interface Props {
  content: string | null
  navShown: boolean
}

export const LawContentPanel: React.FC<Props> = ({content, navShown}) => {
  const contentRef = React.useRef<HTMLDivElement>(null)

  React.useLayoutEffect(() => {
    if (!contentRef.current) {
      return
    }
    const linkElements = contentRef.current.querySelectorAll('a')
    linkElements.forEach((linkElement) => {
      const href = linkElement.getAttribute('href')
      if (href && href.startsWith('/')) {
        linkElement.setAttribute('href', `https://www.ris.bka.gv.at${href}`)
      }
    })
  }, [contentRef])

  if (!content) {
    return null
  }
  return (
    <AlignedContentPanel navShown={navShown}>
      <div
        className={LAW_CSS_CLASSNAME}
        dangerouslySetInnerHTML={{__html: content}}
        ref={contentRef}
        style={{width: '100%'}}
      />
    </AlignedContentPanel>
  )
}
