import * as React from 'react'
import {ResultDocument} from '../../types/SearchResult'
import {formatDate} from '../../utils/formatDate'
import {Card} from '../card/Card'
import {createNewsLink} from './createNewsLink'

interface Props {
  newsEntry: ResultDocument
}

export const NewsPanelCard = ({newsEntry}: Props) => {
  const union = newsEntry.properties.union
  const unionOrigin = union ? `Gewerkschaft ${newsEntry.properties.union}` : undefined
  const origin = newsEntry.properties.firstNote || unionOrigin
  return (
    <Card
      title={newsEntry.name}
      aboveTitle={formatDate(newsEntry.properties.news_date || new Date())}
      aboveTitle2={origin}
      linkTo={createNewsLink(newsEntry) || undefined}
      height="100%"
    />
  )
}
