import {Epic, ofType} from 'redux-observable'
import {of} from 'rxjs/internal/observable/of'
import {catchError, map, switchMap} from 'rxjs/operators'
import {RootState, Services} from '../../app/types'
import {ErrorAction, setError, ThrowError} from './error.actions'
import {postErrorService$} from './error.services'
import {ClientError, ErrorActionKeys, ErrorType} from './error.types'

interface ErrorEpic extends Epic<ErrorAction, ErrorAction, RootState, Services> {}

const createErrorAction = (error: ClientError) => {
  return setError(error.message, error.type, error.timestamp.getTime())
}

const throwError$: ErrorEpic = (action$, state$, {ajax, history}) =>
  action$.pipe(
    ofType<ThrowError>(ErrorActionKeys.THROW_ERROR),
    switchMap((action) => {
      const {error, errorInfo} = action
      const errorType = errorInfo
        ? ErrorType.REACT_ERROR
        : error.status === 404
        ? ErrorType.NOT_FOUND
        : ErrorType.SIDE_EFFECT
      const message = error.message
      const {pathname, search} = history.location
      const timestamp = new Date()
      const clientError: ClientError = {
        type: errorType,
        location: pathname + (search && search.length > 0 ? search : ''),
        userAgent: window.navigator.userAgent,
        message,
        timestamp,
        state: state$.value,
        componentStack: action.errorInfo,
        stacktrace: error.stack,
      }
      return postErrorService$(ajax, clientError).pipe(
        map(() => createErrorAction(clientError)),
        catchError(() => of(createErrorAction(clientError)))
      )
    })
  )

export const errorEpics = [throwError$]
