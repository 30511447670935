import * as React from 'react'
import {useFela} from 'react-fela'
import {Theme} from '../../../../app/theme'
import {Breadcrumb, ReaderBreadcrumbsPanel} from '../../../../commons/components/header/ReaderBreadcrumbsPanel'
import {ReaderHeaderPanel} from '../../../../commons/components/header/ReaderHeaderPanel'
import {ReaderSubHeaderPanel} from '../../../../commons/components/header/ReaderSubHeaderPanel'
import {
  ReaderLayoutContext,
  ReaderLayoutContextValue,
} from '../../../../commons/layouts/reader-layout/ReaderLayoutContext'
import {DigitalLogo} from '../../../../digital/components/digital-logo/DigitalLogo'
import {bookPathWebBookSlugId} from '../../../store/book.paths'

interface Props {
  bookTitle: string
  bookId: string
}

export function BookWebbookSlugReaderHeaderPanel({bookId, bookTitle}: Props) {
  const {theme} = useFela<Theme>()
  const {setHeaderHeight} = React.useContext<ReaderLayoutContextValue>(ReaderLayoutContext)
  React.useLayoutEffect(() => {
    const headerHeight = theme.layout.reader.headerHeight + theme.layout.reader.toolbarHeight
    setHeaderHeight(headerHeight)
  }, [setHeaderHeight, theme.layout.reader.headerHeight, theme.layout.reader.toolbarHeight])

  const logo = <DigitalLogo small />
  const breadcrumbs: Breadcrumb[] = [
    // {name: 'Bücher', linkTo: bookPathOverview()},
    {name: bookTitle, linkTo: bookPathWebBookSlugId(bookId)},
  ]
  return (
    <>
      <ReaderHeaderPanel logo={logo} />
      <ReaderSubHeaderPanel height={theme.layout.reader.toolbarHeight}>
        <ReaderBreadcrumbsPanel breadcrumbs={breadcrumbs} />
      </ReaderSubHeaderPanel>
    </>
  )
}
