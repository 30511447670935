import {Box} from '@indoqa/style-system'
import {IStyle} from 'fela'
import * as React from 'react'
import {useFela} from 'react-fela'
import {Theme} from '../../../app/theme'
import {FacetValue} from '../../types/SearchResult'
import {FacetEntry} from './FacetEntry'

interface Props<Filters> {
  name: string
  values: FacetValue[]
  filters: Filters
  currentFilter: keyof Filters
  translations?: {[key: string]: string}
  performFiltering: (nextFilters: Filters) => void
}

export const StringFacetPanel = <Filters extends {}>(props: Props<Filters>) => {
  const {name, values, filters, translations, performFiltering, currentFilter} = props
  const {theme} = useFela<Theme>()
  const selectedValue = filters[currentFilter] as unknown
  const facets = values
    .filter((value) => value.count > 0)
    .map((value) => {
      const onClick = () => {
        const nextFilters: Filters = {
          ...filters,
          [currentFilter]: selectedValue === value.name ? undefined : value.name,
        }
        performFiltering(nextFilters)
      }
      const label = translations ? translations[value.name] : value.name
      return (
        <FacetEntry
          key={value.name}
          label={label}
          value={value}
          selected={value.name === selectedValue}
          onClick={onClick}
        />
      )
    })
  const hasFacets = facets.length > 0
  if (!hasFacets) {
    return null
  }
  const facetsContainerStyle: IStyle = {
    borderLeft: theme.borders.border1,
  }
  return (
    <Box mb={3}>
      <Box<Theme> fontStyle="brandBold">{name}</Box>
      <Box ml={1} pl={2} style={facetsContainerStyle}>
        {facets}
      </Box>
    </Box>
  )
}
