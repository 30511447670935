import {Box, Flex} from '@indoqa/style-system'
import * as React from 'react'
import {useFela} from 'react-fela'
import {searchResultWidthRules} from '../../../app/mixins'
import {Theme} from '../../../app/theme'
import {Facets, ResultDocument, ResultGroup, SearchResult} from '../../types/SearchResult'
import {CollapseIcon} from './CollapseIcon'
import {ResultGroupPanel} from './ResultGroupPanel'

export type RenderDocumentGroup = (
  resultGroup: ResultGroup,
  collapsed: boolean,
  setCollapsed: (nextCollapsed: boolean) => void
) => JSX.Element
export type RenderDocument = (
  resultDocument: ResultDocument,
  resultGroup: ResultGroup,
  searchId?: string
) => JSX.Element
export type RenderFacets = (facets: Facets) => JSX.Element

interface Props {
  searchResult: SearchResult
  resultNamePlural: string
  resultNameSingular: string
  renderDocumentGroup: RenderDocumentGroup
  renderDocument: RenderDocument
  renderFacets?: RenderFacets
  disableCollapsing?: boolean
}

export const SearchResultPanel: React.FC<Props> = (props) => {
  const {
    searchResult,
    resultNamePlural,
    resultNameSingular,
    renderDocumentGroup,
    renderDocument,
    renderFacets,
    disableCollapsing,
  } = props
  const [collapsed, setCollapsed] = React.useState<boolean>(!disableCollapsing)
  const {theme} = useFela<Theme>()
  const {documentGroups} = searchResult
  const {documentGroupsFound} = searchResult

  const hasResults = documentGroups && documentGroups.length > 0
  const resultOverview = hasResults
    ? `Es wurden Ergebnisse in ${searchResult.documentGroupsFound} 
    ${documentGroupsFound === 1 ? resultNameSingular : resultNamePlural} gefunden.`
    : 'Leider hat Ihre Suche zu keinen Ergebnissen geführt.'

  const resultGroups = documentGroups
    ? documentGroups.map((resultGroup) => {
        return (
          <ResultGroupPanel
            key={resultGroup.groupDocument.id}
            resultGroup={resultGroup}
            renderDocumentGroup={renderDocumentGroup}
            renderDocument={renderDocument}
            collapsed={collapsed}
            searchId={searchResult.tracking?.searchId}
          />
        )
      })
    : null

  const showCollapseIcon = disableCollapsing !== true
  return (
    <Flex fullWidth nowrap>
      <Box style={searchResultWidthRules(theme)} grow={1}>
        <Flex mb={3} pl={2} mr={2} justifyContent="space-between" nowrap>
          <Box>{resultOverview}</Box>
          {showCollapseIcon && hasResults && (
            <CollapseIcon collapsed={collapsed} toggle={() => setCollapsed(!collapsed)} />
          )}
        </Flex>
        {resultGroups}
      </Box>
      <Box pl={4} ml={4} grow={1} display={['none', 'block']}>
        {renderFacets && searchResult.facets ? renderFacets(searchResult.facets) : null}
      </Box>
    </Flex>
  )
}
