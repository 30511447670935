import * as React from 'react'
import {Pool, sendSelectSignal} from './idqsa'

interface Props {
  resultItemId?: string
  searchId?: string
  pool: Pool
  children: React.ReactNode
}

export const SelectSignal = ({resultItemId, searchId, pool, children}: Props) => {
  return (
    <div
      onClick={() => {
        if (searchId && resultItemId) {
          sendSelectSignal(pool, resultItemId, searchId)
        }
      }}
    >
      {children}
    </div>
  )
}
