import {DocumentInfo} from '../../commons/types/DocumentInfo'
import {LinkType} from '../../commons/types/Link'
import {ResultDocument} from '../../commons/types/SearchResult'
import {StructureNodePredicate} from '../../commons/utils/findDocumentByPara'
import {findDocumentInfo} from '../../commons/utils/findDocumentInfo'
import {LinkPredicate} from '../../commons/utils/findFirstDocumentInfo'
import {getLawReferenceTitle} from '../../law/store/law.utils'
import {formatValidityDateApi} from '../utils/validityDateUtils'
import {HIST_GEHALTSTABELLE_NAME} from './kv.constants'
import {AngArb, DocsetStructure, KvReaderQueryParams} from './kv.types'

export const isKvDocument: LinkPredicate = (link) => {
  return link.type === LinkType.KV_TIME_SLICE
}

export const isKvPara: LinkPredicate = (link) => {
  return link.type === LinkType.KV_PARAGRAPH
}

export const findKvDocumentInfo = (
  structure: DocsetStructure,
  searchMode: boolean,
  proposedDocumentId?: string,
  proposedParaId?: string
): DocumentInfo | null => {
  const mainDocumentInfo = findDocumentInfo(
    structure.main,
    isKvDocument,
    isKvPara,
    searchMode,
    proposedDocumentId,
    proposedParaId,
    getLawReferenceTitle
  )

  // if the documentId was ok, go with the mainDocumentId
  if (mainDocumentInfo && mainDocumentInfo.documentId === proposedDocumentId) {
    return mainDocumentInfo
  }

  // if there is no family kv, the altered mainDocumentInfo is ok
  if (!structure.family) {
    return mainDocumentInfo
  }

  // use the family kv if the proposed document is ok
  const familyDocumentInfo = findDocumentInfo(
    structure.family,
    isKvDocument,
    isKvPara,
    searchMode,
    proposedDocumentId,
    proposedParaId,
    getLawReferenceTitle
  )
  if (familyDocumentInfo && familyDocumentInfo.documentId === proposedDocumentId) {
    return familyDocumentInfo
  }

  // if no proposed document was found, fall back to the first found
  if (mainDocumentInfo && mainDocumentInfo.documentId) {
    return mainDocumentInfo
  }
  if (familyDocumentInfo && familyDocumentInfo.documentId) {
    return familyDocumentInfo
  }

  // otherwise fall back to the mainDocumentInfo
  return mainDocumentInfo
}

export const isTimeSliceNode: StructureNodePredicate = (node) => {
  return node.link.type === LinkType.KV_TIME_SLICE
}

export const createDocsetName = (structure: DocsetStructure | null, isMain: boolean) => {
  if (!structure) {
    return ''
  }
  let name = structure.main.link.name
  const angArb = angArbToString(structure.main.angArb)
  if (angArb) {
    name += ` (${angArb})`
  }
  if (structure.family && !isMain) {
    name += ' | ' + structure.family.link.name
  }
  return name
}

export const isMainDocument = (structure: DocsetStructure | null, documentId?: string): boolean => {
  if (!structure) {
    return true
  }
  const documents = structure.main.children ? structure.main.children.filter((doc) => doc.link.id === documentId) : null
  return !!(documents && documents.length > 0)
}

export const haveKvParamsChanged = (
  requestedKvParams: KvReaderQueryParams = {},
  currentKvParams: KvReaderQueryParams
) => {
  if (
    !(currentKvParams.quickSearch === undefined && requestedKvParams.quickSearch === undefined) &&
    currentKvParams.quickSearch !== requestedKvParams.quickSearch
  ) {
    return true
  }
  if (
    !(currentKvParams.validityDate === undefined && requestedKvParams.validityDate === undefined) &&
    currentKvParams.validityDate !== requestedKvParams.validityDate
  ) {
    return true
  }
  return (
    !(currentKvParams.userQuery === undefined && requestedKvParams.userQuery === undefined) &&
    currentKvParams.userQuery !== requestedKvParams.userQuery
  )
}

export const getValidityDate = (kvParams: KvReaderQueryParams): string | undefined => {
  const quickSearch = kvParams.quickSearch
  if (quickSearch && quickSearch === HIST_GEHALTSTABELLE_NAME) {
    return undefined
  }
  return formatValidityDateApi(kvParams.validityDate || new Date())
}

export const getKvDocumentName = (doc: ResultDocument) => {
  const angArb = angArbToString(doc.properties.ang_arb)
  let name = ''
  if (doc.highlights?.name && doc.highlights?.name.length > 0) {
    name = doc.highlights.name[0].replace('<mark>', '<strong>').replace('<mark/>', '</strong>')
  } else {
    name = doc.name
  }
  return name + (angArb ? ` (${angArb})` : '')
}

export const angArbToString = (angArb?: AngArb[]) => {
  if (angArb && angArb.length > 0) {
    if (angArb.length > 1) {
      return 'Arb./Ang.'
    }
    if (angArb.includes(AngArb.ARB)) {
      return 'Arb.'
    }
    if (angArb.includes(AngArb.ANG)) {
      return 'Ang.'
    }
  }
  return null
}

export const angArbToLongString = (angArb?: AngArb[]) => {
  if (angArb && angArb.length > 0) {
    if (angArb.length > 1) {
      return 'Arbeiter und Angestellte'
    }
    if (angArb.includes(AngArb.ARB)) {
      return 'Arbeiter'
    }
    if (angArb.includes(AngArb.ANG)) {
      return 'Angestellte'
    }
  }
  return null
}

export const angArbToPathPartString = (angArb: AngArb[]) => {
  if (angArb && angArb.length > 0) {
    if (angArb.length > 1) {
      return 'Arbeiter-Angestellte'
    }
    if (angArb.includes(AngArb.ARB)) {
      return 'Arbeiter'
    }
    if (angArb.includes(AngArb.ANG)) {
      return 'Angestellte'
    }
  }
  return null
}

export const createDocsetNamePathPart = (name: string, angArb?: AngArb[]) => {
  if (!angArb) {
    return name
  }
  return `${name}_${angArbToPathPartString(angArb)}`
}

export const isInSearchMode = (kvParams: KvReaderQueryParams) => {
  return (kvParams.quickSearch !== undefined && kvParams.quickSearch !== '') || kvParams.userQuery !== undefined
}
