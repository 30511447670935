import {Text} from '@indoqa/style-system'
import {IStyle} from 'fela'
import * as React from 'react'
import {useFela} from 'react-fela'
import {Link} from 'react-router-dom'
import {Theme} from '../../../app/theme'

export type OnClick = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void
export type ButtonAction = OnClick | string

export enum Type {
  CTA = 'CTA',
  INFO = 'INFO',
  LIGHT = 'LIGHT',
  REDUCED = 'REDUCED',
}

interface Props {
  text: string
  linkTo: ButtonAction
  type?: Type
  disabled?: boolean
  whiteSpaceWrap?: boolean
}

const getBgColor = (type: Type, theme: Theme) => {
  switch (type) {
    case Type.CTA:
      return theme.colors.bgCtaButton
    case Type.INFO:
      return theme.colors.info
    case Type.LIGHT:
      return theme.colors.secondary
    case Type.REDUCED:
      return theme.colors.bgContentEmphasised
  }
}

const getColor = (type: Type, theme: Theme) => {
  if (type === Type.REDUCED) {
    return theme.colors.textReduced
  }
  return theme.colors.textInverted
}

export const Button: React.FC<Props> = ({text, linkTo, type = Type.LIGHT, whiteSpaceWrap = false}) => {
  const {css, theme} = useFela<Theme>()

  const actionButton = type === Type.CTA || type === Type.INFO

  const baseStyle: IStyle = {
    whiteSpace: whiteSpaceWrap ? undefined : 'nowrap',
    fontSize: actionButton ? '100%' : '80%',
    fontWeight: 'bold',
    color: getColor(type, theme),
    backgroundColor: getBgColor(type, theme),
    textDecoration: 'none',
    paddingTop: actionButton ? theme.spacing.space3 : theme.spacing.space2,
    paddingRight: actionButton ? theme.spacing.space4 : theme.spacing.space3,
    paddingBottom: actionButton ? theme.spacing.space3 : theme.spacing.space2,
    paddingLeft: actionButton ? theme.spacing.space4 : theme.spacing.space3,
    borderRadius: actionButton ? 10 : 0,
    maxWidth: '80vw',
  }

  if (isOnClick(linkTo)) {
    const buttonStyle: IStyle = {
      boxShadow: 'none',
      borderWidth: 0,
      cursor: 'pointer',
    }
    return (
      <button onClick={linkTo} className={css(baseStyle, buttonStyle, rippleStyle)}>
        {text}
      </button>
    )
  }

  const linkStyle: IStyle = {
    '& a': {
      ...baseStyle,
      marginTop: '6',
    },
  }
  const getLinkTo = () => {
    if (!linkTo) {
      return text
    }
    if (linkTo.startsWith('mailto:') || linkTo.startsWith('http')) {
      return (
        <a href={linkTo} rel="noreferrer" target="_blank">
          {text}
        </a>
      )
    }
    return <Link to={linkTo}>{text}</Link>
  }
  return <Text style={[linkStyle, linkRippleStyle]}>{getLinkTo()}</Text>
}

function isOnClick(action: ButtonAction): action is OnClick {
  return typeof action === 'function'
}

const rippleBaseStyle: IStyle = {
  display: 'inline-block',
  position: 'relative',
  overflow: 'hidden',
  transform: 'translate3d(0,0,0)',
}

const rippleAfterStyle: IStyle = {
  content: '""',
  display: 'block',
  position: 'absolute',
  width: '100%',
  height: '100%',
  top: 0,
  left: 0,
  pointerEvents: 'none',
  backgroundImage: 'radial-gradient(circle, #000 10%, transparent 10.01%)',
  backgroundRepeat: 'no-repeat',
  backgroundPosition: '50%',
  transform: 'scale(10,10)',
  opacity: 0,
  transition: 'transform .5s, opacity 1s',
}

const rippleActiveAfterStyle: IStyle = {
  transform: 'scale(0,0)',
  opacity: 0.2,
  transition: '0s',
}

// see https://github.com/mladenplavsic/css-ripple-effect
const linkRippleStyle: IStyle = {
  '& a': {
    ...rippleBaseStyle,
  },
  '& a:after': {
    ...rippleAfterStyle,
  },
  '& a:active:after': {
    ...rippleActiveAfterStyle,
  },
}

const rippleStyle: IStyle = {
  ...rippleBaseStyle,
  '&:after': {
    ...rippleAfterStyle,
  },
  '&:active:after': {
    ...rippleActiveAfterStyle,
  },
}
