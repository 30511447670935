import {Box, Flex} from '@indoqa/style-system'
import {IStyle} from 'fela'
import * as React from 'react'
import {useFela} from 'react-fela'
import {Theme} from '../../../app/theme'
import {Resizer} from '../../../commons-shared/resizer/Resizer'
import {ReaderLayoutContext, ReaderLayoutContextValue} from './ReaderLayoutContext'

export const ReaderLayoutNavbar: React.FC = ({children}) => {
  const {css, theme} = useFela<Theme>()
  const {navShown, setNavbarWidth} = React.useContext<ReaderLayoutContextValue>(ReaderLayoutContext)
  const scrollableContainerRef = React.useRef<HTMLDivElement>(null)
  const widthRef = React.useRef(theme.layout.reader.navbarWidth)

  React.useLayoutEffect(() => {
    setNavbarWidth(theme.layout.reader.navbarWidth)
  }, [setNavbarWidth, theme.layout.reader.navbarWidth])

  const navStyle: IStyle = {
    display: navShown ? 'flex' : 'none',
    position: 'relative',
    tablet: {
      display: 'flex',
    },
    print: {
      display: 'none',
    },
  }

  const onResize = (moveBy: number) => {
    if (scrollableContainerRef.current) {
      const moveTo = widthRef.current + moveBy
      const nextWidth = Math.max(200, Math.min(moveTo, window.innerWidth - 400))
      scrollableContainerRef.current.style.width = `${nextWidth}px`
      widthRef.current = nextWidth
    }
  }
  if (!children) {
    return null
  }

  const scrollableContainerStyle: IStyle = {
    height: '100%',
    overflowY: 'hidden',
    width: '100vw',
    tablet: {
      width: widthRef.current ? `${widthRef.current}px` : '100%',
    },
    print: {
      display: 'none',
    },
  }

  return (
    <Flex fullHeight grow={0} style={navStyle}>
      <Box fullHeight fullWidth>
        <Resizer onResize={onResize} />
        <div className={css(scrollableContainerStyle)} ref={scrollableContainerRef}>
          {children}
        </div>
      </Box>
    </Flex>
  )
}
