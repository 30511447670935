import {Theme} from '../../app/theme'

export const isMobile = (theme: Theme) => {
  const width = Math.max(document.documentElement.clientWidth, window.innerWidth || 0)
  const tabletMinWidthInPx = theme.breakpoints.tablet.minWidth
  if (!tabletMinWidthInPx.endsWith('px')) {
    return false
  }
  const tabletMinWith = parseInt(tabletMinWidthInPx.substr(0, tabletMinWidthInPx.length - 2), 10)
  return width < tabletMinWith
}
