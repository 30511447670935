import {DocumentInfo} from '../../commons/types/DocumentInfo'
import {LinkType} from '../../commons/types/Link'
import {StructureNode, StructureSubtype} from '../../commons/types/Structure'
import {StructureNodePredicate} from '../../commons/utils/findDocumentByPara'
import {findDocumentInfo} from '../../commons/utils/findDocumentInfo'
import {LinkPredicate} from '../../commons/utils/findFirstDocumentInfo'

export const isBookChapterLink: LinkPredicate = (link) => link.type === LinkType.BOOKS_CHAPTER

export const findBookChapter = (
  structure: StructureNode,
  documentId?: string,
  proposedParaId?: string
): DocumentInfo | null => {
  return findDocumentInfo(
    structure,
    isBookChapterLink,
    isBookChapterLink,
    false,
    documentId,
    proposedParaId,
    undefined,
    true
  )
}

export const isBookChapterSubType: StructureNodePredicate = (node) => {
  return node.subType === StructureSubtype.CHAPTER
}
