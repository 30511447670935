import moment from 'moment'
import {kvPathContentPageId, kvPathDocument} from '../../../kv/store/kv.paths'
import {KvReaderQueryParams} from '../../../kv/store/kv.types'
import {LinkType} from '../../types/Link'
import {ResultDocument} from '../../types/SearchResult'

export const createNewsLink = (resultDocument: ResultDocument): string | null => {
  const {id, language, link, properties, validTo, validFrom} = resultDocument
  if (link.type === LinkType.WEBC_DOCUMENT) {
    return kvPathContentPageId(link.id)
  }
  const docSetId = `${properties.docSetId}_${language}`
  const timeSliceId = properties.timeSliceId
  const docSetName = properties.docSetName
  const documentName = link.name
  if (!docSetId || !properties.docSetId || !timeSliceId || !docSetName || !documentName || !id) {
    return null
  }
  const queryParams: KvReaderQueryParams = {paraId: id}
  const validToMoment = moment(validTo)
  const validFromMoment = moment(validFrom)
  if (validToMoment.isBefore(new Date())) {
    queryParams.validityDate = validFromMoment.toDate()
  }
  return kvPathDocument(docSetId, timeSliceId, docSetName, documentName, queryParams)
}
