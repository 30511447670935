import {useQueryParam} from '@oegbv/ui-shared'
import * as React from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {useParams} from 'react-router'
import {bookLoadWebBookContent} from '../../book/store/book.actions'
import {selectWebBookContent, selectWebBookDocumentInfo, selectWebBookStructure} from '../../book/store/book.selectors'
import {ReaderFooterPanel} from '../../commons/components/footer/ReaderFooterPanel'
import {DigitalHelmet} from '../../commons/components/helmet/DigitalHelmet'
import {MainContainerPanel} from '../../commons/components/main/MainContainerPanel'
import {SettingsPanel} from '../../commons/components/settings/SettingsPanel'
import {ReaderLayout} from '../../commons/layouts/reader-layout/ReaderLayout'
import {useError} from '../../error/hooks/useError'
import {ErrorPage} from '../../error/pages/ErrorPage'
import {ArBookContentPanel} from '../components/ArBookContentPanel'
import {ArBookStructurePanel} from '../components/book/ArBookStructurePanel'
import {ARHeaderPanel} from '../components/header/ARHeaderPanel'
import {ArOverviewPanel} from '../components/overview/ArOverviewPanel'
import {ArSearchResultPanel} from '../components/search-result-panel/ArSearchResultPanel'
import {ArFilters, clearSearch, search} from '../store/ar.actions'
import {arProductName} from '../store/ar.constants'
import {arPathOverview, arPathSearch, arQueryParams} from '../store/ar.paths'
import {selectSearchResult} from '../store/ar.selectors'
import {ArSearchParams} from '../store/ar.types'
import {getBookName} from '../utils/getBookName'

export type PerformFiltering = (nextFilters: ArFilters) => void

enum ContentMode {
  OVERVIEW = 'OVERVIEW',
  SEARCH = 'SEARCH',
  BOOK = 'BOOK',
}

const getContentMode = (query?: string | null, bookId?: string | null): ContentMode => {
  if (bookId) {
    return ContentMode.BOOK
  }
  if (query) {
    return ContentMode.SEARCH
  }
  return ContentMode.OVERVIEW
}

export const ArSearchPage = () => {
  const dispatch = useDispatch()
  const [showSettings, setShowSettings] = React.useState(false)

  const previousQuery = React.useRef('')
  const previousFilterBook = React.useRef<string | null | undefined>(undefined)
  const previousFilterSkriptum = React.useRef<string | null | undefined>(undefined)
  const previousFilterBookTitlesOnly = React.useRef<string | null | undefined>(undefined)
  const previousFilterVideosOnly = React.useRef<string | null | undefined>(undefined)

  const {query} = useParams<ArSearchParams>()

  const filterBook = useQueryParam(arQueryParams.filterHandbook)
  const filterSkriptum = useQueryParam(arQueryParams.filterSkriptum)
  const filterBookTitlesOnly = useQueryParam(arQueryParams.filterBookTitlesOnly)
  const filterVideosOnly = useQueryParam(arQueryParams.filterVideosOnly)
  const filters = {
    handbook_name: filterBook,
    skriptum_name: filterSkriptum,
    bookTitleOnly: filterBookTitlesOnly === 'true',
    videoOnly: filterVideosOnly === 'true',
  }

  const bookId = useQueryParam(arQueryParams.bookId)
  const chapterId = useQueryParam(arQueryParams.chapterId)
  const paraId = useQueryParam(arQueryParams.paraId)
  const pageQueryParam = useQueryParam(arQueryParams.page)
  const page = pageQueryParam ? parseInt(pageQueryParam, 10) : 0

  const searchResult = useSelector(selectSearchResult)
  const structure = useSelector(selectWebBookStructure)
  const contentNode = useSelector(selectWebBookContent)
  const documentInfo = useSelector(selectWebBookDocumentInfo)

  const contentMode = getContentMode(query, bookId)
  const previousContentModeRef = React.useRef(contentMode)
  const bookName = getBookName(structure)

  React.useEffect(() => {
    previousContentModeRef.current = contentMode
    if (bookId) {
      dispatch(bookLoadWebBookContent(bookId, chapterId, paraId, true))
      return
    }
    if (query) {
      const queryChanged = previousQuery.current !== query
      const filterSkriptumChanged = previousFilterSkriptum.current !== filterSkriptum
      const filterBookChanged = previousFilterBook.current !== filterBook
      const filterBookTitleChanged = previousFilterBookTitlesOnly.current !== filterBookTitlesOnly
      const filterVideosOnlyChanged = previousFilterVideosOnly.current !== filterVideosOnly
      if (
        queryChanged ||
        filterBookChanged ||
        filterSkriptumChanged ||
        filterBookTitleChanged ||
        filterVideosOnlyChanged
      ) {
        dispatch(clearSearch(!queryChanged))
      }
      dispatch(
        search(query, page, {
          handbook_name: filterBook,
          skriptum_name: filterSkriptum,
          bookTitleOnly: filterBookTitlesOnly === 'true',
          videoOnly: filterVideosOnly === 'true',
        })
      )
      previousQuery.current = query
      previousFilterBook.current = filterBook
      previousFilterSkriptum.current = filterSkriptum
      previousFilterVideosOnly.current = filterVideosOnly
      previousFilterBookTitlesOnly.current = filterBookTitlesOnly

      return
    }
    // no videos (CP-1013)
    // dispatch(loadOverviewVideos())
  }, [
    query,
    bookId,
    chapterId,
    paraId,
    filterBook,
    filterSkriptum,
    filterBookTitlesOnly,
    filterVideosOnly,
    page,
    contentMode,
    dispatch,
  ])

  const performFiltering: PerformFiltering = (nextFilters: ArFilters) => {
    dispatch(search(query ?? '', 0, nextFilters))
  }

  // error handling
  const hasError = useError()
  if (hasError) {
    return <ErrorPage />
  }

  const arHeader = (
    <ARHeaderPanel
      noSearch={contentMode === ContentMode.OVERVIEW}
      query={query}
      bookName={bookName}
      bookId={bookId}
      documentInfo={documentInfo}
      page={page}
      filters={filters}
      paraId={paraId}
    />
  )
  const arFooter = <ReaderFooterPanel nowrap />
  const nav =
    contentMode === ContentMode.BOOK ? (
      <ArBookStructurePanel
        structure={structure}
        paraId={documentInfo?.paraId}
        userInput={query!}
        page={page}
        filters={filters}
      />
    ) : undefined

  const canonical = query ? arPathSearch(encodeURIComponent(query)) : arPathOverview()
  const showQueryStringInTitle =
    query && searchResult?.documentsFound && searchResult.documentsFound > 0 && contentMode === ContentMode.SEARCH
  const showParaNameAndBookTitle = contentMode === ContentMode.BOOK && contentNode?.title !== undefined
  const paraName = showParaNameAndBookTitle ? `${contentNode?.title} - ${bookName}` : ''

  const title = showQueryStringInTitle
    ? `${query} - ${arProductName}`
    : showParaNameAndBookTitle
    ? paraName
    : arProductName

  return (
    <ReaderLayout
      header={arHeader}
      nav={nav}
      footer={arFooter}
      enableScrollTop={contentMode === ContentMode.SEARCH && previousContentModeRef.current !== ContentMode.SEARCH}
    >
      <DigitalHelmet title={title} canonical={canonical} />

      {contentMode === ContentMode.SEARCH && (
        <MainContainerPanel>
          <ArSearchResultPanel
            searchResult={searchResult}
            userInput={query!}
            page={page}
            performFiltering={performFiltering}
            filters={filters}
          />
        </MainContainerPanel>
      )}

      {contentMode === ContentMode.OVERVIEW && (
        <MainContainerPanel>
          <ArOverviewPanel />
        </MainContainerPanel>
      )}

      {contentMode === ContentMode.BOOK && (
        <ArBookContentPanel bookId={bookId!} contentNode={contentNode} paraId={documentInfo?.paraId!} />
      )}

      <SettingsPanel bottomPosition={false} onToggle={() => setShowSettings(!showSettings)} />
    </ReaderLayout>
  )
}
