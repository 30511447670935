import * as React from 'react'
import {useHistory, useLocation} from 'react-router-dom'
import {KV_BASE_PATH, KV_LEGACY_BASE_PATH} from '../store/kv.paths'

export const KvLegacyUrlRedirectPage: React.FC = ({children}) => {
  const location = useLocation()
  const history = useHistory()
  const nextPath = `${KV_BASE_PATH}${location.pathname.substr(KV_LEGACY_BASE_PATH.length)}${location.search}`
  history.replace(nextPath)
  return null
}
