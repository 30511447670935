import {IStyle} from 'fela'
import * as React from 'react'
import {useFela} from 'react-fela'
import history from '../../../app/history'
import {Theme} from '../../../app/theme'

interface Props {
  title: string
  shortTitle: string
  linkTo: string | null
}

export const ReaderToolbarButton: React.FC<Props> = ({title, shortTitle, linkTo}) => {
  const {css, theme} = useFela<Theme>()
  const buttonStyle = (mobile: boolean): IStyle => ({
    display: mobile ? 'block' : 'none',
    ...theme.fontStyles.brandBold,
    color: theme.colors.text,
    backgroundColor: theme.colors.bgContentEmphasised,
    border: 0,
    fontSize: theme.fontSizes.small,
    paddingTop: '0.2em',
    paddingBottom: '0.05em',
    cursor: 'pointer',
    whiteSpace: 'nowrap',
    tablet: {
      display: !mobile ? 'block' : 'none',
    },
    print: {
      display: 'none',
    },
  })
  const pushLink = () => {
    if (linkTo) {
      history.push(linkTo)
    }
  }
  return (
    <>
      <button className={css(buttonStyle(true))} onClick={pushLink}>
        {shortTitle}
      </button>
      <button className={css(buttonStyle(false))} onClick={pushLink}>
        {title}
      </button>
    </>
  )
}
