import {Box, Flex} from '@indoqa/style-system'
import {IStyle} from 'fela'
import * as React from 'react'
import {useFela} from 'react-fela'
import {centeredLayoutRules, clippedBackgroundImageRules, containerRules} from '../../../app/mixins'
import {Theme} from '../../../app/theme'

interface Props {
  backgroundImage?: string
  extendedSearch?: JSX.Element
  disableClipping?: boolean
}

const EXTENDED_SEARCH_STATE_KEY = 'kvsystem/extended-search'

const getPersistentShowExtendedSearch = (): boolean => {
  return window.localStorage.getItem(EXTENDED_SEARCH_STATE_KEY) === 'true'
}

const setPersistentShowExtendedSearch = (show: boolean) => {
  window.localStorage.setItem(EXTENDED_SEARCH_STATE_KEY, show ? 'true' : 'false')
}

export const SearchAreaPanel: React.FC<Props> = ({backgroundImage, extendedSearch, disableClipping, children}) => {
  const {theme} = useFela<Theme>()
  const [showExtendedSearch, setShowExtendedSearch] = React.useState(getPersistentShowExtendedSearch())
  const {searchArea: searchAreaTheme} = theme.layout

  const updateShowExtendedSearch = (show: boolean) => {
    setShowExtendedSearch(show)
    setPersistentShowExtendedSearch(show)
  }

  const height = searchAreaTheme.height + (showExtendedSearch ? 140 : 0)
  const paddingTop = 37
  const containerWrapperStyle: IStyle = {
    position: 'relative',
    height,
    transitionProperty: 'height',
    transitionDuration: '0.1s',
  }
  const innerWrapperStyle: IStyle = {
    width: '100%',
    tablet: {
      maxWidth: theme.layout.search.maxWidth,
    },
  }
  const middleContainerStyle: IStyle = {
    position: 'absolute',
    height,
    paddingTop,
    top: 0,
    left: 0,
    width: '100%',
    transitionProperty: 'height',
    transitionDuration: '0.1s',
  }
  return (
    <Flex fullWidth style={containerWrapperStyle}>
      <Flex style={[clippedBackgroundImageRules(backgroundImage, disableClipping), middleContainerStyle]} />
      <Flex style={middleContainerStyle} fullWidth>
        <Flex
          style={[centeredLayoutRules(theme), containerRules(theme)]}
          fullWidth
          px={2}
          alignItems="baseline"
          direction="column"
        >
          <Flex fullWidth nowrap alignItems="baseline">
            <Box style={innerWrapperStyle} grow={1}>
              {children}
            </Box>
            {extendedSearch && (
              <Box
                pl={2}
                underline
                cursorPointer
                onClick={() => updateShowExtendedSearch(!showExtendedSearch)}
                display={['none', 'block']}
              >
                Erweiterte Suche
              </Box>
            )}
          </Flex>
          {extendedSearch && showExtendedSearch && <Box display={['none', 'block']}>{extendedSearch}</Box>}
        </Flex>
      </Flex>
    </Flex>
  )
}
