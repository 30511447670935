import {Box, Flex} from '@indoqa/style-system'
import de from 'date-fns/locale/de'
import * as React from 'react'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import {useFela} from 'react-fela'
import {Theme} from '../../../app/theme'
import {ThemedIcon} from '../../../commons-shared/themed-icon/ThemedIcon'
import {ReactComponent as CalendarIcon} from '../../icons/calendar-sm.svg'

interface Props {
  date?: Date
  onChange: (date: Date) => void
  disabled?: boolean
  inverted?: boolean
}

/*
 * ReactDatePicker:
 * see samples https://reactdatepicker.com/
 * see properties: https://github.com/Hacker0x01/react-datepicker/blob/master/docs/datepicker.md
 */
export const ValidityDatePicker: React.FC<Props> = ({onChange, date, disabled, inverted = true}) => {
  const containerRef = React.useRef<HTMLDivElement>(null)
  const datePickerRef = React.useRef<any>(null)
  const {theme} = useFela<Theme>()
  const inputStyle: any = {
    '& .react-datepicker__input-container': {
      paddingTop: 1,
    },
    '& input': {
      backgroundColor: 'transparent',
      color: inverted ? theme.colors.textInverted : theme.colors.text,
      fontSize: theme.fontSizes.small,
      border: 0,
      paddingTop: 1,
      paddingRight: 0,
      paddingBottom: 0,
      paddingLeft: 1,
      lineHeight: 1,
      width: 80,
    },
    '& .react-datepicker__month-read-view--down-arrow': {
      borderWidth: '0.50rem',
      top: '5px',
      marginLeft: '25px',
    },
    '& .react-datepicker__year-read-view--down-arrow': {
      borderWidth: '0.50rem',
      top: '5px',
      marginLeft: '25px',
    },
  }
  const iconColor = inverted ? theme.colors.iconInverted : theme.colors.icon
  const focusInput = () => {
    if (!containerRef.current || disabled) {
      return
    }
    const input = containerRef.current.querySelectorAll('input')
    if (input && input.length === 1) {
      input[0].focus()
    }
  }

  const onSelectionChange = (date: Date) => {
    onChange(date)
    // force closing the date picker
    // see https://github.com/Hacker0x01/react-datepicker/issues/1223
    datePickerRef.current?.setOpen(false)
  }

  return (
    <Flex style={inputStyle} innerRef={containerRef}>
      <Box pr={1} cursorPointer onClick={() => focusInput()}>
        <ThemedIcon size={16} color={iconColor} hoverColor={iconColor}>
          <CalendarIcon />
        </ThemedIcon>
      </Box>
      <DatePicker
        maxDate={new Date()}
        showYearDropdown
        showMonthDropdown
        dateFormat="dd.MM.yyyy"
        popperPlacement="top-end"
        todayButton="Heute"
        locale={de}
        selected={date || new Date()}
        onChange={onChange}
        onMonthChange={onSelectionChange}
        onYearChange={onSelectionChange}
        disabledKeyboardNavigation
        disabled={disabled}
        dropdownMode="select"
        closeOnScroll
        ref={datePickerRef}
      />
    </Flex>
  )
}
