import {Box} from '@indoqa/style-system'
import * as React from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {Link} from 'react-router-dom'
import {MainContainerPanel} from '../../../commons/components/main/MainContainerPanel'
import {NewsPanel} from '../../../commons/components/news/NewsPanel'
import {kvLoadNews} from '../../store/kv.actions'
import {kvPathNews} from '../../store/kv.paths'
import {selectKvTopNews} from '../../store/kv.selectors'

export const KvNewsPanel = () => {
  const dispatch = useDispatch()
  React.useEffect(() => {
    dispatch(kvLoadNews())
  }, [dispatch])

  const topNews = useSelector(selectKvTopNews)
  if (!topNews || topNews.length === 0) {
    return null
  }
  return (
    <MainContainerPanel>
      <Box fullWidth mb={3}>
        <Box mb={3}>
          <h1>Abschlüsse</h1>
        </Box>
        <Box fullWidth>
          <NewsPanel news={topNews} />
          <Box pt={3} textAlign="right" fullWidth>
            <Link to={kvPathNews()}>Mehr ...</Link>
          </Box>
        </Box>
      </Box>
    </MainContainerPanel>
  )
}
