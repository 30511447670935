export interface LinkHandler {
  createLink: (idref: string) => string
  executeAction: (idref: string) => void
}

const PROCESSED_ATTR = 'p'
const PROCESSED_ATTR_VALUE = 'true'

export const handleContentPanelLink = (linkHandler: LinkHandler, element: Element, idrefAttr: string) => {
  const idref = element.getAttribute(idrefAttr)
  if (!idref) {
    return
  }

  const hasBeenProcessed = element.getAttribute(PROCESSED_ATTR) === PROCESSED_ATTR_VALUE
  if (hasBeenProcessed) {
    return
  }

  element.setAttribute('href', '#')
  element.addEventListener('click', (e) => {
    e.preventDefault()
    linkHandler.executeAction(idref)
  })

  // support "open in new tab"
  element.addEventListener('mouseenter', (e) => {
    if (e) {
      const target = e.currentTarget as HTMLAnchorElement
      const link = linkHandler.createLink(idref)
      target?.setAttribute('href', link)
    }
  })
  element.setAttribute(PROCESSED_ATTR, PROCESSED_ATTR_VALUE)
}
