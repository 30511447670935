const isScrollable = function (ele: Element) {
  const hasScrollableContent = ele.scrollHeight > ele.clientHeight
  const overflowYStyle = window.getComputedStyle(ele).overflowY
  const isOverflowHidden = overflowYStyle.indexOf('hidden') !== -1
  return hasScrollableContent && !isOverflowHidden
}

export function getScrollableParent(ele: Element): Element {
  if (!ele || ele === document.body) {
    return document.body
  }
  if (isScrollable(ele)) {
    return ele
  }
  return getScrollableParent(ele.parentNode as Element)
}
