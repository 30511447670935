/*
 Note:
 This component is included directly into the sources of this project to simplify the
 build process.

 License:
 Copyright 2019, Indoqa Software Design und Beratung GmbH

 Permission is hereby granted, free of charge, to any person obtaining a copy
 of this software and associated documentation files (the "Software"), to deal
 in the Software without restriction, including without limitation the rights
 to use, copy, modify, merge, publish, distribute, sublicense, and/or sell
 copies of the Software, and to permit persons to whom the Software is
 furnished to do so, subject to the following conditions:
 The above copyright notice and this permission notice shall be included in all
 copies or substantial portions of the Software.

 THE SOFTWARE IS PROVIDED "AS IS", WITHOUT WARRANTY OF ANY KIND, EXPRESS OR
 IMPLIED, INCLUDING BUT NOT LIMITED TO THE WARRANTIES OF MERCHANTABILITY,
 FITNESS FOR A PARTICULAR PURPOSE AND NONINFRINGEMENT. IN NO EVENT SHALL
 THE AUTHORS OR COPYRIGHT HOLDERS BE LIABLE FOR ANY CLAIM, DAMAGES OR OTHER
 LIABILITY, WHETHER IN AN ACTION OF CONTRACT, TORT OR OTHERWISE, ARISING FROM,
 OUT OF OR IN CONNECTION WITH THE SOFTWARE OR THE USE OR OTHER DEALINGS IN THE SOFTWARE.
*/
import {IStyle} from 'fela'
import {EventEmitter} from '../event-emitter/EventEmitter'
import {
  EventHandlers,
  OnClickEvent,
  OnContextEvent,
  SelectedNode,
  StructureAccessors,
  TreeNodeStyle,
} from './treeView.types'

export const overflowHiddenStyle: IStyle = {
  overflow: 'hidden',
}
export const cursorPointerStyle: IStyle = {
  cursor: 'pointer',
}

export const createEventHandlers = (): EventHandlers => ({
  mouseOver: new EventEmitter(),
  mouseOut: new EventEmitter(),
  click: new EventEmitter<OnClickEvent>(),
  context: new EventEmitter<OnContextEvent>(),
})

// recursive, depth-first algorithm that finds the path to a selected node
function findPath<S>(
  structure: S,
  parentPath: string[],
  resultPath: string[],
  structureAccessors: StructureAccessors<S>,
  selectedNode?: SelectedNode
) {
  if (!selectedNode) {
    return
  }
  const structureChildren = structureAccessors.getChildren(structure)
  const structureId = structureAccessors.getId(structure)
  if (structureChildren) {
    structureChildren.forEach((child) => {
      const nextPath = [...parentPath, structureId]
      if (structureAccessors.getId(child) === selectedNode.id) {
        resultPath.push(...nextPath)
        resultPath.push(selectedNode.id)
      } else {
        findPath(child, nextPath, resultPath, structureAccessors, selectedNode)
      }
    })
  }
}

export function findAllPaths<S>(
  structure: S,
  parentPath: string[],
  resultPath: string[],
  structureAccessors: StructureAccessors<S>
) {
  const structureChildren = structureAccessors.getChildren(structure)
  const structureId = structureAccessors.getId(structure)
  if (structureChildren) {
    structureChildren.forEach((child) => {
      const nextPath = [...parentPath, structureId]
      if (structureAccessors.isLeaf(child)) {
        resultPath.push(...nextPath)
      } else {
        findAllPaths(child, nextPath, resultPath, structureAccessors)
      }
    })
  }
}

export function calcNodePaths<S>(
  structure: S,
  selectedNode: SelectedNode,
  structureAccessors: StructureAccessors<S>,
  openAll: boolean
) {
  const nodePaths: string[] = []
  if (openAll) {
    findAllPaths(structure, [], nodePaths, structureAccessors)
    return nodePaths
  }

  findPath(structure, [], nodePaths, structureAccessors, selectedNode)
  return nodePaths
}

export const calcIndent = (indentMultiplier: number, outdentMultiplier: number, style: TreeNodeStyle) => {
  return `calc(${indentMultiplier} * ${style.indent} + ${style.padding} - ${outdentMultiplier} * ${style.outdent})`
}
