import {Flex} from '@indoqa/style-system'
import {IStyle} from 'fela'
import * as React from 'react'
import {useFela} from 'react-fela'
import history from '../../app/history'
import {Theme} from '../../app/theme'

interface Props {
  message: string
  linkTo: string
  buttonText: string
  onClick?: () => void
  disableLinkTo?: boolean
}

export const ErrorPanel: React.FC<Props> = ({message, linkTo, buttonText, onClick, disableLinkTo}) => {
  const {css, theme} = useFela<Theme>()

  const contentStyle: IStyle = {
    minHeight: 500,
  }
  const buttonStyle: IStyle = {
    borderRadius: 2,
    backgroundColor: theme.colors.primaryLight,
    ...theme.fontStyles.brand,
    color: theme.colors.textInverted,
    fontSize: theme.fontSizes.small,
    border: 0,
    paddingTop: '0.3em',
    paddingRight: '1em',
    paddingBottom: '0.3em',
    paddingLeft: '1em',
    marginTop: '1em',
    cursor: 'pointer',
  }
  return (
    <Flex style={contentStyle} justifyContent="center" alignItems="center" direction="column">
      <h3>{message}</h3>
      <button
        onClick={() => {
          if (!disableLinkTo) {
            history.push(linkTo)
          }
          if (onClick) {
            onClick()
          }
        }}
        className={css(buttonStyle)}
      >
        {buttonText}
      </button>
    </Flex>
  )
}
