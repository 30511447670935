import {Box, Col, ColRow, Grid} from '@indoqa/style-system'
import {IStyle} from 'fela'
import * as React from 'react'
import {useFela} from 'react-fela'
import {Theme} from '../../../app/theme'
import {SelectivePrintingContext} from './SelectivePrintingContext'

export const SelectivePrintingComponent: React.FC = ({children}) => {
  const {theme} = useFela<Theme>()
  const selectivePrintingContext = React.useContext(SelectivePrintingContext)
  const paraIdsCount = selectivePrintingContext?.countParaIds() ?? 0
  const style: IStyle = {
    display: 'none',
    desktop: {
      display: 'block',
      position: 'sticky',
      top: 0,
      zIndex: 10,
      backgroundColor: theme.colors.bgContentEmphasised,
    },
    print: {
      display: 'none',
    },
  }
  const message =
    paraIdsCount > 1
      ? `Es wurden ${paraIdsCount} Absätze zum Drucken ausgewählt.`
      : 'Es wurde 1 Absatz zum Drucken ausgewählt.'
  const anyParaSelected = selectivePrintingContext?.containsAnyParaId() ?? false
  const cssWrapperClass = anyParaSelected ? 'selective-printing' : undefined
  return (
    <>
      {anyParaSelected && (
        <Box style={style} p={1}>
          <Grid>
            <ColRow>
              <Col size={9}>{message}</Col>
              <Col size={3}>
                <Box
                  grow={1}
                  ml={1}
                  underline
                  onClick={() => selectivePrintingContext?.clearParaIds()}
                  textAlign="right"
                  cursorPointer
                >
                  Auswahl aufheben
                </Box>
              </Col>
            </ColRow>
          </Grid>
        </Box>
      )}
      <div className={cssWrapperClass}>{children}</div>
    </>
  )
}
