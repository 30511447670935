interface Props {
  footNotes?: {[key: string]: string}
  footNoteIds?: string[]
}

export function ContentPanelPrintFootnotes({footNotes, footNoteIds}: Props) {
  if (!footNotes || !footNoteIds || footNoteIds.length === 0) {
    return null
  }
  return (
    <div
      className="footnote-catalogue"
      style={{
        backgroundColor: '#EFEFEF',
        borderLeft: '3px solid black',
        padding: '0.5rem',
        margin: '1.75rem 0 1.75rem 0',
      }}
    >
      <h3>Fußnoten</h3>
      <ul style={{padding: 0}}>
        {footNoteIds.map((id) => {
          return (
            <li style={{display: 'flex', alignItems: 'baseline'}} key={id}>
              <div
                style={{
                  whiteSpace: 'nowrap',
                  fontWeight: 'bold',
                  marginRight: '1ch',
                  width: '5ch',
                  textAlign: 'right',
                  flexShrink: 0,
                  flexGrow: 0,
                }}
              >
                ({id}){' '}
              </div>
              <div style={{display: 'inline'}} dangerouslySetInnerHTML={{__html: footNotes[id]}} />
            </li>
          )
        })}
      </ul>
    </div>
  )
}
