import {Box, Col, ColRow, Grid} from '@indoqa/style-system'
import * as React from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {Link} from 'react-router-dom'
import {MainContainerPanel} from '../../../commons/components/main/MainContainerPanel'
import {kvLoadChanges} from '../../store/kv.actions'
import {kvPathChanges} from '../../store/kv.paths'
import {selectKvChangesItems} from '../../store/kv.selectors'
import {KvChangesItem} from '../../store/kv.types'
import {KvChangesItemPanel} from './KvChangesItemPanel'

interface Props {
  showLinks?: boolean
}

const renderItems = (items: KvChangesItem[], showLinks: boolean) => (
  <Col size={[12, 4]} pr={2}>
    <ul>
      {items.map((item) => (
        <KvChangesItemPanel item={item} key={item.link.id} showLink={showLinks} />
      ))}
    </ul>
  </Col>
)

export const KvChangesOverviewListPanel = ({showLinks = true}: Props) => {
  const dispatch = useDispatch()
  React.useEffect(() => {
    dispatch(kvLoadChanges())
  }, [dispatch])

  const items = useSelector(selectKvChangesItems)
  return (
    <MainContainerPanel>
      <Box fullWidth mb={4}>
        <Box mb={3}>
          <h1>Aktuelle Änderungen</h1>
        </Box>
        <Grid fullWidth>
          <ColRow>
            {items && renderItems(items.slice(0, 3), showLinks)}
            {items && renderItems(items.slice(3, 6), showLinks)}
            {items && renderItems(items.slice(6, 9), showLinks)}
          </ColRow>
        </Grid>
        {showLinks && (
          <Box fullWidth textAlign="right" mt={2}>
            <Link to={kvPathChanges()}>Mehr ...</Link>
          </Box>
        )}
      </Box>
    </MainContainerPanel>
  )
}
