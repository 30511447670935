import {Box} from '@indoqa/style-system'
import * as React from 'react'
import {ResultItemTemplate} from '../../../commons/components/search-result/ResultItemTemplate'
import {ActivationState, getArfbrDebugFromSessionStorage} from '../../../commons/components/settings/settings'
import {Pool} from '../../../commons/idqsa/idqsa'
import {ResultItemProps} from '../../store/ar.types'
import {getResourceAvailabilityForUser} from '../../utils/getResourceAvailabilityForUser'
import {getAvailability} from './utils/getAvailability'

interface Props extends ResultItemProps {
  showNameBelow?: boolean
  height?: number
}

export const ArVideoResultItem: React.FC<Props> = ({resultDocument, showNameBelow, height}) => {
  const arfbrDebug = getArfbrDebugFromSessionStorage()
  const {youtubeLink} = resultDocument.properties
  if (!youtubeLink) {
    return null
  }
  const vPos = youtubeLink.lastIndexOf('v=')
  if (vPos === -1) {
    return null
  }
  const youtubeId = youtubeLink.substring(vPos + 2)
  const videoIframe = (
    <iframe
      title="YouTube"
      height={height || '315'}
      src={`https://www.youtube-nocookie.com/embed/${youtubeId}`}
      allowFullScreen
      allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
      style={{border: 0, width: '100%', maxWidth: '560'}}
    />
  )
  if (showNameBelow) {
    return (
      <Box textAlign="center">
        {videoIframe}
        {resultDocument.name}
      </Box>
    )
  }
  return (
    <ResultItemTemplate
      resultItemId={resultDocument.id}
      name={resultDocument.name}
      type="VIDEO"
      typeBgColor="contentTypeVideo"
      disableBadge={arfbrDebug === ActivationState.NO}
      userResourceAvailability={getResourceAvailabilityForUser(getAvailability(resultDocument))}
      pool={Pool.ARFBR}
    >
      {videoIframe}
    </ResultItemTemplate>
  )
}
