import * as React from 'react'
import {useSelector} from 'react-redux'
import {selectErrorTimestamp} from '../store/error.selectors'

/**
 * An error should be rendered only once.
 * This is achieved by a React reference which stores the most recently rendered error.
 */
export const useError = (): boolean => {
  const timestampRef = React.useRef(0)
  const storedTimestamp = useSelector(selectErrorTimestamp)
  const hasError = timestampRef.current < storedTimestamp
  timestampRef.current = storedTimestamp
  return hasError
}
