import {Box, Flex} from '@indoqa/style-system'
import * as React from 'react'
import {ajax} from 'rxjs/ajax'
import {Button} from '../../commons/components/button/Button'
import {authDetailsOverview} from '../auth-details.paths'
import {postForAuthDetails} from '../store/auth-details.services'

enum Status {
  INITIAL = 'INITIAL',
  SUCCESS = 'SUCCESS',
  ERROR = 'ERROR',
}

export const AuthenticationDetailsPanel = () => {
  const [status, setStatus] = React.useState(Status.INITIAL)

  const submit = () => {
    postForAuthDetails(ajax).subscribe(
      (value) => {
        setStatus(Status.SUCCESS)
      },
      () => {
        setStatus(Status.ERROR)
      }
    )
  }

  if (status === Status.SUCCESS) {
    return (
      <Flex fullWidth direction="column" center>
        <Box>Vielen Dank, Ihre Daten wurden übermittelt.</Box>
        <Box pt={4}>
          <Button text="Zurück zur Homepage" linkTo="/" />
        </Box>
      </Flex>
    )
  }

  if (status === Status.ERROR) {
    return (
      <Flex fullWidth direction="column" center>
        <Box>Leider ist ein Fehler aufgetreten</Box>
        <Box pt={4}>
          <Button
            text="Zurück"
            linkTo={() => {
              setStatus(Status.INITIAL)
              authDetailsOverview()
            }}
          />
        </Box>
      </Flex>
    )
  }

  return (
    <Flex fullWidth direction="column" center>
      <Box>
        Durch den Klick auf "Senden" übermitteln Sie uns Informationen, die uns dabei helfen, Berechtigungsprobleme zu
        lösen.
        <br />
        Dieser Vorgang erfolgt entsprechend der Bestimmungen der{' '}
        <a href="https://www.oegbverlag.at/datenschutzerklaerung/">Datenschutzerklärung</a>.
      </Box>
      <Box pt={4}>
        <Button text="Senden" linkTo={() => submit()} />
      </Box>
    </Flex>
  )
}
