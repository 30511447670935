import {useQueryParam} from '@oegbv/ui-shared'
import * as React from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {FooterPanel} from '../../commons/components/footer/FooterPanel'
import {DigitalHelmet} from '../../commons/components/helmet/DigitalHelmet'
import {MainContainerPanel} from '../../commons/components/main/MainContainerPanel'
import {SearchAreaPanel} from '../../commons/components/search-area/SearchAreaPanel'
import {ContentLayout} from '../../commons/layouts/content-layout/ContentLayout'
import {useError} from '../../error/hooks/useError'
import {ErrorPage} from '../../error/pages/ErrorPage'
import {LawHeaderPanel} from '../components/header/LawHeaderPanel'
import {LawOverviewGroupsPanel} from '../components/overview/LawOverviewGroupsPanel'
import lawSearchAreaBackgroundImage from '../components/overview/LawOverviewSearchBg.jpg'
import {LawSearchInput} from '../components/search/LawSearchInput'
import {LawSearchResultPanel} from '../components/search/LawSearchResultPanel'
import {lawLoadOverview, lawSearch, lawSearchAutoComplete} from '../store/law.actions'
import {lawProductName} from '../store/law.constants'
import {lawPathOverview, lawQueryParams} from '../store/law.paths'
import {
  selectOverview,
  selectSearchAutoCompleteItems,
  selectSearchResult,
  selectSearchUserQuery,
} from '../store/law.selectors'

const MAX_LAW_COUNT = 5

export const LawOverviewPage: React.FC = () => {
  const q = useQueryParam(lawQueryParams.userQuery)
  const dispatch = useDispatch()
  React.useLayoutEffect(() => {
    if (q) {
      dispatch(lawSearch(q))
    } else {
      dispatch(lawLoadOverview(MAX_LAW_COUNT))
    }
  }, [dispatch, q])

  const overview = useSelector(selectOverview)
  const searchUserQuery = useSelector(selectSearchUserQuery)
  const searchResult = useSelector(selectSearchResult)
  const autoCompleteItems = useSelector(selectSearchAutoCompleteItems)

  // error handling
  const hasError = useError()
  if (hasError) {
    return <ErrorPage />
  }

  const header = <LawHeaderPanel noSearch />
  const footer = <FooterPanel />
  return (
    <ContentLayout header={header} footer={footer}>
      <DigitalHelmet title={lawProductName} canonical={lawPathOverview(q!)} />
      <SearchAreaPanel backgroundImage={lawSearchAreaBackgroundImage}>
        <LawSearchInput
          userQuery={searchUserQuery}
          search={(userQuery) => dispatch(lawSearch(userQuery))}
          items={autoCompleteItems}
          reload={(prefix) => dispatch(lawSearchAutoComplete(prefix))}
        />
      </SearchAreaPanel>
      <MainContainerPanel>
        {overview && !searchResult ? <LawOverviewGroupsPanel overview={overview} /> : null}
        {searchResult ? <LawSearchResultPanel searchResult={searchResult} /> : null}
      </MainContainerPanel>
    </ContentLayout>
  )
}
