import * as React from 'react'
import {endTrackReadSignal, Pool, trackReadSignal} from './idqsa'

export function useTrackReadSignal(
  elementCallback: () => Element | null | undefined,
  pool?: Pool,
  resultItemId?: string | null
): void {
  React.useEffect(() => {
    const element = elementCallback()
    if (pool && resultItemId && element) {
      trackReadSignal(pool, resultItemId, element)
      return () => {
        endTrackReadSignal(pool)
      }
    }
    return
  }, [resultItemId, pool, elementCallback])
}
