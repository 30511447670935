import {Flex, HeaderFlex} from '@indoqa/style-system'
import * as React from 'react'
import {useFela} from 'react-fela'
import {
  noPrintRule,
  removePaddingLeftPrintRule,
  transparentBackgroundPrintRule,
  undecoratedLinksRules,
} from '../../../app/mixins'
import {Theme} from '../../../app/theme'
import {LoginButton} from './LoginButton'

interface Props {
  logo: JSX.Element
  search?: JSX.Element
}

export const ReaderHeaderPanel: React.FC<Props> = ({logo, search}) => {
  const {theme} = useFela<Theme>()
  return (
    <HeaderFlex
      fullWidth
      bg="bgContentEmphasised"
      px={2}
      height={theme.layout.reader.headerHeight}
      style={[undecoratedLinksRules(), transparentBackgroundPrintRule(), removePaddingLeftPrintRule()]}
      alignItems="center"
      justifyContent="space-between"
    >
      <Flex fullHeight alignItems="center">
        {logo}
      </Flex>
      <Flex<Theme> fullHeight alignItems="center" fontStyle="brand" style={noPrintRule()}>
        {search}
        <LoginButton />
      </Flex>
    </HeaderFlex>
  )
}
