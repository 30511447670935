import {Box} from '@indoqa/style-system'
import * as React from 'react'
import {Theme} from '../../../app/theme'
import {BookWebbookIdTreeView} from '../../../book/components/webbooks-id/structure/BookWebbookIdTreeView'
import {BookStructureNode} from '../../../book/store/book.types'
import {isBookChapterSubType} from '../../../book/store/book.utils'
import {AlignedDigitalStructurePanel} from '../../../commons/components/structure/AlignedDigitalStructurePanel'
import {CreateDigitalLink} from '../../../commons/components/structure/digitalStructure.types'
import {findDocumentInfoByPara} from '../../../commons/utils/findDocumentByPara'
import {convertDate} from '../../../commons/utils/formatDate'
import {ArFilters} from '../../store/ar.actions'
import {arPathBook} from '../../store/ar.paths'

interface Props {
  structure: BookStructureNode | null
  paraId?: string | null
  userInput: string
  filters?: ArFilters
  page?: number
}

export const ArBookStructurePanel: React.FC<Props> = ({structure, paraId, userInput, filters, page}) => {
  if (!structure) {
    return null
  }
  const rechtsstand = structure.properties?.rechtsstand
  const createDigitalLink: CreateDigitalLink = (paraId) => {
    const documentInfo = findDocumentInfoByPara(structure, paraId, isBookChapterSubType)
    if (!documentInfo || !documentInfo.documentId || !documentInfo.documentName) {
      throw Error('no document info')
    }
    return arPathBook(userInput, structure.link.id, documentInfo.documentId, paraId, filters, page)
  }
  const titel = structure.properties?.quelle_lang
  return (
    <AlignedDigitalStructurePanel noPaddingTop>
      {rechtsstand && (
        <Box<Theme> bg="bgContentEmphasised" fullWidth px={2} pt={2} pb={1} fontStyle="brand" fontSize="small">
          {titel}
          <br />
          Rechtsstand: {convertDate(rechtsstand)}
        </Box>
      )}
      <BookWebbookIdTreeView structure={structure} paraId={paraId} createDigitalLink={createDigitalLink} />
    </AlignedDigitalStructurePanel>
  )
}
