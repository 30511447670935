import {Box} from '@indoqa/style-system'
import * as React from 'react'
import {Theme} from '../../../app/theme'
import {RenderDigitalNodeProps} from '../../../commons/components/structure/digitalStructure.types'
import {DigitalStructureFolderTemplate} from '../../../commons/components/structure/DigitalStructureFolderTemplate'
import {useLoadStructureContent} from '../../../commons/components/structure/useLoadStructureContent'
import {LinkType} from '../../../commons/types/Link'
import {formatDate} from '../../../commons/utils/formatDate'
import {DocsetStructureNode} from '../../store/kv.types'
import {findFirstKvParaChild} from '../../utils/findFirstKvParaChild'

const renderDocumentDate = (validFrom: Date, amended?: Date) => (
  <Box<Theme> ellipsis fontSize="verySmall" grow={0} overflowHidden style={{lineHeight: 1.2}}>
    {formatDate(validFrom)}
    {amended ? `(idF ${formatDate(amended)})` : ''}
  </Box>
)

export const KvStructureFolderPanel: React.FC<RenderDigitalNodeProps<DocsetStructureNode>> = (props) => {
  const {structure, style, inNodePath, selected, createDigitalLink} = props
  const {link, validFrom, amended} = structure
  const isDocument = structure.link.type === LinkType.KV_TIME_SLICE
  const firstChild = findFirstKvParaChild(structure)
  const inactive = structure.noContent
  useLoadStructureContent(props, firstChild)
  const createLink = createDigitalLink ? () => createDigitalLink(props.structure.link.id) : undefined
  return (
    <DigitalStructureFolderTemplate
      label={link.name}
      subLabel={isDocument && renderDocumentDate(validFrom, amended)}
      paddingRight={style.padding}
      selected={inNodePath || selected}
      createLink={createLink}
      inactive={inactive}
    />
  )
}
