import {Box} from '@indoqa/style-system'
import * as React from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {Link, useParams} from 'react-router-dom'
import {undecoratedLinksRules} from '../../app/mixins'
import {Theme} from '../../app/theme'
import {FooterPanel} from '../../commons/components/footer/FooterPanel'
import {DigitalHelmet} from '../../commons/components/helmet/DigitalHelmet'
import {MainContainerPanel} from '../../commons/components/main/MainContainerPanel'
import {ContentLayout} from '../../commons/layouts/content-layout/ContentLayout'
import {StructureNode} from '../../commons/types/Structure'
import {sortStructureNodes} from '../../commons/utils/sortStructureNodes'
import {useError} from '../../error/hooks/useError'
import {ErrorPage} from '../../error/pages/ErrorPage'
import {LawHeaderPanel} from '../components/header/LawHeaderPanel'
import {LawEntryPanel} from '../components/overview/LawEntryPanel'
import {lawLoadGroup} from '../store/law.actions'
import {lawPathGroup, lawPathOverview} from '../store/law.paths'
import {selectGroup} from '../store/law.selectors'

interface LocationParams {
  groupId: string
}

const TOP_LAWS = 5

const getTopLaws = (laws: StructureNode[]) => {
  return laws.slice(0, TOP_LAWS)
}

const getOtherLaws = (laws: StructureNode[]) => {
  return laws.slice(TOP_LAWS).sort(sortStructureNodes)
}

export const LawGroupPage: React.FC = () => {
  const {groupId} = useParams<LocationParams>()
  const dispatch = useDispatch()
  React.useEffect(() => {
    if (groupId) {
      dispatch(lawLoadGroup(groupId))
    }
  }, [dispatch, groupId])

  const group = useSelector(selectGroup)

  // error handling
  const hasError = useError()
  if (hasError) {
    return <ErrorPage />
  }

  if (!group || !groupId) {
    return null
  }

  const {brand, properties} = group
  const hidden = properties?.hidden
  const showBrand = brand === undefined || brand
  const header = showBrand ? <LawHeaderPanel noSearch /> : <Box />
  const otherLaws = group.children ? getOtherLaws(group.children) : []
  return (
    <ContentLayout header={header} footer={<FooterPanel />}>
      <DigitalHelmet title={group.link.name} canonical={lawPathGroup(groupId, group.link.name)} />
      <MainContainerPanel>
        <Box fullWidth>
          <Box py={2}>
            <Box<Theme>
              fullWidth
              bold
              bg="bgContentEmphasised"
              px={2}
              pb={1}
              pt={2}
              mb={3}
              fontSize="veryBig"
              fontStyle="brandBold"
              style={undecoratedLinksRules()}
            >
              {group.link.name}
            </Box>
            <Box pl={2}>
              {group.children &&
                getTopLaws(group.children).map((entry) => (
                  <LawEntryPanel
                    key={entry.link.id}
                    id={entry.link.id}
                    name={entry.link.name}
                    shortName={entry.simpleTitle}
                    groupId={group.link.id}
                  />
                ))}
            </Box>
            {otherLaws.length > 0 && (
              <Box pl={2} mt={4}>
                <Box<Theme> fontStyle="brandBold" fontSize="big">
                  Weitere Normen:
                </Box>
                {otherLaws.map((entry) => (
                  <LawEntryPanel
                    key={entry.link.id}
                    id={entry.link.id}
                    name={entry.link.name}
                    shortName={entry.simpleTitle}
                    groupId={group.link.id}
                  />
                ))}
              </Box>
            )}
            {showBrand && !hidden && (
              <Box pt={2} textAlign="right">
                <Link to={lawPathOverview()}>Zurück zur Übersicht</Link>
              </Box>
            )}
          </Box>
        </Box>
      </MainContainerPanel>
    </ContentLayout>
  )
}
