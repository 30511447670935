import {Box, Flex} from '@indoqa/style-system'
import * as React from 'react'
import {SettingsPanel} from '../../components/settings/SettingsPanel'

interface Props {
  header: JSX.Element
  footer: JSX.Element
  noFooterMarginTop?: boolean
}

/**
 * A simple layout with a header, contentDocument area and a footer. It supports all screen sizes.
 * It makes sure that the footer is at the bottom of the screen or below if the contentDocument is larger.
 *
 * @param header The header taking all the space it needs.
 * @param children The contentDocument area which takes all the space it needs and may push down the footer as far as necessary.
 * @param footer The footer at the bottom of the screen or below.
 */
export const ContentLayout: React.FC<Props> = ({header, noFooterMarginTop, children, footer}) => {
  return (
    <Flex style={{minHeight: '100%'}} grow={0} direction="column" overflowHidden>
      <Box fullWidth>{header}</Box>
      <Box fullWidth grow={1}>
        {children}
      </Box>
      <Box fullWidth grow={0} mt={noFooterMarginTop ? 0 : 4}>
        {footer}
      </Box>
      <SettingsPanel bottomPosition />
    </Flex>
  )
}
