import {ContentNode} from '../../commons/types/ContentNode'
import {Link} from '../../commons/types/Link'
import {ResultDocument, SearchResult} from '../../commons/types/SearchResult'
import {StructureNode} from '../../commons/types/Structure'
import {ArFilters} from './ar.actions'

export enum ArActionKeys {
  SEARCH = 'ar/SEARCH',
  SEARCH_SUCCESS = 'ar/FETCH_SUCCESS',
  SEARCH_ERROR = 'ar/FETCH_ERROR',
  SEARCH_CLEAR = 'ar/SEARCH_CLEAR',
  LOAD_QUESTION = 'ar/LOAD_QUESTION',
  LOAD_QUESTION_SUCCESS = 'ar/LOAD_QUESTION_SUCCESS',
  LOAD_QUESTION_ERROR = 'ar/LOAD_QUESTION_ERROR',
  LOAD_STRUCTURE = 'ar/LOAD_STRUCTURE',
  LOAD_STRUCTURE_SUCCESS = 'ar/LOAD_STRUCTURE_SUCCESS',
  LOAD_STRUCTURE_ERROR = 'ar/LOAD_STRUCTURE_ERROR',
  LOAD_OVERVIEW_VIDEOS = 'ar/LOAD_OVERVIEW_VIDEOS',
  LOAD_OVERVIEW_VIDEOS_SUCCESS = 'ar/LOAD_OVERVIEW_VIDEOS_SUCCESS',
}

export interface ResultItemProps {
  resultDocument: ResultDocument
  userInput: string
  filters?: ArFilters
  page?: number
  searchId?: string
}

export interface ArState {
  loading: boolean
  error: string | null
  searchResult: SearchResult | null
  questionAnswer: QuestionAnswer | null
  bookStructure: StructureNode | null
  overviewVideos: SearchResult | null
}

export interface LinkElements {
  elements?: Link[]
}

export interface QuestionAnswer {
  bookId: string
  chapterId: string
  id: string
  bookName: string
  chapterName: string
  date: Date
  contentNode: ContentNode
  othersHtml: string
  publishersHtml: string
  authorsHtml: string
  parent: Link
  children: LinkElements
  siblings: LinkElements
}

export interface ArInventory {
  books: SearchResult
  videos: SearchResult
}

export interface Completion {
  type: 'USER' | 'PREDICTION'
  value: string
}

export type Completions = Completion[]

export interface CompletionsResponse {
  completions: Completions[]
  tracking?: {
    searchId?: string
  }
}

export interface ArSearchParams {
  query: string
}
