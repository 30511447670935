import {Box, Col, ColRow, Flex, Grid} from '@indoqa/style-system'
import {useQueryParam} from '@oegbv/ui-shared'
import * as React from 'react'
import {useSelector} from 'react-redux'
import {useHistory} from 'react-router-dom'
import {Theme} from '../../../app/theme'
import {StringFacetPanel} from '../../../commons/components/facets/StringFacetPanel'
import {ResultDocument} from '../../../commons/types/SearchResult'
import {bookPathOverview, bookQueryParams} from '../../store/book.paths'
import {selectEbooks} from '../../store/book.selectors'
import {EBooksFilters} from '../../store/book.types'
import {EbookPanel} from './EbookPanel'

const renderEbookCol = (ebook: ResultDocument) => (
  <Col size={[12, 6]} key={ebook.id}>
    <EbookPanel ebook={ebook} />
  </Col>
)

export const EbooksPanel = () => {
  const ebooks = useSelector(selectEbooks)
  const filterReihe = useQueryParam(bookQueryParams.reihe)
  const filters: EBooksFilters = {reihe: filterReihe}
  const history = useHistory()

  if (!ebooks || !ebooks.documents) {
    return null
  }

  const facets = ebooks.facets

  const performFiltering = (nextFilters: EBooksFilters) => {
    history.push(bookPathOverview('', nextFilters))
  }

  /* The facets are disabled by the display attribute set to none in all cases */
  return (
    <Flex fullWidth nowrap>
      <Box grow={1}>
        <Grid>
          <ColRow>{ebooks.documents.map(renderEbookCol)}</ColRow>
        </Grid>
      </Box>
      <Box width={375} grow={0} shrink={0} display={['none', 'none']} p={2}>
        <Box fullWidth>
          <Box<Theme> fontStyle="brandBold" fontSize="big" pb={2} pt={1}>
            Suche verfeinern
          </Box>
          {facets && facets.reihe && (
            <StringFacetPanel<EBooksFilters>
              name="Reihen"
              currentFilter="reihe"
              values={facets.reihe}
              filters={filters}
              performFiltering={(nextFilters) => {
                performFiltering(nextFilters)
              }}
            />
          )}
        </Box>
      </Box>
    </Flex>
  )
}
