import {Box} from '@indoqa/style-system'
import {IStyle} from 'fela'
import * as React from 'react'
import theme from '../../../app/theme'

interface Props {
  hidden?: boolean
  noPaddingTop?: boolean
}

export const AlignedDigitalStructurePanel: React.FC<Props> = ({hidden, noPaddingTop, children}) => {
  const style: IStyle = {
    display: hidden ? 'none' : 'block',
    minHeight: '100%',
    height: '100%',
    overflowY: 'auto',
    borderRight: `1px solid ${theme.colors.bgContentEmphasised}`,
  }
  return (
    <Box pt={noPaddingTop ? 0 : 3} style={style}>
      {children}
    </Box>
  )
}
