import querystring from 'query-string'
import * as Rx from 'rxjs'
import {AjaxCreationMethod} from 'rxjs/internal/observable/dom/AjaxObservable'
import {addClientInfo} from '../../commons/idqsa/idqsa'
import {getHeaders} from '../../commons/store/service-utils'
import {SearchResult} from '../../commons/types/SearchResult'
import {ArFilters} from './ar.actions'
import {ArInventory, CompletionsResponse, QuestionAnswer} from './ar.types'

export const PAGE_SIZE = 10

export const arSearchService$ = (
  ajax: AjaxCreationMethod,
  userQuery: string,
  start: number,
  count: number,
  filters?: ArFilters,
  previousSearchId?: string
): Rx.Observable<SearchResult> => {
  const queryParams: {[key: string]: string} = {}
  queryParams['query'] = decodeURIComponent(userQuery) // just in case (some browsers seem to pass encoded query strings)
  queryParams['page'] = String(start / PAGE_SIZE)
  queryParams['page-size'] = String(count - start)
  queryParams['boost.name'] = String(5)
  queryParams['boost.kapiteltitel'] = String(3)
  queryParams['boost.kapitelebene'] = String(10)
  queryParams['boost.werkstitel'] = String(100)

  if (filters) {
    if (filters.handbook_name) {
      queryParams['handbook_name'] = filters.handbook_name
    }
    if (filters.skriptum_name) {
      queryParams['skriptum_name'] = filters.skriptum_name
    }
    if (filters.videoOnly) {
      queryParams['type'] = 'videos'
    }
    if (filters.bookTitleOnly) {
      queryParams['type'] = 'books'
    }
  }

  addClientInfo(queryParams, previousSearchId)

  return ajax.getJSON(`/api/v1/arfbr/search2?${querystring.stringify(queryParams)}`, getHeaders())
}

export const arAutoCompleteService$ = (ajax: AjaxCreationMethod, input: string): Rx.Observable<CompletionsResponse> => {
  if (!input || input.length < 2) {
    return Rx.of({completions: []})
  }
  const queryParams: Record<string, string> = {}
  queryParams['input'] = decodeURIComponent(input) // just in case (some browsers seem to pass encoded query strings)
  addClientInfo(queryParams)

  return ajax.getJSON(`/api/v1/arfbr/auto-complete?${querystring.stringify(queryParams)}`, getHeaders())
}

export const loadQAService$ = (ajax: AjaxCreationMethod, id: string): Rx.Observable<QuestionAnswer> => {
  return ajax.getJSON(`/api/v1/book/questions/${id}`, getHeaders())
}

export const loadOverviewVideosService$ = (ajax: AjaxCreationMethod): Rx.Observable<SearchResult> => {
  return ajax.getJSON(`/api/v1/arfbr/videos?page=1&page-size=3&randomized=true`, getHeaders())
}

export const loadAllResources = (ajax: AjaxCreationMethod): Rx.Observable<ArInventory> => {
  return Rx.forkJoin({
    books: ajax.getJSON<SearchResult>(`/api/v1/arfbr/books?page=0&page-size=100`, getHeaders()),
    videos: ajax.getJSON<SearchResult>(`/api/v1/arfbr/videos?page=0&page-size=100&randomized=false`, getHeaders()),
  })
}
