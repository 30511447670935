import {Box} from '@indoqa/style-system'
import * as React from 'react'
import {FooterLink} from './FooterLink'
import {FooterLinkDatenschutzeinstellungen} from './FooterLinkDatenschutzeinstellungen'

interface Props {
  nowrap?: boolean
}

export const FooterLinks = ({nowrap}: Props) => {
  return (
    <>
      <FooterLink name="Verlag des ÖGB GmbH" linkTo="https://www.oegbverlag.at/" marginLeft={0} />
      <Box display={[nowrap ? 'none' : 'block', 'block']}>
        <FooterLink name="Impressum" linkTo="https://www.oegbverlag.at/cms/V01/V01_999_impressum/impressum" />
        <FooterLink name="Datenschutz" linkTo="https://www.oegbverlag.at/cms/V01/V01_999_datenschutz/datenschutz" />
        <FooterLinkDatenschutzeinstellungen />
        <FooterLink name="Kontakt" linkTo="mailto:office@oegbverlag.at" />
      </Box>
    </>
  )
}
