import * as React from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {kvLoadSourceInfo} from '../../store/kv.actions'
import {selectReaderSourceInfo} from '../../store/kv.selectors'

interface Props {
  documentId?: string
  paraId?: string
}

export const KvSourceInfoPanel: React.FC<Props> = ({documentId, paraId}) => {
  const dispatch = useDispatch()
  React.useEffect(() => {
    dispatch(kvLoadSourceInfo(documentId, paraId))
  }, [documentId, paraId, dispatch])
  const sourceInfo = useSelector(selectReaderSourceInfo)
  return (
    <>
      <h3>Source</h3>
      <p>
        <strong>documentId</strong>:<br /> {documentId}
      </p>
      <p>
        <strong>paraId</strong>:<br /> {paraId}
      </p>

      {sourceInfo && (
        <div>
          <p>
            <strong>lastProcessed</strong>:<br /> {sourceInfo.lastProcessed}
          </p>
          <p>
            <strong>sourceFiles</strong>
          </p>
          {sourceInfo.sourceFiles && (
            <ul>
              {sourceInfo.sourceFiles.map((file) => (
                <li key={file.name}>
                  {file.name} | {file.sourcePackage}
                </li>
              ))}
            </ul>
          )}
        </div>
      )}
    </>
  )
}
