import {Text} from '@indoqa/style-system'
import {IStyle} from 'fela'
import * as React from 'react'
import {Theme} from '../../../app/theme'

interface Props {
  title: string
}

export const KvExtendedSearchLabel: React.FC<Props> = ({title}) => {
  const style: IStyle = {
    width: 110,
  }
  return (
    <Text<Theme> style={style} fontStyle="brand">
      {title}
    </Text>
  )
}
