import {mergeSearchResults} from '../../commons/store/mergeSearchResults'
import {LawAction, LawLoadContent} from './law.actions'
import {LawActionKeys, LawState} from './law.types'

const initialState: LawState = {
  overview: null,
  group: null,
  reader: {
    documentInfo: null,
    structure: null,
    content: null,
    completeContent: null,
  },
  search: {
    userQuery: '',
    page: 0,
    result: null,
    autoCompleteItems: [],
  },
}
const willStructureUpdate = (state: LawState, action: LawLoadContent): boolean => {
  if (!state.reader.structure) {
    return true
  }
  return state.reader.structure.link.id !== action.lawId
}

export const lawReducer = (state: LawState = initialState, action: LawAction): LawState => {
  switch (action.type) {
    case LawActionKeys.LOAD_OVERVIEW:
      return {
        ...state,
        search: {
          ...state.search,
          userQuery: '',
          page: 0,
          result: null,
        },
      }
    case LawActionKeys.LOAD_OVERVIEW_SUCCESS:
      return {
        ...state,
        overview: action.overview,
      }
    case LawActionKeys.LOAD_GROUP:
      return {
        ...state,
        group: null,
      }
    case LawActionKeys.LOAD_GROUP_SUCCESS:
      return {
        ...state,
        group: action.group,
      }
    case LawActionKeys.LOAD_CONTENT:
      return {
        ...state,
        reader: {
          ...state.reader,
          documentInfo: {
            documentId: action.documentId,
          },
          content: '',
          structure: willStructureUpdate(state, action) ? null : state.reader.structure,
          completeContent: null,
        },
      }
    case LawActionKeys.LOAD_CONTENT_SUCCESS:
      return {
        ...state,
        reader: {
          documentInfo: action.documentInfo,
          content: action.documentContent,
          structure: action.structure,
          completeContent: null,
        },
      }
    case LawActionKeys.LOAD_COMPLETE_CONTENT:
      return {
        ...state,
        reader: {
          ...state.reader,
          documentInfo: null,
          content: null,
          completeContent: '',
        },
      }
    case LawActionKeys.LOAD_COMPLETE_CONTENT_SUCCESS:
      return {
        ...state,
        reader: {
          ...state.reader,
          structure: action.structure,
          completeContent: action.lawCompleteContent,
        },
      }
    case LawActionKeys.SEARCH: {
      return {
        ...state,
        search: {
          ...state.search,
          userQuery: action.userQuery,
        },
      }
    }
    case LawActionKeys.SEARCH_SUCCESS:
      return {
        ...state,
        search: {
          ...state.search,
          result: action.searchResult,
        },
      }
    case LawActionKeys.SEARCH_MORE_SUCCESS:
      return {
        ...state,
        search: {
          ...state.search,
          page: action.page,
          result: mergeSearchResults(state.search.result, action.searchResult),
        },
      }
    case LawActionKeys.SEARCH_AUTO_COMPLETE_SUCCESS:
      return {
        ...state,
        search: {
          ...state.search,
          autoCompleteItems: action.items,
        },
      }
  }
  return state
}
