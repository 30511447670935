import {Flex} from '@indoqa/style-system'
import * as React from 'react'
import {clippedBackgroundImageRules} from '../../../app/mixins'
import {ContentNode} from '../../../commons/types/ContentNode'
import {KvIntroPanel} from './KvIntroPanel'
import kvOverviewBg from './KvOverviewBg.jpg'

interface Props {
  contentNodeLeft?: ContentNode
}

export const KvStage = ({contentNodeLeft}: Props) => {
  return (
    <Flex style={[clippedBackgroundImageRules(kvOverviewBg)]} pb={4}>
      <KvIntroPanel contentNodeLeft={contentNodeLeft} />
    </Flex>
  )
}
