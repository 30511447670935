import * as React from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {useParams} from 'react-router-dom'
import {ContentPanel} from '../../commons/components/content/ContentPanel'
import {FooterPanel} from '../../commons/components/footer/FooterPanel'
import {DigitalHelmet} from '../../commons/components/helmet/DigitalHelmet'
import {MainContainerPanel} from '../../commons/components/main/MainContainerPanel'
import {ContentLayout} from '../../commons/layouts/content-layout/ContentLayout'
import {useError} from '../../error/hooks/useError'
import {ErrorPage} from '../../error/pages/ErrorPage'
import {KvHeaderPanel} from '../components/header/KvHeaderPanel'
import {kvLoadContent} from '../store/kv.actions'
import {kvProductName} from '../store/kv.constants'
import {kvPathChanges} from '../store/kv.paths'
import {selectKvContentNode} from '../store/kv.selectors'

interface LocationParams {
  webcDocumentId: string
}

export const KvContentPage = () => {
  const {webcDocumentId} = useParams<LocationParams>()
  const dispatch = useDispatch()

  // scroll to top
  React.useLayoutEffect(() => {
    window.scrollTo({top: 0})
  }, [])

  // load news
  React.useEffect(() => {
    dispatch(kvLoadContent('kvsystem', webcDocumentId))
  }, [dispatch, webcDocumentId])

  const contentNode = useSelector(selectKvContentNode)

  const hasError = useError()
  if (hasError) {
    return <ErrorPage />
  }

  if (!webcDocumentId) {
    return null
  }

  const header = <KvHeaderPanel noSearch />
  const footer = <FooterPanel />
  return (
    <ContentLayout header={header} footer={footer}>
      <DigitalHelmet title={kvProductName} canonical={kvPathChanges()} />
      <MainContainerPanel>
        <ContentPanel contentNode={contentNode} />
      </MainContainerPanel>
    </ContentLayout>
  )
}
