import {IStyle} from 'fela'
import * as React from 'react'
import {useFela} from 'react-fela'
import {invisibleButtonRules} from '../../../app/mixins'
import {Theme} from '../../../app/theme'
import {ThemedIcon} from '../../../commons-shared/themed-icon/ThemedIcon'
import {ReactComponent as MenuIcon} from '../../icons/menu.svg'

interface Props {
  onClick: () => void
}

export const MenuButton: React.FC<Props> = ({onClick}) => {
  const {css} = useFela<Theme>()
  const buttonStyle: IStyle = {
    marginTop: -3,
    tablet: {
      display: 'none',
    },
  }
  return (
    <button onClick={onClick} className={css(invisibleButtonRules, buttonStyle)}>
      <ThemedIcon size={20} verticalCenter>
        <MenuIcon />
      </ThemedIcon>
    </button>
  )
}
