import {Button, Type} from '../../../commons/components/button/Button'

export const ArTestzugangButton = () => {
  return (
    <Button
      type={Type.INFO}
      linkTo={() => {
        window.open('https://arbeitsrecht-fuer-betriebsraete.at/testzugang/')
      }}
      text="Kostenlosen Testzugang anfordern"
    />
  )
}
