import * as React from 'react'
import {connect} from 'react-redux'
import {ErrorPage} from '../pages/ErrorPage'
import {throwError} from '../store/error.actions'

interface Props {
  throwError?: (error: any, errorInfo: React.ErrorInfo) => void
  children: React.ReactNode
}

interface State {
  hasError: boolean
}

class AppErrorBoundaryImpl extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props)
    this.state = {hasError: false}
  }

  static getDerivedStateFromError() {
    return {hasError: true}
  }

  componentDidCatch(error: Error, errorInfo: React.ErrorInfo) {
    console.log('error', error)
    console.log('errorInfo', errorInfo)
    if (this.props.throwError) {
      this.props.throwError(error, errorInfo)
    }
  }

  render() {
    const resetError = () => {
      this.setState({hasError: false})
    }
    if (this.state.hasError) {
      return <ErrorPage reactError onClick={() => resetError()} />
    }
    return this.props.children
  }
}

export const AppErrorBoundary = connect<any>(null, {throwError})(AppErrorBoundaryImpl)
