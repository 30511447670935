import * as React from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {SearchMore} from '../../../commons/components/search-more/SearchMore'
import {kvSearchMore} from '../../store/kv.actions'
import {selectKvSearchPage, selectKvSearchResult} from '../../store/kv.selectors'

export const KvSearchMore: React.FC = () => {
  const dispatch = useDispatch()
  const page = useSelector(selectKvSearchPage)
  const result = useSelector(selectKvSearchResult)
  const totalCount = result?.documentGroupsFound || 0
  return (
    <SearchMore
      totalCount={totalCount}
      alreadyLoadedCount={(page + 1) * 10}
      loadMoreAction={() => dispatch(kvSearchMore())}
    />
  )
}
