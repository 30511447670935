import * as React from 'react'
import {useFela} from 'react-fela'
import history from '../../../../app/history'
import {treeNodeRules} from '../../../../app/mixins'
import {Theme} from '../../../../app/theme'
import {TreeView} from '../../../../commons-shared/treeview/TreeView'
import {RenderNode, SelectedNode, StructureAccessors} from '../../../../commons-shared/treeview/treeView.types'
import {CreateDigitalLink} from '../../../../commons/components/structure/digitalStructure.types'
import {StructureNode, StructureSubtype} from '../../../../commons/types/Structure'
import {BookStructureNode} from '../../../store/book.types'
import {BookWebbookSlugStructureFolderPanel} from './BookWebbookSlugStructureFolderPanel'
import {BookWebbookSlugStructureLeafPanel} from './BookWebbookSlugStructureLeafPanel'

interface Props {
  structure: StructureNode | null
  createDigitalLink: CreateDigitalLink
  paraId?: string | null
  paramsKey?: string
}

const BOOK_SLUG_STRUCTURE_NODE_ACCESSORS: StructureAccessors<StructureNode> = {
  getId: (structure) => structure.link.id || structure.link.name,
  getChildren: (structure) => structure.children,
  isLeaf: (structure) =>
    !(structure.children && structure.children.length > 0 && structure.subType !== StructureSubtype.SL),
  isActive: () => true,
}

export const BookWebbookSlugTreeView = ({structure, paraId, paramsKey, createDigitalLink}: Props) => {
  const {theme} = useFela<Theme>()
  const [selectedNode, setSelectedNode] = React.useState<SelectedNode>({id: paraId || ''})

  React.useEffect(() => {
    setSelectedNode({id: paraId || ''})
  }, [setSelectedNode, paraId, paramsKey])

  if (!structure) {
    return null
  }

  const loadContent = (slug: string) => {
    const link = createDigitalLink(slug)
    history.push(link)
  }

  const renderFolderNode: RenderNode<BookStructureNode> = (props) => (
    <BookWebbookSlugStructureFolderPanel
      {...props}
      loadDigitalContent={loadContent}
      createDigitalLink={createDigitalLink}
    />
  )

  const renderLeafNode: RenderNode<BookStructureNode> = (props) => (
    <BookWebbookSlugStructureLeafPanel
      {...props}
      loadDigitalContent={loadContent}
      createDigitalLink={createDigitalLink}
    />
  )

  return (
    <TreeView<StructureNode>
      structureAccessors={BOOK_SLUG_STRUCTURE_NODE_ACCESSORS}
      structure={structure}
      style={treeNodeRules(theme)}
      renderFolderNode={renderFolderNode}
      renderLeafNode={renderLeafNode}
      selectedNode={selectedNode}
      reloadKey={paramsKey}
    />
  )
}
