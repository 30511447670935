import * as React from 'react'
import {RenderDigitalNodeProps} from '../../../../commons/components/structure/digitalStructure.types'
import {DigitalStructureLeafTemplate} from '../../../../commons/components/structure/DigitalStructureLeafTemplate'
import {StructureNode} from '../../../../commons/types/Structure'
import {useLoadStructureSlugContent} from './useLoadStructureSlugContent'

export const BookWebbookSlugStructureLeafPanel: React.FC<RenderDigitalNodeProps<StructureNode>> = (props) => {
  const {inNodePath, selected, createDigitalLink} = props
  useLoadStructureSlugContent(props)
  const createLink = createDigitalLink ? () => createDigitalLink(props.structure.slug!) : undefined
  return (
    <DigitalStructureLeafTemplate
      label={props.structure.link.name}
      selected={inNodePath || selected}
      paddingRight={props.style.padding}
      createLink={createLink}
    />
  )
}
