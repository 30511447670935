import {Flex, Text} from '@indoqa/style-system'
import {IStyle} from 'fela'
import * as React from 'react'
import {useFela} from 'react-fela'
import {Theme} from '../../../app/theme'
import {DIGITAL_PARA_CSS_CLASSNAME} from '../../../commons/css-rules/DigitalCSSRules'
import {ReaderLayoutContext, ReaderLayoutContextValue} from '../../../commons/layouts/reader-layout/ReaderLayoutContext'
import {getScrollableParent} from '../../../commons/utils/getScrollableParent'
import {DocsetStructure} from '../../store/kv.types'
import {getHitsFromDocsetStructure} from './getHitsFromStructure'

interface Props {
  structure: DocsetStructure | null
  documentId?: string
  paraId?: string
  goToPara: GoToPara
  userQuery?: string
}

export type GoToPara = (nextDocumentId: string, nextDocumentName: string, nextPara: string) => void

export const KvSearchBar = ({structure, paraId, documentId, goToPara, userQuery}: Props) => {
  const {theme, css} = useFela<Theme>()
  const {setSearchBarHeight, dimensions, contentRef} = React.useContext<ReaderLayoutContextValue>(ReaderLayoutContext)
  const [currentHitPos, setCurrentHitPos] = React.useState(0)
  const [currentMarkPos, setCurrentMarkPos] = React.useState(0)
  const [allParaMarks, setAllParaMarks] = React.useState<NodeListOf<HTMLElement> | null>(null)
  const [useLastMark, setUseLastMark] = React.useState(false)

  const previewUserQueryRef = React.useRef<string | null>(null)
  const previousParaIdRef = React.useRef<string | null>(null)
  const previousDocumentId = React.useRef<string | null>(null)
  const previousContentRef = React.useRef<React.RefObject<HTMLElement> | null>(null)

  const hits = React.useMemo(() => getHitsFromDocsetStructure(structure), [structure])

  React.useLayoutEffect(() => {
    if (previewUserQueryRef.current && userQuery !== previewUserQueryRef.current) {
      setCurrentHitPos(0)
      setCurrentMarkPos(0)
      setAllParaMarks(null)
      setUseLastMark(false)
      previewUserQueryRef.current = null
      previousParaIdRef.current = null
      previousDocumentId.current = null
      previousContentRef.current = null
    }
    previewUserQueryRef.current = userQuery || null
  }, [userQuery])

  React.useLayoutEffect(() => {
    setSearchBarHeight(40)
  }, [setSearchBarHeight])

  React.useLayoutEffect(() => {
    if (contentRef && contentRef.current) {
      // find the current hit that belongs to the para (this expects that all paraIds are UNIQUE across documents!)
      for (let i = 0; i < hits.length; i++) {
        const eachHit = hits[i]
        if (eachHit.paraId === paraId) {
          setCurrentHitPos(i)
        }
      }

      // load all marks
      const allMarks = contentRef.current.querySelectorAll(`mark`) as NodeListOf<HTMLElement>
      const paraIdMarks = contentRef.current.querySelectorAll(`.${paraId} mark`) as NodeListOf<HTMLElement>
      setAllParaMarks(allMarks)

      let nextMarkPos = currentMarkPos

      // handle initial load of para in freshly loaded documents
      if (previousDocumentId.current !== documentId && paraIdMarks && paraIdMarks.length > 0) {
        for (let i = 0; i < allMarks.length; i++) {
          const eachMark = allMarks[i]
          if (paraIdMarks[0] === eachMark) {
            setCurrentMarkPos(i)
            nextMarkPos = i
            break
          }
        }
      }

      // handle going backwards
      if (useLastMark) {
        nextMarkPos = allMarks.length - 1
        if (previousContentRef.current !== contentRef) {
          setUseLastMark(false)
        }
        setCurrentMarkPos(nextMarkPos)
      }

      // highlight the current mark and scroll to it
      if (allMarks !== null && allMarks.length > nextMarkPos) {
        const nextMark = allMarks[nextMarkPos]
        if (nextMark) {
          allMarks.forEach((mark) => (mark.style.backgroundColor = 'yellow'))
          nextMark.style.backgroundColor = 'orange'
          nextMark.scrollIntoView()
          getScrollableParent(nextMark).scrollBy({top: -100})
        }
      }

      previousParaIdRef.current = paraId || null
      previousDocumentId.current = documentId || null
      previousContentRef.current = contentRef
    }
  }, [contentRef, paraId, currentMarkPos, documentId, currentHitPos, hits, useLastMark])

  const hasPreviousMark = () => allParaMarks !== null && currentMarkPos > 0
  const hasPreviousDocumentHit = () => {
    return currentHitPos > 0
  }
  const goToPreviousMarkOrHit = () => {
    if (hasPreviousMark() && allParaMarks) {
      const previousMarkPos = currentMarkPos - 1
      const previousHitPos = currentHitPos - 1
      setCurrentMarkPos(previousMarkPos)
      setCurrentHitPos(previousHitPos)
      // change the URL (-> set the correct highlight in the structure) if the mark is in another para
      if (previousMarkPos >= 0 && !isElementInPara(allParaMarks[previousMarkPos], paraId)) {
        const previousHit = hits[previousHitPos]
        goToPara(previousHit.documentId, previousHit.documentName, previousHit.paraId)
      }
      return
    }

    // try to load the previous document (time-slice) if available
    if (!hasPreviousDocumentHit()) {
      return
    }

    const nextHit = hits[currentHitPos - 1]
    setUseLastMark(true)
    setCurrentHitPos(currentHitPos - 1)
    setCurrentMarkPos(0)
    goToPara(nextHit.documentId, nextHit.documentId, nextHit.paraId)
  }

  const hasNextMark = () => {
    return allParaMarks && currentMarkPos < allParaMarks.length - 1
  }
  const hasNextDocumentHit = () => {
    return currentHitPos < hits.length - 1
  }
  const goToNextMarkOrHit = () => {
    if (hasNextMark() && allParaMarks) {
      const nextMarkPos = currentMarkPos + 1
      setCurrentMarkPos(nextMarkPos)
      const nextMark = allParaMarks[nextMarkPos]

      // change the URL (-> set the correct highlight in the structure) if the mark is in another para
      if (!isElementInPara(nextMark, paraId)) {
        const nextHit = hits[currentHitPos + 1]
        if (nextHit && nextHit.paraId !== paraId) {
          goToPara(nextHit.documentId, nextHit.documentName, nextHit.paraId)
        }
      }
      return
    }

    // try to load the next document (time-slice) if available
    if (!hasNextDocumentHit()) {
      return
    }
    const nextHit = hits[currentHitPos + 1]
    setCurrentMarkPos(0)
    setCurrentHitPos(currentHitPos + 1)
    goToPara(nextHit.documentId, nextHit.documentName, nextHit.paraId)
  }
  const previousButtonDisabled = !hasPreviousDocumentHit() && !hasPreviousMark()
  const nextButtonDisabled = !hasNextDocumentHit() && !hasNextMark()
  return (
    <Flex fullWidth bg={theme.colors.bgContentEmphasised} height={`${dimensions.searchBarHeight}px`} center>
      <button
        onClick={goToPreviousMarkOrHit}
        disabled={previousButtonDisabled}
        className={css(createButtonStyle(theme, previousButtonDisabled))}
      >
        Zum vorigen Treffer
      </button>
      <Text mx={2}>{/*{currentHitPos + 1} / {hits.length}*/}</Text>
      <button
        onClick={goToNextMarkOrHit}
        disabled={nextButtonDisabled}
        className={css(createButtonStyle(theme, nextButtonDisabled))}
      >
        Zum nächsten Treffer
      </button>
    </Flex>
  )
}

const createButtonStyle = (theme: Theme, disabled: boolean): IStyle => ({
  backgroundColor: disabled ? '#ababab' : theme.colors.primaryLight,
  color: theme.colors.textInverted,
  fontSize: '80%',
  boxShadow: 'none',
  borderWidth: 0,
  cursor: 'pointer',
  paddingTop: theme.spacing.space1,
  paddingRight: theme.spacing.space2,
  paddingBottom: theme.spacing.space1,
  paddingLeft: theme.spacing.space2,
})

const isElementInPara = (element?: HTMLElement, currentParaId?: string): boolean => {
  if (!currentParaId || !element) {
    return false
  }
  if (element.className.indexOf(DIGITAL_PARA_CSS_CLASSNAME) >= 0) {
    // only inspect the first ancestor para
    // see CLS-Test: /kvsystem/kollektivvertrag/SI-2336_de,198263_20210701/KHM-Museumsverband_Angestellte/Kuendigungsschutz-fuer-Arbeitnehmerinnen-und-Arbeitnehmer,-die-das-bestehende-Dienstverhaeltnis-zum-KHM-vor-dem-112012-begruendet-haben?p=pr198417_8334564&q=%C3%B6sterreich&v=2021-11-01
    return element.className.indexOf(currentParaId) >= 0
  }
  const parent = element.parentElement
  if (parent) {
    return isElementInPara(parent, currentParaId)
  }
  return false
}
