import {LinkType} from '../../commons/types/Link'
import {StructureNode} from '../../commons/types/Structure'

export const findFirstKvParaChild = (node: StructureNode): string | undefined => {
  if (node.link.type !== LinkType.KV_TIME_SLICE) {
    return node.link.id
  }
  const {children} = node
  if (!children) {
    return undefined
  }
  for (let i = 0; i < children.length; i++) {
    const result = findFirstKvParaChild(children[i])
    if (result) {
      return result
    }
  }
  return undefined
}
