import {ContentNode} from '../../commons/types/ContentNode'
import {DocumentInfo} from '../../commons/types/DocumentInfo'
import {ResultDocument, SearchResult} from '../../commons/types/SearchResult'
import {BaseStructureNode} from '../../commons/types/Structure'

export enum BookActionKeys {
  LOAD_EBOOKS = 'book/LOAD_EBOOKS',
  LOAD_EBOOKS_SUCCESS = 'book/LOAD_EBOOKS_SUCCESS',
  LOAD_EBOOKS_ERROR = 'book/LOAD_EBOOKS_ERROR',
  LOAD_EBOOK = 'book/LOAD_EBOOK',
  LOAD_EBOOK_SUCCESS = 'book/LOAD_EBOOK_SUCCESS',
  LOAD_EBOOK_ERROR = 'book/LOAD_EBOOK_ERROR',
  LOAD_WEBBOOK_CONTENT = 'book/LOAD_WEBBOOK',
  LOAD_WEBBOOK_CONTENT_SUCCESS = 'book/LOAD_WEBBOOK_SUCCESS',
  LOAD_WEBBOOK_CONTENT_ERROR = 'book/LOAD_WEBBOOK_ERROR',
}

export interface BookState {
  readonly ebooks: SearchResult | null
  readonly ebook: ResultDocument | null
  readonly reader: {
    readonly structure: BookStructureNode | null
    readonly content: ContentNode | null
    readonly documentInfo: DocumentInfo | null
  }
}

export interface EbookMeta {
  autoren: string
  'cover-id': string
  edition_version: string
  herausgeber: string
  isbn: string
  'pdf-id': string
  quelle_kurz: string
  quelle_lang: string
  rechtsstand: string
  reihe: string
  titel: string
  untertitel: string
  download?: string
  'linked-resource'?: string
}

export interface Ebook {
  id: string
  element: string
  typ: string
  title: string
  meta: EbookMeta
  children: ContentNode[]
  subtitle: string
  userGroups?: string[]
}

export interface EbooksStructure {
  children: Ebook[]
}

export interface BookStructureNode extends BaseStructureNode<BookStructureNode> {
  noContent?: boolean
  properties?: {
    quelle_kurz?: string
    quelle_lang?: string
    rechtsstand?: string
    autoren?: string
  }
}

export interface EBooksFilters {
  reihe?: string | null
}
