import {Box} from '@indoqa/style-system'
import * as React from 'react'

interface Props {
  tablet: JSX.Element
  mobile: JSX.Element
}

export const ReaderSearchInputPanel: React.FC<Props> = ({mobile, tablet}) => {
  return (
    <>
      <Box display={['none', 'block']} width={400} pr={3}>
        {tablet}
      </Box>
      <Box display={['block', 'none']}>{mobile}</Box>
    </>
  )
}
