import * as React from 'react'
import {ResultItemTemplate} from '../../../commons/components/search-result/ResultItemTemplate'
import {ActivationState, getArfbrDebugFromSessionStorage} from '../../../commons/components/settings/settings'
import {Pool} from '../../../commons/idqsa/idqsa'
import {ResultItemProps} from '../../store/ar.types'
import {getResourceAvailabilityForUser} from '../../utils/getResourceAvailabilityForUser'
import {getResultDocumentText} from '../../utils/resultItem.utils'
import {getAvailability} from './utils/getAvailability'

export const ArWebcDocumentResultItem: React.FC<ResultItemProps> = ({resultDocument, searchId}) => {
  const arfbrDebug = getArfbrDebugFromSessionStorage()
  return (
    <ResultItemTemplate
      resultItemId={resultDocument.id}
      searchId={searchId}
      name={resultDocument.name}
      type={'WEBC'}
      typeBgColor="contentTypeWebcDocument"
      disableBadge={arfbrDebug === ActivationState.NO}
      userResourceAvailability={getResourceAvailabilityForUser(getAvailability(resultDocument))}
      pool={Pool.ARFBR}
    >
      <p dangerouslySetInnerHTML={{__html: getResultDocumentText(resultDocument)}} />
    </ResultItemTemplate>
  )
}
