import {Box, Flex} from '@indoqa/style-system'
import {IStyle} from 'fela'
import * as React from 'react'
import {Button} from '../button/Button'

interface Props {
  title?: string
  aboveTitle?: string
  aboveTitle2?: string
  linkTo?: string
  linkText?: string
  height?: string | number
}

export const Card: React.FC<Props> = ({title, aboveTitle, aboveTitle2, linkTo, linkText, children, height}) => {
  const style: IStyle = {
    wordBreak: 'break-word',
    overflowY: 'auto',
    overflowX: 'hidden',
  }
  return (
    <Flex
      px={3}
      py={2}
      shadow="elevation1"
      style={style}
      height={height || 300}
      direction="column"
      nowrap
      justifyContent="space-between"
      bg="#FFF"
    >
      <Box mb={3}>
        {aboveTitle && (
          <Box color="textReduced" fontSize="small">
            {aboveTitle}
          </Box>
        )}
        {aboveTitle2 && (
          <Box color="textReduced" fontSize="small" bold>
            {aboveTitle2}
          </Box>
        )}
        {title && <h3 style={{marginTop: 0, fontSize: '105%'}}>{title}</h3>}
        <Box>{children}</Box>
      </Box>
      {linkTo && (
        <Box>
          <Box mb={3}>
            <Button linkTo={linkTo} text={linkText || 'MEHR ERFAHREN'} />
          </Box>
        </Box>
      )}
    </Flex>
  )
}
