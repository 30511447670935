import {IRenderer} from 'fela'
import {Theme} from '../../app/theme'

export const LAW_CSS_CLASSNAME = 'cp_law'
const C = `.${LAW_CSS_CLASSNAME}`

export const headlineRules = (theme: Theme) => {
  return `
    ${C} h1.Titel, 
    ${C} .UeberschrG1, 
    ${C} .InhaltUeberschriftWithinTable 
    {
      font-size: ${theme.fontSizes.text};
    }
    
    ${C} .UeberschrG1-,
    ${C} h3
    {
      font-size: ${theme.fontSizes.text};
    }
    
    ${C} h3 
    {
      margin-top: ${theme.spacing.space2}; 
    }
    
    ${C} .UeberschrG2,
    {
      font-size: ${theme.fontSizes.text};
    }
    
    ${C} .UeberschrPara,
    ${C} .InhaltSpalte 
    {
      font-size: ${theme.fontSizes.text};
    }
    
    ${C} .UeberschrPara,
    ${C} .UeberschrG1,
    ${C} .UeberschrG1-,
    ${C} .UeberschrG2,
    ${C} .UeberschrArt,
    ${C} .InhaltUeberschriftWithinTable,
    ${C} .InhaltSpalte 
    {
      font-weight: bold;
      margin-bottom: ${theme.spacing.space2};
    }
    
    ${C} .onlyScreenreader 
    {
      display: none;
    }
    
    ${C} .sr-only
    {
      display: none;
    }
    
    ${C} .AlignCenter 
    {
      text-align: center;
    }
    
    ${C} .GldSymbol 
    {
      font-weight: bold;
    }
    
    ${C} .Abs 
    {
       margin-bottom: ${theme.spacing.space2};
    }
    
    ${C} p.Titel 
    {
      margin-bottom: 0;
    }
    
    ${C} .ErlText 
    {
      margin-bottom: ${theme.spacing.space2};
      font-style: italic;
    }
    
    ${C} .InhaltUeberschriftWithinTable 
    {
      margin-top: ${theme.spacing.space3};
    }
    
    ${C} .InhaltSpalte 
    {
      margin-top: ${theme.spacing.space2};
    }
    
    ${C} table 
    {
      border-collapse: separate;
      padding-bottom: 10px;
      margin-bottom: 8px;
      width: 100%;
    }
    
    ${C} table.tableOfContent td.tableOfContentEintrag1 p.InhaltEintrag
    {
      white-space: nowrap;
    }
    
    ${C} table.leaders
    {
      margin-top: -10px;
      margin-bottom: 0;
      padding-bottom: 0;
    }
    
    ${C} p.ZifferE1,
    ${C} p.ZifferE2,
    ${C} p.ZifferE3,
    ${C} p.ZifferE4,
    ${C} p.ZifferE5 
    {
      padding-bottom: 1pt;
    }
    
    ${C} td 
    {
      padding: 1px;
      line-height: 1.4;
    }
    
    ${C} td p 
    {
      padding: 0;
      margin: 0;
    }
    
    ${C} tr.invisible 
    {
      height: 0;
    }
    
    ${C} p.Titel 
    {
      font-weight: bold;
    }
    
    ${C} .Kursiv 
    {
      font-style: italic;
    }
    
    ${C} .AlignRight 
    {
      text-align: right;
    }
    
    ${C} .VAlignTop 
    {
      vertical-align: top;
    }
    
    ${C} .AlignJustify 
    {
      text-align: justify;
    }
    
    ${C} .W2 
    {
      min-width: 2px;
      width: 2px
    }
    
    ${C} .W15 
    {
      min-width: 15px;
      width: 15px
    }
    
    ${C} .W20 
    {
      min-width: 20px;
      width: 20px
    }
    
    ${C} .W22 
    {
      min-width: 22px;
      width: 22px
    }
    
    ${C} .W42 
    {
      min-width: 42px;
      width: 42px
    }
    
    ${C} .W90 
    {
      min-width: 90px;
      width: 90px
    }
    
    ${C} .wi100 
    {
      width: 100%;
    }
    
    ${C} li {
      margin-bottom: 0.5rem;
    }
    
    ${C} .wai-list li {
      display: flex;
      flex-direction: row;
    }
    
    ${C} ol.wai-list, ${C} ul.wai-list, ${C} ol.wai-absatz-list {
      list-style-type: none;
      margin-top: 0;
      margin-bottom: 0;
      padding-left: 0;
    }
    
    ${C} .wai-list .SymE0 {
      flex: 0 0 auto;
      width: 23pt;
      text-align: left;
    }
    
    ${C} .Abs_small_indent .Absatzzahl, ${C} .Abs .Absatzzahl {
      margin-right: 1ch;
    }
    
    ${C} .SymE1 .AufzaehlungE1 span {
      white-space: nowrap;
    }
    
    ${C} .SymE1 .AufzaehlungE1 {
      white-space: nowrap;
      padding-right: 1ch;
    }
  `
}

export const renderLawCSS = (renderer: IRenderer, theme: Theme) => {
  renderer.renderStatic(headlineRules(theme))
}
