import {DocumentInfo} from '../../commons/types/DocumentInfo'
import {LinkType} from '../../commons/types/Link'
import {ResultDocument} from '../../commons/types/SearchResult'
import {StructureNode} from '../../commons/types/Structure'
import {findDocumentInfo} from '../../commons/utils/findDocumentInfo'
import {LinkPredicate} from '../../commons/utils/findFirstDocumentInfo'
import {lawPathReaderIds, lawPathReaderLawId} from './law.paths'
import {LawDocumentLookupInfo} from './law.types'

const createSubTitle = (subTitle?: string) => {
  return subTitle ? ` - ${subTitle}` : ''
}

export const getLawReferenceTitle = (structureNode: StructureNode) => {
  const {link, subTitle} = structureNode
  return link ? `${link.name}${createSubTitle(subTitle)}` : ''
}

export const isRisDocument: LinkPredicate = (link) => link.type === LinkType.RIS_DOCUMENT

export const findLawDocument = (structure: StructureNode, proposedDocumentId?: string): DocumentInfo | null => {
  return findDocumentInfo(
    structure,
    isRisDocument,
    () => false,
    false,
    proposedDocumentId,
    undefined,
    getLawReferenceTitle
  )
}

export const getLawAbbreviation = (doc: ResultDocument): string | null => {
  const lawAbbreviationHighlights = doc.highlights?.string_property_lawAbbreviation
  if (lawAbbreviationHighlights && lawAbbreviationHighlights?.length > 0) {
    return lawAbbreviationHighlights[0].replace('<mark>', '<strong>').replace('<mark/>', '</strong>')
  }
  return doc.properties?.lawAbbreviation || null
}

export const getLawName = (doc: ResultDocument): string => {
  const lawNameHighlight = doc.highlights?.name
  if (lawNameHighlight && lawNameHighlight?.length > 0) {
    return lawNameHighlight[0].replace('<mark>', '<strong>').replace('<mark/>', '</strong>')
  }
  return doc.name || ''
}

export const createLawRedirectLink = (lookupInfo: LawDocumentLookupInfo): string => {
  return lookupInfo.documentId
    ? lawPathReaderIds(lookupInfo.lawId, lookupInfo.documentId)
    : lawPathReaderLawId(lookupInfo.lawId)
}
