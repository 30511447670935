import {Flex, Text} from '@indoqa/style-system'
import {IStyle} from 'fela'
import * as React from 'react'
import {useFela} from 'react-fela'
import {Link} from 'react-router-dom'
import {Theme} from '../../../app/theme'
import {ThemedIcon} from '../../../commons-shared/themed-icon/ThemedIcon'
import {ReactComponent as ChevronRightIcon} from '../../../commons/icons/chevron-right.svg'

export interface Breadcrumb {
  name: string
  linkTo?: string
  emphasize?: boolean
}

interface Props {
  breadcrumbs: Breadcrumb[]
}

const breadcrumbStyle = (pos: number): IStyle => {
  return {
    display: pos === 0 ? 'inline' : 'none',
    whiteSpace: 'nowrap',
    tablet: {
      display: 'inline',
    },
    '& > a': {
      textDecoration: 'none',
      color: 'inherit',
    },
  }
}

const renderBreadcrumb = (breadcrumb: Breadcrumb, id: number, emphasizeColor: string) => {
  const {name, linkTo} = breadcrumb
  const content = linkTo ? <Link to={linkTo}>{name}</Link> : name
  return (
    <Text
      ellipsis={id !== 0}
      key={id}
      fontSize="small"
      style={breadcrumbStyle(id)}
      color={breadcrumb.emphasize ? emphasizeColor : undefined}
    >
      {content}
    </Text>
  )
}

const renderSeparator = (id: number) => (
  <Text key={`${id}_sep`} style={breadcrumbStyle(id)}>
    <ThemedIcon size={14} color="white">
      <ChevronRightIcon />
    </ThemedIcon>
  </Text>
)

export const ReaderBreadcrumbsPanel: React.FC<Props> = ({breadcrumbs}) => {
  const {theme} = useFela<Theme>()
  const emphasizeColor = theme.colors.textHighlightAlternative
  if (breadcrumbs.length === 0) {
    return null
  }
  const panels = []
  panels.push(renderBreadcrumb(breadcrumbs[0], 0, emphasizeColor))
  if (breadcrumbs.length > 1) {
    for (let i = 1; i < breadcrumbs.length; i = i + 1) {
      const breadcrumb = breadcrumbs[i]
      if (breadcrumb.name.length > 0) {
        panels.push(renderSeparator(i))
        panels.push(renderBreadcrumb(breadcrumb, i, emphasizeColor))
      }
    }
  }
  return (
    <Flex nowrap overflowHidden alignItems="baseline" style={{lineHeight: 1.2}}>
      {panels}
    </Flex>
  )
}
