import {Box} from '@indoqa/style-system'
import {IStyle} from 'fela'
import * as React from 'react'
import {useFela} from 'react-fela'
import {removePaddingLeftPrintRule, transparentBackgroundPrintRule} from '../../../app/mixins'
import {Theme} from '../../../app/theme'

export const ReaderContentTopInfo: React.FC = ({children}) => {
  const {theme} = useFela<Theme>()
  const style: IStyle = {
    print: {
      position: 'static',
      height: 'auto',
      marginTop: -15,
      borderBottom: theme.borders.border1,
      ...theme.fontStyles.brand,
    },
  }
  return (
    <Box<Theme>
      fullWidth
      bg="bgContentEmphasised"
      display={['block', 'none']}
      p={2}
      fontStyle="brandBold"
      maxWidth="100%"
      style={[style, transparentBackgroundPrintRule(), removePaddingLeftPrintRule()]}
      overflowHidden
    >
      {children}
    </Box>
  )
}
