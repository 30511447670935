import {Text} from '@indoqa/style-system'
import {IStyle} from 'fela'
import * as React from 'react'
import {useFela} from 'react-fela'
import {Theme} from '../../../app/theme'
import {ThemedIcon} from '../../../commons-shared/themed-icon/ThemedIcon'
import {ReactComponent as Icon} from './feedback.svg'

interface Props {
  onClick: () => void
}

export const FeedbackIcon = ({onClick}: Props) => {
  const {theme} = useFela<Theme>()
  const iconColor = theme.colors.iconInverted
  const style: IStyle = {
    display: 'none',
    tablet: {
      display: 'inline',
    },
  }
  return (
    <Text onClick={onClick} mr={3} cursorPointer style={style}>
      <ThemedIcon size={16} color={iconColor} hoverColor={iconColor}>
        <Icon />
      </ThemedIcon>
    </Text>
  )
}
