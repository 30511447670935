import {Box, Flex, Text} from '@indoqa/style-system'
import {IStyle} from 'fela'
import * as React from 'react'
import {useFela} from 'react-fela'
import {Theme} from '../../../app/theme'
import {ThemedIcon} from '../../../commons-shared/themed-icon/ThemedIcon'
import {ReactComponent as ArrowLeftIcon} from '../../../commons/icons/arrow-left.svg'
import {ReactComponent as CloseIcon} from '../../../commons/icons/close.svg'
import {ReactComponent as SearchIcon} from '../../../commons/icons/search.svg'
import {ReactComponent as TocIcon} from '../../../commons/icons/table-of-content.svg'
import {ReaderLayoutContext, ReaderLayoutContextValue} from '../../../commons/layouts/reader-layout/ReaderLayoutContext'
import {DocumentInfo} from '../../../commons/types/DocumentInfo'
import {ResetAllSearch} from './KvReaderHeaderPanel'

interface Props {
  searchInput: JSX.Element
  quickSearchInput: JSX.Element
  resetAllSearch: ResetAllSearch
  documentInfo: DocumentInfo
  docsetName: string
}

export const KvReaderMobileModalSearch: React.FC<Props> = ({
  searchInput,
  quickSearchInput,
  resetAllSearch,
  documentInfo,
  docsetName,
  children,
}) => {
  const {theme} = useFela<Theme>()
  const {disableNav, navShown, toggleNav} = React.useContext<ReaderLayoutContextValue>(ReaderLayoutContext)
  const [reduced, setReduced] = React.useState(true)
  const style: IStyle = {
    position: 'fixed',
    top: 0,
    left: 0,
    height: theme.layout.reader.headerHeight + theme.layout.reader.toolbarHeight * 2,
    width: '100vw',
    zIndex: theme.zIndexes.modal,
  }
  if (reduced) {
    return (
      <Text cursorPointer onClick={() => setReduced(false)}>
        <ThemedIcon size={20}>
          <SearchIcon />
        </ThemedIcon>
      </Text>
    )
  }
  const documentName = documentInfo.documentName ? ` > ${documentInfo.documentName}` : ''
  return (
    <Flex fullWidth direction="column" style={style}>
      <Flex fullWidth height={theme.layout.reader.headerHeight} bg="bgContentEmphasised" center px={2} nowrap>
        <Text
          cursorPointer
          onClick={() => {
            disableNav()
            setReduced(true)
            resetAllSearch()
          }}
          pr={2}
        >
          <ThemedIcon size={20}>
            <ArrowLeftIcon />
          </ThemedIcon>
        </Text>
        <Box grow={1}>{searchInput}</Box>
      </Flex>
      <Flex
        fullWidth
        height={theme.layout.reader.toolbarHeight}
        bg="primaryLight"
        color="textInverted"
        px={2}
        alignItems="center"
      >
        <Text onClick={() => toggleNav()} pr={2}>
          <ThemedIcon size={14} color={theme.colors.iconInverted} hoverColor={theme.colors.iconInverted} verticalCenter>
            {navShown ? <CloseIcon /> : <TocIcon />}
          </ThemedIcon>
        </Text>

        {quickSearchInput}
      </Flex>
      <Flex<Theme>
        fullWidth
        height={theme.layout.reader.toolbarHeight}
        bg="bgContentEmphasised"
        px={2}
        alignItems="center"
        fontStyle="brandBold"
      >
        <Text ellipsis>
          {docsetName}
          {documentName}
        </Text>
      </Flex>
    </Flex>
  )
}
