import * as React from 'react'
import {RenderNodeProps} from '../../../commons-shared/treeview/treeView.types'
import {DigitalStructureFolderTemplate} from '../../../commons/components/structure/DigitalStructureFolderTemplate'
import {StructureNode} from '../../../commons/types/Structure'

export const LawStructureFolderPanel: React.FC<RenderNodeProps<StructureNode>> = (props) => {
  const {structure, style, inNodePath, selected} = props
  return (
    <DigitalStructureFolderTemplate
      label={structure.link.name}
      subLabel={structure.subTitle}
      paddingRight={style.padding}
      selected={inNodePath || selected}
    />
  )
}
