import {Flex} from '@indoqa/style-system'
import * as React from 'react'
import {ResultGroupTemplate} from '../../../commons/components/search-result/ResultGroupTemplate'
import {
  RenderDocument,
  RenderDocumentGroup,
  SearchResultPanel,
} from '../../../commons/components/search-result/SearchResultPanel'
import {Pool} from '../../../commons/idqsa/idqsa'
import {SelectSignal} from '../../../commons/idqsa/SelectSignal'
import {SearchResult} from '../../../commons/types/SearchResult'
import {lawPathReaderLawId} from '../../store/law.paths'
import {LawParagraphResultItem} from './LawParagraphResultItem'
import {LawSearchMore} from './LawSearchMore'

interface Props {
  searchResult: SearchResult
}

export const LawSearchResultPanel: React.FC<Props> = ({searchResult}) => {
  const {tracking} = searchResult

  const renderDocumentGroup: RenderDocumentGroup = (documentGroup) => {
    const name = documentGroup.groupDocument.name
    const link = lawPathReaderLawId(documentGroup.groupDocument.id)
    return (
      <SelectSignal searchId={tracking?.searchId} resultItemId={documentGroup.groupDocument.id} pool={Pool.LAW}>
        <ResultGroupTemplate name={name} link={link} />
      </SelectSignal>
    )
  }

  const renderDocument: RenderDocument = (resultDocument, resultGroup, searchId) => {
    return <LawParagraphResultItem resultDocument={resultDocument} searchId={searchId} key={resultDocument.id} />
  }

  return (
    <Flex direction="column" fullWidth>
      <SearchResultPanel
        searchResult={searchResult}
        resultNamePlural="Gesetzen"
        resultNameSingular="Gesetz"
        renderDocumentGroup={renderDocumentGroup}
        renderDocument={renderDocument}
        disableCollapsing
      />
      <LawSearchMore />
    </Flex>
  )
}
