export interface Credentials {
  secret: string
}

export interface Auth {
  url: string
  realm: string
  clientId: string
  credentials: Credentials
}

export interface Configuration {
  auth: Auth
  entryPoint: string | null
}

export interface Link {
  reqParam: string
  base: string
}

export interface Links {
  digital: Link
  ar: Link
  drda: Link
  kvs: Link
  soz: Link
}

export enum AlphaFeatures {
  ALL = 'ALL',
}

export interface InitialState {
  conf: Configuration
  links: Links
  version: string
  commitShortId: string
  commitTime: string
  alphaFeatures?: AlphaFeatures
  it: boolean
  signals?: boolean
}
