import useEventListener from '@use-it/event-listener'
import React from 'react'
import {useFela} from 'react-fela'
import {Theme} from '../../../app/theme'
import {isMobile} from '../../utils/isMobile'

export const useMobile = () => {
  const [mobile, setMobile] = React.useState(false)
  const {theme} = useFela<Theme>()
  React.useLayoutEffect(() => {
    setMobile(isMobile(theme))
  }, [theme])
  useEventListener('resize', () => setMobile(isMobile(theme)), undefined)
  return mobile
}
