import {Link} from './Link'

export enum StructureSubtype {
  FA = 'FA',
  SL = 'SL',
  CHAPTER = 'chapter',
}

export interface BaseStructureNode<S> {
  link: Link
  children?: S[]
  hits?: number
  noContent?: boolean
}

export interface StructureNode extends BaseStructureNode<StructureNode> {
  subType?: StructureSubtype
  subTitle?: string
  simpleTitle?: string
  titleNum?: string
  slug?: string
}
