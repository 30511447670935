const PARAM_SET = 'set'
const PARAM_KU = 'ku'
const PARAM_ARFBR_DEBUG = 'arfbr-debug'

export enum ActivationState {
  YES = 'yes',
  NO = 'no',
}

export const getCurrentSetFromSessionStorage = () => {
  const set = window.sessionStorage.getItem(PARAM_SET)
  if (!set) {
    return 'LIVE'
  }
  return set
}

export const saveCurrentSetToSessionStorage = (set: string) => {
  window.sessionStorage.setItem(PARAM_SET, set)
}

export const getKuSupportFromSessionStorage = (): ActivationState => {
  const ku = window.sessionStorage.getItem(PARAM_KU)
  return ActivationState.NO === ku ? ActivationState.NO : ActivationState.YES
}

export const saveKuSupportToSessionStorage = (support: ActivationState) => {
  window.sessionStorage.setItem(PARAM_KU, support)
}

export const getArfbrDebugFromSessionStorage = (): ActivationState => {
  const sn = window.sessionStorage.getItem(PARAM_ARFBR_DEBUG)
  return ActivationState.YES === sn ? ActivationState.YES : ActivationState.NO
}

export const saveArfbrDebugToSessionStorage = (support: ActivationState) => {
  window.sessionStorage.setItem(PARAM_ARFBR_DEBUG, support)
}
