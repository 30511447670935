import * as React from 'react'
import {useFela} from 'react-fela'
import {Theme} from '../../../app/theme'
import {Badge} from '../../../ar/components/badge/Badge'
import {SearchInput} from '../../../commons-shared/search-input/SearchInput'
import {
  Clear,
  Reload,
  RenderItem,
  Search,
  SelectSuggestion,
  StyleConfig,
} from '../../../commons-shared/search-input/SearchInput.types'
import {KvAutoCompleteItem} from '../../store/kv.types'

interface Props {
  userQuery: string
  items: KvAutoCompleteItem[]
  reload: Reload
  selectSuggestion: SelectSuggestion<KvAutoCompleteItem>
  search: Search
  showClear: boolean
  clear: Clear
}

const styleConfig: Partial<StyleConfig> = {
  borderRadius: 0,
}

export const KvSearchInput: React.FC<Props> = ({
  userQuery,
  items,
  reload,
  selectSuggestion,
  search,
  showClear,
  clear,
}) => {
  const {theme} = useFela<Theme>()
  const renderItem: RenderItem<KvAutoCompleteItem> = (item) => (
    <span dangerouslySetInnerHTML={{__html: item.highlightedName}} />
  )
  const renderItemIcon: RenderItem<KvAutoCompleteItem> = (item) => {
    return (
      <Badge bg={theme.colors.contentTypeKv} small>
        KV
      </Badge>
    )
  }
  return (
    <SearchInput
      inputValue={userQuery}
      items={items}
      styleConfig={styleConfig}
      reload={reload}
      selectSuggestion={selectSuggestion}
      renderItem={renderItem}
      renderItemIcon={renderItemIcon}
      search={search}
      clear={clear}
      showClear={showClear}
    />
  )
}
