import {DigitalAction} from './digital.actions'
import {DigitalActionKeys, DigitalState} from './digital.types'

const initialState: DigitalState = {
  tocs: {},
  contentNodes: {},
  mappedContentNodes: {},
}

export const digitalReducer = (state: DigitalState = initialState, action: DigitalAction): DigitalState => {
  switch (action.type) {
    case DigitalActionKeys.LOAD_CONTENT: {
      const nextContentNodes = {...state.contentNodes}
      delete nextContentNodes[action.tocId]
      return {
        ...state,
        contentNodes: nextContentNodes,
      }
    }
    case DigitalActionKeys.LOAD_CONTENT_SUCCESS: {
      return {
        ...state,
        tocs: {
          ...state.tocs,
          [action.tocId]: action.toc,
        },
        contentNodes: {
          ...state.contentNodes,
          [action.tocId]: action.contentNode,
        },
      }
    }
    case DigitalActionKeys.LOAD_MAPPED_CONTENT: {
      const nextMappedContentNodes = {...state.mappedContentNodes}
      delete nextMappedContentNodes[action.tocId]
      return {
        ...state,
        mappedContentNodes: nextMappedContentNodes,
      }
    }
    case DigitalActionKeys.LOAD_MAPPED_CONTENT_SUCCESS: {
      return {
        ...state,
        mappedContentNodes: {
          ...state.mappedContentNodes,
          [action.tocId]: action.mappedContentNodes,
        },
      }
    }
  }
  return state
}
