import {authenticator} from '@oegbv/ui-shared'
import * as React from 'react'
import {arPathPlusInfo} from '../../store/ar.paths'
import {ArLink} from './ArLink'

export function ExclusivePlusLink() {
  const isAuthenticated = authenticator.isAuthenticated()
  if (isAuthenticated) {
    return null
  }

  return (
    <sup>
      <ArLink name="Exklusiv in Arbeitsrecht für Betriebsräte PLUS" link={arPathPlusInfo()} accent />{' '}
    </sup>
  )
}
