import {Box, Text} from '@indoqa/style-system'
import * as React from 'react'
import {useFela} from 'react-fela'
import {Link} from 'react-router-dom'
import {textColorLinksRules, undecoratedLinksRules} from '../../../app/mixins'
import {Theme} from '../../../app/theme'
import {lawPathReaderLawWithGroup} from '../../store/law.paths'

interface Props {
  id: string
  name: string
  groupId?: string
  shortName?: string
}

export const LawEntryPanel: React.FC<Props> = ({name, shortName, groupId, id}) => {
  const {theme} = useFela<Theme>()
  const linkTo = lawPathReaderLawWithGroup(id, name, shortName, groupId)
  return (
    <Box<Theme> key={id} my={2} style={[undecoratedLinksRules, textColorLinksRules(theme)]}>
      <Link to={linkTo}>
        {shortName && <Text bold>{shortName} - </Text>} {name}
      </Link>
    </Box>
  )
}
