import {Box, Col, ColRow, Flex, Grid} from '@indoqa/style-system'
import * as React from 'react'
import {useFela} from 'react-fela'
import {Theme} from '../../../app/theme'
import {ValidityDatePicker} from '../../../commons/components/data-picker/ValidityDatePicker'
import {PerformFiltering} from '../../pages/KvOverviewPage'
import {KvFilters} from '../../store/kv.actions'
import {kvFacetTranslations} from '../../store/kv.constants'
import {formatValidityDateApi, parseValidityDate} from '../../utils/validityDateUtils'
import {KvExtendedSearchLabel} from './KvExtendedSearchLabel'
import {KvExtendedSearchSelect} from './KvExtendedSearchSelect'

interface Props {
  filters: KvFilters
  performFiltering: PerformFiltering
}

export const KvExtendedSearch: React.FC<Props> = ({filters, performFiltering}) => {
  const {theme} = useFela<Theme>()
  return (
    <Box pt={3}>
      <Grid width={theme.layout.search.maxWidth} spacing={theme.spacing.space2}>
        <ColRow>
          <Col size={6}>
            <Flex nowrap>
              <KvExtendedSearchLabel title="Stichtag:" />
              <ValidityDatePicker
                inverted={false}
                date={parseValidityDate(filters.validOn)}
                onChange={(date) => {
                  const nextFilters: KvFilters = {
                    ...filters,
                    validOn: formatValidityDateApi(date),
                  }
                  performFiltering(nextFilters)
                }}
              />
            </Flex>
          </Col>
          <Col size={6}>
            <KvExtendedSearchLabel title="Ang./Arb.:" />
            <KvExtendedSearchSelect
              filters={filters}
              performFiltering={performFiltering}
              target="ang_arb"
              values={kvFacetTranslations.ang_arb}
              noSelectionLabel="Beide"
            />
          </Col>
        </ColRow>
        <ColRow>
          <Col size={6}>
            <KvExtendedSearchLabel title="Gewerkschaft:" />
            <KvExtendedSearchSelect
              filters={filters}
              performFiltering={performFiltering}
              target="union"
              values={kvFacetTranslations.union}
            />
          </Col>
          <Col size={6}>
            <KvExtendedSearchLabel title="Bundesland:" />
            <KvExtendedSearchSelect
              filters={filters}
              performFiltering={performFiltering}
              target="province"
              values={kvFacetTranslations.province}
            />
          </Col>
        </ColRow>
        <ColRow>
          <Col size={6}>
            <KvExtendedSearchLabel title="Letzte Änderung:" />
            <KvExtendedSearchSelect
              filters={filters}
              performFiltering={performFiltering}
              target="contentModified"
              values={kvFacetTranslations.contentModified}
            />
          </Col>
          <Col size={6}>
            <KvExtendedSearchLabel title="Branche:" />
            <KvExtendedSearchSelect
              filters={filters}
              performFiltering={performFiltering}
              target="sector"
              values={kvFacetTranslations.sector}
            />
          </Col>
        </ColRow>
      </Grid>
    </Box>
  )
}
