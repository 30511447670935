import {Box, Flex, Text} from '@indoqa/style-system'
import * as React from 'react'
import {useFela} from 'react-fela'
import {Theme} from '../../../app/theme'
import {ThemedIcon} from '../../../commons-shared/themed-icon/ThemedIcon'
import {useMobile} from '../../hooks/use-mobile/useMobile'
import {ReactComponent as CloseIcon} from '../../icons/close.svg'
import {ReactComponent as TocIcon} from '../../icons/table-of-content.svg'
import {ReaderLayoutContext, ReaderLayoutContextValue} from '../../layouts/reader-layout/ReaderLayoutContext'
import {SettingsPanel} from '../settings/SettingsPanel'
import {Breadcrumb, ReaderBreadcrumbsPanel} from './ReaderBreadcrumbsPanel'
import {ReaderSubHeaderPanel} from './ReaderSubHeaderPanel'

interface Props {
  breadcrumbs: Breadcrumb[]
  height: number
  tools: JSX.Element
  renderSourceInfo?: () => JSX.Element
}

export const ReaderToolbarPanel: React.FC<Props> = ({tools, height, breadcrumbs, renderSourceInfo}) => {
  const {navShown, toggleNav} = React.useContext<ReaderLayoutContextValue>(ReaderLayoutContext)
  const [showSettings, setShowSettings] = React.useState(false)
  const mobile = useMobile()
  const {theme} = useFela<Theme>()
  return (
    <ReaderSubHeaderPanel height={height}>
      {mobile && (
        <Text onClick={() => toggleNav()} pr={2}>
          <ThemedIcon size={14} color={theme.colors.iconInverted} hoverColor={theme.colors.iconInverted} verticalCenter>
            {navShown ? <CloseIcon /> : <TocIcon />}
          </ThemedIcon>
        </Text>
      )}

      <Flex grow={1} nowrap overflowHidden>
        <ReaderBreadcrumbsPanel breadcrumbs={breadcrumbs} />
      </Flex>

      <Box grow={0}>{tools}</Box>

      <SettingsPanel
        bottomPosition={false}
        disableIcon={navShown && mobile}
        onToggle={() => setShowSettings(!showSettings)}
        renderSourceInfo={renderSourceInfo}
      />
    </ReaderSubHeaderPanel>
  )
}
