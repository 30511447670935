import {Flex} from '@indoqa/style-system'
import * as React from 'react'
import {useFela} from 'react-fela'
import {searchResultWidthRules} from '../../../app/mixins'
import {Theme} from '../../../app/theme'
import {Button} from '../button/Button'

interface Props {
  totalCount: number
  alreadyLoadedCount: number
  loadMoreAction: () => void
}

export const SearchMore: React.FC<Props> = ({totalCount, alreadyLoadedCount, loadMoreAction}) => {
  const {theme} = useFela<Theme>()
  if (totalCount === 0 || totalCount < alreadyLoadedCount) {
    return null
  }
  return (
    <Flex center style={searchResultWidthRules(theme)}>
      <Button linkTo={loadMoreAction} text="Mehr Ergebnisse anzeigen" />
    </Flex>
  )
}
