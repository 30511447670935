import {oegbvBaseColors, OegbvBaseColors, OegbvBaseTheme, oegbvBaseTheme} from '@oegbv/ui-shared'

interface Layout {
  readonly centeredLayoutBoundaries: {
    readonly minWidth: number
    readonly maxWidth: number
  }
  readonly search: {
    readonly maxWidth: number
  }
  readonly searchArea: {
    readonly height: number
  }
  readonly searchInput: {
    readonly bg: string
  }
  readonly header: {
    readonly height: number
  }
  readonly reader: {
    readonly headerHeight: number
    readonly toolbarHeight: number
    readonly footerHeight: number
    readonly navbarWidth: number
    readonly mobileContentHeaderHeight: number
  }
}

interface Colors extends OegbvBaseColors {
  readonly contentTypeBookChapter: string
  readonly contentTypeWebcDocument: string
  readonly contentTypeChapter: string
  readonly contentTypeDefinition: string
  readonly contentTypeNews: string
  readonly contentTypeQuestion: string
  readonly contentTypeVideo: string
  readonly contentTypeKv: string
  readonly contentTypeLaw: string
}

export interface Theme extends OegbvBaseTheme {
  readonly layout: Layout
  readonly colors: Colors
}

const theme: Theme = {
  ...oegbvBaseTheme,
  colors: {
    ...oegbvBaseTheme.colors,
    contentTypeBookChapter: oegbvBaseColors.blue_800,
    contentTypeWebcDocument: oegbvBaseColors.red_500,
    contentTypeChapter: oegbvBaseColors.blue_800,
    contentTypeDefinition: oegbvBaseColors.cyan_600,
    contentTypeNews: oegbvBaseColors.blue_800,
    contentTypeQuestion: oegbvBaseColors.blue_500,
    contentTypeVideo: oegbvBaseColors.blue_800,
    contentTypeKv: oegbvBaseColors.green_500,
    contentTypeLaw: oegbvBaseColors.violet_600,
  },
  layout: {
    centeredLayoutBoundaries: {
      minWidth: 992,
      maxWidth: 1180,
    },
    search: {
      maxWidth: 650,
    },
    searchArea: {
      height: 140,
    },
    searchInput: {
      bg: oegbvBaseColors.white,
    },
    header: {
      height: 70,
    },
    reader: {
      headerHeight: 45,
      toolbarHeight: 40,
      footerHeight: 30,
      navbarWidth: 350,
      mobileContentHeaderHeight: 30,
    },
  },
}

export default theme
