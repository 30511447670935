import * as React from 'react'

interface Props {
  url: string
  render: (document: any) => React.ReactNode
  asText?: boolean
}

export const ResourceLoader: React.FC<Props> = ({url, render, asText}) => {
  const [resource, setResource] = React.useState<any | null>(null)
  React.useEffect(() => {
    fetch(url)
      .then((response) => (asText ? response.text() : response.json()))
      .then((result) => setResource(result))
  }, [url, asText])
  if (resource === null) {
    return null
  }
  return <>{render(resource)}</>
}
