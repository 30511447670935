import * as React from 'react'
import {ContentPanel} from '../../commons/components/content/ContentPanel'
import {ResourceLoader} from './ResourceLoader'

interface Props {
  url: string
}

export const UIE_ContentNodeSample: React.FC<Props> = ({url}) => (
  <ResourceLoader url={url} render={(contentNode) => <ContentPanel contentNode={contentNode} />} />
)
