import {Flex, VStack} from '@indoqa/style-system'
import {authenticator, useQueryParam} from '@oegbv/ui-shared'
import * as React from 'react'
import {useHistory} from 'react-router'
import {Button, Type} from '../../commons/components/button/Button'
import {FooterPanel} from '../../commons/components/footer/FooterPanel'
import {DigitalHelmet} from '../../commons/components/helmet/DigitalHelmet'
import {ContentLayout} from '../../commons/layouts/content-layout/ContentLayout'
import {DigitalHeaderPanel} from '../components/header/DigitalHeaderPanel'
import {LoginProviderPanel, Provider} from '../components/login-provider/LoginProviderPanel'
import {
  digitalAkLoginPage,
  digitalKvsLoginPage,
  digitalLoginPage,
  digitalPathOverview,
  digitalQueryParams,
} from '../store/digital.paths'

const getPathAndParameters = (url: string): string => {
  const urlObject = new URL(url)
  return urlObject.pathname + urlObject.search
}

export const DigitalLoginPage = () => {
  const history = useHistory()
  const redirectUri = useQueryParam(digitalQueryParams.redirect)
  // go to homepage if the user is already logged in
  if (authenticator.isAuthenticated()) {
    if (redirectUri && redirectUri.length > 0) {
      history.push(getPathAndParameters(redirectUri))
    } else {
      history.push(digitalPathOverview())
    }
  }

  const authenticateVerlag = () => {
    // create an artificial history entry
    history.push(digitalLoginPage())
    authenticator.login(undefined, redirectUri)
  }

  const authenticateAk = () => {
    history.push(digitalAkLoginPage(redirectUri))
  }

  const authenticateKvs = () => {
    history.push(digitalKvsLoginPage(redirectUri))
  }

  const authenticateOegb = () => {
    history.push(digitalLoginPage(redirectUri))
    authenticator.login('OEGB_OICD', redirectUri)
  }

  const header = <DigitalHeaderPanel noSearch noLogin />
  const footer = <FooterPanel />
  return (
    <ContentLayout header={header} footer={footer} noFooterMarginTop>
      <DigitalHelmet canonical={digitalLoginPage()} />
      <Flex px={3} pt={16} justifyContent="center" fullWidth direction="column">
        <VStack spacing={16}>
          <LoginProviderPanel provider={Provider.OEGB} onClick={() => authenticateOegb()} />
          <LoginProviderPanel provider={Provider.AK} onClick={() => authenticateAk()} />
          <LoginProviderPanel provider={Provider.OEGBV} onClick={() => authenticateVerlag()} />
        </VStack>
        <Flex pb={6} pt={12} justifyContent="center">
          <Button linkTo={() => authenticateKvs()} text="Login für KVS-Kund:innen" type={Type.REDUCED} />
        </Flex>
      </Flex>
    </ContentLayout>
  )
}
