import * as React from 'react'
import {useHistory} from 'react-router'
import {SearchMore} from '../../../commons/components/search-more/SearchMore'
import {ArFilters} from '../../store/ar.actions'
import {arPathSearch} from '../../store/ar.paths'

interface Props {
  userInput: string
  page?: number
  filters?: ArFilters
}

export const ArSearchMore = ({userInput, page, filters}: Props) => {
  const history = useHistory()
  const nextPage = (page || 0) + 1
  const loadMore = () => {
    history.push(arPathSearch(userInput, filters, nextPage))
  }
  return <SearchMore totalCount={100} alreadyLoadedCount={nextPage * 10} loadMoreAction={() => loadMore()} />
}
