import {Flex} from '@indoqa/style-system'
import {IStyle} from 'fela'
import * as React from 'react'
import {useEnableBodyClassReader} from '../../hooks/use-body-class-reader/useBodyClassReader'
import {ReaderLayoutContent} from './ReaderLayoutContent'
import {Dimensions, ReaderLayoutContext, ReaderLayoutContextValue} from './ReaderLayoutContext'
import {ReaderLayoutFooter} from './ReaderLayoutFooter'
import {ReaderLayoutHeader} from './ReaderLayoutHeader'
import {ReaderLayoutNavbar} from './ReaderLayoutNavbar'

interface Props {
  header: JSX.Element
  nav?: JSX.Element
  searchbar?: JSX.Element
  footer?: JSX.Element
  enableScrollTop?: boolean
  contentOverflowYHidden?: boolean
}

export const ReaderLayout: React.FC<Props> = ({
  header,
  nav,
  searchbar,
  footer,
  enableScrollTop,
  contentOverflowYHidden,
  children,
}) => {
  useEnableBodyClassReader()

  const [navShown, setNavShown] = React.useState(false)
  const [dimensions, setDimensions] = React.useState<Dimensions>({
    headerHeight: 0,
    footerHeight: 0,
    navWidth: 0,
    searchBarHeight: 0,
  })
  const [contentRef, setContentRef] = React.useState<React.RefObject<HTMLElement> | null>(null)

  // use callbacks with empty dependency arrays to avoid re-creating the
  // context manipulating functions (this would lead to executing the
  // effects that call them because their dependency arrays change)
  const toggleNav = React.useCallback(() => {
    setNavShown((currentNav) => !currentNav)
  }, [])
  const enableNav = React.useCallback(() => {
    setNavShown(true)
  }, [])
  const disableNav = React.useCallback(() => {
    setNavShown(false)
  }, [])
  const setHeaderHeight = React.useCallback((height: number) => {
    setDimensions((currentDimensions) => ({
      ...currentDimensions,
      headerHeight: height,
    }))
  }, [])
  const setFooterHeight = React.useCallback((height: number) => {
    setDimensions((currentDimensions) => ({
      ...currentDimensions,
      footerHeight: height,
    }))
  }, [])
  const setNavbarWidth = React.useCallback((width: number) => {
    setDimensions((currentDimensions) => ({
      ...currentDimensions,
      navWidth: width,
    }))
  }, [])
  const setSearchBarHeight = React.useCallback((height: number) => {
    setDimensions((currentDimensions) => ({
      ...currentDimensions,
      searchBarHeight: height,
    }))
  }, [])

  const readerLayoutContextValue: ReaderLayoutContextValue = {
    contentRef,
    dimensions,
    navShown,
    enableNav,
    disableNav,
    toggleNav,
    setHeaderHeight,
    setFooterHeight,
    setNavbarWidth,
    setSearchBarHeight,
    setContentRef,
    scrollTopEnabled: enableScrollTop || false,
  }

  const containerStyle: IStyle = {
    height: '100%',
    overflow: 'hidden',
    print: {
      display: 'block',
      overflow: 'visible',
      height: 'auto',
    },
  }
  const mainContainerStyle: IStyle = {
    height: `calc(100% - ${dimensions.headerHeight}px - ${dimensions.footerHeight}px - ${dimensions.searchBarHeight}px)`,
    print: {
      display: 'block',
      overflow: 'visible',
      height: 'auto',
    },
  }
  return (
    <ReaderLayoutContext.Provider value={readerLayoutContextValue}>
      <Flex fullWidth fullHeight direction="column" nowrap style={containerStyle}>
        <ReaderLayoutHeader>{header}</ReaderLayoutHeader>
        <Flex fullWidth grow={1} style={mainContainerStyle} nowrap>
          <ReaderLayoutNavbar>{nav}</ReaderLayoutNavbar>
          <ReaderLayoutContent overflowYHidden={contentOverflowYHidden}>{children}</ReaderLayoutContent>
        </Flex>
        {searchbar}
        <ReaderLayoutFooter>{footer}</ReaderLayoutFooter>
      </Flex>
    </ReaderLayoutContext.Provider>
  )
}
