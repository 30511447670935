import {BookStructureNode} from '../../book/store/book.types'

export const getBookName = (structure: BookStructureNode | null): string => {
  if (!structure) {
    return '-'
  }
  const {properties} = structure
  if (properties) {
    if (properties.quelle_kurz) {
      return properties.quelle_kurz
    }
    if (properties.quelle_lang) {
      return properties.quelle_lang
    }
  }
  if (structure.link.name) {
    return structure.link.name
  }
  return structure.link.name
}
