import {authenticator} from '@oegbv/ui-shared'
import * as React from 'react'
import {FooterPanel} from '../../commons/components/footer/FooterPanel'
import {DigitalHelmet} from '../../commons/components/helmet/DigitalHelmet'
import {useEnableBodyClassReader} from '../../commons/hooks/use-body-class-reader/useBodyClassReader'
import {ContentLayout} from '../../commons/layouts/content-layout/ContentLayout'
import {DigitalHeaderPanel} from '../../digital/components/header/DigitalHeaderPanel'
import {digitalLoginPage, digitalPathOverview} from '../../digital/store/digital.paths'
import {ErrorPanel} from '../components/ErrorPanel'

interface Props {
  url: string
}

const MSG_UNAUTHENTICATED = 'Sie haben nicht erforderlichen Berechtigungen, um auf die Seite zuzugreifen.'
const MSG_UNAUTHORIZED = 'Bitte melden Sie sich an!'

export const MissingCredentialsErrorPage = ({url}: Props) => {
  const isAuthenticated = authenticator.isAuthenticated()
  useEnableBodyClassReader()

  const header = <DigitalHeaderPanel noSearch noMenuEntryHighlight />
  const footer = <FooterPanel />

  const message = isAuthenticated ? MSG_UNAUTHENTICATED : MSG_UNAUTHENTICATED
  const errorPanel = isAuthenticated ? (
    <ErrorPanel message={MSG_UNAUTHENTICATED} buttonText="Zur Startseite" linkTo={digitalPathOverview()} />
  ) : (
    <ErrorPanel message={MSG_UNAUTHORIZED} buttonText="Login" linkTo={digitalLoginPage(encodeURIComponent(url))} />
  )

  return (
    <ContentLayout header={header} footer={footer}>
      <DigitalHelmet canonical={digitalPathOverview()} noIndex title={message} />
      {errorPanel}
    </ContentLayout>
  )
}
