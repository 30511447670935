export class Queue<T> {
  items: T[]

  constructor() {
    this.items = []
  }

  enqueue(items: T[]) {
    const copiedItems = [...items]
    this.items = [...copiedItems, ...this.items]
  }

  dequeue() {
    return this.items.splice(0, 1)[0]
  }

  get length(): number {
    return this.items.length
  }
}
