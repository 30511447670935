import {IRenderer} from 'fela'
import {History} from 'history'
import * as React from 'react'
import {RendererProvider} from 'react-fela'
import {Provider as Redux} from 'react-redux'
import {Router} from 'react-router'
import {Store} from 'redux'
import {RootState} from './types'

interface Props {
  store: Store<RootState>
  history: History
  renderer: IRenderer
  children: React.ReactNode
}

/**
 * The BaseApp sets up Redux and Fela. This needs to be a separate component so that hot-reloading works. Hence don't move it to index.tsx.
 */
const BaseApp: React.FC<Props> = ({store, history, renderer, children}: Props) => (
  <Redux store={store}>
    <RendererProvider renderer={renderer}>
      <Router history={history}>{children}</Router>
    </RendererProvider>
  </Redux>
)

// Note: this has to be a default export, named export break hot-reloading. Check thoroughly if you change it!
export default BaseApp
