import {Observable} from 'rxjs'
import {AjaxCreationMethod} from 'rxjs/internal/observable/dom/AjaxObservable'
import {map} from 'rxjs/operators'
import {ClientError} from './error.types'

export const postErrorService$ = (ajax: AjaxCreationMethod, clientError: ClientError): Observable<void> => {
  return ajax
    .post('/api/v1/error-logging', JSON.stringify(clientError), {'Content-Type': 'application/json'})
    .pipe(map((result) => result.response))
}
