import {getInitialState} from '@oegbv/ui-shared'
import * as React from 'react'
import history from '../../app/history'
import {digitalPathOverview} from '../../digital/store/digital.paths'
import {AlphaFeatures} from '../types/InitialState'

interface Props {
  alwaysShowFeature?: boolean
  redirectToHomepage?: boolean
}

export const FeatureToggle: React.FC<Props> = ({alwaysShowFeature, redirectToHomepage, children}) => {
  const isEnabled = alwaysShowFeature || getInitialState().alphaFeatures === AlphaFeatures.ALL
  React.useLayoutEffect(() => {
    if (!isEnabled && redirectToHomepage) {
      history.push(digitalPathOverview())
    }
  })
  if (isEnabled) {
    return <>{children}</>
  }
  return null
}
