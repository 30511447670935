import {Box} from '@indoqa/style-system'
import {AlignedContentPanel} from '../../commons/components/content/AlignedContentPanel'
import {ContentPanel} from '../../commons/components/content/ContentPanel'
import {Pool} from '../../commons/idqsa/idqsa'
import {ContentNode} from '../../commons/types/ContentNode'
import {ArBookMetaComponent} from './ArBookMetaComponent'

interface Props {
  bookId: string
  contentNode: ContentNode | null
  paraId: string
}

// Note: The outer box with a maxWidth of 1200 provides space for marginalia (see DigitalCSSRules.ts)
export const ArBookContentPanel = ({contentNode, paraId}: Props) => {
  return (
    <Box maxWidth={1240} fullWidth>
      <AlignedContentPanel navShown={true} maxWidth={1000}>
        <ContentPanel
          contentNode={contentNode}
          currentNodeId={{id: paraId}}
          enableLawLinks
          disablePrinting
          trackingInfo={{pool: Pool.ARFBR, resultItemId: paraId}}
        />
        <ArBookMetaComponent meta={contentNode?.meta} />
      </AlignedContentPanel>
    </Box>
  )
}
