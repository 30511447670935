import {Action} from 'redux'
import {SearchResult} from '../../commons/types/SearchResult'
import {StructureNode} from '../../commons/types/Structure'
import {ThrowError} from '../../error/store/error.actions'
import {ArActionKeys, QuestionAnswer} from './ar.types'

export type ArAction =
  | ArSearch
  | ArSearchSuccess
  | ArSearchClear
  | ArLoadQuestion
  | ArLoadQuestionSuccess
  | ArLoadStructure
  | ArLoadStructureSuccess
  | ArLoadOverviewVideos
  | ArLoadOverviewVideosSuccess
  | ThrowError

// ----------------------------------------------------------------------------

export interface ArFilters {
  skriptum_name?: string | null
  handbook_name?: string | null
  bookTitleOnly?: boolean
  videoOnly?: boolean
}

// ----------------------------------------------------------------------------

export interface ArSearch extends Action {
  type: ArActionKeys.SEARCH
  userQuery: string
  page?: number
  filters?: ArFilters
}

export const search = (userQuery: string, page?: number, filters?: ArFilters): ArSearch => ({
  type: ArActionKeys.SEARCH,
  userQuery,
  page,
  filters,
})

export interface ArSearchSuccess extends Action {
  type: ArActionKeys.SEARCH_SUCCESS
  searchResult: SearchResult
  replace?: boolean
}

export const searchSuccess = (searchResult: SearchResult, replace?: boolean): ArSearchSuccess => ({
  type: ArActionKeys.SEARCH_SUCCESS,
  searchResult,
  replace,
})

export interface ArSearchClear extends Action {
  type: ArActionKeys.SEARCH_CLEAR
  keepFacets?: boolean
}

export const clearSearch = (keepFacets?: boolean): ArSearchClear => ({
  type: ArActionKeys.SEARCH_CLEAR,
  keepFacets,
})

// ----------------------------------------------------------------------------

export interface ArLoadQuestion extends Action {
  type: ArActionKeys.LOAD_QUESTION
  id: string
}

export const loadQuestion = (id: string): ArLoadQuestion => ({
  type: ArActionKeys.LOAD_QUESTION,
  id,
})

export interface ArLoadQuestionSuccess extends Action {
  type: ArActionKeys.LOAD_QUESTION_SUCCESS
  content: QuestionAnswer
}

export const loadQuestionSuccess = (content: QuestionAnswer): ArLoadQuestionSuccess => ({
  type: ArActionKeys.LOAD_QUESTION_SUCCESS,
  content,
})

// ----------------------------------------------------------------------------

export interface ArLoadStructure extends Action {
  type: ArActionKeys.LOAD_STRUCTURE
  id: string
}

export const loadStructure = (id: string): ArLoadStructure => ({
  type: ArActionKeys.LOAD_STRUCTURE,
  id,
})

export interface ArLoadStructureSuccess extends Action {
  type: ArActionKeys.LOAD_STRUCTURE_SUCCESS
  structure: StructureNode
}

export const loadStructureSuccess = (structure: StructureNode): ArLoadStructureSuccess => ({
  type: ArActionKeys.LOAD_STRUCTURE_SUCCESS,
  structure,
})

// ----------------------------------------------------------------------------

export interface ArLoadOverviewVideos extends Action {
  type: ArActionKeys.LOAD_OVERVIEW_VIDEOS
}

export const loadOverviewVideos = (): ArLoadOverviewVideos => ({
  type: ArActionKeys.LOAD_OVERVIEW_VIDEOS,
})

export interface ArLoadOverviewVideosSuccess extends Action {
  type: ArActionKeys.LOAD_OVERVIEW_VIDEOS_SUCCESS
  videosSearchResult: SearchResult
}

export const loadOverviewVideosSuccess = (videosSearchResult: SearchResult): ArLoadOverviewVideosSuccess => ({
  type: ArActionKeys.LOAD_OVERVIEW_VIDEOS_SUCCESS,
  videosSearchResult,
})
