import {Action} from 'redux'
import {ErrorActionKeys, ErrorType} from './error.types'

export type ErrorAction = ThrowError | SetError

export interface SetError extends Action {
  type: ErrorActionKeys.SET_ERROR
  message: string
  errorType: ErrorType
  timestamp: number
}

export const setError = (message: string, errorType: ErrorType, timestamp: number): SetError => ({
  type: ErrorActionKeys.SET_ERROR,
  message,
  errorType,
  timestamp,
})

export interface ThrowError extends Action {
  type: ErrorActionKeys.THROW_ERROR
  error: any
  errorInfo?: React.ErrorInfo
}

export const throwError = (error: any, errorInfo?: React.ErrorInfo): ThrowError => ({
  type: ErrorActionKeys.THROW_ERROR,
  error,
  errorInfo,
})
