import {IStyle} from 'fela'
import * as React from 'react'
import {useFela} from 'react-fela'
import {Theme} from '../../../app/theme'
import {PerformFiltering} from '../../pages/KvOverviewPage'
import {KvFilters} from '../../store/kv.actions'

interface Props {
  filters: KvFilters
  performFiltering: PerformFiltering
  target: keyof KvFilters
  values: {[key: string]: string}
  noSelectionLabel?: string
}

export const KvExtendedSearchSelect: React.FC<Props> = ({
  filters,
  performFiltering,
  target,
  values,
  noSelectionLabel = 'Alle',
}) => {
  const {css, theme} = useFela<Theme>()
  const options = Object.keys(values).map((k) => {
    return (
      <option key={k} value={k}>
        {values[k]}
      </option>
    )
  })
  const style: IStyle = {
    width: 175,
    ...theme.fontStyles.brandLight,
    padding: theme.spacing.space1,
  }
  return (
    <select
      className={css(style)}
      value={filters[target] ?? ''}
      onChange={(e) => {
        const nextFilters: KvFilters = {
          ...filters,
          [target]: e.currentTarget.value,
        }
        performFiltering(nextFilters)
      }}
    >
      <option value="">{noSelectionLabel}</option>
      {options}
    </select>
  )
}
