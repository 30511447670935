import {Col, ColRow, Grid} from '@indoqa/style-system'
import * as React from 'react'
import {ResultDocument} from '../../types/SearchResult'
import {NewsPanelCard} from './NewsPanelCard'

interface Props {
  news: ResultDocument[]
}

export const NewsPanel: React.FC<Props> = ({news}) => {
  return (
    <Grid width="100%" spacing="2rem" maxWidth="100%">
      <ColRow>
        {news.map((newsEntry, i) => (
          <Col size={[12, 3]} key={i}>
            <NewsPanelCard newsEntry={newsEntry} />
          </Col>
        ))}
      </ColRow>
    </Grid>
  )
}
