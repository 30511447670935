import {Observable, of} from 'rxjs'
import {AjaxCreationMethod} from 'rxjs/internal/observable/dom/AjaxObservable'
import {ContentNode} from '../types/ContentNode'
import {TableOfContent} from '../types/TableOfContent'
import {getHeaders} from './service-utils'

export const loadWebcDocumentService$ = (ajax: AjaxCreationMethod, documentId?: string): Observable<ContentNode> => {
  return ajax.getJSON(`/api/v1/webc/documents/${documentId}`, getHeaders())
}

export const loadTableOfContentService$ = (
  ajax: AjaxCreationMethod,
  tocId: string,
  currentToC: TableOfContent | null
): Observable<TableOfContent> => {
  if (currentToC && currentToC.id === tocId) {
    return of(currentToC)
  }
  return ajax.getJSON(`/api/v1/webc/toc-info/${tocId}`, getHeaders())
}
