import {Box} from '@indoqa/style-system'
import {ReactComponent as AustriaMapSvg} from './austria.svg'

interface Props {
  provinces?: string[]
}

const FILL_COLOR = 'red'

export const AustrianMapPanel = ({provinces}: Props) => {
  const getFillColor = (province: string): string | undefined => {
    return provinces?.includes(province) ? FILL_COLOR : undefined
  }
  const style: any = {
    '& svg': {
      width: '100%',
      height: '100%',
    },
    '& svg #BL_V': {
      fill: getFillColor('V'),
    },
    '& svg #BL_T': {
      fill: getFillColor('T'),
    },
    '& svg #BL_S': {
      fill: getFillColor('S'),
    },
    '& svg #BL_K': {
      fill: getFillColor('K'),
    },
    '& svg #BL_ST': {
      fill: getFillColor('ST'),
    },
    '& svg #BL_OOE': {
      fill: getFillColor('OÖ'),
    },
    '& svg #BL_B': {
      fill: getFillColor('B'),
    },
    '& svg #BL_NOE': {
      fill: getFillColor('NÖ'),
    },
    '& svg #BL_W': {
      fill: getFillColor('W'),
    },

    '& svg:hover': {},
  }
  return (
    <Box style={style} fullWidth>
      <AustriaMapSvg />
    </Box>
  )
}
