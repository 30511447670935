import {DocumentInfo} from '../types/DocumentInfo'
import {BaseStructureNode} from '../types/Structure'
import {findFirstLeaf} from './findFirstDocumentInfo'

export type CreateDocumentName<T> = (node: T) => string

const isValidParaId = <T extends BaseStructureNode<T>>(
  structureNode: T,
  proposedParaId: string,
  hitsOnly: boolean
): boolean => {
  const {link, children, hits} = structureNode
  const follow = !hitsOnly ? true : hits !== undefined && hits > 0
  if (link.id === proposedParaId && follow) {
    return true
  }
  if (!children) {
    return false
  }
  for (let i = 0; i < children.length; i += 1) {
    const containsParaId = isValidParaId(children[i], proposedParaId, hitsOnly)
    if (containsParaId) {
      return true
    }
  }
  return false
}

export const findDocumentInfoById = <T extends BaseStructureNode<T>>(
  structureNode: T,
  proposedDocumentId: string,
  proposedParaId: string,
  searchMode: boolean,
  createDocumentName?: CreateDocumentName<T>,
  allowNoContent?: boolean
): DocumentInfo | null => {
  const {link, children} = structureNode

  // console.log('link.id', link.id)
  // console.log('proposedDocumentId', proposedDocumentId)
  // console.log('proposedParaId', proposedParaId)
  // console.log('searchMode', searchMode)
  // console.log('hits', hits)
  // console.log('allowNoContent', allowNoContent)
  // console.log('-----------------------')

  if (link && link.id === proposedDocumentId && (!structureNode.noContent || allowNoContent)) {
    const documentName = createDocumentName ? createDocumentName(structureNode) : structureNode.link.name

    // 1) use the requested paraId if it is valid
    // 2) in searchMode use the first leaf
    // 3) otherwise use the id of the current node
    const validParaId = isValidParaId(structureNode, proposedParaId, searchMode)
    // console.log('validParaId', validParaId)
    const paraId = validParaId
      ? proposedParaId
      : searchMode
      ? findFirstLeaf(structureNode, searchMode, () => false)
      : structureNode.link.id

    return {
      documentId: proposedDocumentId,
      documentName,
      paraId: paraId || undefined,
    }
  }

  if (!children) {
    return null
  }

  for (let i = 0; i < children.length; i += 1) {
    const documentInfo = findDocumentInfoById(
      children[i],
      proposedDocumentId,
      proposedParaId,
      searchMode,
      createDocumentName,
      allowNoContent
    )
    if (documentInfo) {
      return documentInfo
    }
  }

  return null
}
