import {Box} from '@indoqa/style-system'
import * as React from 'react'
import {clippedBackgroundImageRules} from '../../../app/mixins'

interface Props {
  backgroundImage: string
}

export const StagePanel: React.FC<Props> = ({backgroundImage, children}) => {
  return (
    <Box style={clippedBackgroundImageRules(backgroundImage)} height={387}>
      {children}
    </Box>
  )
}
