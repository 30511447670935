/*
 Note:
 This component is included directly into the sources of this project to simplify the
 build process.

 License:
 Copyright 2019, Indoqa Software Design und Beratung GmbH

 Permission is hereby granted, free of charge, to any person obtaining a copy
 of this software and associated documentation files (the "Software"), to deal
 in the Software without restriction, including without limitation the rights
 to use, copy, modify, merge, publish, distribute, sublicense, and/or sell
 copies of the Software, and to permit persons to whom the Software is
 furnished to do so, subject to the following conditions:
 The above copyright notice and this permission notice shall be included in all
 copies or substantial portions of the Software.

 THE SOFTWARE IS PROVIDED "AS IS", WITHOUT WARRANTY OF ANY KIND, EXPRESS OR
 IMPLIED, INCLUDING BUT NOT LIMITED TO THE WARRANTIES OF MERCHANTABILITY,
 FITNESS FOR A PARTICULAR PURPOSE AND NONINFRINGEMENT. IN NO EVENT SHALL
 THE AUTHORS OR COPYRIGHT HOLDERS BE LIABLE FOR ANY CLAIM, DAMAGES OR OTHER
 LIABILITY, WHETHER IN AN ACTION OF CONTRACT, TORT OR OTHERWISE, ARISING FROM,
 OUT OF OR IN CONNECTION WITH THE SOFTWARE OR THE USE OR OTHER DEALINGS IN THE SOFTWARE.
*/
import {Box} from '@indoqa/style-system'
import * as React from 'react'
import {ThemedIcon} from '../themed-icon/ThemedIcon'
import {ReactComponent as OpenIcon} from './icons/caret-bottom.svg'
import {ReactComponent as ClosedIcon} from './icons/caret-right.svg'
import {TreeNode} from './TreeNode'
import {
  RenderNode,
  ScrollTo,
  SelectedNode,
  StructureAccessors,
  StyleContainerNode,
  StyleContainerNodeProp,
  StyleNodeProps,
  TreeNodeStyle,
  TreeViewContextValue,
} from './treeView.types'
import {calcNodePaths} from './treeView.utils'
import {TreeViewContext} from './TreeViewContext'

interface Props<S> {
  structure: S
  structureAccessors: StructureAccessors<S>
  selectedNode?: SelectedNode
  style?: Partial<TreeNodeStyle>
  renderLeafNode: RenderNode<S>
  renderFolderNode: RenderNode<S>
  styleContainerNode?: StyleContainerNodeProp<S>
  reloadKey?: string
  openAll?: boolean
}

export const DEFAULT_STYLE: TreeNodeStyle = {
  indent: '1.1rem',
  outdent: '0.55rem',
  padding: '0.33rem',
  colorSelected: 'transparent',
  colorHover: 'transparent',
}

function mergedStyleContainerNode<S>(customFn?: StyleContainerNodeProp<S>): StyleContainerNode<S> {
  return (props: StyleNodeProps<S>) => {
    const customStyles = customFn ? customFn(props) : {}
    const defaultIcon = (
      <ThemedIcon size={10} verticalCenter>
        {props.open ? <OpenIcon /> : <ClosedIcon />}
      </ThemedIcon>
    )
    const defaultStyle = {}
    return Object.assign({icon: defaultIcon, style: defaultStyle}, customStyles)
  }
}

export const DEFAULT_SELECTED_NODE: SelectedNode = {
  id: '',
}

const GOLDEN_RATIO = 0.312

export function TreeView<S>({
  structureAccessors,
  selectedNode = DEFAULT_SELECTED_NODE,
  structure,
  style = {},
  reloadKey = '',
  renderFolderNode,
  renderLeafNode,
  openAll = false,
  styleContainerNode,
}: Props<S>) {
  const containerRef = React.useRef<HTMLDivElement>(null)
  const selectedNodePath = calcNodePaths(structure, selectedNode, structureAccessors, openAll)

  // console.log('TreeView: structure', structure)
  // console.log('TreeView: selectedNode', selectedNode)
  // console.log('TreeView: selectedNodePath', selectedNodePath)
  // console.log('TreeView: reloadKey', reloadKey)

  const scrollTo: ScrollTo = (top, elementHeight) => {
    if (!containerRef.current) {
      return
    }
    const parentElement = containerRef.current.parentElement
    if (!parentElement) {
      return
    }

    const containerRelativeTop = top - parentElement.offsetTop
    const marginTop = parentElement.clientHeight * GOLDEN_RATIO - elementHeight
    const nextTop = Math.max(0, containerRelativeTop - marginTop)
    const visibleFrom = parentElement.scrollTop
    const visibleTo = parentElement.clientHeight + parentElement.scrollTop

    const outsideContainer = containerRelativeTop < visibleFrom || containerRelativeTop + elementHeight > visibleTo

    // console.group('TreeView.scrollTo()')
    // console.log('top', top)
    // console.log('nextTop', nextTop)
    // console.log('------------------------')
    // console.log('visibleFrom', visibleFrom)
    // console.log('visibleTo', visibleTo)
    // console.log('outsideContainer', outsideContainer)
    // console.log('------------------------')
    // console.log('parentElement.scrollTo', parentElement.scrollTo)
    // console.groupEnd()

    // do not scroll, if the scroll target is visible
    // IE11 does not provide a scrollTo method on elements; if this is necessary
    // use https://github.com/idmadj/element-scroll-polyfill
    if (outsideContainer && parentElement.scrollTo) {
      parentElement.scrollTo({top: nextTop})
    }
  }

  const treeViewContextValue: TreeViewContextValue<S> = {
    selectedNode,
    scrollTo,
    selectedNodePath,
    scrolledToSelectedNode: false,
    style: Object.assign({}, DEFAULT_STYLE, style),
    renderFolderNode,
    renderLeafNode,
    styleContainerNode: mergedStyleContainerNode(styleContainerNode),
    structureAccessors,
    reloadKey: reloadKey,
  }

  // Keep the context value in the parent component state and only change it if necessary
  // see https://reactjs.org/docs/context.html#caveats
  const [treeViewState, setTreeViewState] = React.useState(treeViewContextValue)
  React.useLayoutEffect(() => {
    if (treeViewState.selectedNode.id !== selectedNode.id || treeViewState.reloadKey !== reloadKey) {
      const nextNodePath = calcNodePaths(structure, selectedNode, structureAccessors, openAll)
      setTreeViewState({
        ...treeViewState,
        selectedNode,
        selectedNodePath: nextNodePath,
        scrolledToSelectedNode: false,
        reloadKey,
      })
    }
  }, [selectedNode, treeViewState, structureAccessors, structure, reloadKey, openAll])

  return (
    <TreeViewContext.Provider value={treeViewState}>
      <Box innerRef={containerRef}>
        <TreeNode structure={structure} level={0} first last />
      </Box>
    </TreeViewContext.Provider>
  )
}
