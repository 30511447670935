import moment from 'moment'

export const formatDate = (date?: Date): string => {
  if (!date) {
    return ''
  }
  return moment(date).format('DD.MM.YYYY')
}

export const convertDate = (dateString: string): string => {
  try {
    return moment(dateString, 'YYYY-MM-DD').format('DD.MM.YYYY')
  } catch (e) {
    return dateString
  }
}
