import {Epic, ofType} from 'redux-observable'
import {interval, of} from 'rxjs'
import {catchError, delayWhen, map, switchMap} from 'rxjs/operators'
import {RootState, Services} from '../../app/types'
import {getClientInfo} from '../../commons/idqsa/idqsa'
import {loadBookStructureService$} from '../../commons/store/structure.service'
import {throwError} from '../../error/store/error.actions'
import {
  ArAction,
  ArLoadOverviewVideos,
  ArLoadQuestion,
  ArLoadStructure,
  ArSearch,
  clearSearch,
  loadOverviewVideosSuccess,
  loadQuestionSuccess,
  loadStructureSuccess,
  searchSuccess,
} from './ar.actions'
import {arPathSearch} from './ar.paths'
import {selectBookStructure, selectSearchResult} from './ar.selectors'
import {arSearchService$, loadOverviewVideosService$, loadQAService$, PAGE_SIZE} from './ar.services'
import {ArActionKeys} from './ar.types'

interface ArEpic extends Epic<ArAction, ArAction, RootState, Services> {}

const MAX_PAGE_COUNT = 10

const arSearch$: ArEpic = (action$, state$, {ajax, history}) => {
  return action$.pipe(
    ofType<ArSearch>(ArActionKeys.SEARCH),
    delayWhen(() => {
      const clientInfo = getClientInfo()
      return interval(clientInfo ? 0 : 500)
    }),
    switchMap((action) => {
      const {userQuery, filters, page = 0} = action
      const sanitizedPage = Math.min(page, MAX_PAGE_COUNT)
      history.replace(arPathSearch(userQuery, filters, sanitizedPage))
      if (userQuery === '') {
        return of(clearSearch())
      }

      const currentSearchResult = selectSearchResult(state$.value)
      let start = currentSearchResult?.documents?.length || 0
      if (start > 0) {
        start = (start / MAX_PAGE_COUNT) * MAX_PAGE_COUNT
      }
      const count = (page + 1) * PAGE_SIZE
      if (start === count && currentSearchResult) {
        return of(searchSuccess(currentSearchResult, true))
      }

      const previousSearchId = currentSearchResult?.tracking?.searchId

      return arSearchService$(ajax, userQuery, start, count, filters, previousSearchId).pipe(
        map((arSearchResult) => searchSuccess(arSearchResult, start === 0)),
        catchError((e) => of(throwError(e)))
      )
    })
  )
}

const loadQuestion$: ArEpic = (action$, state$, {ajax}) => {
  return action$.pipe(
    ofType<ArLoadQuestion>(ArActionKeys.LOAD_QUESTION),
    switchMap((action) => {
      return loadQAService$(ajax, action.id).pipe(
        map((qaDocument) => loadQuestionSuccess(qaDocument)),
        catchError((e) => of(throwError(e)))
      )
    })
  )
}

const loadBookStructure$: ArEpic = (action$, state$, {ajax}) => {
  return action$.pipe(
    ofType<ArLoadStructure>(ArActionKeys.LOAD_STRUCTURE),
    switchMap((action) => {
      const currentStructure = selectBookStructure(state$.value)
      return loadBookStructureService$(ajax, action.id, currentStructure).pipe(
        map((structure) => loadStructureSuccess(structure)),
        catchError((e) => of(throwError(e)))
      )
    })
  )
}

const loadOverviewVideos$: ArEpic = (action$, state$, {ajax}) => {
  return action$.pipe(
    ofType<ArLoadOverviewVideos>(ArActionKeys.LOAD_OVERVIEW_VIDEOS),
    switchMap(() => {
      return loadOverviewVideosService$(ajax).pipe(
        map((videosSearchResult) => loadOverviewVideosSuccess(videosSearchResult))
      )
    })
  )
}

export const arEpics = [arSearch$, loadQuestion$, loadBookStructure$, loadOverviewVideos$]
