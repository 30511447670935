import * as React from 'react'
import {HeaderPanel} from '../../../commons/components/header/HeaderPanel'
import {DigitalLogo} from '../../../digital/components/digital-logo/DigitalLogo'

interface Props {
  noSearch?: boolean
}

export const KvHeaderPanel: React.FC<Props> = ({noSearch}) => {
  return <HeaderPanel logo={<DigitalLogo />} noSearch={noSearch} />
}
