import * as React from 'react'
import {Link} from 'react-router-dom'
import {formatDate} from '../../../commons/utils/formatDate'
import {kvPathKvDocSetId} from '../../store/kv.paths'
import {KvChangesItem} from '../../store/kv.types'
import {angArbToString} from '../../store/kv.utils'

interface Props {
  item: KvChangesItem
  showLink: boolean
}

export const KvChangesItemPanel = ({item, showLink}: Props) => {
  const {link, lastModified} = item
  const angArb = angArbToString(item.angArb)
  const title = showLink ? (
    <Link to={kvPathKvDocSetId(link.id)}>{link.name + (angArb ? ` (${angArb})` : '')}</Link>
  ) : (
    <strong>{link.name}</strong>
  )
  return (
    <li key={link.id}>
      {title}
      <br />
      <small>{formatDate(lastModified)}</small>
    </li>
  )
}
