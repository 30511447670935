import {Text} from '@indoqa/style-system'
import {IStyle} from 'fela'
import * as React from 'react'
import {useFela} from 'react-fela'
import {Theme} from '../../../app/theme'

interface Props {
  bg: string
  color?: string
  small?: boolean
}

export const Badge: React.FC<Props> = ({children, bg, color, small}) => {
  const {theme} = useFela<Theme>()
  const style: IStyle = {
    top: -2,
    position: 'relative',
    textTransform: 'uppercase',
    fontWeight: 'bold',
  }
  return (
    <Text
      style={style}
      r={small ? 2 : 4}
      py={small ? 0 : 1}
      px={small ? 1 : 2}
      mr={small ? 1 : 2}
      bg={bg}
      fontSize="verySmall"
      color={color ? color : theme.colors.textInverted}
    >
      {children}
    </Text>
  )
}
