import {Box} from '@indoqa/style-system'
import {IStyle} from 'fela'
import * as React from 'react'
import {useFela} from 'react-fela'
import {Theme} from '../../../app/theme'
import {HIST_GEHALTSTABELLE_NAME} from '../../store/kv.constants'

interface Props {
  onChange: (value: string) => void
  selected?: string
  disabled?: boolean
}

export const QuickSearchSelect: React.FC<Props> = ({onChange, selected = '', disabled}) => {
  const {css, theme} = useFela<Theme>()
  const isChrome = navigator.appVersion.indexOf('Chrome/') !== -1
  const selectStyle: IStyle = {
    marginTop: -3,
    ...theme.fontStyles.brand,
    paddingTop: isChrome ? 3 : 0,
    paddingBottom: isChrome ? 3 : 0,
    outline: 'none',
  }
  const optionStyle: IStyle = {
    paddingTop: 3,
    paddingBottom: 3,
    ...theme.fontStyles.brandLight,
    outline: 'none',
  }
  const optionClassName = css(optionStyle)
  const creationOption = (label: string, value?: string) => {
    return (
      <option className={optionClassName} value={value}>
        {label}
      </option>
    )
  }
  const createDefaultOption = () => {
    const label = selected ? 'Schnellabfrage zurücksetzen' : 'Schnell abfragen'
    return (
      <>
        <option className={optionClassName} value="">
          {label}
        </option>
        {selected && <option value="">--------------------------------------</option>}
      </>
    )
  }
  return (
    <Box pt={1} pr={3} fontSize="small">
      <select
        className={css(selectStyle)}
        onChange={(e) => {
          onChange(e.target.value)
        }}
        value={selected}
        disabled={disabled}
      >
        {createDefaultOption()}
        {creationOption('Gehaltstabelle')}
        {creationOption(HIST_GEHALTSTABELLE_NAME)}
        {creationOption('Arbeitszeit')}
        {creationOption('Dienstverhinderung/Entgelt')}
        {creationOption('Dienstverhinderung/Freizeit')}
        {creationOption('Durchrechnungszeitraum')}
        {creationOption('Geltungsbeginn')}
        {creationOption('Kündigung')}
        {creationOption('Lehrlingseinkommen')}
        {creationOption('Lohn/Gehaltsordnung')}
        {creationOption('Mehrarbeit')}
        {creationOption('Probezeit')}
        {creationOption('Reisekosten')}
        {creationOption('Sonderzahlungen')}
        {creationOption('Überstunden')}
        {creationOption('Urlaubsgeld')}
        {creationOption('Verfall')}
        {creationOption('Verwendungsgruppen')}
        {creationOption('Weihnachtsremuneration')}
        {creationOption('Zulage/Zuschlag')}
      </select>
    </Box>
  )
}
