import * as React from 'react'
import {useFela} from 'react-fela'
import history from '../../../app/history'
import {Theme} from '../../../app/theme'
import {Breadcrumb} from '../../../commons/components/header/ReaderBreadcrumbsPanel'
import {ReaderHeaderPanel} from '../../../commons/components/header/ReaderHeaderPanel'
import {ReaderToolbarButton} from '../../../commons/components/header/ReaderToolbarButton'
import {ReaderToolbarPanel} from '../../../commons/components/header/ReaderToolbarPanel'
import {SearchButton} from '../../../commons/components/header/SearchButton'
import {ReaderLayoutContext, ReaderLayoutContextValue} from '../../../commons/layouts/reader-layout/ReaderLayoutContext'
import {DocumentInfo} from '../../../commons/types/DocumentInfo'
import {StructureNode} from '../../../commons/types/Structure'
import {cleanUrlPathPart} from '../../../commons/utils/urlUtils'
import {DigitalLogo} from '../../../digital/components/digital-logo/DigitalLogo'
import {lawProductName} from '../../store/law.constants'
import {
  lawPathGroupId,
  lawPathOverview,
  lawPathReaderLawComplete,
  lawPathReaderLawId,
  lawPathReaderLawWithGroup,
} from '../../store/law.paths'

interface Props {
  lawStructure: StructureNode | null
  lawDocumentInfo: DocumentInfo | null
  navShown: boolean
  groupId?: string | null
}

const hasGroupId = (groupId?: string | null) => {
  return groupId !== undefined && groupId !== null
}

export const LawReaderHeaderPanel: React.FC<Props> = ({
  lawStructure,
  lawDocumentInfo,
  navShown: navShownInitial,
  groupId,
}) => {
  const {theme} = useFela<Theme>()
  const {setHeaderHeight, navShown} = React.useContext<ReaderLayoutContextValue>(ReaderLayoutContext)
  React.useLayoutEffect(() => {
    const headerHeight = theme.layout.reader.headerHeight + theme.layout.reader.toolbarHeight
    setHeaderHeight(headerHeight)
  }, [setHeaderHeight, theme.layout.reader.headerHeight, theme.layout.reader.toolbarHeight])

  // logo
  const logo = <DigitalLogo small />

  // breadcrumbs
  const breadcrumbs: Breadcrumb[] = hasGroupId(groupId)
    ? [
        {
          name: 'Zurück zur Übersicht',
          linkTo: lawPathGroupId(groupId!),
        },
      ]
    : [{name: lawProductName, linkTo: lawPathOverview()}]
  if (lawStructure) {
    const linkTo = lawPathReaderLawWithGroup(
      lawStructure.link.id,
      lawStructure.link.name,
      lawStructure.subTitle,
      groupId
    )
    const name = lawStructure.subTitle ? `${lawStructure.subTitle} - ${lawStructure.link.name}` : lawStructure.link.name
    breadcrumbs.push({name, linkTo})
  }
  if (lawDocumentInfo && lawDocumentInfo.documentName) {
    breadcrumbs.push({name: lawDocumentInfo.documentName})
  }

  // toolbar
  const lawLink = lawStructure ? lawPathReaderLawId(lawStructure.link.id) : null
  const completeLawLink = lawStructure
    ? lawPathReaderLawComplete(lawStructure.link.id, cleanUrlPathPart(lawStructure.link.name), lawStructure.subTitle)
    : null
  const tools = navShownInitial ? (
    !navShown ? (
      <ReaderToolbarButton title="Gesamtdokument anzeigen" shortTitle="Gesamt" linkTo={completeLawLink} />
    ) : (
      <div />
    )
  ) : (
    <ReaderToolbarButton title="Einzeldokument anzeigen" shortTitle="Einzeln" linkTo={lawLink} />
  )

  const search = <SearchButton onClick={() => history.push(lawPathOverview())} />
  return (
    <>
      <ReaderHeaderPanel logo={logo} search={search} />
      <ReaderToolbarPanel tools={tools} breadcrumbs={breadcrumbs} height={theme.layout.reader.toolbarHeight} />
    </>
  )
}
