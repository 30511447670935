import * as React from 'react'
import {useDispatch} from 'react-redux'
import {useParams} from 'react-router'
import {FooterPanel} from '../../commons/components/footer/FooterPanel'
import {DigitalHelmet} from '../../commons/components/helmet/DigitalHelmet'
import {ContentLayout} from '../../commons/layouts/content-layout/ContentLayout'
import {STRUCTURE_IDS} from '../../commons/store/structure.service'
import {useError} from '../../error/hooks/useError'
import {ErrorPage} from '../../error/pages/ErrorPage'
import {DigitalHeaderPanel} from '../components/header/DigitalHeaderPanel'
import {ProductsOverview} from '../components/products-overview/ProductsOverview'
import {DigitalStage} from '../components/stage/DigitalStage'
import {digitalLoadContent} from '../store/digital.actions'
import {digitalPathOverview} from '../store/digital.paths'

interface LocationParams {
  contentNodeId: string
}

const DigitalContentPage: React.FC = () => {
  const dispatch = useDispatch()
  const {contentNodeId} = useParams<LocationParams>()

  React.useLayoutEffect(() => {
    dispatch(digitalLoadContent(STRUCTURE_IDS.digital, contentNodeId))
  }, [dispatch, contentNodeId])

  // const contentNodes = useSelector(selectDigitalContentNodes)
  // const contentNode = contentNodes[STRUCTURE_IDS.digital]

  // error handling
  const hasError = useError()
  if (hasError) {
    return <ErrorPage />
  }

  // const testSystem = getInitialState().it

  // const search = (
  //   <SearchAreaPanel backgroundImage={lawSearchAreaBackgroundImage}>
  //     <SearchInput search={() => undefined} userQuery={''} placeholder="Alle Inhalte durchsuchen" />
  //   </SearchAreaPanel>
  // )

  const header = <DigitalHeaderPanel noSearch />
  const footer = <FooterPanel />
  return (
    <ContentLayout header={header} footer={footer} noFooterMarginTop>
      <DigitalHelmet canonical={digitalPathOverview()} />
      {/*{!contentNodeId && !testSystem && <DigitalStage />}*/}
      {/*{contentNode && !testSystem && (*/}
      {/*  <MainContainerPanel>*/}
      {/*    <ContentPanel contentNode={contentNode} disablePrinting />*/}
      {/*  </MainContainerPanel>*/}
      {/*)}*/}
      {/*{testSystem && search}*/}
      {/*{!contentNodeId && testSystem && <ProductsOverview />}*/}
      {/*{!contentNodeId && testSystem && <InfoContainer />}*/}
      <DigitalStage />
      <ProductsOverview />
    </ContentLayout>
  )
}

export {DigitalContentPage}
