import * as React from 'react'
import {HeaderPanel} from '../../../commons/components/header/HeaderPanel'
import {DigitalLogo} from '../digital-logo/DigitalLogo'

interface Props {
  noSearch?: boolean
  noMenuEntryHighlight?: boolean
  noLogin?: boolean
}

export const DigitalHeaderPanel = (props: Props) => {
  return <HeaderPanel logo={<DigitalLogo />} {...props} />
}
